import { ReportData, ReportFields, useEditReportState, useMergeEditReportState } from 'context/editReport';
import styles from './styles.module.scss';
import { ToggleBoolean } from "components/Forms"
import { FormattedMessage } from 'react-intl';
import { ExclamationButtonIcon } from 'components/Icons';

export interface AttachReportToggleProps {
  reportField: ReportFields.AllowSupportCenterReportDetails | ReportFields.AllowCampusReportDetails;
}

const AttachReportLabel = ({ 
  reportField 
}: AttachReportToggleProps) => {
  if (reportField === ReportFields.AllowSupportCenterReportDetails) {
    return (
      <FormattedMessage
        id="accessSupport.attachReportDetails"
        defaultMessage="Do you want to attach your report details to send to a counsellor or support center?"
        values={{
          counsellor: (
            <span className={styles.highlightedText}>
              <FormattedMessage
                id="accessSupport.counsellor"
                defaultMessage="counsellor"
              />
            </span>
          ),
          supportCenter: (
            <span className={styles.highlightedText}>
              <FormattedMessage
                id="accessSupport.supportCenter"
                defaultMessage="support center"
              />
            </span>
          ),
        }}
      />
    );
  } else {
    return (
      <FormattedMessage
        id="accessCampusSupport.attachReportDetails"
        defaultMessage="Do you want to attach your report details to send to a campus Sexual Violence Prevention office?"
        values={{
          preventionOffice: (
            <span className={styles.highlightedText}>
              <FormattedMessage
                id="accessCampusSupport.attachReportDetails.preventionOffice"
                defaultMessage="a campus Sexual Violence Prevention office"
              />
            </span>
          ),
        }}
      />
    );
  }
}

export const AttachReportToggle = ({ 
  reportField 
}: AttachReportToggleProps) => {
  const mergeReportData = useMergeEditReportState();
  const reportData: ReportData = useEditReportState();

  const updateAllowAttachment = (
    allowAttachment: string | boolean,
    attachReportType: string
  ) => {
    mergeReportData({
      [attachReportType as keyof ReportData]: allowAttachment,
    });
  };

  return (
    <div className={styles.attachReport}>
      <ToggleBoolean
        label={<AttachReportLabel reportField={reportField}/>}
        value={reportData[reportField]}
        groupName={reportField}
        onClick={updateAllowAttachment}
        className={styles.consentField}
      />
      <div className={styles.exclamationReceiveSupport}>
        <ExclamationButtonIcon className={styles.iconSize} />
        <FormattedMessage id="accessSupport.attachReportDetails.stillReceiveSupport" />
      </div>
    </div>
  );
}
