import React, { ReactNode, FC } from "react";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import styles from "./styles.module.scss";

export enum ArrowDirection {
  Up = "up",
  Down  = "down",
  Left = "left",
  Right = "right"
}

export enum ButtonStyle {
  Primary = "primary",
  Secondary  = "secondary",
  None = "none",
  Full = "full",
  Underline = "underline",
  DarkPrimaryUnderline = 'darkPrimaryUnderline',
  Icon = 'icon',
  SkinnyPrimary = 'skinnyPrimary',
  SkinnySecondary = 'skinnySecondary',
  DarkPrimary = 'darkPrimary',
  DarkPrimaryInverted = 'darkPrimaryInverted',
  DarkSecondary = 'darkSecondary',
  DarkSecondaryInverted = 'darkSecondaryInverted',
  PathOption = 'pathOption',
  SurveyLink = 'surveyLink'
}

export enum ButtonType {
  Submit = "submit",
  Button  = "button",
  Reset = "reset"
}

export enum ArrowPosition {
  Left = "left",
  Right  = "right",
}

export interface IButtonProps {
  children?: ReactNode;
  style?: ButtonStyle;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  key?: string;
  type?: "submit" | "button" | "reset" | undefined;
  loading?: boolean;
}

interface LinkButtonProps extends IButtonProps {
  to: string;
};

interface ArrowLinkProps extends IButtonProps {
  to: string;
  arrowPosition?: ArrowPosition;
  arrowDirection?: ArrowDirection;
  onClick?: () => void;
};

interface ArrowButtonProps extends IButtonProps {
  clickAction: () => void;
  arrowPosition?: ArrowPosition;
  arrowDirection?: ArrowDirection;
};

export const LinkButton: FC<LinkButtonProps> = ({
  to,
  onClick,
  style= ButtonStyle.Primary,
  children,
  className,
}) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={classNames(styles.button, styles.linkButton, style && styles[style], className)}
    >
      {children}
    </Link>
  );
};

export const ArrowLink: FC<ArrowLinkProps> = ({
  to,
  style= ButtonStyle.Primary,
  arrowPosition= ArrowPosition.Left,
  arrowDirection= ArrowDirection.Left,
  children,
  className,
  onClick
}) => {
  return (
    <Link
      to={to}
      className={classNames(className, styles.button, styles[style])} 
      onClick={onClick}
    >
      <p className={styles.arrowLink}>
        { arrowPosition === "left" && <i className={classNames(styles.arrowClass, `pos-${arrowPosition}` && styles[`pos-${arrowPosition}`], arrowDirection)}></i> }
        {children}
        { arrowPosition === "right" && <i className={classNames(styles.arrowClass, `pos-${arrowPosition}` && styles[`pos-${arrowPosition}`], arrowDirection)}></i> }
      </p>
    </Link>
  );
};

export const ArrowButton: FC<ArrowButtonProps> = ({
  style = ButtonStyle.None,
  clickAction,
  arrowPosition = ArrowPosition.Left,
  arrowDirection = ArrowDirection.Left,
  children,
  className = "",
}) => {
  return (
      <button onClick={clickAction} className={classNames(styles.button, styles.arrowButton, styles[style], className)}>
        { (arrowPosition === ArrowPosition.Left) && <i className={classNames(styles.arrowClass, styles[`pos-${arrowPosition}`], styles[arrowDirection], styles[style])}></i> }
        {children}
        { (arrowPosition === ArrowPosition.Right) && <i className={classNames(styles.arrowClass, styles[`pos-${arrowPosition}`], styles[arrowDirection], styles[style])}></i> }
      </button >
  );
};


export const DoubleArrowButton: FC<LinkButtonProps> = ({
  to,
  onClick,
  style = ButtonStyle.Primary,
  children,
  className,
  loading = false,
  disabled = false,
  key = "",
  type="submit"
}) => {
  return (
    <Link
      className={classNames(styles.button, style && styles[style], className)}
      {...{
        to,
        onClick,
        disabled,
        type
      }}
    >
      {loading ? <div className={styles.loadingContainer}>
        <div className={styles.loading}>
        </div>
      </div> : (
        <div className={classNames(styles.doubleArrowContent, className)}>
          {children}
          <div className={styles.doubleArrowIconContainer}>
            <div className={styles.doubleArrowIconLeft}>
              <div className={classNames("i--icon-arrow-right-white")} />
            </div>    
            <div className={styles.doubleArrowIconRight}>
              <div className={classNames("i--icon-arrow-right-white")} />
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};

export const Button: FC<IButtonProps> = ({
  style = ButtonStyle.Primary,
  children,
  className,
  loading = false,
  onClick = () => {},
  disabled = false,
  key = "",
  type="submit"
}) => {
  if (style === 'none') {
    if (disabled) {
      onClick = () => {};
    }
    return (<div
      className={className}
      tabIndex={0}
      role="button"
      {...{
        onClick,
        onKeyPress: onClick,
        key,
        type
      }}
    >
      {children}
    </div>);
  }

  return (
    <button
      className={classNames(styles.button, style && styles[style], className && className)}
      {...{
        disabled,
        onClick,
        type
      }}
    >
      {loading ? <div className={styles.loadingContainer}>
        <div className={styles.loading}>
        </div>
      </div> : children}
    </button>
  );
};

