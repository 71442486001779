import React, { useState, useEffect, ReactNode } from "react";
import { useIntl } from 'react-intl';
import { Button, ButtonStyle } from 'components/Buttons';
import { LocationIcon, LocationIconGrey } from "components/Icons";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { useAddressPredictions } from 'lib/usePlaces';

import { useAppState } from 'context';
import classNames from "classnames";

import styles from './styles.module.scss';


interface LocationSuggestionsType {
  predictions: Array<any>;
  predictionsLoading: boolean;
  selected: boolean;
  address: string;
  onSelected: (prediction: any) => void;
}

const LocationSuggestions = ({ predictions, predictionsLoading, onSelected, selected, address } : LocationSuggestionsType) => {
  const intl = useIntl();

  if(selected || !predictions || !address ) {
    return null;
  }

  return (
    <div className={styles.locationSuggestions}>
      <ul className={styles.locationList}>
        {predictions && predictions.map((prediction: any) => (
          <li key={prediction.reference} className={styles.locationSuggestionListItem}>
            <div className={styles.greyLocationIcon}><LocationIconGrey /></div>
            <Button style={ButtonStyle.None} onClick={() => { onSelected(prediction); } }>{prediction.description}</Button>
          </li>
        ))}
        </ul>
      { predictionsLoading ? <p>{ intl.formatMessage({ id:'common.loading'})}</p> : null }
    </div>
  );
};

interface LocationInputType {
  address: string;
  onChange?: (event: string) => void;
  question?: ReactNode;
  className?: string;
  required?: boolean;
  errorMessage?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
}

const LocationInput = ({ address, placeholder, onChange, question, className, required, errorMessage, disabled } : LocationInputType) => {
  const intl = useIntl();
  return (
    <div>
      <div className={classNames(question ? styles.locationsContainerWithQuestion : styles.locationsContainerWithoutQuestion, className)}>
        <div className={styles.locationsLabelContainer}>
          {question && <label>{question}</label>}
          {required && <span className={styles.required}>*</span>}
        </div>
        <div className={classNames(styles.inputContainer)}>
          <TextField type={TextFieldTypes.Text}
                    name="locationInput" 
                    onChange={onChange}
                    value={address}
                    placeholder={placeholder || intl.formatMessage({id: "report.address"})}
                    className={classNames(errorMessage && styles.errorField, styles.locationInput)} 
                    disabled={disabled} />
          <LocationIcon className={styles.locationIcon}/>
        </div>
      </div>
      {errorMessage && ( 
        <div>
          <p className={styles.errorMessage}>{errorMessage}</p>
        </div> 
      )}
    </div>

  );
}


interface LocationProps {
  value?: string;
  onChange?: (value: string) => void;
  question?: ReactNode;
  className?: string;
  required?: boolean;
  errorMessage?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
}

export const Location = ({value, onChange, question, className, required, errorMessage, placeholder, disabled}: LocationProps) => {
  const [address, setAddress] = useState(value ? value : '');
  const [usingDefault, setUsingDefault] = useState(value ? true : false);
  const [selectedPrediction, setSelectedPrediction] = useState<any>(null);

  useEffect(() => {
    setAddress(value || '');
  }, [value]);

  useEffect(() => {
    onChange && typeof address !== "undefined" && address !== '' && onChange(address);
  }, [address]);

  const onLocationChange = (newAddress: string) => {
    setUsingDefault(false);
    setSelectedPrediction(null);
    setAddress(newAddress);
  };

  const onSelected = (prediction: any) => {
    setSelectedPrediction(prediction);
    setAddress(prediction.description);
  };

  const appState = useAppState();
  const [predictions, predictionsLoading] = useAddressPredictions(address, appState.googleMapsLoaded ? true : false);

  return (<>
    <LocationInput {...{ address, onChange: onLocationChange, question, className, required, errorMessage, placeholder, disabled }} />
    <LocationSuggestions {...{ predictions, predictionsLoading: (predictionsLoading ? true : false), onSelected, selected: selectedPrediction !== null || usingDefault, address }} />
  </>);
}
