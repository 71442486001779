import * as base from "./lib/baseActions";
import { FeelingScale } from "pages/Feedback/ExperienceScale";

export interface FeedbackType {
  experience: FeelingScale | undefined;
  helpfulness: number;
  recommended: number;
  howToImprove: string | undefined;
}

const getFeedback = async () => {

  const getFeedbackResult = await base.get(`feedback`);
  
  return getFeedbackResult;
};

const createFeedback = async (data: FeedbackType) => {

  const createFeedbackResult = await base.post(
    `feedback`,
    data
  );

  return createFeedbackResult;
};

export {
  getFeedback,
  createFeedback
};
