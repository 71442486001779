import { useEffect, useState, useRef } from 'react';

// modifie from https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
const useScript = (url: string) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const script = useRef<HTMLScriptElement | undefined>(undefined);

  useEffect(() => {

    if(url) {
      script.current = document.createElement('script');
  
      script.current.src = url;
      script.current.async = true;
      script.current.onload = function() { // Other browsers
        setScriptLoaded(true);
      };
  
      document.body.appendChild(script.current);
    }

    return () => {
      if(script.current) {
        document.body.removeChild(script.current);
      }
    }
  }, [url]);


  // allow access to whether it's loaded, and the script ref.
  return [scriptLoaded, script];
};

export default useScript;
