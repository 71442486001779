import styles from "./styles.module.scss";
import { BaseModal } from "../BaseModal";
import { WriteStoryImage } from "components/Images";
import { FormattedMessage } from "react-intl";
import { Button, ButtonStyle, LinkButton } from "components/Buttons";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePaths } from "App/routing";

interface SurveyRequestModalProps {
  surveyRequestModalIsOpen: boolean;
  setSurveyRequestModalIsOpen: (value: boolean) => void;
  ModalText?: () => JSX.Element;
}

const DefaultModalText = () => {
  return (
    <FormattedMessage 
      id="surveyRequestModal.body.default" 
      defaultMessage="Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?"
    />
  );
};

export const SurveyRequestModal = ({ 
  surveyRequestModalIsOpen,
  setSurveyRequestModalIsOpen,
  ModalText = DefaultModalText,
}: SurveyRequestModalProps) => {
  
  const history = useHistory();
  const location = useLocation();

  const onCloseModal = () => {
    setSurveyRequestModalIsOpen(false);
    if (location.pathname !== RoutePaths.index) {
      history.push(RoutePaths.index);
    }
  }

  return (
    <BaseModal
      isOpen={surveyRequestModalIsOpen}
      onClose={onCloseModal}
      className={styles.surveyRequestModal}
      icon={<WriteStoryImage className={styles.writeStoryImage}/>}
    >
      <div className={styles.body}>
        <p className={styles.textContainer}>
          <ModalText />
        </p>
        <LinkButton 
          className={styles.yesButton} 
          to={RoutePaths.survey}>
          <FormattedMessage id="surveyRequestModal.button.yes" defaultMessage="Yes, I would like to"/>
        </LinkButton>

        <Button 
          onClick={onCloseModal} 
          style={ButtonStyle.Underline}
          className={styles.noButton}>
          <FormattedMessage id="surveyRequestModal.button.no" defaultMessage="No, thank you"/>
        </Button>
      </div>
    </BaseModal>
  );
}