import React from "react";
import { useIntl } from 'react-intl';
import { ReportSections } from "../../../Report";


interface SectionHeaderProps {
  sectionKey: string;
}

export const SectionHeader = ( { sectionKey }: SectionHeaderProps ) => {

  const intl = useIntl();

  const sectionTranslated = sectionKey && ( sectionKey === ReportSections.SensoryExperience ) 
                              ? intl.formatMessage({ id: "sensoryInformation.title", defaultMessage: "Sensory Information" })
                              : intl.formatMessage({ id: `report.${sectionKey}.title` });

  return (
    <>
    {!sectionTranslated.includes("reportBreak") && // filter out break sections
      ( <h1>{sectionTranslated}</h1> )}
    </>
  ); 
}
