import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { PictureOptionsType } from '../';
import { optionKeyPrefix } from 'actions/questionActions';

export const pictureOption = (pictureOptionKey: string) => pictureOptionKey?.split(optionKeyPrefix)[1];

interface PictureOptionType {
  pictureOptionKey: string;
  pictureOptionsType: PictureOptionsType;
  groupName: string;
  optionGroupKey: string;
  onClick: (c: any) => void;
  text?: string;
  checked?: boolean;
  disabled?: boolean;
}

export const PictureOption: FC<PictureOptionType> = ({
  pictureOptionKey, 
  pictureOptionsType,
  groupName, 
  optionGroupKey,
  checked = false, 
  text, 
  onClick,
  disabled,
}) => {
  const pictureClassName = `i--icon-${pictureOptionsType}-${pictureOption(pictureOptionKey)}`;
  if (disabled) {
    onClick = () => {};
  }
  // we handle click using container
  return (
    <div className={classNames(styles.pictureChoiceContainer)} onClick={() => { onClick(pictureOptionKey); }} >  

      <input className={classNames(styles.radio)} readOnly type="radio" checked={checked} id={optionGroupKey} name={groupName} value={pictureOptionKey} disabled={disabled} />

      <label className={classNames(styles.pictureStyling, pictureClassName)} htmlFor={optionGroupKey}/>

      {text && (<label className={styles.pictureLabel}>{text}</label>)}
        
    </div>
  );
};
