import { Button, ButtonStyle } from "components/Buttons";
import React from "react";
import { FormattedMessage } from 'react-intl';
import { GenericHeaderBodyConstructorModal } from "../GenericHeaderBodyConstructorModal";
import styles from './styles.module.scss';

interface YoungerThanSixteenInfoModalProps {
  youngerThanSixteenInfoModalIsOpen: boolean;
  setYoungerThanSixteenInfoModalIsOpen: (value: boolean) => void;
}

const youngerThanSixteenInfoModalContent = [
  {
    body: (
      <FormattedMessage id="youngerThanSixteenInfo.supportCenter" 
        values={{ 
          obligation: <span className={styles.highlightedText}><FormattedMessage id="youngerThanSixteenInfo.supportCenter.obligation" defaultMessage="Support Center Workers have an obligation and a duty"/></span> 
        }} 
        defaultMessage="Support Center Workers have an obligation and a duty to report to the Police if you are under 16. They can only report if you provide identifying information; if you do not provide this information they will not make a report."/>
    )
  },
  {
    body: (
      <FormattedMessage id="youngerThanSixteenInfo.police" 
        values={{ 
          obligation: <span className={styles.highlightedText}><FormattedMessage id="youngerThanSixteenInfo.police.obligation" defaultMessage="Police have a legal duty and responsibility"/></span> 
        }} 
        defaultMessage="Police have a legal duty and responsibility to investigate. They can only investigate if you provide identifying information; if you do not provide this information they will be unable to investigate."/>
    )
  }
];

export const YoungerThanSixteenInfoModal = ({ youngerThanSixteenInfoModalIsOpen, setYoungerThanSixteenInfoModalIsOpen } : YoungerThanSixteenInfoModalProps) => {
  return (
    <GenericHeaderBodyConstructorModal key="youngerThanSixteenInfoModal"
      genericHeaderBodyConstructorModalIsOpen={youngerThanSixteenInfoModalIsOpen}
      setGenericHeaderBodyConstructorModalIsOpen={setYoungerThanSixteenInfoModalIsOpen}
      className={styles.youngerThanSixteenInfoModal}
      title={<FormattedMessage id="youngerThanSixteenInfo.title" defaultMessage="Providing the help and safety you deserve"/>}
      headerBodyArray={youngerThanSixteenInfoModalContent}
      children={(
        <Button onClick={() => { setYoungerThanSixteenInfoModalIsOpen(false); }} 
                style={ButtonStyle.Secondary}
                className={styles.iUnderstandButton}>
          <FormattedMessage id="button.iUnderstand" defaultMessage="I understand"/>
        </Button>
      )}/>
  );
}
