import { ThoughtBubbleIcon } from "components/Icons";
import classNames from "classnames";
import styles from './styles.module.scss';

interface ThoughtBlurbProps {
  content?: JSX.Element;
  className?: string;
  color?: ThoughtBlurbColor
}

export enum ThoughtBlurbColor {
  Blue = "blue",
  Pink = "pink",
}

export const ThoughtBlurb = ({
  className, 
  content,
  color = ThoughtBlurbColor.Blue,
}: ThoughtBlurbProps ) => {
  return (
    <div className={classNames(styles.thoughtBlurbContainer, className, styles[color])}>
      <ThoughtBubbleIcon className={styles.thoughtBubbleIcon}/>
      {content}
    </div>
  );
}
