import { ReactNode, useMemo, useRef } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { AnswersInfo } from "context/editAnswers";
import { PrintReportPage } from "./ReportPage";
import { QuestionType, fetchPrintQuestionSections, SectionTypes } from "actions/questionActions";
import { SectionType } from "actions/questionActions";
import PrintHeader from "./PrintHeader";
import styles from './styles.module.scss';
import PrintFooter from "./PrintFooter";

import { forwardRef } from "react";
import { useReactToPrint } from "react-to-print";

export enum ReportSections {
  Incident = "s-incident",
  Offender = "s-offender",
  Break = "s-break",
  Experience = "s-experience",
  OtherDetails = "s-details",
  SensoryExperience = "s-sensory",
  Impact = "s-impact"
}

// this list is hardly complete (there are 58 question keys) - can expand later if necessary
export enum ReportSectionQuestions {
  TellYourStory =  `q-tellYourStory`,
  FileAttachments = "q-fileAttachments",
  OtherAttachments = "q-otherAttachments",
  OffenderKnow = "q-offenderKnow",
  When = "q-when",
  Where = "q-whereAddress",
  PriorRelationship = "q-priorRelationship"
}

export enum ReportSectionAnswers {
  TellYourStory =  `q-tellYourStory`,
  FileAttachments = "q-fileAttachments",
  OtherAttachments = "q-otherAttachments",
  OffenderKnow = "q-offenderKnow",
  When = "q-when",
  Where = "q-whereAddress",
  PriorRelationship = "q-priorRelationship"
}

export enum ReportSectionAnswersPriorRelationshipOptions {
  RomanticPartners = "o-romanticPartners",
  FamilyMember = "o-familyMember"
}


export interface ReportFormErrorType {
  answerKey: string;
  question: QuestionType;
  message?: ReactNode;
}

export const validateQuestions = (reportSection: SectionType | null, reportAnswers: AnswersInfo) => {
  let errors: Map<string, ReportFormErrorType> = new Map();

  const answersMap = reportAnswers.answers;

  if(reportSection) {
    errors = reportSection.questions.reduce((result: Map<string, ReportFormErrorType>, question) => {
      const fullQuestionKey = `${reportSection.key}:${question.key}`;
      const answerForQuestion = answersMap.get(fullQuestionKey);
      
      if(question.required && (!answerForQuestion || !(answerForQuestion.value && answerForQuestion.value.answer))) {
        result.set(
          fullQuestionKey,
          {
            answerKey: fullQuestionKey,
            question: question,
            message: <FormattedMessage id="report.form.error.required" defaultMessage="This question is required" />
          }
        );
      }
      
      return result;
    }, errors);
  }

  return {
    errors,
    valid: errors.size === 0, 
  };
}

export const useReportPrintComponent = () => {
  const printRef = useRef<React.ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => { return printRef.current },
    documentTitle: "",
    removeAfterPrint: true,
  });

  return {
    printRef,
    handlePrint
  };
}
export const ReportPrintComponent = forwardRef((props, ref: any) => (
  <div className={styles.reportPrintRefWrapper}>
    <div ref={ref} ><ReportPrint /></div>
  </div>
));

interface ReducerResult {
  sections: SectionType[];
  numPages: number;
}

interface ReportPrintProps {
  ref?: any;
}

export const ReportPrint = () => {

  const questionSections: SectionType[] = fetchPrintQuestionSections();

  const updatedQuestionSections = useMemo(() => {
    return questionSections.reduce((res: ReducerResult, qs: SectionType) => {
      if(qs.type === SectionTypes.PageBreak) { 
        return {
          sections: [...res.sections, {...qs, pageNum: res.numPages}],
          numPages: res.numPages+1
        }
      } else {
        return {
          sections: [
            ...res.sections, { ...qs, pageNum: res.numPages }
          ],
          numPages: res.numPages
        };
      }
    },{ sections: [] as SectionType[], numPages: 1});
  }, [questionSections]);

  return (
    <div className={classNames(styles.printReportPage)}>
        <PrintHeader />
        <div className={styles.sections}>

          {
            updatedQuestionSections.sections.map((q) => {
              if(q.type === SectionTypes.PageBreak) {
                return <div className={styles.pageBreak}><div className={styles.pageNum}></div></div>
              }
              return (<PrintReportPage currentReportSection={q} />)
            })
          }

        </div>
      <PrintFooter />
    </div>
  );
}
