import React from "react";
import { QuestionType, SectionType } from "actions/questionActions";
import { useEditAnswersState, AnswerValue, Answer } from "context/editAnswers";
import styles from './styles.module.scss';
import _ from "lodash";
import { isEmptyArray } from "formik";
import { SectionHeader } from "./SectionHeader";
import { SectionQuestionAnswer } from "./SectionQuestionAnswer";
import classNames from "classnames";
import { sortByOrder } from "lib/commonFunctions";
import questions from "data/questions.json";

 
export const SectionQuestionAnswers = () => {

  const answerData = useEditAnswersState();

  const filteredQuestionAnswerArray = ( section: SectionType ) => {
    const questions = section.questions.sort(sortByOrder);
  
    const questionAnswerArray = questions?.map((question: QuestionType) => {
      const answerKey: string = `${section.key}:${question.key}`;
      const answerObject: Answer | undefined = answerData?.answers?.get(answerKey);
      const answerObjectValue: AnswerValue  | undefined = answerObject?.value;

      let hasAnswer = false;

      if (answerObjectValue) {
        const isArray = Array.isArray(answerObjectValue.answer)
        hasAnswer = answerObjectValue && !!(isArray ? answerObjectValue.answer.length : answerObjectValue.answer)
      }

      return hasAnswer && 
        ( <SectionQuestionAnswer sectionKey={section.key} questionKey={question.key} answerObjectValue={answerObjectValue} key={answerKey}/> );
    });

    return ( _.filter(questionAnswerArray, (entry) => { return entry; }) ); //removes answerless entries
  }

  return (
    <div className={styles.sectionQuestionAnswers}>
      {questions?.sections.map((section) => {
        const sectionQuestionAnswers = filteredQuestionAnswerArray(section);

        return !isEmptyArray(sectionQuestionAnswers) && (  //removes empty sections
          <div className={classNames(styles.section, styles[section.key])} key={section.key}>
            <SectionHeader sectionKey={section.key}/>
            <div>
              {sectionQuestionAnswers}
            </div>
          </div>
        );  
      })}
    </div>
);
}
