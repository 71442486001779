import React, { useEffect, useState } from "react";
import { questionKeyPrefix, QuestionTypes } from "actions/questionActions";
import { useEditAnswersState } from "context/editAnswers";
import { ReportSections } from "pages/Report";
import classNames from "classnames";
import styles from './styles.module.scss';
import { pictureOption } from "components/Forms/PictureOptions/PictureOption";

interface OffenderFaceProps {
  className?: string;
}

export const OffenderFace = ({ className }: OffenderFaceProps) => {
  const answerData = useEditAnswersState();

  const [offenderComplexion, setOffenderComplexion] = useState<string>();
  const [offenderHairColour, setOffenderHairColour] = useState<string>();

  useEffect(() => {
    const selectedComplexion = answerData?.answers?.get(`${ReportSections.Offender}:${questionKeyPrefix}${QuestionTypes.Complexion}`)?.value?.answer;
    setOffenderComplexion(pictureOption(selectedComplexion));
    const selectedHairColour = answerData?.answers?.get(`${ReportSections.Offender}:${questionKeyPrefix}${QuestionTypes.HairColour}`)?.value?.answer;
    setOffenderHairColour(pictureOption(selectedHairColour));
  }, [answerData]);

  return (
    <div className={classNames(styles.offenderFace, className)}>
      <div className={classNames(styles.hair, styles.pictureStyling, `i--icon-offender-hairColour-${offenderHairColour}`)}/>
      <div className={classNames(styles.face, styles.pictureStyling, `i--icon-offender-complexion-${offenderComplexion}`)}/>
    </div>
  );
}
