import React from "react";
import styles from './styles.module.scss';
import { SensoryIcon } from "components/Icons";


interface SensoryIconCardProps {
  iconType: string;
  sensoryTitle: string;
  answer: string;
}

export const SensoryIconCard = ( {iconType, sensoryTitle, answer}: SensoryIconCardProps ) => {
  return (
    <div className={styles.sensoryIconCard}>
      <SensoryIcon sense={iconType} className={styles.sensoryIconSize}/>
      <div className={styles.sensoryIconCardContent}>
        <h2>{sensoryTitle}</h2>
        <p>{answer}</p>
      </div>
    </div>
  );
}
