/// JWT Call helper for base Actions
import { jwtData } from "lib/config";
import axios, { AxiosResponse } from "axios";

const config = {
  refreshUrl: (jwtData && jwtData.refreshUrl) ? jwtData.refreshUrl : '/users/refreshJwt',
  authToken: (jwtData && jwtData.authToken) ? jwtData.authToken : 'authToken',
  refreshToken: (jwtData && jwtData.refreshToken) ? jwtData.refreshToken : 'refreshToken'
};

interface axiosAuthError {
  status: number;
  data?: { 
    status?: string;
    data?: { 
      message?: string;
    }
  }
};

interface refreshData {
    token?: string;
}

/// Wrap function - execute wrappedFunction, and if jwt expired, refresh jwt and then try to execute wrappedFunction again
const wrapRefreshAuthTokenOnFail = (wrappedFunction: () => Promise<any>) => {
  return wrappedFunction()
    .then((result) => {
      // the API sometimes returns badly
      if(result && result.status === 200 && result.data && result.data.status && result.data.status === "jwt expired" ) {
        return Promise.reject(result);
      } else {
        return result;
      }
    })
    .catch((err) => {
      return refreshAuthToken(err)
        .then(() => {
          return wrappedFunction();
        });
    })
    .catch((err) => {
      return Promise.reject(cleanErrorObject(err));
    });
};


/// Refresh Auth Token
/// if there is refresh token and the err is jwt expired, refresh auth token
const refreshAuthToken = async (err : axiosAuthError) => {
  const jwtToken = localStorage.getItem(config.refreshToken);

  if(hasJWTExpired(err) && jwtToken) {
    const jwtBody = { refreshToken: jwtToken };

    try {
      const result = await axios.post<any, AxiosResponse<refreshData>>(config.refreshUrl, jwtBody);

      if(result && result.data && result.data.token) {
        localStorage.setItem(config.authToken, result.data.token);
        return result;      
      } else {
        throw new Error('Auth Token Empty');
      }
    } catch (err) {
      if(err && err.data &&  err.data.data && err.data.data.message === 'invalid refresh token') {
        // Invalid refresh token, remove jwt token & authToken
        localStorage.removeItem(config.refreshToken);
        localStorage.removeItem(config.authToken);
      }

      return Promise.reject(err);
    }
  } else {
    return Promise.reject(err);
  }
};

/// check if jwt has expired
const hasJWTExpired = (err : axiosAuthError ) => {
  return (err.status === 401 && err.data && err.data.data && err.data.data.message === "jwt expired") ||
    (err.status===200 && err.data && err.data.status && err.data.status === "jwt expired");
};

/// Clean a normal Error object from axios
const cleanErrorObject = (error : { data?: any, status: number, statusText?: string }) => {
  const errorObject = {
    data:error.data,
    status: error.status,
    statusText: error.statusText,
    message: getErrorMessage(error),
    original: error
  };

  return errorObject;
};

/// internal Helpers

/// Get the error message from an axios call
const getErrorMessage = (error: { data?: { msg?: string, message?: string }, statusText?: string }) => {
  if(error.data && error.data.msg) {
    return error.data.msg;
  } else if (error.data && error.data.message) {
    return error.data.message;
  } else {
    return error.statusText;
  }
};

export {
  wrapRefreshAuthTokenOnFail,
  refreshAuthToken,
  hasJWTExpired,
  cleanErrorObject 
};
