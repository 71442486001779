import { TalkPoliceImage, TalkSupportImage, UseVoiceImage } from "components/Images";
import React from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import styles from './styles.module.scss';


const contactLawEnfOrSuppCenModalContent = [
  {
    image: <TalkPoliceImage/>,
    header: (
      <FormattedMessage id="contactLawEnfOrSuppCen.lawEnf.header" 
        defaultMessage="Law Enforcement"/>
    ),
    body: {
      first: (
        <FormattedMessage id="contactLawEnfOrSuppCen.lawEnf.body.first" 
          values={{ lawEnfWillHaveAccess: <span className={styles.highlightedText}><FormattedMessage id="contactLawEnfOrSuppCen.lawEnf.body.first.lawEnfWillHaveAccess" defaultMessage="Law enforcement will have access"/></span> }}
          defaultMessage="This is considered as submitting a report to police. Law enforcement will have access to your report."/>
      ),
      second: (
        <FormattedMessage id="contactLawEnfOrSuppCen.lawEnf.body.second" 
          defaultMessage="Knowing how the experience has impacted you will be an important part of the case, if it should proceed to trial after the investigation."/>
      )
    }
  },
  {
    image: <TalkSupportImage/>,
    header: (
      <FormattedMessage id="contactLawEnfOrSuppCen.suppCen.header" 
        defaultMessage="Law Enforcement"/>
    ),
    body: {
      first: (
        <FormattedMessage id="contactLawEnfOrSuppCen.suppCen.body.first" 
          values={{ suppCenWillHaveAccess: <span className={styles.highlightedText}><FormattedMessage id="contactLawEnfOrSuppCen.suppCen.body.first.suppCenWillHaveAccess" defaultMessage="Support Center Workers will have access"/></span> }}
          defaultMessage="You will be directed to a partner website where you will be able to provide contact details so that a support worker can contact you to better understand the support you need or are looking for."/>
      ),
      second: (
        null
      )
    }
  },
  {
    image: <UseVoiceImage/>,
    header: (
      <FormattedMessage id="contactLawEnfOrSuppCen.campusCen.header" 
        defaultMessage="Law Enforcement"/>
    ),
    body: {
      first: (
        <FormattedMessage id="contactLawEnfOrSuppCen.campusCen.body.first" 
          values={{ ifYouChose: <span className={styles.highlightedText}><FormattedMessage id="contactLawEnfOrSuppCen.campusCen.body.first.ifYouChose" defaultMessage="If you choose to, you can file a complaint to the school."/></span> }}
          defaultMessage="Disclose online to the Sexual Violence Prevention Office (SVPO). This means that someone from the SVPO will connect with you to give you information about your options and on-campus services. {ifYouChose}"/>
      ),
      second: (
        null
      )
    }
  }
];

interface ContactLawEnfOrSuppCenModalProps {
  contactLawEnfOrSuppCenModalIsOpen: boolean;
  setContactLawEnfOrSuppCenModalIsOpen: (value: boolean) => void;
}

export const ContactLawEnfOrSuppCenModal = ({ 
  contactLawEnfOrSuppCenModalIsOpen, 
  setContactLawEnfOrSuppCenModalIsOpen 
} : ContactLawEnfOrSuppCenModalProps) => {
  
  const closeModal = () => {
    setContactLawEnfOrSuppCenModalIsOpen(false);
  }
  
  return (
    <BaseModal title={(
        <h5 className={styles.title}>
          <FormattedMessage id="report.meaningForYou" defaultMessage="What does this mean for you?"/>
        </h5>
      )}
      centerTitle={true}
      isOpen={contactLawEnfOrSuppCenModalIsOpen} 
      onClose={closeModal} 
      className={styles.contactLawEnfOrSuppCenModal}>

        <div className={styles.contactOptionContainer}>
          {contactLawEnfOrSuppCenModalContent.map((contactOption, index) => {
            return (
              <div className={styles.contactOption} key={index}>
                <div className={styles.contactImage}>{contactOption.image}</div>
                <h5>{contactOption.header}</h5>
                <div className={styles.body}>{contactOption.body.first}</div>
                <p className={styles.body}>{contactOption.body.second}</p>
              </div>
            );
          })}
        </div>

    </BaseModal>
  );
}
