import classNames from "classnames";
import { ExternalLinkIcon, ExternalLinkIconBlue, ExternalLinkIconPink, ExternalLinkIconWhite } from "components/Icons";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';

export enum LearnMoreLinkType {
  Home = "/",
  About = "/about",
  PrivacyPolicy = "/privacy-policy",
  Resources = "/resources-library",
  HowVestaJournalCanHelp = "/documenting-your-story-in-a-sexual-assault-or-rape-case/",
}

export enum LearnMoreColor {
  Grey = 'grey',
  Pink = 'pink',
  White = 'white',
  Blue = 'blue',
}

const vestaSitePrefix = "https://www.vestasit.com/";

export const learnMoreLink = (type: LearnMoreLinkType | undefined) => {
  return `${vestaSitePrefix}${type || LearnMoreLinkType.Home}`;
}

const getLinkIconComponent = (iconColor: LearnMoreColor) => {
  switch(iconColor) {
    case LearnMoreColor.Pink:
      return ExternalLinkIconPink;
    case LearnMoreColor.White:
      return ExternalLinkIconWhite;
    case LearnMoreColor.Blue:
      return ExternalLinkIconBlue;
    default:
      return ExternalLinkIcon;
  }
}

export interface LearnMoreLinkProps {
  learnMoreLinkType?: LearnMoreLinkType;
  customText?: ReactNode;
  className?: string;
  showExternalLinkIcon?: boolean;
  iconColor?: LearnMoreColor;
  textColor?: LearnMoreColor;
}

export const LearnMoreLink = ({ 
  learnMoreLinkType, 
  customText, 
  className,
  showExternalLinkIcon = true,
  iconColor = LearnMoreColor.Grey,
  textColor = LearnMoreColor.Pink,
}: LearnMoreLinkProps) => {
  
  const LinkIconComponent = getLinkIconComponent(iconColor);
  return (
    <a href={learnMoreLink(learnMoreLinkType)} 
      rel="noopener noreferrer" 
      target="_blank" 
      className={classNames(styles.learnMoreLink, className)}>
        <span className={classNames(styles.learnMoreText, styles[textColor])}>
          {customText ? customText : (<FormattedMessage id="report.learnMore" defaultMessage="Learn more"/>)}
        </span>
        {showExternalLinkIcon && <LinkIconComponent className={styles.externalLinkIcon}/>}
    </a>
  );
}
