import { creatContextStore } from './base';

export enum UserFields {
  FullName = "fullName",
  AgeRange  = "ageRange",
  PhoneNumber  = "phoneNumber",
  Address  = "address",
  Email  = "email",
  MethodOfContact = "methodOfContact"
};

export enum AgeRange {
  OlderThan19 = "olderThanNineteen",
  SixteenToEighteen = "sixteenToEighteen",
  YoungerThanSixteen = "youngerThanSixteen" 
}

export interface UserInfo {
  email?: string;
  password?: string;
  nickname?: string;
  fullName?: string;
  ageRange?: AgeRange;
  address?: string;
  phoneNumber?: string;
  reportKey?: string;
  reportId?: number;
  dateSubmitted?: Date;
  otherReportKeys?: string[];
  methodOfContact?: string;
};

const initialState = {
};

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<UserInfo>(initialState);

export {
  Provider as EditUserContextProvider,
  useState as useEditUserState,
  useSetState as useSetEditUserState,
  useMergeState as useMergeEditUserState,
};
