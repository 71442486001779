import _ from "lodash";
import * as base from "./lib/baseActions";

export interface fetch211ResourcesData {
  latitude?: number;
  longitude?: number; 
  locationQuery?: string; 
  pageNumber?: number; 
  pageSize?: number;
  lang?: string;
  searchTerm?: string;
  distance?: string; 
}

const fetch211Resources = async ({ latitude, longitude, locationQuery = "", pageNumber = 0, pageSize, lang, searchTerm = "*", distance = "50" }: fetch211ResourcesData) => {

  const data = {
    lat: latitude,
    long: longitude,
    lang,
    dataset: "on",
    term: searchTerm,
    location: locationQuery,
    pageSize,
    page: pageNumber,
    distance
  };

  try {
    const result = await base.post(
      `211`,
      data
    );
    
    return result.data.results;
  } catch(err) {
  }

  // on fail, return
  return;
};

export {
  fetch211Resources
};
