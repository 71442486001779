
// Other Report Info
import { creatContextStore } from "./base";
import { NoteType } from "actions/noteActions";
import { ReportPathType } from "pages/ReportPath";
import { UploadedAttachmentType, UnSubmittedAttachmentType } from "actions/awsActions";
import { Organization } from "actions/organizationActions";
import { TypeOfSupport } from "actions/typeOfSupportActions";


export enum ReportStates {
  Started="reportStarted",
  NotStarted="reportNotStarted",
  Completed="reportCompleted",
  Submitted="reportSubmitted",
  Editing="reportEditing"
}

export enum ReportFields {
  AllowPoliceContact = "allowPoliceContact",
  AllowSupportCenterContact  = "allowSupportCenterContact",
  AllowVestaContact = "allowVestaContact",
  AllowCampusContact = "allowCampusContact",
  AcknowledgementOfConsent = "acknowledgementOfConsent",
  AllowAttachmentsForCounsellorOrSupportCenter = 'allowAttachmentsForCounsellorOrSupportCenter',
  AllowSupportCenterReportDetails = 'allowSupportCenterReportDetails',
  AllowCampusReportDetails = 'allowCampusReportDetails',
  ReportLocation = 'reportLocation',
  Attachments = "attachmentsToUpload",
  SupportOrganization = "supportOrganization",
  CampusSupportOrganization = "campusSupportOrganization",
  TypeOfSupport = "typeOfSupport",
  SupportLocation = "supportLocation",
};

export interface ReportData {
  id?: number;
  userId?: number;
  type?: string;
  acknowledgementOfConsent?: boolean;
  allowPoliceContact?: boolean;
  allowSupportCenterContact?: boolean;
  allowVestaContact?: boolean;
  allowCampusContact?: boolean;
  allowAttachmentsForCounsellorOrSupportCenter?: boolean;
  allowSupportCenterReportDetails?: boolean;
  allowCampusReportDetails?: boolean;
  reportLocation?: string;
  dateSubmitted?: Date;
  dateReviewed?: Date;
  datePassedToLawEnforcement?: Date;
  dateInvestigationStarted?: Date;
  methodOfContact?: string;
  fullName?: string;
  notes?: NoteType[];
  reportPathType?: ReportPathType|string;
  attachmentsToUpload?: UnSubmittedAttachmentType[];
  uploadedAttachments?: UploadedAttachmentType[];
  supportOrganization?: Organization;
  campusSupportOrganization?: Organization;
  typeOfSupport?: TypeOfSupport[];
  supportLocation?: string;
};

const initialState = {
  //this initialization is needed on ReviewReport & UpdateSubmitted so better to do it in 1 place here
  acknowledgementOfConsent: false,
  allowPoliceContact: false,
  allowSupportCenterContact: false,
  allowVestaContact: false,
  allowCampusContact: false,
  allowAttachmentsForCounsellorOrSupportCenter: false,
  allowCampusReportDetails: false,
  allowSupportCenterReportDetails: false,
  reportLocation: '',
  attachments: [],
  uploadedAttachments: [],
};

export const isSubmitted = (userInfo: ReportData) => {
  return !!userInfo.dateSubmitted;
}

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<ReportData>(initialState);

export {
  Provider as EditReportContextProvider,
  useState as useEditReportState,
  useSetState as useSetEditReportState,
  useMergeState as useMergeEditReportState
};
