import React, { ReactNode } from "react";
import { BaseModal } from "components/Modals/BaseModal";
import styles from './styles.module.scss';
import classNames from "classnames";


export interface HeaderBodyType {
  header?: ReactNode;
  body?: ReactNode;
};

interface GenericHeaderBodyConstructorModalProps {
  genericHeaderBodyConstructorModalIsOpen: boolean;
  setGenericHeaderBodyConstructorModalIsOpen: (value: boolean) => void;
  title?: ReactNode;
  headerBodyArray: Array<HeaderBodyType>;
  className?: string;
  children?: ReactNode;
  showCloseButton?: boolean;
}

export const GenericHeaderBodyConstructorModal = ({ 
  genericHeaderBodyConstructorModalIsOpen, 
  setGenericHeaderBodyConstructorModalIsOpen, 
  title, 
  headerBodyArray,
  className,
  children,
  showCloseButton
} : GenericHeaderBodyConstructorModalProps) => {

  const closeGenericHeaderBodyConstructorModal = () => {
    setGenericHeaderBodyConstructorModalIsOpen(false);
  }

  return (
    <BaseModal isOpen={genericHeaderBodyConstructorModalIsOpen} 
      title={title} 
      showCloseButton={showCloseButton}
      onClose={closeGenericHeaderBodyConstructorModal} 
      className={classNames(styles.genericHeaderBodyConstructorModal, className)}>

      <div className={styles.content}>
        {headerBodyArray.map((headerBody: HeaderBodyType, index) => {
          return (
            <div className={styles.headerBody} key={index}>
              {headerBody.header && <h5 className={styles.bodyTitles}>{headerBody.header}</h5>}
              {headerBody.body && <div className={styles.body}>{headerBody.body}</div>}
            </div>
          );
        })}
      </div>

      {children}

    </BaseModal>
  );
}
