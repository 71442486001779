import { fetchBreakPrevQuestionSection } from "actions/questionActions";
import classNames from "classnames";
import { Button, ButtonStyle } from "components/Buttons";
import { ArrowButton, ArrowDirection, ArrowPosition } from "components/Buttons/Button";
import { CoffeeImage } from "components/Images";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';


export interface ReportBreakProps {
  onPreviousSectionClick: () => void,
  onSkipSensoryQuestionsClick: () => void,
  onContinueToSensoryQuestionsClick: () => void,
  onStepAwayForLongerClick: () => void,
}
  
export const ReportBreak = React.memo(({ 
  onPreviousSectionClick, 
  onSkipSensoryQuestionsClick, 
  onContinueToSensoryQuestionsClick, 
  onStepAwayForLongerClick 
}: ReportBreakProps) => {
  const breakPrevQuestionSection = fetchBreakPrevQuestionSection();
  return (
    <div className={styles.reportBreak}>
      <div className={styles.prevButtonContainer}>
        <ArrowButton arrowDirection={ArrowDirection.Up} 
          clickAction={onPreviousSectionClick} 
          arrowPosition={ArrowPosition.Right} 
          style={ButtonStyle.Secondary}>
            <FormattedMessage id="report.prev" defaultMessage="Prev"/>: <FormattedMessage id={`report.${breakPrevQuestionSection?.key}`} defaultMessage={breakPrevQuestionSection?.title}/>
        </ArrowButton>
      </div>

      <div className={styles.breakContent}>
        <div className={styles.breakHeader}>
          <div className={styles.coffeeIcon}><CoffeeImage/></div>

          <h1><FormattedMessage id="reportBreak.title" defaultMessage="Do you need a break?"/></h1>
        </div>

        <div className={styles.body}>
          <p><FormattedMessage id="reportBreak.nextSectionAbout" defaultMessage="The next section will ask you about anything you might have seen, heard, felt, or tasted, as well as how this experience has impacted your life. These questions may trigger trauma responses."/></p>
          
          <p className={styles.proceedOnlyIfComfortable}>
            <FormattedMessage id="reportBreak.proceedOnlyIfComfortable" defaultMessage="Proceed only if you feel comfortable or have access to support."/>
          </p>

          <p><FormattedMessage id="reportBreak.skipOrContinue" defaultMessage="You can skip this section or continue if you feel comfortable."/></p>
        </div>

        <div className={styles.bottomButtons}>
          <Button onClick={onSkipSensoryQuestionsClick} style={ButtonStyle.Primary} className={classNames(ButtonStyle.Full, styles.skipSensoryQuestionsButton)}>
            <FormattedMessage id="report.skipSensoryQuestions" defaultMessage="Skip the sensory questions"/>
          </Button>

          <Button onClick={onContinueToSensoryQuestionsClick} 
            style={ButtonStyle.Primary}>
              <FormattedMessage id="report.continueToSensoryQuestions" defaultMessage="Continue to sensory questions"/>
          </Button>

          <Button onClick={onStepAwayForLongerClick} style={ButtonStyle.Underline} className={classNames(ButtonStyle.Full, styles.saveAndExitLink)}>
            <FormattedMessage id="report.stepAwayForLonger" defaultMessage="I need to step away for longer"/>
          </Button>
        </div>
      </div>
    </div>
  );
});
