import { post, put } from "./lib/baseActions";
import axios from "axios";
import _keys from "lodash.keys";

export interface UnSubmittedAttachmentType {
  key: string; // not the same as api unique key - this one is coming from getFileKey func
  filename: string,
  uploaded: boolean;
  file: File;
};

export interface UploadedAttachmentType { // this is api attachments type + file
  id: number;
  reportId: number;
  filename: string;
  url: string;
  uploaded: boolean;
  uniqueKey: any;
  file?: File;
};

const uploadReportAnswerAttachments = async (reportId: number, attachments: Array<UnSubmittedAttachmentType>) => {
  const answerUploads = await uploadReportImages(reportId, attachments);
 
  return {
    reportId,
    attachments,
    uploadedAnswers: answerUploads,
  };
};

const uploadReportImages = async (reportId: number, attachments: Array<UnSubmittedAttachmentType>): Promise<UploadedAttachmentType[]> => {

  const url = `reports/${reportId}/attachments`;
  const updateUrl = `reports/${reportId}/attachments`;

  try {
    const uploadImagePromises = attachments.map(async (attachment: UnSubmittedAttachmentType) => {
      const { filename, file } = attachment;
  
      const uploadImageResult = await uploadImage(url, updateUrl, filename, file);
      const { id, reportId, uniqueKey, uploaded } = uploadImageResult;
      return {
        id,
        reportId,
        filename,
        url,
        uploaded,
        uniqueKey,
        file
      };
    });

    const uploadImagePromisesResult: UploadedAttachmentType[] = await Promise.all(uploadImagePromises);
    return uploadImagePromisesResult;
  } catch (err) {
    console.log('failed to upload images:', err);
    throw err;
  }
};

const uploadImage = async (url: string, updateUrl: string, name: string, file: File): Promise<UploadedAttachmentType> => {
  let attachmentId: any = undefined;

  const postFormData = {
    name,
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size,
  };

  try {
    const postResult = await post(url, postFormData);
    const postResultData = postResult.data;

    const signedRequest = postResultData.signedRequest;
    await putFile(signedRequest, file);

    attachmentId = postResultData.id;
    const putReportAttachmentsResult = await put(`${updateUrl}/${attachmentId}`, { uploaded: true });
    
    const { id, reportId, filename, uniqueKey, uploaded } = putReportAttachmentsResult?.data || {};
    return {
      id,
      reportId,
      filename,
      url,
      uploaded: true,
      uniqueKey,
      file
    };
  } catch (err) {
    console.log("failed to upload file:", err);
    throw err;
  }
};

/// post file data to endpoint
// Do not use the Bearer base Action endpoints
const putFile = (endpoint : string, data: any) => {
  let formData = new FormData();
  let keys = _keys(data);

  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  return axios.put(endpoint, data, getConfig({'content-type':'multipart/form-data'}));

};

const getConfig = (overrideParams = {}) => {
  return {
    ...overrideParams
  };
};


export {
  uploadImage,
  uploadReportImages,
  uploadReportAnswerAttachments,
};
