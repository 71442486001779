import { useState, useEffect } from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { UserInfo, useMergeEditUserState } from 'context/editUser';
import { useEditUserState } from 'context';
import { useEditAnswersState } from 'context/editAnswers';
import { createReportUser, updateReportUser } from "actions/userActions";
import { createReportAnswers, getReportByKey, updateReportAnswers } from "actions/reportActions";
import { ReportData, ReportFields, useEditReportState, useMergeEditReportState } from "context/editReport";
import { SavedReportModal } from "../SavedReportModal";
import { SubmitReportModal } from "../SubmitReportModal";
import { Formik, FormikHelpers } from "formik";
import { ErrorSaveSubmitDeleteModal, ErrorType } from "components/Modals/ErrorSaveSubmitDeleteModal";
import { DeleteModal } from "../DeleteModal";
import useUpdateAttachments from "lib/useUpdateAttachments";
import { AttachmentFailedModal } from "../AttachmentFailedModal";
import { RoutePaths } from "App/routing";
import { useHistory } from "react-router-dom";
import { UpdateQuestionType } from "pages/UpdateSubmittedReport/UpdateQuestions";
import { NoteType, createReportNotes } from "actions/noteActions";

export enum SaveModalActionType {
  Save = "Save",
  Submit  = "Submit",
  SaveAfterSubmit  = "SaveAfterSubmit"
}

export interface SaveReportUserInfoType {
  reportKey?: string;
  fullName?: string;
}

export interface UseSaveReportProps {
  modalAction: SaveModalActionType;
  setAttachmentFailedModalIsOpen?: (x: boolean) => void;
  setErrorSaveSubmitDeleteModalIsOpen?: (x: boolean) => void;
  onSave?: () => void;
}

export const useSaveReport = ({
  modalAction,
  onSave,
  
}: UseSaveReportProps) => {
  const isSubmission = (modalAction === SaveModalActionType.Submit);

  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();
  const mergeReportData = useMergeEditReportState();
  const answerData = useEditAnswersState();
  const reportData: ReportData = useEditReportState();
  const updateAttachments = useUpdateAttachments();

  const [attachmentFailedModalIsOpen, setAttachmentFailedModalIsOpen] = useState(false);
  const [errorSaveSubmitDeleteModalIsOpen, setErrorSaveSubmitDeleteModalIsOpen] = useState(false);
  
  const [saveReportUserInfo, setSavedReportUserInfo] = useState<SaveReportUserInfoType>({reportKey:'', fullName:''});

  const [isSavedReportModalOpen, setIsSavedReportOpen] = useState(false);
  const [isSubmitReportModalOpen, setIsSubmitReportOpen] = useState(false);
  const isActionTypeSave = (modalAction === SaveModalActionType.Save);
  const isActionTypeSaveAfterSubmit = (modalAction === SaveModalActionType.SaveAfterSubmit);
  const history = useHistory();

  const onSavePre = () => {
    if (isActionTypeSave || isActionTypeSaveAfterSubmit) {
      setIsSavedReportOpen(true);
    } else {
      setIsSubmitReportOpen(true);
      history.push(RoutePaths.reportSubmitSuccess);
    }
  }

  useEffect(() => {
    if (userInfo?.reportKey) {
      setSavedReportUserInfo({ reportKey: userInfo.reportKey, fullName: userInfo.fullName });
    }
  }, [userInfo]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [notes, setNotes] = useState<{[key in UpdateQuestionType]?: NoteType}>();
  
  const saveReport = async () => {
    setIsUpdating(true);
    const reportId = reportData?.id?.toString();
    const reportKey = userInfo.reportKey;
    // must have created report already, so has report key.
    if (reportId && reportKey) {
      try {
        const reportUserInfo = {...userInfo, ...reportData};
        const reportWithModifiedConsentData = Object.entries(reportUserInfo).reduce((reportWithModifiedConsentData: any, c) => {
          if (Object.values(ReportFields).includes(c[0] as ReportFields) && typeof c[1] === 'boolean' ) {
            reportWithModifiedConsentData[c[0]] = (c[1] === true) ? 1 : 0;
          }
          return reportWithModifiedConsentData;
        }, reportUserInfo);
        reportWithModifiedConsentData.supportLocationId = reportData.supportOrganization?.id
        reportWithModifiedConsentData.campusSupportLocationId = reportData.campusSupportOrganization?.id
        reportWithModifiedConsentData.typeOfSupport = reportData.typeOfSupport

        let filteredNotes;
        if (notes) {
          filteredNotes = Array.from(new Set(Object.values(notes))).filter(n => {
            return (n && (n.note !== undefined) && (n.note !== null) && (n.note !== ""));
          });
        }
        filteredNotes = (filteredNotes || []) as NoteType[];

        if (filteredNotes.length) {
          await createReportNotes(reportId, { notes: filteredNotes });
        }


        try {
          const fullAnswers = answerData; 
          await updateReportAnswers(reportId, fullAnswers);
          await updateAttachments(reportData.id);
        } catch(err) {
          setAttachmentFailedModalIsOpen(true);
        }

              // We should only submit after creating.
        const submitConsentData = { ...reportWithModifiedConsentData, reportKey, reportId };
        const updateReportUserResult = await updateReportUser(submitConsentData, isSubmission);

        mergeReportData({...updateReportUserResult.report, notes: [...reportData.notes || [], ...filteredNotes]});
        mergeUserData({...updateReportUserResult.user});
        
        onSavePre();
        onSave && onSave();

        setIsUpdating(false);
      } catch (err) {
        setIsUpdating(false);
        setErrorSaveSubmitDeleteModalIsOpen(true);
      }
    } else {
      // NO REPORT should go through password creation modal instead.
    }
  }

  return {
    saveReport,
    errorSaveSubmitDeleteModalIsOpen, 
    setErrorSaveSubmitDeleteModalIsOpen,
    attachmentFailedModalIsOpen, 
    setAttachmentFailedModalIsOpen,
    saveReportUserInfo,
    isSavedReportModalOpen, 
    setIsSavedReportOpen,
    isSubmitReportModalOpen, 
    setIsSubmitReportOpen,
    notes,
    setNotes,
  };
}

export interface SaveModalProps {
  saveModalAction: SaveModalActionType;
  isBreak?: boolean;
  errorSaveSubmitDeleteModalIsOpen: boolean; 
  setErrorSaveSubmitDeleteModalIsOpen: (val: boolean) => void;
  attachmentFailedModalIsOpen: boolean; 
  setAttachmentFailedModalIsOpen: (val: boolean) => void;
  saveReportUserInfo:SaveReportUserInfoType;
  isSavedReportModalOpen: boolean;
  setIsSavedReportOpen: (val: boolean) => void;
  isSubmitReportModalOpen: boolean;
  setIsSubmitReportOpen: (val: boolean) => void;
}

export const SaveModal = ({
  saveModalAction,
  errorSaveSubmitDeleteModalIsOpen,
  setErrorSaveSubmitDeleteModalIsOpen,
  attachmentFailedModalIsOpen,
  setAttachmentFailedModalIsOpen,
  saveReportUserInfo,
  isSavedReportModalOpen,
  setIsSavedReportOpen,
  isSubmitReportModalOpen,
  setIsSubmitReportOpen,
}: SaveModalProps) => {
  const isSubmission = (saveModalAction === SaveModalActionType.Submit);
  return (<>
    <SavedReportModal 
      isSavedReportModalOpen={isSavedReportModalOpen}
      setIsSavedReportOpen={setIsSavedReportOpen}
      reportKey={saveReportUserInfo.reportKey}
      fullName={saveReportUserInfo.fullName}/>

    <SubmitReportModal 
      submitReportModalIsOpen={isSubmitReportModalOpen}
      setSubmitReportModalIsOpen={setIsSubmitReportOpen}
      reportKey={saveReportUserInfo.reportKey}/>

    <ErrorSaveSubmitDeleteModal 
      errorSaveSubmitDeleteModalIsOpen={errorSaveSubmitDeleteModalIsOpen} 
      setErrorSaveSubmitDeleteModalIsOpen={setErrorSaveSubmitDeleteModalIsOpen} 
      errorType={isSubmission ? ErrorType.Submit : ErrorType.Save}/>

    <AttachmentFailedModal 
      attachmentFailedModalIsOpen={attachmentFailedModalIsOpen}
      setAttachmentFailedModalIsOpen={setAttachmentFailedModalIsOpen}/>
  </>);
}
