import questions from "data/questions.json";
import printQuestions from "data/printQuestions.json";
import { sortByOrder } from "lib/commonFunctions";
import { ReportSections } from "pages/Report";

export const questionKeyPrefix = "q-";
export const optionKeyPrefix = "o-";

export interface OptionType {
  key: string;
  order: number;
  text: string;
};

export interface FormattedOptionType {
  value: string;
  text: string;
  disabled?: boolean;
};

export interface RequiredPreviousAnswers {
  key: string;
  sectionKey: string;
  questionKey: string;
  visibleOnValue: string;
};

export enum QuestionTypes {
  Boolean = "boolean",
  Options = "options",
  Number = "number",
  Text = "text",
  TextLong = "text-long",
  Note = "note",
  MultiChoice = "multichoice",
  Complexion = "complexion",
  HairColour = "hairColour",
  OffenderFace = "offender-face", 
  Address = "address",
  DateTimeRange = "dateTimeRange",
  Attachments = "attachments"
};

export enum SectionTypes {
  Question = "question",
  Submit = "submit",
  Break = "break",
  PageBreak = "page-break"
}

export interface QuestionType {
  description?: string;
  sectionKey?: string; // override of Section key
  key: string;
  type: string;
  text: string;
  order: number;
  required?: boolean;
  placeholder?: string;
  encryptedAndSecure?: boolean;
  imageKey?: string;
  options?: Array<OptionType>;
  scale?: Array<OptionType>;
  requiredPreviousAnswers?: Array<RequiredPreviousAnswers>;
  defaultValue?: string;
};

export interface SectionType {
  key: string;
  title: string;
  description: string;
  imageKey: string;
  type: string;
  reportPathType?: string;
  why?: string;
  step: number;
  order: number;
  showHowToAnswerIfUnsureText?: boolean;
  showGoThroughAtOwnPace?: boolean;
  questions: Array<QuestionType>;
  pageNum?: number; // for printing
};

export interface QuestionsType {
  version: string;
  name: string;
  key: string;
  createdOn: string;
  updatedOn: string;
  language: string;
  sections: Array<SectionType>;
};

export interface DataWrapper<T> {
  data?: T
};

export const fetchPrevQuestionSection = (sections: SectionType[], currentSectionOrder: number) => {
  return sections.find((section: SectionType) => {
    return (section.order === (currentSectionOrder - 1));
  });
};

export const fetchNextQuestionSection = (sections: SectionType[], currentSectionOrder: number) => {
  return sections.find((section: SectionType) => {
    return (section.order > currentSectionOrder);
  });
};

export const fetchQuestionSections = (): SectionType[] => {
  return questions.sections.sort(sortByOrder);
};

export const fetchQuestionSection = (sectionKey: ReportSections): SectionType | undefined => {
  return questions.sections.find((section: SectionType) => {
    return (section.key === sectionKey);
  });
};

export const fetchQuestion = (questionType: string, section: SectionType): QuestionType | undefined => {
  return section.questions.find((question: QuestionType) => {
    return (question.type === questionType);
  });
};

export const fetchBreakPrevQuestionSection = () => {
  const questionSections = fetchQuestionSections();
  return questionSections.find((section: SectionType, index: number) => {
    return (questionSections[index + 1].key === ReportSections.Break);
  });
};

export const fetchSensoryNextQuestionSection = () => {
  const questionSections = fetchQuestionSections().reverse();
  return questionSections.find((section: SectionType, index: number) => {
    return (questionSections[index + 1].key === ReportSections.SensoryExperience);
  });
};

export const fetchPrintQuestionSections = (): SectionType[] => {
  return printQuestions.sections.sort(sortByOrder);
};

export const fetchPrintQuestionSection = (sectionKey: ReportSections): SectionType | undefined => {
  return printQuestions.sections.find((section: SectionType) => {
    return (section.key === sectionKey);
  });
};

export const fetchBreakPrevPrintQuestionSection = () => {
  const questionSections = fetchPrintQuestionSections();
  return questionSections.find((section: SectionType, index: number) => {
    return (questionSections[index + 1].key === ReportSections.Break);
  });
};

export const fetchSensoryNextPrintQuestionSection = () => {
  const questionSections = fetchPrintQuestionSections().reverse();
  return questionSections.find((section: SectionType, index: number) => {
    return (questionSections[index + 1].key === ReportSections.SensoryExperience);
  });
};
