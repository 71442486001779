import { PrintSectionQuestion } from './sectionQuestions';
import { QuestionType, SectionType } from "actions/questionActions";
import styles from './styles.module.scss';
import { useEditAnswersState, getCurrentAnswer  } from 'context/editAnswers';
import { ReportFormErrorType } from "pages/Report";
import { HowToAnswerIfUnsure } from "./HowToAnswerIfUnsure";
import { GoThroughAtOwnPace } from "./GoThroughAtOwnPace";

interface ReportPageProps {
  currentReportSection?: SectionType;
  errors?: Map<string, ReportFormErrorType>;
}

const getCurrentError = (errors: Map<string, ReportFormErrorType>, section: SectionType, question: QuestionType) => {
  const error = errors.get(`${section.key}:${question.key}`)
  return error ? error : undefined;
}

export const PrintReportPage = ( { currentReportSection, errors }: ReportPageProps ) => {
  const answers = useEditAnswersState();

  return (
    <div className={styles.reportPageContent} >
      <h3>{currentReportSection?.title}</h3>
      {currentReportSection?.showHowToAnswerIfUnsureText && <HowToAnswerIfUnsure/>}
      {currentReportSection?.showGoThroughAtOwnPace && <GoThroughAtOwnPace/>}
      {currentReportSection?.questions?.map((question: QuestionType) => {
        const currentAnswer = getCurrentAnswer(answers, currentReportSection, question);
          const currentError = getCurrentError(errors || new Map(), currentReportSection, question);

          return (<>
              <PrintSectionQuestion 
                question={question}
                key={`${question.sectionKey ? question.sectionKey : currentReportSection.key}${question.key}`}
                value={currentAnswer?.value?.answer}
                scale={currentAnswer?.value?.scale}
                error={currentError}/>
            </>)
      })}

    </div>
  );
}
