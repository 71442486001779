import React, { FC } from "react";
import classNames from "classnames";
import styles from './styles.module.scss';

export interface IImageProps {
    className?: string;
}

export const TalkPoliceImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-talk-to-police-image")} 
    />
  );
}

export const TalkSupportImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-talk-to-support-image")} 
    />
  );
}

export const TalkPoliceOrSupportImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-talk-to-police-or-support-image")} 
    />
  );
}

export const CoffeeImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-coffee")} 
    />
  );
}

export const AppTalkImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-app-talk")} 
    />
  );
}

export const WriteStoryImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-write-story")} 
    />
  );
}

export const QueensLogoImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-queens-logo")} 
    />
  );
}

export const OffenderStatementImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-offender-statement")} 
    />
  );
}

export const PhoenixImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-phoenix")} 
    />
  );
}

export const UseVoiceImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-use-voice")} 
    />
  );
}

export const WindowHandsTouchImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-window-hands-touch")} 
    />
  );
}


export const FlowersImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-vesta-flowers")} 
    />
  );
}


export const DoveImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-vesta-dove")} 
    />
  );
}


export const ShoulderSupportImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-shoulder-support")} 
    />
  );
}

export const WindowHopeImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-window-hope")} 
    />
  );
}


export const WhiteExclamationImage: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--icon-white-exclamation")} 
    />
  );
}




