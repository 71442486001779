import { useState, useCallback, useEffect, ReactNode, FC } from "react";
import { SingleDateInput } from './SingleDateInput';
import { RangeInput } from './RangeInput';
import { RadioChoice } from "components/Forms/Toggle/RadioChoice";
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import classNames from "classnames";


export enum DefaultTimeTypes {
  Date = 'date',
  TimeRange = 'timeRange',
  IDontKnow = 'null',
  Unselected = 'unselected'

}

const TimeTypes = {
  Date: {type: 'date', intlKey: 'report.o-date'},
  TimeRange: {type: 'timeRange', intlKey: 'report.o-timeRange'},
  IDontKnow: {type: 'null', intlKey: 'report.o-idk'},
}

interface DateTimeProps {
  label?: ReactNode;
  required?: boolean;
  className?: string;
  groupName: string;
  isRange?: boolean;
  startName?: string;
  endName?: string;
  value?: Array<Date>;
  onChange?: (value: Array<Date>) => void;
  errorMessage?: ReactNode;
  showDateRange?: boolean;
  readOnlyMode?: boolean;
  defaultDateTimeType?: DefaultTimeTypes;
  disabled?: boolean;
}

const calculateCurrentRangeValues = (value?: Array<Date>, dateOrRangeDefault?: DefaultTimeTypes) => {
  if (value?.length) {
  return (value && value.length > 1) ? TimeTypes.TimeRange.type : TimeTypes.Date.type;
  } else if(dateOrRangeDefault === DefaultTimeTypes.Unselected) {
    return DefaultTimeTypes.Unselected;
  } else {
   return TimeTypes.IDontKnow.type;
  }
}

export const DateTime = ({
  label, 
  required, 
  groupName, 
  isRange = false, 
  startName = 'startDate', 
  endName = 'endDate', 
  value = [], 
  onChange = () => {}, 
  errorMessage, 
  showDateRange = false,
  readOnlyMode = false,
  defaultDateTimeType = DefaultTimeTypes.IDontKnow,
  disabled,
}: DateTimeProps) => {
  const intl = useIntl();

  const formattedOptions = Object.entries(TimeTypes).map(
    ([k, value]) => {
      return {
        value: value.type,
        text: intl.formatMessage({ id: value.intlKey })
      };
    }
  );

  // On initial creation,
  // if there are 2 dates, start with 
  const dateOrRangeDefault = defaultDateTimeType || DefaultTimeTypes.IDontKnow;
  const [dateOrRange, setDateOrRange]  = useState<string>(dateOrRangeDefault);

  const onOptionSelect = (optionSelected: string) => {
    setDateOrRange(optionSelected);
    if(optionSelected === TimeTypes.IDontKnow.type) {
      onChange([])
    } else if (dateOrRange === TimeTypes.Date.type && value.length > 1) {
      onChange([value[0]])
    }
  }

  useEffect(() => {
    if (value?.length) {
      setDateOrRange((value.length > 1) ? TimeTypes.TimeRange.type : TimeTypes.Date.type);
    }
  }, []);


   const setStartTime = useCallback((newDate) => {
    if (value && (value.length > 0) && (dateOrRange === TimeTypes.TimeRange.type)) {
      const newDateTime = [...(value)];

      newDateTime[0] = newDate;
      onChange(newDateTime);
    } else {
      const newValue = [newDate];
      onChange(newValue);
    }
  }, [value, onChange, dateOrRange]);

  const setEndTime = useCallback((endDate) => {
    const newDateTime = [...value];

    if (newDateTime.length === 0) {
      const newDateVal = [null, endDate];
      onChange(newDateVal);
    } else if (newDateTime.length >= 1) {
      const newDateVal = [value[0], endDate];
      onChange(newDateVal);
    }
    
  }, [value, onChange]);

  const checkForInputType = (type: string) => {
   if(showDateRange) {
     return (
         <RangeInput 
           startName={startName}
           endName={endName}
           values={value}
           onStartChange={(date) => { setStartTime(date); }}
           onEndChange={(date) => { setEndTime(date); }}
         />); 
   }


    switch (type) {
      case TimeTypes.Date.type:
        return (
          <SingleDateInput 
            value={(value?.length > 0) ? value[0] : undefined} 
            inputName={startName} 
            onChange={(date) => { setStartTime(date); }}
            disabled={disabled}
          />);
      case TimeTypes.TimeRange.type:
        return (
          <RangeInput 
            startName={startName}
            endName={endName}
            values={value}
            onStartChange={(date) => { setStartTime(date); }}
            onEndChange={(date) => { setEndTime(date); }}
            disabled={disabled}
          />);
      case TimeTypes.IDontKnow.type:
        return null;
    }
  }

  interface DatePickerRangeType { 
  }

  const DatePickerRange: FC<DatePickerRangeType> = () => {
    return (
      <div className={styles.dateTime}>
        <RadioChoice 
          label={label}
          groupName={groupName}
          value={readOnlyMode ? calculateCurrentRangeValues(value, dateOrRangeDefault) :dateOrRange}
          onChange={onOptionSelect}
          options={formattedOptions}
          required={required}
          className={styles.dateRangeType}
          disabled={disabled}
        />
        {checkForInputType(dateOrRange)}
      </div>
    );
  }

  interface DatePickerSingleType {
  }

  const DatePickerSingle: FC<DatePickerSingleType> = () => {
    return (
      <div className={classNames(styles.dateTime, styles.singleDateTime)}>
        <p className={styles.dateTimeLabel}>
          {label}
          {required && (<span className={styles.required}>*</span>)}
        </p>
        <SingleDateInput 
          value={(value?.length > 0) ? value[0] : undefined} 
          inputName={startName} 
          onChange={(date) => { setStartTime(date); }}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <div className={classNames(errorMessage && styles.errorField)}>
      {isRange ? <DatePickerRange /> : <DatePickerSingle />}
      {errorMessage && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}
    </div>
  );
}
