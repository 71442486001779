import React from "react";
import { FormattedMessage } from 'react-intl';
import { GenericHeaderBodyConstructorModal } from "../GenericHeaderBodyConstructorModal";
import { LearnMoreLink, LearnMoreLinkType } from "components/Common/LearnMore";
import styles from './styles.module.scss';

interface PrivacyAndOutcomesModalProps {
  privacyAndOutcomesModalIsOpen: boolean;
  setPrivacyAndOutcomesModalIsOpen: (value: boolean) => void;
}

const privacyAndOutcomesModalContent = [
  {
    header: <FormattedMessage id="privacyAndOutcomes.howIsItUsed.header" defaultMessage="How is it used?"/>,
    body: (
      <FormattedMessage id="privacyAndOutcomes.howIsItUsed.body" 
        values={{ noAccessWithoutConsent: <span className={styles.highlightedText}><FormattedMessage id="privacyAndOutcomes.howIsItUsed.body.noAccessWithoutConsent" defaultMessage="No one will be able to access it without your consent"/></span>, }}
        defaultMessage="You will be asked trauma-informed questions and will also be able to choose if you would like to remain anonymous, if you want to submit your information or when to engage with the legal system. The information you enter and save will be encrypted. No one will be able to access it without your consent, not even Vesta."/>
    )
  },
  {
    header: <FormattedMessage id="privacyAndOutcomes.outcomes.header" defaultMessage="What are the outcomes?"/>,
    body: (
      <FormattedMessage id="privacyAndOutcomes.outcomes.body" 
        values={{ notAPoliceReport: <span className={styles.highlightedText}><FormattedMessage id="privacyAndOutcomes.outcomes.body.notAPoliceReport" defaultMessage="This is not a police report."/></span> }} 
        defaultMessage="This is not a police report. An investigation will not be started. Instead, the data filed in this report will be added to informational databases used to aid law enforcement. By doing this, we hope to make our community a safer place for everyone."/>
    )
  }
];

export const PrivacyAndOutcomesModal = ({ 
  privacyAndOutcomesModalIsOpen, 
  setPrivacyAndOutcomesModalIsOpen 
} : PrivacyAndOutcomesModalProps) => {
  return (
    <GenericHeaderBodyConstructorModal 
      genericHeaderBodyConstructorModalIsOpen={privacyAndOutcomesModalIsOpen}
      setGenericHeaderBodyConstructorModalIsOpen={setPrivacyAndOutcomesModalIsOpen}
      className={styles.privacyAndOutcomesModal}
      headerBodyArray={privacyAndOutcomesModalContent}
      children={<LearnMoreLink learnMoreLinkType={LearnMoreLinkType.PrivacyPolicy} className={styles.learnMoreLink}/>}/>
  );
}
