import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ArrowDirection, ButtonStyle, LinkButton } from "components/Buttons/Button";
import { FormattedMessage } from "react-intl";
import { RoutePaths } from "App/routing";


// Header for all pages
export interface HeaderProps {
  showBackButton?: boolean;
  showBackArrow?: boolean;
  isReturnHomeType?: boolean;
  className?: string;
}

const Header = ( { className, showBackButton = true, showBackArrow = false, isReturnHomeType = false } : HeaderProps ) => { 
  return (
    <header className={classNames(className, showBackArrow && styles.hasBackArrow)}>
      <LinkButton to={RoutePaths.index}
        style={ButtonStyle.None}
        className={classNames(styles.header, !showBackArrow && styles.hasBackArrow)}>
          <div className={classNames(styles.headerImage, isReturnHomeType ? styles.homeIcon : styles.vestaLogo, showBackButton && styles.hasBackButton)}/>
      </LinkButton>
      <LinkButton to={RoutePaths.index}
        style={ButtonStyle.None}
        className={classNames(styles.backLinkContainer, styles.header)}>
        <div className={classNames(styles.backLink, showBackArrow && styles.hasBackArrow)}>
              {showBackArrow && <div className={styles.arrowIcon}><i className={classNames(ArrowDirection.Left)}/></div>}
              {isReturnHomeType && <p className={classNames(styles.attention, styles.vestaBlue)}><FormattedMessage id="common.returnHome" defaultMessage="Return Home"/></p>}
        </div>
      </LinkButton>
    </header>
  );
};

export default Header;
