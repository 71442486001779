import classNames from "classnames";
import { footerData, vestaExternalLinks } from "lib/config";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';

interface FooterProps {
  isReportForm?: boolean;
  isBreak?: boolean;
}

const Footer = ({isReportForm, isBreak} : FooterProps) => {
  return (
    <footer className={classNames(styles.footer, 
                      isReportForm && styles.reportForm, 
                      isBreak && styles.reportBreak)}>
      <div className={styles.footerLeft}>
        <div className={styles.footerName}>{footerData.name}</div>
        <div>{footerData.address}</div>
        <div>{footerData.email}</div>
      </div>
      <div className={styles.footerRight}>
        <FormattedMessage id={"report.print.footer.fillOutJournal"}   defaultMessage="You also have the option to fill out your journal out online at:" />
        <div className={styles.link}>
          {vestaExternalLinks.app}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
