import React, { useState } from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { Button, ButtonStyle } from "components/Buttons";
import { useMergeEditUserState } from "context/editUser";
import { loginReportUser } from 'actions/userActions';
import { LockIcon } from "components/Icons";
import styles from './styles.module.scss';
import classNames from "classnames";
import { parsedPhoneNumber } from "lib/commonFunctions";
import usePopulateReportNotesAnswers from "lib/usePopulateReportNotesAnswers";
import { resetError } from "components/Forms/Attachments";


interface PasswordModalProps {
  isPasswordModalOpen: boolean;
  reportKey: string;
  setIsPasswordModalOpen: (value: boolean) => void;
}

export const LoginErrors = {
  InvalidCredentials:{ id:'badCredentials', intlId:'login.error.invalidCredentials'},
  General:{ id:'general', intlId:'login.error.general'},
  Timeout:{ id:'timeout', intlId:'login.error.timeout' }
};

export interface LoginErrorType {
  id: string;
  intlId: string;
};

export const LoginModal = ({ isPasswordModalOpen, reportKey, setIsPasswordModalOpen } : PasswordModalProps) => {

  const mergeUserData = useMergeEditUserState();
  const populateReportNotesAnswers = usePopulateReportNotesAnswers();

  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<LoginErrorType | null>(null);
  
  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  }

  const onPasswordChange = (newPassword: string) => {
    setPassword(newPassword);
  }

  const onLogin = async () => {
    try {
      const userResult = await loginReportUser({reportKey, password});
      if (userResult) {
        mergeUserData({ ...userResult, phoneNumber: parsedPhoneNumber(userResult?.phoneNumber) });

        const reportId = userResult?.reportId;
        const reportKey = userResult?.reportKey;
        await populateReportNotesAnswers(reportId, reportKey);

        setIsPasswordModalOpen(false);
        setError(null);
      };
    } catch (err) {
      switch (err.status) {
        case 400:
          setError(LoginErrors.InvalidCredentials);
          break;
        case 429:
          setError(LoginErrors.Timeout);
          break;
        default:
          setError(LoginErrors.General);
          break;
      }
      return err;
    }
  }

  return (
    <BaseModal isOpen={isPasswordModalOpen} 
        onClose={closePasswordModal}
        showCloseButton={true} >
      <div className={styles.loginModalContent}>    
        <div className={styles.lockIcon}><LockIcon /></div>
        <p className={styles.reportKey}>{reportKey}</p>
        <p className={styles.header}>
          <FormattedMessage id="login.title" 
                            defaultMessage={'This report is password protected'}/></p>
        <p className={styles.subheader}>
          <FormattedMessage id="login.body"
                            defaultMessage={'Please provide your password to access this report'} />
          </p>
        <TextField type={TextFieldTypes.Password} 
          label={"Password"} 
          name={"Password"} 
          className={classNames(styles.passwordInput, error && styles.errorField)}
          onChange={(e) => { onPasswordChange(e); }} />
        <div className={styles.errorMessage}>
          {error && (<FormattedMessage id={error.intlId} defaultMessage={"Sorry, there was an error."} />)}
        </div>
        <Button onClick={() => { resetError(); onLogin(); }} 
                style={ButtonStyle.Secondary}
                className={styles.accessReportButton}>
          <FormattedMessage id="login.accessReport" defaultMessage={'Access Report'}></FormattedMessage>
        </Button>
      </div>
    </BaseModal>
  );
}
