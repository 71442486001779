import React from "react";
import { FormattedMessage } from 'react-intl';
import { ReportData, useEditReportState } from "context/editReport";
import { FlagIconPink } from "components/Icons";
import { ReportFields } from 'context/editReport';
import styles from './styles.module.scss';
import classNames from "classnames";

type ReportSummaryFields = Exclude<ReportFields, typeof ReportFields.Attachments>;

interface ReportSummaryFieldProps {
  field: ReportSummaryFields,
  fieldValue: boolean | undefined;
  showWhatToExpect: boolean;
}

const permissionValue = ({ field, fieldValue }: Partial<ReportSummaryFieldProps>) => {
  if (field === ReportFields.AllowVestaContact) {
    return fieldValue 
      ? (<FormattedMessage id="reportSummary.allowVestaContact.beEmailed" defaultMessage="be emailed"/>) 
      : (<FormattedMessage id="reportSummary.allowVestaContact.notBeEmailed" defaultMessage="not be emailed"/>);
  } else {
    return fieldValue 
      ? (<FormattedMessage id="common.yes" defaultMessage="Yes"/>) 
      : (<FormattedMessage id="common.no" defaultMessage="No"/>);
  }
}

const ReportSummaryField = ({ field, fieldValue, showWhatToExpect }: ReportSummaryFieldProps) => {
  return (
    <div className={styles.reportSummaryField}>
      <p className={classNames(styles.header, styles.attention)}>
        <FlagIconPink className={styles.iconSize}/>
        <FormattedMessage id={`reportSummary.${field}.header`} defaultMessage={field}/>
        <span className={classNames(styles.permission, styles.attention)}>{permissionValue({field, fieldValue})}</span>
      </p>
      {showWhatToExpect && (
        <p className={styles.whatToExpect}>
          {fieldValue
            ? (<FormattedMessage id={`reportSummary.${field}.yes`}/>) 
            : (<FormattedMessage id={`reportSummary.${field}.no`}/>)} 
        </p>
      )}
    </div>
  );
}

interface ReportSummaryProps {
  className?: string;
  showWhatToExpect?: boolean;
}

export const ReportSummary = ( { className, showWhatToExpect = true }: ReportSummaryProps ) => {
  const reportData: ReportData = useEditReportState();
  return (
    <div className={classNames(styles.reportSummary, className)}>
      {[ReportFields.AllowPoliceContact,
        ReportFields.AllowSupportCenterContact,
        ReportFields.AllowCampusContact,
        ReportFields.AllowVestaContact,
        ReportFields.AllowSupportCenterReportDetails,
        ReportFields.AllowCampusReportDetails,
      ].map((reportSummaryField: ReportFields) => (
          <ReportSummaryField 
            field={reportSummaryField as ReportSummaryFields} 
            fieldValue={reportData[reportSummaryField] as boolean} 
            showWhatToExpect={showWhatToExpect} 
            key={reportSummaryField}
          />
      ))}
    </div>
  );
}
