import { useIntl } from "react-intl";

type PlacesAndSupportInfo = {
  TYPE_OF_SUPPORT: any
  PLACE_INFO: any,
  Places: any;
}


function PlacesAndSupport() {
  const intl = useIntl();

  const Places = [
    { value: 1, text: 'Kingston' ,intakeFormLink:"https://www.sackingston.com/contact"}, 
    { value: 3, text: 'Chatham-Kent' ,intakeFormLink:''}, 
    { value: 4, text: 'Durham Region' ,intakeFormLink:''}, 
    { value: 5, text: 'Eastern Ontario' ,intakeFormLink:''}, 
    { value: 6, text: 'Grey Bruce' ,intakeFormLink:''}, 
    { value: 8, text: 'Haliburton' ,intakeFormLink:''}, 
    { value: 9, text: 'Kawartha' ,intakeFormLink:''}, 
    { value: 10, text: 'Pine-Ridge' ,intakeFormLink:''}, 
    { value: 12, text: 'Hastings Prince Edward',intakeFormLink:'' }, 
    { value: 14, text: 'Frontenac' ,intakeFormLink:''}, 
    { value: 15, text: 'Lennox & Addington' ,intakeFormLink:''}, 
    { value: 16, text: 'Lambton' ,intakeFormLink:''}, 
    { value: 18, text: 'Grenville and Lanark' ,intakeFormLink:''}, 
    { value: 19, text: 'Middlesex-London' ,intakeFormLink:''}, 
    { value: 20, text: 'Niagara Region' ,intakeFormLink:''}, 
    { value: 21, text: 'North Bay Parry Sound' ,intakeFormLink:''}, 
    { value: 22, text: 'Northwestern' ,intakeFormLink:''}, 
    { value: 23, text: 'Ottawa' ,intakeFormLink:''}, 
    { value: 24, text: 'Peel' ,intakeFormLink:''}, 
    { value: 25, text: 'Peterborough' ,intakeFormLink:''}, 
    { value: 26, text: 'Porcupine' ,intakeFormLink:''}, 
    { value: 28, text: 'Sudbury and Districts' ,intakeFormLink:''}, 
    { value: 30, text: 'Simcoe Muskoka' ,intakeFormLink:''}, 
]

const PLACE_INFO = {
    organizationId: 0,
    organizationLocation: "Kingston",
    organizationName: "SAC Kingston",
    hasDashboardAccess: false,
    hasReportsAccess: false,
    websiteLink: "https://www.sackingston.com",
    intakeFormLink: "https://www.sackingston.com/contact",
    crisisLink: "https://www.webelievesurvivors.ca/chat-now",
    crisisPhone: "905-657-8888"
}
    
const TYPE_OF_SUPPORT = [
  { value: '1', text: intl.formatMessage({ id: 'supportTypes.individualCounselling' })}, 
  { value: '2', text: intl.formatMessage({ id: 'supportTypes.groupCounselling' })}, 
  { value: '3', text: intl.formatMessage({ id: 'supportTypes.crisisLineTextChat' })},
  { value: '4', text: intl.formatMessage({ id: 'supportTypes.financialSupport' })},
]

  return {
    TYPE_OF_SUPPORT,
    PLACE_INFO,
    Places,
  }
}

export default PlacesAndSupport


