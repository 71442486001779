import React, { useState } from "react";
import { BaseModal } from "../BaseModal";
import { FormattedMessage } from 'react-intl';
import { Button, ButtonStyle } from "../../../components/Buttons/Button";
import styles from './styles.module.scss';
import { useEditUserState, UserInfo } from "context/editUser";
import { deleteReportUserAnswers } from "actions/reportActions";
import { useLogoutUser } from 'actions/userActions';
import { ConfirmationModal, ConfirmationType } from "components/Modals/ConfirmationModal";
import { ErrorSaveSubmitDeleteModal, ErrorType } from "../ErrorSaveSubmitDeleteModal";
import { SurveyRequestModal } from "../SurveyRequestModal";

interface DeleteModalProps {
  deleteModalIsOpen: boolean;
  setDeleteModalIsOpen: any;
}

export const DeleteModal = ({ deleteModalIsOpen, setDeleteModalIsOpen } : DeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [errorSaveSubmitDeleteModalIsOpen, setErrorSaveSubmitDeleteModalIsOpen] = useState(false);
  const [surveyRequestModalIsOpen, setSurveyRequestModalIsOpen] = useState(false);

  const userInfo: UserInfo = useEditUserState();
  const logoutUser = useLogoutUser();
  
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  }

  const openSurveyRequestModal = () => {
    setSurveyRequestModalIsOpen(true); 
  }

  const onClickDelete = async () => {
    const reportKey = userInfo.reportKey;

    if (reportKey) { //determine what the "report not already entered into database" validation should look like 
      setIsLoading(true);
      try {
        await deleteReportUserAnswers(reportKey);
        logoutUser();
        setIsLoading(false);
        closeDeleteModal();
        openSurveyRequestModal();
      } catch (err) {
        setIsLoading(false);
        setErrorSaveSubmitDeleteModalIsOpen(true);
      }
    } else {
      closeDeleteModal();
      openSurveyRequestModal();
    }
  }

  return (
    <>
    <BaseModal showCloseButton={false} 
               isOpen={deleteModalIsOpen} 
               centerTitle={true} 
               titleId="report.delete" 
               onClose={closeDeleteModal} >
      <div className={styles.deleteModalContent}>
        <p><FormattedMessage id="report.deleteBody" /></p>
        <div className={styles.buttonsContainer}>
          <Button loading={isLoading}  onClick={onClickDelete} style={ButtonStyle.Primary} className={ButtonStyle.Full}>
            <FormattedMessage id="report.delete" />
          </Button>
          <Button onClick={closeDeleteModal} style={ButtonStyle.Underline} className={ButtonStyle.Full}>
            <FormattedMessage id="report.cancelAndReturn" />
          </Button>
        </div>
      </div>
    </BaseModal>

    <ConfirmationModal confirmationModalIsOpen={confirmationModalIsOpen} 
        setConfirmationModalIsOpen={setConfirmationModalIsOpen} 
        confirmationType={ConfirmationType.ReportDeleted}/>

    <ErrorSaveSubmitDeleteModal errorSaveSubmitDeleteModalIsOpen={errorSaveSubmitDeleteModalIsOpen} 
      setErrorSaveSubmitDeleteModalIsOpen={setErrorSaveSubmitDeleteModalIsOpen} 
      errorType={ErrorType.Delete}/>

    <SurveyRequestModal 
      surveyRequestModalIsOpen={surveyRequestModalIsOpen}
      setSurveyRequestModalIsOpen={setSurveyRequestModalIsOpen}
    />
    </>
  );
}
