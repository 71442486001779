// The base API url for taxplan.api
export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;

// Jwt specific data 
export const jwtData = { 
  refreshUrl: '/users/refreshJwt',
  authToken: 'authToken',
  refreshToken: 'refreshToken'
};

export const vestaExternalLinks = {
  app: 'https://app.vestasit.com',
  vesta: 'https://www.vestasit.com/',
  contact: 'https://www.vestasit.com/contact-us/',
  resources: 'https://www.vestasit.com/resources-library/',
  privacyPolicy: 'https://www.vestasit.com/privacy-policy/',
  termsOfConditions: 'https://www.vestasit.com/terms-of-use/',
  twoOneOneOntario: 'http://www.211ontario.ca',
}

export const footerData = {
  name: `VESTA Social Innovation Technologies`,
  address: `10 Dundas St E Suite 1002 Toronto, ON M5B 2G9`,
  email: `info@vestasit.com`
}

export const GtmContainerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID;

export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
