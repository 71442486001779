import React, { useState } from "react";
import { SectionQuestion } from './sectionQuestions';
import { QuestionType, SectionType } from "actions/questionActions";
import styles from './styles.module.scss';
import { useAddAnswer, useEditAnswersState, getCurrentAnswer, AnswersInfo, getAnswerKey, useSetEditAnswersState  } from 'context/editAnswers';
import { ReportFormErrorType } from "pages/Report";
import { ThoughtBlurb } from "components/Common/thoughtBlurb";
import { FormattedMessage } from "react-intl";

interface ReportPageProps {
  currentReportSection?: SectionType;
  errors?: Map<string, ReportFormErrorType>;
}

const getCurrentError = (errors: Map<string, ReportFormErrorType>, section: SectionType, question: QuestionType) => {
  const error = errors.get(`${section.key}:${question.key}`)
  return error ? error : undefined;
}

const checkIfQuestionIsVisible = (question: QuestionType, answers: AnswersInfo) => {
  // If question has visibility rules,
  if(question.requiredPreviousAnswers && question.requiredPreviousAnswers.length > 0){        
    // Check each rule
    
    return question.requiredPreviousAnswers.reduce((result: boolean, rule) => {
      //let answersArray = [];
      const answersMap= answers.answers;

      // if already had an answer that didn't match, or no answers, not visible.
      if(!result || !answersMap || answersMap.size === 0) {
        return false;
      }

      // if answers, check that answer is correct value. If not, then return false.
      const applicableAnswer = answersMap.get(getAnswerKey(rule.sectionKey, rule.questionKey));

      if(applicableAnswer && (applicableAnswer.value.answer === rule.visibleOnValue)) {
        return true;
      } 
        
      return false;
    }, true);
  } else {
    // If a question doesn't have rules, it's always visible
    return true;
  }
}

const howToAnswerIfUnsureElement = (
  <p className={styles.smallText}><FormattedMessage 
  id="report.howToAnswerIfUnsure"
  defaultMessage="It’s okay to not know or not to be sure. It’s better to leave a question blank than to guess. There are no right or wrong answers."
  values={{ 
    highlight: <span className={styles.highlightedText}><FormattedMessage id="report.howToAnswerIfUnsure.highlight" defaultMessage=" This is..."/></span>,
  }} 
  /></p>
);

export const ReportPage = ( { currentReportSection, errors }: ReportPageProps ) => {
  const addAnswer = useAddAnswer();
  const answers = useEditAnswersState();
  const setReportState = useSetEditAnswersState();

  return (
    <div className={styles.reportPageContent} >
      {currentReportSection?.showHowToAnswerIfUnsureText && <ThoughtBlurb content={howToAnswerIfUnsureElement}/>}
      {currentReportSection?.questions?.map((question: QuestionType) => {
        const currentAnswer = getCurrentAnswer(answers, currentReportSection, question);
        const currentError = getCurrentError(errors || new Map(), currentReportSection, question);

        const isVisible = checkIfQuestionIsVisible(question, answers);

        return isVisible && (
          <SectionQuestion 
            question={question}
            locked={currentAnswer?.locked}
            key={`${currentReportSection.key}${question.key}`}
            value={currentAnswer?.value?.answer}
            scale={currentAnswer?.value?.scale}
            updatedAnswer={({value, scale}) => {
              addAnswer(setReportState, answers, currentReportSection, question, value, scale);
            }}
            error={currentError}/>
          )
      })}

    </div>
  );
}
