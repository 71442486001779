import React from "react";
import { RadioChoice } from "components/Forms/Toggle/RadioChoice";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { PhoneNumber } from 'components/Forms/TextField/PhoneNumber';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMergeEditUserState, useEditUserState, UserInfo, UserFields, AgeRange } from "context/editUser";
import { ReportData, useEditReportState } from "context/editReport";
import styles from './styles.module.scss';
import classNames from "classnames";
import { parsedPhoneNumber } from "lib/commonFunctions";
import { FormattedOptionType } from "actions/questionActions";
import { ReportPathType } from "pages/ReportPath";

export enum ContactTypes {
  Email = 'Email', 
  Phone = 'Phone', 
  Both = 'Both'
}

//ContactOptions can have any subset of ContactTypes
const ContactOptions = {
  Email: ContactTypes.Email, 
  Phone: ContactTypes.Phone, 
  Both: ContactTypes.Both
} 

interface MethodOfContactProps {
  reviewReportButtonClicked: boolean;
}

export const isValidEmail = (email: string | undefined): boolean => !!(email && /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/.test(email));
export const isValidPhoneNumber = (phoneNumber: string | undefined): boolean => !!(phoneNumber && (phoneNumber.length === 10));

export const MethodOfContact = ({reviewReportButtonClicked}: MethodOfContactProps) => {
  const intl = useIntl();
  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();
  const reportData: ReportData = useEditReportState();
  
  const optionsForMethodOfContact: FormattedOptionType[] = Object.values(ContactOptions).map(
    (contactOption) => ({ 
      value: contactOption, 
      text: contactOption, 
      disabled: (false)
    })
  );

  const isReportPathAnonymous = (reportData.reportPathType === ReportPathType.ReportAnonymously);
  const isYoungerThanSixteen = (userInfo.ageRange === AgeRange.YoungerThanSixteen);
  const isAnonymousAndOverSixteen = (isReportPathAnonymous && !isYoungerThanSixteen);

  const hasAtLeastOneConsent = (reportData.allowPoliceContact || reportData.allowSupportCenterContact || reportData.allowVestaContact || reportData.allowCampusContact);
  const allowPoliceContact  = reportData.allowPoliceContact ? true : false;

  const isContactTypeRequired = (contactType: ContactTypes.Phone | ContactTypes.Email) => {
    return (hasAtLeastOneConsent && (
      (userInfo.methodOfContact === ContactOptions[contactType]) ||
      (userInfo.methodOfContact === ContactTypes.Both) ||
      (hasAtLeastOneConsent)
      ));
  }

  const emailValidationMessage = () => {
    if (reviewReportButtonClicked) {
      if (isContactTypeRequired(ContactTypes.Email)) {
        if (!userInfo.email) {
          return intl.formatMessage({ id: "personalInfo.form.error.methodOfContact.fill.email", defaultMessage: "Please fill in method of contact" });
        } else if (!isValidEmail(userInfo.email)) {
          return intl.formatMessage({ id: "personalInfo.form.error.invalidEmail", defaultMessage: "Must be a valid email" });
        }
      }
    } else {
      return null;
    } 
  }

  const phoneValidationMessage = () => {
    if (reviewReportButtonClicked) {
      if (isContactTypeRequired(ContactTypes.Phone)) {
        if (!userInfo.phoneNumber) {
          return intl.formatMessage({ id: "personalInfo.form.error.methodOfContact.fill.phone", defaultMessage: "Please fill in method of contact" });
        } else if (!isValidPhoneNumber(userInfo.phoneNumber)) {
          return intl.formatMessage({ id: "personalInfo.form.error.invalidPhone", defaultMessage: "Must be a 10 digit phone number" });
        }
      }
    } else {
      return null;
    }         
  }
  const fullNameValidationMessage = () => {
    if (reviewReportButtonClicked && !userInfo.fullName) {
      return intl.formatMessage({ id: "personalInfo.form.error.fullName.fill", defaultMessage: "Please fill in name" });
    } else {
      return null;
    }         
  }

  return (
    <div className={classNames(styles.methodOfContactContainer, isAnonymousAndOverSixteen && styles.displayNone)}>

      <PhoneNumber onChange={(phoneNumber) => { mergeUserData({ [UserFields.PhoneNumber]: parsedPhoneNumber(phoneNumber) }); }}
        className={classNames(!isContactTypeRequired(ContactTypes.Phone) && styles.greyOpacity)}  
        required={isContactTypeRequired(ContactTypes.Phone)} 
        disabled={!isContactTypeRequired(ContactTypes.Phone)}
        errorClassName={styles.errorMessage} 
        errorMessage={phoneValidationMessage()}/>  

      <TextField label={<FormattedMessage id="report.email" defaultMessage="Email"/>} 
        type={TextFieldTypes.InlineText} 
        name="email" 
        placeholder={intl.formatMessage({ id: "report.email", defaultMessage: "Email" })} 
        value={userInfo.email} 
        onChange={(email) => { mergeUserData({ email }); }}
        required={isContactTypeRequired(ContactTypes.Email)} 
        disabled={!isContactTypeRequired(ContactTypes.Email)}
        className={classNames(styles.field, !isContactTypeRequired(ContactTypes.Email) && styles.greyOpacity)} 
        labelClassName={styles.labels}
        errorClassName={styles.errorMessage}
        errorMessage={emailValidationMessage()}/>

      <TextField label={<FormattedMessage id="report.fullName" defaultMessage="Name"/>} 
        type={TextFieldTypes.InlineText} 
        name="name" 
        placeholder={intl.formatMessage({ id: "report.fullName", defaultMessage: "Name" })} 
        value={userInfo.fullName} 
        onChange={(fullName) => { mergeUserData({ fullName }); }}
        required={true} 
        disabled={!hasAtLeastOneConsent}
        className={classNames(styles.field, !hasAtLeastOneConsent && styles.greyOpacity)} 
        labelClassName={styles.labels}
        errorClassName={styles.errorMessage}
        errorMessage={fullNameValidationMessage()}/>
          
      <RadioChoice label={<FormattedMessage id="updateReport.methodOfContact" defaultMessage="Method of Contact"/>}
        groupName="methodOfContact"
        value={userInfo.methodOfContact}
        options={optionsForMethodOfContact}
        onChange={(methodOfContact) => { mergeUserData({ methodOfContact }); }} 
        disabled={!hasAtLeastOneConsent}
        required={hasAtLeastOneConsent}
        className={classNames(!hasAtLeastOneConsent && styles.greyOpacity)} 
        errorClassName={styles.errorMessage}
        errorMessage={reviewReportButtonClicked && hasAtLeastOneConsent && !userInfo.methodOfContact && (
          <FormattedMessage id="personalInfo.form.error.methodOfContact.select" defaultMessage="Please select method of contact"/>
        )}/>

    </div>
  );
}
