import React from "react";
import { BaseModal } from "../BaseModal";
import { FormattedMessage } from 'react-intl';
import { Button, ButtonStyle } from "../../../components/Buttons/Button";
import styles from './styles.module.scss';
import { useMergeEditReportState, useEditReportState, ReportData } from "context/editReport";
import { ReportSummary } from "pages/ReviewReport/ReportSummary";
import { ExternalLinkIcon } from "components/Icons";

interface ConsentModalProps {
  consentModalIsOpen: boolean;
  setConsentModalIsOpen: (value: boolean) => void;
}

enum ConsentScenario {
  NoPoliceNoSupport = 1,
  NoPoliceYesSupport = 2,
  YesPoliceYesSupport = 3,
  YesPoliceNoSupport = 4
};

export const ConsentModal = ({ consentModalIsOpen, setConsentModalIsOpen } : ConsentModalProps) => {

  const reportData: ReportData = useEditReportState();
  const mergeReportData = useMergeEditReportState();

  const closeConsentModal = () => {
    setConsentModalIsOpen(false);
  }

  const consent = () => {
    mergeReportData({ acknowledgementOfConsent: true });
    setConsentModalIsOpen(false);
  }

  const doNotConsent = () => { 
    mergeReportData({ acknowledgementOfConsent: false });
    setConsentModalIsOpen(false);
  }

  const consentScenario = () => {
    if ( !reportData.allowPoliceContact ) {
      return !reportData.allowSupportCenterContact 
                ? ConsentScenario.NoPoliceNoSupport 
                : ConsentScenario.NoPoliceYesSupport;
    } else if ( reportData.allowPoliceContact ) {
      return reportData.allowSupportCenterContact 
              ? ConsentScenario.YesPoliceYesSupport 
              : ConsentScenario.YesPoliceNoSupport;
    }
  }

  return (
    <BaseModal showCloseButton={false} 
               centerTitle={true} 
               isOpen={consentModalIsOpen}
              titleId={"report.acknowledgementOfConsentHeader"} 
              onClose={closeConsentModal}
              className={styles.consentModal} >
      <ReportSummary showWhatToExpect={false} className={styles.consents}/>
      <div className={styles.consentBody}>
        <FormattedMessage id={`report.acknowledgementOfConsentBody.consentScenario${consentScenario()}`} 
          values={{ 
            privacyPolicyLink: 
              <a href="https://www.vestasit.com/privacy-policy/" rel="noopener noreferrer" target="_blank" className={styles.privacyPolicyLink}>
                <FormattedMessage id="report.acknowledgementOfConsentBody.privacyPolicyLink" defaultMessage="vestasit.com/privacy-policy"/>
                <ExternalLinkIcon className={styles.externalLinkIcon}/>
              </a> 
          }}/>
      </div>
      <Button onClick={consent} style={ButtonStyle.Secondary} className={ButtonStyle.Full}>
        <FormattedMessage id="report.iConsent" />
      </Button>
      <div className={styles.iDoNotConsent}>
        <Button onClick={doNotConsent} style={ButtonStyle.Underline}>
          <FormattedMessage id="report.doNotConsent" />
        </Button>
      </div>
    </BaseModal>
  );
}
