
import { intl as reportIntl } from './report.intl';

export const intl = {
    'en-CA': { ...{
      'common.backButton': 'Go back',
      'common.backToMainMenu': 'Back to main menu',
      'common.backToHome': 'Back To Home',
      'common.returnHome': 'Return Home',
      'common.emailUs': 'Email Us',
      'common.viewMap': 'View map', 
      'common.hello': 'Hello',
      'common.yes': 'Yes',
      'common.no': 'No', 
      'common.show': 'Show', 
      'common.hide': 'Hide', 
      'common.resourcesNearMe': 'Find services near me',
      'common.resourcesLocationTypes': 'Address, City, or Postal Code...',
      'home.header.yourVoiceMatters': 'Your voice matters.',
      'home.header.whoIsVesta':'Who is Vesta?',
      'home.header.accessVestaJournal': 'Access your Vesta journal',
      'home.surveyLinkText': 'Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?',
      'home.documentingYourExperience.title': 'Documenting your experience.',
      'home.documentingYourExperience.body': 'The Vesta journal is a tool for anyone who has experienced in-person or online gender-based violence, misconduct, harassment, and discrimination. If you are in immediate crisis, please contact your local sexual assault hotline.',
      'home.point1.title': 'Option 1',
      'home.point1.body': 'Writing down your experience can help you process what happened and help you heal. You can fill out your Vesta journal online:',
      'home.point2.title': 'Option 2',
      'home.point2.body': 'Continue a journal you’ve already started:',
      'home.point3.title': 'Option 3',
      'home.point3.body': 'Download and print your own blank journal pages.',
      'home.accessVestaJournalButton': 'Start a new journal',
      'home.howCanVestaJournalHelpButton': 'How can my Vesta journal help me?',
      'home.location.flagText': 'By providing your city, you’re helping us identify patterns and create safer spaces.',
      'home.location.placeholder': 'Example: Toronto, Ontario',
      'home.multiSelect.header.title': 'Download the journal pages you need',
      'home.multiSelect.header.body': 'Each page contains questions to help you break down the details of your experience.',
      'home.multiSelect.option.aboutIncident': 'About the Incident',
      'home.multiSelect.option.unknownOffender': 'Unknown offender',
      'home.multiSelect.option.knownOffender': 'Known offender',
      'home.multiSelect.option.sensoryExperience': 'Sensory Experience',
      'home.multiSelect.option.impactOfExperience': 'The Impact of this Experience',
      'home.downloadButton': 'Download my journal pages',
      'home.clickthrough.header.title': 'How your Vesta journal can help you',
      'home.clickthrough.header.body': 'Your Vesta journal helps you document your experience using your own words, giving them a space to be recognized and documented in case you decide to report it now, or in the future to the authorities. Here are two important reminders on how your Vesta journal can help you:',
      'home.clickthrough.point1.title': 'Option 1',
      'home.clickthrough.point1.body': 'Access the Vesta journal online, and document your experience online. You have the option to print or save your journal after.',
      'home.clickthrough.goToLandingButton': 'I want to download my journal pages',
      'home.clickthrough.point2.title': 'Option 2',
      'home.clickthrough.point2.body': 'If you are considering connecting with someone who can help you process what happened, or investigate the incident(s), you can use your Vesta journal to help guide you through your conversation with them. Depending on your location, Vesta can help you connect to local organizations.',
      'home.clickthrough.learnMore': 'Learn more about other ways your Vesta journal can help you.',
      'home.clickthrough.connectButtonThroughJournalButton': 'Connect through your Vesta journal',
      'home.clickthrough.connectInPerson.title': 'If you prefer to connect in person',
      'home.clickthrough.connectInPerson.body': 'and have downloaded Vesta journal pages that you have printed out and filled in, remember to bring them with you.',
      'home.clickthrough.printAnotherCopy.title': 'If you have filled in the Vesta journal online before',
      'home.clickthrough.printAnotherCopy.body': 'and would like to print another copy, access your journal with the key and password provided to you.',
      'input.keyPlaceholder': 'A12B3C',
      'login.accessReport':'Access Report',
      'login.title': 'This report is password protected',
      'login.body': 'Please provide your password to access this report. Your key is case-sensitive.',
      'login.error.general': 'There was an error with logging in. Please try again later. If you continue to have problems, please contact support.',
      'login.error.invalidCredentials': "Sorry, but the Report Key or Password you entered in was wrong. Please try again, or contact us at support.",
      'login.error.timeout': 'There have been too many failed logins on this report. Please try again later. If you continue to have problems, please contact support.',
      'report.leaveQuestionBlankBlurb.ifNotSure':'If you aren\'t sure',
      'report.leaveQuestionBlankBlurb':'{ifNotSure} about your answer, it’s best to leave the question blank or choose “I don’t know”.',
      'report.reminderToSaveBlurb':'Your information will NOT be automatically saved. If you want to save your information for later, {saveYourReport}. Read our {privacyPolicy}',
      'report.leaveQuestionBlankBlurb.saveYourReport':'click SAVE before leaving the page',
      'report.leaveQuestionBlankBlurb.privacyPolicy':'Privacy Policy',
      'report.accessSavedReport': 'Access your VESTA journal',
      'report.beginFiling': 'Begin filing report',
      'report.letUsKnowName': 'To begin, let us know your name',
      'report.fullName': 'Name',
      'report.ageRange': 'Age',
      'report.phoneNumber':'Phone Number',
      'report.address': 'Address',
      'report.email': 'Email',
      'report.media': 'Media',
      'report.feedbackForm': 'feedback form',
      'report.methodOfContact': 'Method of Contact',
      'report.allowSupportCenterContact': 'Do you want access to a {supportCenter}?',
      'report.allowSupportCenterContact.counselor': 'counselor',
      'report.allowSupportCenterContact.supportCenter': 'support center',
      'report.allowSupportCenterContact.additionalInfo': 'Because you said {YES}, we’ll ask a few more questions so we can have the information to provide you with the support you need',
      'report.allowCampusSupport.additionalInfo': 'This service is currently only available at Queens university. If your university is not listed and want it added, please let us know in the {feedbackForm}',
      'report.allowVestaContact':'Do you want your {encryptedKey} to access your report emailed to you?',
      'report.allowVestaContact.encryptedKey':'encrypted key',
      'report.noVestaContactAllowed':'Please make sure you remember your encrypted key because it will not be emailed to you.',
      'report.allowPoliceContact': 'Allow {police} to contact you?',
      'report.allowPoliceContact.police': 'police',
      'report.allowPoliceContact.why': 'Why?',
      'report.howToAnswerIfUnsure': 'It’s okay to not know or not to be sure. It’s better to leave a question blank than to guess. There are no right or wrong answers. Offender information will only be shared if {highlight} and want to be connected to services.',
      'report.howToAnswerIfUnsure.highlight': 'you provide consent',
      'report.goThroughAtOwnPace': 'Go through this part of your journal at a pace that you are comfortable with. Take breaks when you need to. {highlight}',
      'report.goThroughAtOwnPace.highlight': ' This is your journal. You are in control.',
      'report.allowCampusContact':'If you are a student at {campusName} do you want to {campusSexualViolenceOffice}?',
      'report.allowCampusContact.campusSexualViolenceOffice':'connect with their Sexual Violence Prevention Services',
      'report.connectingCampusTitle':'Connecting to a campus \nSexual Violence Prevention office',
      'report.sexualViolencePrevention':'Sexual Violence Prevention and Response Office offers non-emergency support by helping you understand your on and off-campus options, connecting you to services, assessing possible next steps that are right for you, including filing a formal complaint. You can access all these services even if you decide not to file a formal complaint.',
      'report.connectingToCampus':'Connecting to a campus sexual violence prevention office is available to {membersOfCommuntiy}',
      'report.connectingToCampus.membersOfCommuntiy':'members of the campus community.',
      'whoIsVesta.body': '{vesta} is a social enterprise at the intersection of gender based technology and access to justice. We are here to help survivors get the help they want, from finding local services, to documenting their experience or reporting to the police. To learn more about Vesta, visit our website. {learnMore}',
      'whoIsVesta.body.vesta': 'Vesta',
      'whoIsVesta.visitOurWebsite': 'To learn more about Vesta, visit our website.',
      'report.howShareYourStory': 'How do you want to share your story?',
      'reportAnonymously.whatThisMeansForYou.header': 'What this means for you',
      'reportAnonymously.whatThisMeansForYou.body': 'This will allow you to share your experience anonymously. A summary of anonymous data will available to our partners.',
      'reportAnonymously.howThisReportWillBeUsed.body': '{notAPoliceReport} An investigation will not be started. Instead, the data filed in this report will be added to informational database used to aid law enforcement, local centres and campus partners to identify trends. By doing this, we hope to make our community a safer place for everyone. {learnMore}',
      'reportAnonymously.howThisReportWillBeUsed.body.notAPoliceReport': 'This is not a police report, nor is it a complaint.',
      'report.meaningForYou': 'What does this mean for you?',
      'accessSupport.header': "Lastly, we want to remind you that {choice}, and you can choose to share as much or as little with your counsellor. Vesta will not be sharing the details of your report.",
      'accessSupport.choice': "this is your choice",
      'accessSupport.closestCity': "If you are in Ontario, Canada what’s the closest city/town that would be convenient for you to receive support?",
      'accessSupport.attachReportDetails': 'Do you want to attach your report details to send to a {counsellor} or {supportCenter}?',
      'accessSupport.attachReportDetails.counsellor': 'counsellor',
      'accessSupport.attachReportDetails.supportCenter': 'support center',
      'accessSupport.attachReportDetails.stillReceiveSupport': 'You don’t need to attach the details of your report to receive support.',
      'accessSupport.typeOfSupport': 'What kind of support are you looking for?',
      'accessSupport.crisisLine.thankYou': 'Thank you for letting us know that you’d like to receive support through Crisis Line/Text',
      'accessSupport.crisisLine.takeADeepBreath': 'Take a deep breath. You are in control.',
      'accessSupport.crisisLine.supportOrganization': '{supportOrganization} has a resource accessible for you through this link: ',
      'accessSupport.crisisLine.link': '{link}',
      'accessSupport.crisisLine.chatNow': 'Chat Now',
      'accessSupport.crisisLine.goBack': 'Go back to your report',
      'accessSupport.crisisLine.linkDetails': 'This will open a new tab where you can access support via chat. Vesta will not be able to see or collect the information you share in the chat.',
      'accessSupport.intakeForm.header': 'You’ve selected {supportOrganization} as your nearest location to receive support',
      'accessSupport.intakeForm.bodyAffirmation': 'That\'s great!',
      'accessSupport.intakeForm.body': 'We will open a new tab to contact SAC {organizationName} for you to get direct access to them and their services. This tab will remain open for you to return to. Remember to:',
      'accessSupport.intakeForm.goToIntakeForm': 'Go to intake form',
      'accessSupport.intakeForm.fillOutintake': 'Fill out intake form in the new tab',
      'accessSupport.intakeForm.returnToVesta': 'Return to your Vesta report to save your record',
      'accessSupport.onlyCrisisLink':'{supportOrganization} only provides a crisis chat line. Your report {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.onlyIntakeLink':'{supportOrganization} has a seperate online intake form. Your record {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.intakeAndCrisisLink':'{supportOrganization} provides a crisis chat line and a seperate online intake form. Your report {cannot} be shared or filed to this location, but you may {continueToFillReport}',      
      'accessSupport.onlyPhone':'{supportOrganization} only provides a phone number. Your report {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.serviceMessage':'Services are being added regularly. Please check back often, or if you don’t see a service or resource you are interested in, you can provide anonymous feedback {feedbackForm}',
      'accessSupport.here':'here',
      'accessCampusSupport.header': 'You can choose to share as much or as little with your Sexual Violence Prevention office. Vesta will not be sharing the details of your report.',
      'accessCampusSupport.subheader': 'Vesta is currently partnered with Queen\'s University. If you are not selecting Queen\'s University and are selecting a different University or College, your report will not be submitted. Your report will be saved and Vesta will inform your organization of choice.',
      'accessCampusSupport.schoolCampus': 'School Campus',
      'accessCampusSupport.attachReportDetails': 'Do you want to attach your report details to send to {preventionOffice}?',
      'accessCampusSupport.attachReportDetails.preventionOffice': 'a campus Sexual Violence Prevention office',
      'contactLawEnfOrSuppCen.lawEnf.header': 'Law Enforcement',
      'contactLawEnfOrSuppCen.lawEnf.body.first': 'This is considered as submitting a report to police. {lawEnfWillHaveAccess} to your report.',
      'contactLawEnfOrSuppCen.lawEnf.body.first.lawEnfWillHaveAccess': 'Law enforcement will have access',
      'contactLawEnfOrSuppCen.lawEnf.body.second': 'Knowing how the experience has impacted you will be an important part of the case, if it should proceed to trial after the investigation.',
      'contactLawEnfOrSuppCen.suppCen.header': 'Support Center',
      'contactLawEnfOrSuppCen.suppCen.body.first': 'You will {suppCenWillHaveAccess} where you will be able to provide contact details so that a support worker can contact you to better understand the support you need or are looking for.',
      'contactLawEnfOrSuppCen.suppCen.body.first.suppCenWillHaveAccess': 'be directed to a partner website',
      'contactLawEnfOrSuppCen.suppCen.body.second': 'Disclose online to the Sexual Violence Prevention Office (SVPO). This means that your contact someone from the SVPO will connect with you to give you information about your options and on-campus services. If you choose to, you can file a complaint to the school.',
      'contactLawEnfOrSuppCen.campusCen.header': 'Campus',
      'contactLawEnfOrSuppCen.campusCen.body.first': 'Disclose online to the Sexual Violence Prevention Office (SVPO). This means that someone from the SVPO will connect with you to give you information about your options and on-campus services. {ifYouChose}',
      'contactLawEnfOrSuppCen.campusCen.body.first.ifYouChose': 'If you choose to, you can file a complaint to the school.',
      'privacyAndOutcomes.howIsItUsed.header': 'How is it used?',
      'privacyAndOutcomes.howIsItUsed.body': 'You will be asked trauma-informed questions and will also be able to choose if you would like to remain anonymous, if you want to submit your information or when to engage with the legal system. The information you enter and save will be encrypted. {noAccessWithoutConsent}, not even Vesta.',
      'privacyAndOutcomes.howIsItUsed.body.noAccessWithoutConsent': 'No one will be able to access it without your consent',
      'privacyAndOutcomes.outcomes.header': 'What are the outcomes?',
      'privacyAndOutcomes.outcomes.body': 'You can choose to remain anonymous or you can provide your contact details. If you choose to remain anonymous, Vesta, the local agency, and police will {onlySeeAggregatedData}. This will be used to gain information about patterns and repeat offenders. No investigation will be launched. If you provide your contact information, you will be contacted by the local police or agency, or both, depending on what you decide.',
      'privacyAndOutcomes.outcomes.body.onlySeeAggregatedData': 'only see anonymous aggregated data',
      'describingOffender.distinguishingFeatures.header': 'Think of prominent or distinguishing features',
      'describingOffender.distinguishingFeatures.body': 'Did they have an accent? Maybe a mannerism or way of speaking. Tattoos, scars, injuries, piercings or even interactions with others.',
      'describingOffender.physicalAppearance.header': 'Think of their physical appearance',
      'describingOffender.physicalAppearance.body': 'Did they have freckles, or dyed hair? Describe any facial hair, or odours, accessories or what they were wearing like a work uniform or otherwise.',
      'youngerThanSixteenInfo.title': 'Providing the help and safety you deserve',
      'youngerThanSixteenInfo.supportCenter': '{obligation} to report to the Police if you are under 16. They can only report if you provide identifying information; if you do not provide this information they will not make a report.',
      'youngerThanSixteenInfo.supportCenter.obligation': 'Support Center Workers have an obligation and a duty',
      'youngerThanSixteenInfo.police': '{obligation} to investigate. They can only investigate if you provide identifying information; if you do not provide this information they will be unable to investigate.',
      'youngerThanSixteenInfo.police.obligation': 'Police have a legal duty and responsibility',
      'reportStart.body.first': 'Take a deep breath, and think about the incident.',
      'reportStart.body.second': 'How you felt, what you saw, and what you heard.',
      'reportStart.body.third': 'Write down as much as possible of what you can remember about your experience. Include minor details. Take as much time as you need.',
      'reportStart.body.fourth': 'You choose who can see your story.',
      'reportStart.body.fifth': 'There are no wrong answers. Spelling and grammar are not important.',
      'reportStart.body.youAreInControl': 'You are in control',
      'reportStart.body.locationText': 'If the city/town that is most convenient for you is not listed and you’d like to see more resources in your area, let us know by providing your city or region.',
      'report.learnMore': 'Learn more',
      'reportStart.learnMore': 'I’d like to learn more',
      'report.reportAnonymously': 'Report anonymously',
      'report.reportAnonymously.Message': 'This will allow you to report your experience anonymously. You will NOT be asked to provide contact information. A summary of anonymous data will be available to police, centers and school partners to help them identify trends and statistics. {bold}',
      'report.reportAnonymously.Message.bold': 'This is not a police report or compliant. No investigation will be launched.',
      'report.contactLawEnfOrSuppCen': 'Connect with or report to',
      'report.contactLawEnfOrSuppCen.Message': 'This option allows you to connect with local services. {bold} Depending on your choice, you will be asked to provide your contact details so that they can connect with you and discuss your options and possible next steps.',
      'report.contactLawEnfOrSuppCen.Message.bold': 'You can decide to connect with all of them or just one or two the choice is yours.',
      'report.contactLawEnfOrSuppCen.Message.Disabled': '{bold1}{linebreak}At this time, {location} does not have organizations partnered with Vesta. To help us bring more services to your area, you may continue to {bold2}',
      'report.contactLawEnfOrSuppCen.Message.Disabled.bold1': 'We encourage you to download and print your journal and connect with your local services.',
      'report.contactLawEnfOrSuppCen.Message.Disabled.bold2': 'submit your journal anonymously.',
      'report.contactLawEnfOrSuppCen.Message.Disabled.locationDefault': 'your location',
      'report.saveAndSeek': 'Save and connect with inperson support services',
      'report.saveAndSeek.Message': 'We will connect you with a local sexual assault centre that offers free, confidential, non-judgmental support for all survivors 12+ regardless of gender or identity of recent and/or historic sexual violence. {bold} You can decide later if you want to share it with anyone.',
      'report.saveAndSeek.Message.bold': 'Your report will not be submitted.',
      'report.saveYourRecord': 'Save your record',
      'report.saveYourRecord.Message': '{bold} You will be asked to set a password to your report so only you may access it. You can decide later if you want to share it with anyone.',
      'report.saveYourRecord.Message.bold': 'Your report will not be submitted.',
      'report.whatDoesThisMean': 'What does this mean?',
      'report.meanForYou': 'What does this mean for you?',
      'report.notReadyStepAway': `I'm not ready. I need to step away.`,
      'resources.loading': 'Loading resources',
      'resources.noResources': 'No resources have been found',
      'resources.nearme.input.placeholder': 'Address, City or Postal Code...',
      'resources.whereDoIStart': 'Where do I start',
      'resources.whereDoIStart.understandFeelings' : 'I want to understand my feelings',
      'resources.whereDoIStart.whatHappensAfterFilingAReport' : 'I want to know what happens after I file a report',
      'resources.whereDoIStart.knowProcess' : 'I want to know the process',
      'resources.header.categories': 'Categories',
      'resources.header.resourcesInYourArea': 'Here are resources that are available in your area',
      'updateReport.reportInformation': 'Report Information',
      'report.submit': 'Submit report and view resources',
      'report.save': 'Save report',
      'report.submitted': 'Your report has been successfully submitted',
      'report.failedAttachment': 'Your attachment failed to upload, but don\'t worry! Your report was successfully submitted. Make sure you remember your {reportKey} and {password} so you can try uploading your attachments again later.',
      'report.failedAttachment.reportKey': 'Report Key',
      'report.failedAttachment.password': 'Password',
      'report.failedAttachment.title': 'We submitted your report, but there was a problem uploading your attachment',
      'report.completeReport': 'Complete report',
      'report.acknowledgementOfConsentHeader': 'Read Acknowledgement of Consent',
      'report.acknowledgementOfConsentBody.consentScenario1': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application, and understand that nobody will be able to view the details of your report without your consent. VESTA, sexual assault clinics, and the police will only have access to non-identifiable aggregate information. For more information about VESTA information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.consentScenario2': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application. VESTA and the police will only have access to non-identifiable aggregate information. For more information about VESTA information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.consentScenario3': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application. You also agree to share the details with the police to start an investigation. VESTA will only have access view non-identifiable aggregate information. For more information about information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.consentScenario4': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application, and to share the information with the police to start an investigation. VESTA will only have access to non-identifiable aggregate information. For more information about VESTA information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.privacyPolicyLink': 'vestasit.com/privacy-policy',
      'report.iConsent': 'I consent',
      'report.doNotConsent': 'I do not consent',
      'report.consentNotGiven': 'Consent not given',
      'report.SaveReport': 'Save record',
      'report.reviewReport': 'Review report',
      'report.saveRecord': 'Save record',
      'report.reviewSupport': 'Next',
      'reviewReport.whatToExpect': ', based on your answers, here is what you can expect to happen when your report is submitted',
      'reviewReport.whatToExpectNoName': 'Based on your answers, here is what you can expect to happen when your report is submitted',
      'report.editReport': 'Edit Report',
      'report.viewReportInfo': 'View all report info',
      'reportSummary.title': 'Report Summary',
      'reportSummary.allowPoliceContact.header': 'Police contact allowed: ',
      'reportSummary.allowPoliceContact.yes': 'You will be contacted by police. Your report will remain anonymous and encrypted, and will be used for statistical data. Law enforcement will be respecftul of your decision and only contact you twice.',
      'reportSummary.allowPoliceContact.no': 'You will not be contacted by police. Your report will remain anonymous and encrypted, and will only be used for statistical data.',
      'reportSummary.allowSupportCenterContact.header': 'Access to support center or counselor: ',
      'reportSummary.allowSupportCenterContact.yes': 'Your contact information will be sent to a support center or counselor.',
      'reportSummary.allowSupportCenterContact.no': 'Your contact information will remain anonymous and encrypted, and not be sent to a support center or counselor.',
      'reportSummary.allowCampusContact.header': 'Access to campus support: ',
      'reportSummary.allowCampusContact.yes': 'Your contact information be sent to a campus support center.',
      'reportSummary.allowCampusContact.no': 'Your contact information will remain anonymous and encrypted, and not be sent to a campus support center.',
      'reportSummary.allowVestaContact.header': 'Encryption key will ',
      'reportSummary.allowVestaContact.beEmailed': 'be emailed',
      'reportSummary.allowVestaContact.not be emailed': 'not be emailed',
      'reportSummary.allowVestaContact.yes': 'Your encryption key to access your report will be emailed to you.',
      'reportSummary.allowVestaContact.no': 'Your encryption key to access your report will not be emailed to you. Please be sure to remember your key and password combination.',
      'reportSummary.allowCampusReportDetails.header': 'Report Details for Campus',
      'reportSummary.allowCampusReportDetails.yes': 'Your report details will be sent to the campus support center with your contact information.',
      'reportSummary.allowCampusReportDetails.no': 'Your report details will remain anonymous and encrypted, and not be sent to a campus support center.',
      'reportSummary.allowSupportCenterReportDetails.header': 'Report Details for Support Center',
      'reportSummary.allowSupportCenterReportDetails.yes': 'Your report details will be sent to the support center or counselor with your contact information.',
      'reportSummary.allowSupportCenterReportDetails.no': 'Your report details will remain anonymous and encrypted, and not be sent to a support center or counselor.',
      'report.otherReports': 'Other submitted reports',
      'report.updateReport': 'Update Report',
      'report.saveAndExit': `I'm not ready. Save and exit.`,
      'report.saveExit' : 'Save and Exit',
      'report.successfullyUpdated': 'Report Successfully Updated',
      'report.successfullyDeleted': 'Report Successfully Deleted',
      'report.dontSave': 'I don’t want to save this report',
      'report.next': 'Next',
      'report.prev': 'Prev',
      'report.continueFilingForm':'Continue filing form',
      'report.why': 'Why?',
      'report.deleteBody': 'Are you sure you want to delete this report? This report will not be saved, and you will not be able to access this report after it has been deleted.',
      'report.delete': 'Delete report',
      'report.cancelAndReturn': 'Cancel and return',
      'report.form.error.required': 'This question is required',
      'supportTypes.individualCounselling': 'Individual Counselling',
      'supportTypes.groupCounselling': 'Group Counselling',
      'supportTypes.crisisLineTextChat': 'Crisis Line Text/Chat',
      'supportTypes.financialSupport': 'Financial Support',
      'personalInfo.finalDetails': 'You’re doing great. We need to ask a few more details to finalize the report.',
      'personalInfo.whyText': 'Why are we asking for this?',
      'personalInfo.whyModal.title': 'Why are we asking for this information?',
      'personalInfo.whyModal.consentTitle': 'Consent',
      'personalInfo.whyModal.consentTitleDetails': 'We ask for your consent to ensure you are aware that by submitting your report, you are consenting to be contacted. {highlight1} If you do not want to be contacted at this time, you can save your record without submitting it and decide later.',
      'personalInfo.whyModal.consentTitleDetails.Highlight1': 'It is your choice.',
      'personalInfo.whyModal.NameAndContactTitle': 'Name and Contact information', 
      'personalInfo.whyModal.NameAndContactDetails': 'When you consent to being contacted by police, a campus or a support center, we request that you provide both email and phone number {highlight1} Typing mistakes happen, please double check that you\'ve typed in the right email address or we may not be able to connect you to the support you selected.',
      'personalInfo.whyModal.NameAndContactDetails.Highlight1': 'to give everyone the best chance of connecting, and providing you with the support you need. We ask for your name to give us a way to properly address you, and to verify who it is we are speaking to.',
      'personalInfo.whyModal.button': 'I understand',
      'personalInfo.relationshipModal.title': 'Relationship to Offender and Consent to Police',
      'personalInfo.relationshipModal.description1': 'You have indicated that the offender is either {highlight}.',
      'personalInfo.relationshipModal.description2': 'If you decide to consent to police contact and once police are made aware, {highlight} and you cannot withdraw or decide to not proceed.',
      'personalInfo.relationshipModal.description3': '{highlight} Allowing police to contact you and access information about the offender is your choice.',
      'personalInfo.relationshipModal.description.highlight1': 'a romantic partner or family member',
      'personalInfo.relationshipModal.description.highlight2': 'they have a duty to investigate',
      'personalInfo.relationshipModal.description.highlight3': 'This is your story.',
      'personalInfo.relationshipModal.button': 'I understand',
      'personalInfo.relationshipModal.cancel': 'I changed my mind. I do not consent to police contact.',
      'personalInfo.howOldAreYou': 'How old are you?',
      'personalInfo.olderThanNineteen': '19 years old +',
      'personalInfo.sixteenToEighteen': '16-18',
      'personalInfo.youngerThanSixteen': 'younger than 16',
      'personalInfo.youngerThanSixteen.info': 'You’ve stated that you are younger than 16: We at Vesta want to ensure you are given the help and safety you deserve. {learnMore}',
      'personalInfo.youngerThanSixteen.info.learnMore': 'Read more on what that means for you.',
      'personalInfo.form.error.required': 'This field is required',
      'personalInfo.form.error.methodOfContact.fill': 'Please fill in method of contact',
      'personalInfo.form.error.methodOfContact.fill.email': 'Please fill in email',
      'personalInfo.form.error.methodOfContact.fill.phone': 'Please fill in phone number',
      'personalInfo.form.error.methodOfContact.select': 'Please select method of contact',
      'personalInfo.form.error.consentRequired': 'Please let us know if you consent to be contacted',
      'personalInfo.form.error.invalidEmail': 'Must be a valid email',
      'personalInfo.form.error.invalidPhone': 'Must be a 10 digit phone number',
      'personalInfo.filedReportBefore': 'Have you filed a report before?',
      'personalInfo.addReportKey': 'Add report key',
      'personalInfo.addReportKeys': 'Add report keys',
      'personalInfo.consentToPoliceModal.title': 'Consent to Police',
      'personalInfo.consentToPoliceModal.text': 'You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location.',
      'personalInfo.consentToPoliceModal.textFirstHighlight': ' the incident location is needed. If you do not remember the exact location, adding the city of the incident is enough.',
      'personalInfo.consentToPoliceModal.textSecondHighlight': 'cannot',
      'personalInfo.consentToPoliceModal.button': 'Save location',
      'personalInfo.consentToPoliceModal.continueWithoutConsent': 'Continue without location and police consent',
      'personalInfo.consetToPoliceModal.locationAddressLabel': 'Please provide the location of the incident',
      'passwordModal.title': 'Password Protect Your Information',
      'passwordModal.body.first': 'We will bring you to a resource to help ground yourself. Remember, you are in control.',
      'passwordModal.body.second.title': 'Your journal has been downloaded!',
      'passwordModal.body.second.message': 'If you would like to come back to this journal in the future, for any reason, such as simply saving your information digitally or choosing to connect to an organization in the future, protect your information by setting a password on your journal. Only you may use this password.',
      'passwordModal.rule.charactersCount': '8 or more characters',
      'passwordModal.rule.specialCharacter': 'Includes special character',
      'passwordModal.rule.numbersCount': 'Includes one or more numbers',
      'passwordModal.rule.uppercaseCount': 'Includes at least one uppercase letter',
      'errorModal.general.main': `Something went awry - don't worry, we're working on it!`,
      'errorModal.submit.main': `We're having trouble submitting your report right now.`,
      'errorModal.save.main': `We're having trouble saving your report right now.`,
      'errorModal.delete.main': `We're having trouble deleting your report right now.`,
      'errorModal.tryAgainLaterOrContactSupport': 'Please try again later, or contact Vesta support at {vestaSupport}',
      'errorModal.contactSupportAndTryAgainLater': 'Contact support at {vestaSupport} and try again later.',
      'errorModal.contactSupport': 'Contact support at {vestaSupport}.',
      'errorModal.vestaSupport': 'support@vestasit.com',
      'errorModal.tryAgainLater': 'You may remain on this page and try again later.',
      'errorModal.doNotHoldInformation': 'If you leave this page, we do not hold your information.',
      'errorModal.safeAndSecure': 'Your information always remains safe and secure.',
      'updateReport.updateYourReport': 'Update your report',
      'updateReport.reportUpdates': 'Report Updates',
      'updateReport.submissionDate': 'Submission date',
      'updateReport.allowSupportCenterContact': 'Allow Support Center Contact',
      'updateReport.allowCampusContact': 'Allow Campus Contact',
      'updateReport.allowPoliceContact': 'Allow Police Contact',
      'updateReport.allowVestaContact': 'Allow Vesta Contact',
      'updateReport.allowSupportCenterReportDetails': 'Allow Support Center to View report Details',
      'updateReport.allowCampusReportDetails': 'Allow Campus Contact to View Report Details',
      'updateReport.attachments': 'Uploaded Attachments',
      'updateReport.methodOfContact': 'Preferred method of contact',
      'updateReport.notReadyButton': 'I\'m not ready',
      'updateReport.incidentInformation': 'Incident Information',
      'updateReport.incidentDescriptionLabel': 'Incident Description',
      'updateReport.incidentDescriptionText': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nisl neque, rhoncus sed magna id, suscipit euismod orci. Vestibulum mollis pulvinar augue, eu egestas nisl semper vel. Curabitur accumsan felis nec venenatis fringilla. Ut molestie nisl a erat euismod molestie. Vivamus feugiat nisl id dolor finibus mollis in vulputate nulla. Phasellus volutpat mauris nunc, nec ullamcorper magna tristique non. Nullam dapibus eu eros vel vestibulum. Nulla id nulla iaculis, rutrum est id, suscipit lorem. Nunc non scelerisque quam.',
      'updateReport.incidentOccurrence': 'Incident Occurrence',
      'updateReport.incidentTime': 'Incident Time',
      'updateReport.incidentLocation': 'Incident Location',
      'updateReport.offenderInformation': 'Offender Information',
      'updateReport.offenderKnown': 'Offender Known',
      'updateReport.offenderGender': 'Offender Gender',
      'updateReport.attachedFiles': 'Attached Files',
      'updateReport.vehicleModel': 'VehicleModel',
      'updateReport.circumcision': 'Circumcision',
      'updateReport.privacyInfo': 'Your report is always encrypted, and your information will remain safe and protected.',
      'updateReport.campusSupportOrganization': 'Campus Support Location',
      'sensoryInformation.title': 'Sensory Information',
      'thirdParty.title': 'Third Party Report',
      'thirdParty.body.first': 'Anonymous reporting is information regarding your sexual assault which, at your request, we can share with the police, without them knowing any of your personal details.',
      'thirdParty.body.second': 'If you decide you don’t want to report the assault to the police, we will ask if you want to provide anonymous information to them. If you do, we will capture an account of what happened but without any of your details and submit this to the police. This will help them build a picture about what is happening in their area but without knowing anything about you – again, this is completely up to you.',
      'thirdParty.list.header': 'The app allows you to:',
      'thirdParty.list.item1': 'Document the event and your experience and save for a later date. No personal details are collected',
      'thirdParty.list.item2': 'Submit an anonymous report without any of your personal details',
      'thirdParty.list.item3': `Submit a report and request to have a local counselor from a community agency, such as a sexual assault center to contact you`,
      'thirdParty.list.item4': 'Submit a report and request that police contact you',
      'thirdParty.body.last': 'Should you choose option 1, 2 or 3 and later want to talk to the police, by entering your personal encryption key, you can amend your report and choose to have the police contact you.',
      'common.loading': 'loading...',
      'form.attachments.attachFile': 'Attach File',
      'button.submitReport': 'Submit Report',
      'button.saveReport': 'Save Report',
      'button.deleteReport': 'I don’t want to save this report',
      'button.savePassword': 'Save password',
      'button.home': 'Home',
      'button.okay': 'Okay',
      'button.start': `Let's start`,
      'button.showMeResources': 'Show me resources',
      'button.viewVestaResources': 'View Vesta resources',
      'button.iUnderstand': 'I understand',
      'reportBreak.title': 'Do you need a break?',
      'reportBreak.nextSectionAbout' : 'The next section will ask you about anything you might have seen, heard, felt, or tasted, as well as how this experience has impacted your life. These questions may trigger trauma responses.',
      'reportBreak.proceedOnlyIfComfortable' : 'Proceed only if you feel comfortable or have access to support.',
      'reportBreak.skipOrContinue' : 'You can skip this section or continue if you feel comfortable.',
      'report.additionalInformation': 'Add comments, notes, or any additional information you would like to include in your initial report',
      'report.additionalInformation.placeholder': `Did you remember anything you'd like to share?`,
      'savedReportModal.savedReport': 'Your report has been saved',
      'savedReportModal.savedReportDetails': `You're doing great {reportUser}. You can return to this report at any time using this key and your selected password. Your key is case-sensitive.`,
      'submitReport.title':'Your report has been successfully submitted',
      'submitReportModal.reportKeyHeader': 'You can use this key and your selected password\nto access your report.',
      'submitReport.body':'{youDidGreat} You’ve taken an amazing step towards healing. Now, we’ll bring you to explore more resources to help you through your journey. Take a deep breath and have a cold glass of water. You are in control.',
      'submitReport.resourceReminder':'Once you view Vesta resources, your session will end. You will need to access your journal using your key and password if you need to download your report later.',
      'submitReport.body.youDidGreat':'You did great!',
      'feedback.experience': 'How was your experience with Vesta?',
      'feedback.helpful': 'How helpful was Vesta for you?',
      'feedback.recommend': 'How likely would you recommend Vesta?',
      'feedback.improve': 'How do you think Vesta could improve?',
      'feedback.improve.placeholder': 'What can we improve about the experience, or ease of use?',
      'feedback.submit': 'Submit feedback',
      'feedback.successfullySubmitted': 'Feedback Successfully Submitted',
      'feedback.errorMessageFill': 'Please answer all feedback form questions before submitting.',
      'vesta.termsOfUse': 'Terms of Use',
      'vesta.privacyPolicy': 'Privacy Policy',
      'search211Term.placeholder': 'Enter a topic or organization',
      'reportSuccess.doesNotHaveInfo': 'Vesta does not have access to any information\nprovided through these links.',
      'reportSuccess.youSelectedLocation': 'You selected {organizationLocation} as the closest city/town that would be convenient for you to receive support.',
      'reportSuccess.orgBlurb': '{organizationName} provides free, confidential, non-judgmental support for all survivors 12+ regardless of gender or identity of recent and/or historic sexual violence.',
      'reportSuccess.providesIntake': '{organizationName} provides a separate intake form {fillOutIntake}',
      'reportSuccess.fillOutIntake': 'for you to fill out. Filling out this intake form does not submit your Vesta report to {organizationName}.',
      'reportSuccess.providesOther': '{organizationName} provides other resources if you want immediate access to support.',
      'reportSuccess.intakeForm': 'Intake form:',
      'reportSuccess.crisisLine': 'Crisis line:',
      'reportSuccess.phoneNumber': 'Phone number:',
      'reportSuccess.youSelectedCampus': 'You’ve selected {campusName}. You will be contacted directly through your preferred method of contact.',
      'reportSuccess.policeContact': 'You will be contacted by {police} directly through your preferred method of contact.',
      'reportSuccess.police': 'police',
      'downloadModal.paragraph': 'You may continue filling out your Vesta journal online to connect to local services. Your information will {highlight} be saved nor shared if you choose not to.',
      'downloadModal.pararaph.highlight': 'not',
      'downloadModal.button.downloadLink': 'Here is your print link for your journal. (If you want a PDF, you can print and then choose to save to PDF)',
      'downloadModal.button.link': 'Download / Print Link',
      'downloadModal.button.continue': 'I would like to continue to connect',
      'downloadModal.button.updateSubmittedReport': 'I would like to update the submitted report',
      'downloadModal.button.saveUnsubmittedReport': 'I would like to save and continue another time',
      'report.saveAndDownload': 'Save and download / print my journal',
      'report.downloadOnly': 'Download / print my journal',
      'surveyRequestModal.button.yes': 'Yes, I would like to',
      'surveyRequestModal.button.no': 'No, thank you',
      'surveyRequestModal.body.default': 'Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?',
      'surveyRequestModal.body.downloadPagesConfirmation': 'Your journal pages have been downloaded! Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?',
      'reportStart.locationNotes.policeAndSupportAndCampus': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres, police, or campus services.',
      'reportStart.locationNotes.policeAndSupport': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres or police.',
      'reportStart.locationNotes.supportAndCampus': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres or campus services.',
      'reportStart.locationNotes.policeAndCampus': 'Because you selected {location}, you have the option to continue to connect to local services, including police or campus services.',
      'reportStart.locationNotes.policeOnly': 'Because you selected {location}, you have the option to continue to connect to local services, including police.',
      'reportStart.locationNotes.supportOnly': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres.',
      'reportStart.locationNotes.campusOnly': 'Because you selected {location}, you have the option to continue to connect to local services, including campus sexual assault services.',
      'reportStart.locationNotes.noOrganizations': 'You may continue to fill out your journal entry, however, there are no local services in your area that you may connect to through Vesta.',
      'reportStart.flagText.second': 'If you have downloaded your Vesta journal before, you may also upload a copy of it as you go through the form.',
      'survey.header': 'Thank you for answering our survey',
      'survey.subHeader': 'Your answers help us identify trends and patterns to create safer spaces for everyone.',
      'survey.where.placeholder': 'Example: Toronto, Ontario',
      'survey.organizationTypeSuggestions.placeholder': 'Examples: My workplace, my school, my sports organization, my local police service, my neighbourhood organization, other',
      'survey.serviceTypeSuggestions.placeholder': 'Example: Lawyer, therapist, self-defence, other',
      'survey.button.submit': 'Submit survey',
      'survey.button.returnHome': 'Return to the homepage',
      'survey.submitModal.title': 'Thank you so much!',
      'survey.submitModal.text': 'Your survey answers have been successfully submitted.',
      'survey.submitModal.submitButton': 'Return to homepage',
      'report.print.footer.fillOutJournal': 'You also have the option to fill out your journal out online at:',
    }, ...(reportIntl['en-CA'])},
    'fr-CA': { ...{
      'common.backButton': 'Go back',
      'common.backToMainMenu': 'Back to main menu',
      'common.backToHome': 'Back To Home',
      'common.returnHome': 'Return Home',
      'common.emailUs': 'Email Us',
      'common.viewMap': 'View map', 
      'common.hello': 'Hello',
      'common.yes': 'Yes',
      'common.no': 'No', 
      'common.show': 'Show', 
      'common.hide': 'Hide', 
      'common.resourcesNearMe': 'Find services near me',
      'common.resourcesLocationTypes': 'Address, City, or Postal Code...',
      'home.header.yourVoiceMatters': 'Your voice matters.',
      'home.header.whoIsVesta':'Who is Vesta?',
      'home.header.accessVestaJournal': 'Access your Vesta journal',
      'home.surveyLinkText': 'Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?',
      'home.documentingYourExperience.title': 'Documenting your experience.',
      'home.documentingYourExperience.body': 'The Vesta journal is a tool for anyone who has experienced in-person or online gender-based violence, misconduct, harassment, and discrimination. If you are in immediate crisis, please contact your local sexual assault hotline.',
      'home.point1.title': 'Option 1',
      'home.point1.body': 'Writing down your experience can help you process what happened and help you heal. You can fill out your Vesta journal online:',
      'home.point2.title': 'Option 2',
      'home.point2.body': 'Continue a journal you’ve already started:',
      'home.point3.title': 'Option 3',
      'home.accessVestaJournalButton': 'Start a new journal',
      'home.howCanVestaJournalHelpButton': 'How can my Vesta journal help me?',
      'home.point3.body': 'Download and print your own blank journal pages.',
      'home.location.flagText': 'By providing your city, you’re helping us identify patterns and create safer spaces.',
      'home.location.placeholder': 'Example: Toronto, Ontario',
      'home.multiSelect.header.title': 'Download the journal pages you need',
      'home.multiSelect.header.body': 'Each page contains questions to help you break down the details of your experience.',
      'home.multiSelect.option.aboutIncident': 'About the Incident',
      'home.multiSelect.option.unknownOffender': 'Unknown offender',
      'home.multiSelect.option.knownOffender': 'Known offender',
      'home.multiSelect.option.sensoryExperience': 'Sensory Experience',
      'home.multiSelect.option.impactOfExperience': 'The Impact of this Experience',
      'home.downloadButton': 'Download my journal pages',
      'home.clickthrough.header.title': 'How your Vesta journal can help you',
      'home.clickthrough.header.body': 'Your Vesta journal helps you document your experience using your own words, giving them a space to be recognized and documented in case you decide to report it now, or in the future to the authorities. Here are two important reminders on how your Vesta journal can help you:',
      'home.clickthrough.point1.title': 'Option 1',
      'home.clickthrough.point1.body': 'Access the Vesta journal online, and document your experience online. You have the option to print or save your journal after.',
      'home.clickthrough.goToLandingButton': 'I want to download my journal pages',
      'home.clickthrough.point2.title': 'Option 2',
      'home.clickthrough.point2.body': 'If you are considering connecting with someone who can help you process what happened, or investigate the incident(s), you can use your Vesta journal to help guide you through your conversation with them. Depending on your location, Vesta can help you connect to local organizations.',
      'home.clickthrough.learnMore': 'Learn more about other ways your Vesta journal can help you.',
      'home.clickthrough.connectButtonThroughJournalButton': 'Connect through your Vesta journal',
      'home.clickthrough.connectInPerson.title': 'If you prefer to connect in person',
      'home.clickthrough.connectInPerson.body': 'and have downloaded Vesta journal pages that you have printed out and filled in, remember to bring them with you.',
      'home.clickthrough.printAnotherCopy.title': 'If you have filled in the Vesta journal online before',
      'home.clickthrough.printAnotherCopy.body': 'and would like to print another copy, access your journal with the key and password provided to you.',      
      'input.keyPlaceholder': 'A12B3C',
      'login.accessReport':'Access Report',
      'login.title': 'This report is password protected',
      'login.body': 'Please provide your password to access this report. Your key is case-sensitive.',
      'login.error.general': 'There was an error with logging in.  Please try again later. If you continue to have problems, please contact support.',
      'login.error.invalidCredentials': "Sorry, but the Report Key or Password you entered in was wrong. Please try again, or contact us at support.",
      'login.error.timeout': 'There have been too many failed logins on this report. Please try again later. If you continue to have problems, please contact support.',
      'report.leaveQuestionBlankBlurb.ifNotSure':'If you aren\'t sure',
      'report.leaveQuestionBlankBlurb':'{ifNotSure} about your answer, it’s best to leave the question blank or choose “I don’t know”.',
      'report.reminderToSaveBlurb':'Your information will NOT be automatically saved. If you want to save your information for later, {saveYourReport}. Read our {privacyPolicy}',
      'report.leaveQuestionBlankBlurb.saveYourReport':'click SAVE before leaving the page',
      'report.leaveQuestionBlankBlurb.privacyPolicy':'Privacy Policy',
      'report.accessSavedReport': 'Access your VESTA journal',
      'report.beginFiling': 'Begin filing report',
      'report.letUsKnowName': 'To begin, let us know your name',
      'report.fullName': 'Name',
      'report.ageRange': 'Age',
      'report.phoneNumber':'Phone Number',
      'report.address': 'Address',
      'report.email': 'Email',
      'report.media': 'Media',
      'report.feedbackForm': 'feedback form',
      'report.methodOfContact': 'Preferred method of contact',
      'updateReport.notReadyButton': 'I\'m not ready',
      'report.allowSupportCenterContact': 'Do you want access to a {supportCenter}?',
      'report.allowSupportCenterContact.counselor': 'counselor',
      'report.allowSupportCenterContact.supportCenter': 'support center',
      'report.allowSupportCenterContact.additionalInfo': 'Because you said {YES}, we’ll ask a few more questions so we can have the information to provide you with the support you need',
      'report.allowCampusSupport.additionalInfo': 'This service is currently only available at Queens university. If your university is not listed and want it added, please let us know in the {feedbackForm}',
      'report.allowVestaContact':'Do you want your {encryptedKey} to access your report emailed to you?',
      'report.allowVestaContact.encryptedKey':'encrypted key',
      'report.noVestaContactAllowed':'Please make sure you remember your encrypted key because it will not be emailed to you.',
      'report.allowPoliceContact': 'Allow {police} to contact you?',
      'report.allowPoliceContact.police': 'police',
      'report.allowPoliceContact.why': 'Why?',
      'report.howToAnswerIfUnsure': 'It’s okay to not know or not to be sure. It’s better to leave a question blank than to guess. There are no right or wrong answers. Offender information will only be shared if {highlight} and want to be connected to services.',
      'report.howToAnswerIfUnsure.highlight': 'you provide consent',
      'report.goThroughAtOwnPace': 'Go through this part of your journal at a pace that you are comfortable with. Take breaks when you need to. {highlight}',
      'report.goThroughAtOwnPace.highlight': ' This is your journal. You are in control.',
      'report.allowCampusContact':'If you are a student at {campusName} do you want to {campusSexualViolenceOffice}?',
      'report.allowCampusContact.campusSexualViolenceOffice':'connect with their Sexual Violence Prevention Services',
      'report.connectingCampusTitle':'Connecting to a campus \nSexual Violence Prevention office',
      'report.sexualViolencePrevention':'Sexual Violence Prevention and Response Office offers non-emergency support by helping you understand your on and off-campus options, connecting you to services, assessing possible next steps that are right for you, including filing a formal complaint. You can access all these services even if you decide not to file a formal complaint.',
      'report.connectingToCampus':'Connecting to a campus sexual violence prevention office is available to {membersOfCommuntiy}',
      'report.connectingToCampus.membersOfCommuntiy':'members of the campus community.',
      'whoIsVesta.body': '{vesta} is a social enterprise at the intersection of gender based technology and access to justice. We are here to help survivors get the help they want, from finding local services, to documenting their experience or reporting to the police. To learn more about Vesta, visit our website. {learnMore}',
      'whoIsVesta.body.vesta': 'Vesta',
      'whoIsVesta.visitOurWebsite': 'To learn more about Vesta, visit our website.',
      'report.howShareYourStory': 'How do you want to share your story?',
      'reportAnonymously.howThisReportWillBeUsed.body': '{notAPoliceReport} An investigation will not be started. Instead, the data filed in this report will be added to informational databases used to aid law enforcement. By doing this, we hope to make our community a safer place for everyone. {learnMore}',
      'reportAnonymously.howThisReportWillBeUsed.body.notAPoliceReport': 'This is not a police report.',
      'report.meaningForYou': 'What does this mean for you?',
      'accessSupport.onlyCrisisLink':'{supportOrganization} only provides a crisis chat line. Your report {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.onlyIntakeLink':'{supportOrganization} has a seperate online intake form. Your record {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.intakeAndCrisisLink':'{supportOrganization} provides a crisis chat line and a seperate online intake form. Your report {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.onlyPhone':'{supportOrganization} only provides a phone number. Your report {cannot} be shared or filed to this location, but you may {continueToFillReport}',
      'accessSupport.serviceMessage':'Services are being added regularly. Please check back often, or if you don’t see a service or resource you are interested in, you can provide anonymous feedback {feedbackForm}',
      'accessSupport.here':'here',
      'accessCampusSupport.header': 'You can choose to share as much or as little with your Sexual Violence Prevention office. Vesta will not be sharing the details of your report.',
      'accessCampusSupport.subheader': 'Vesta is currently partnered with Queen\'s University. If you are not selecting Queen\'s University and are selecting a different University or College, your report will not be submitted. Your report will be saved and Vesta will inform your organization of choice.',
      'accessCampusSupport.schoolCampus': 'School Campus',
      'accessCampusSupport.attachReportDetails': 'Do you want to attach your report details to send to {preventionOffice}?',
      'accessCampusSupport.attachReportDetails.preventionOffice': 'a campus Sexual Violence Prevention office',
      'contactLawEnfOrSuppCen.lawEnf.header': 'Law Enforcement',
      'contactLawEnfOrSuppCen.lawEnf.body.first': 'This is considered as submitting a report to police. {lawEnfWillHaveAccess} to your report.',
      'contactLawEnfOrSuppCen.lawEnf.body.first.lawEnfWillHaveAccess': 'Law enforcement will have access',
      'contactLawEnfOrSuppCen.lawEnf.body.second': 'Knowing how the experience has impacted you will be an important part of the case, if it should proceed to trial after the investigation.',
      'contactLawEnfOrSuppCen.suppCen.header': 'Support Center',
      'contactLawEnfOrSuppCen.suppCen.body.first': 'You will {suppCentWillHaveAccess} where you will be able to provide contact details so that a support worker can contact you to better understand the support you need or are looking for.',
      'contactLawEnfOrSuppCen.suppCen.body.first.suppCenWillHaveAccess': 'Support Center Workers will have access',
      'contactLawEnfOrSuppCen.suppCen.body.second': 'You will be contacted by a Support Center Worker to aid you in your road to healing.',
      'contactLawEnfOrSuppCen.campusCen.header': 'Campus',
      'contactLawEnfOrSuppCen.campusCen.body.first': 'Disclose online to the Sexual Violence Prevention Office (SVPO). This means that someone from the SVPO will connect with you to give you information about your options and on-campus services. {ifYouChose}',
      'contactLawEnfOrSuppCen.campusCen.body.first.ifYouChose': 'If you choose to, you can file a complaint to the school.',
      'privacyAndOutcomes.howIsItUsed.header': 'How is it used?',
      'privacyAndOutcomes.howIsItUsed.body': 'You will be asked trauma-informed questions and will also be able to choose if you would like to remain anonymous, if you want to submit your information or when to engage with the legal system. The information you enter and save will be encrypted. {noAccessWithoutConsent}, not even Vesta.',
      'privacyAndOutcomes.howIsItUsed.body.noAccessWithoutConsent': 'No one will be able to access it without your consent',
      'privacyAndOutcomes.outcomes.header': 'What are the outcomes?',
      'privacyAndOutcomes.outcomes.body': 'You can choose to remain anonymous or you can provide your contact details. If you choose to remain anonymous, Vesta, the local agency, and police will {onlySeeAggregatedData}. This will be used to gain information about patterns and repeat offenders. No investigation will be launched. If you provide your contact information, you will be contacted by the local police or agency, or both, depending on what you decide.',
      'privacyAndOutcomes.outcomes.body.onlySeeAggregatedData': 'only see anonymous aggregated data',
      'describingOffender.distinguishingFeatures.header': 'Think of prominent or distinguishing features',
      'describingOffender.distinguishingFeatures.body': 'Did they have an accent? Maybe a mannerism or way of speaking. Tattoos, scars, injuries, piercings or even interactions with others.',
      'describingOffender.physicalAppearance.header': 'Think of their physical appearance',
      'describingOffender.physicalAppearance.body': 'Did they have freckles, or dyed hair? Describe any facial hair, or odours, accessories or what they were wearing like a work uniform or otherwise.',
      'youngerThanSixteenInfo.title': 'Providing the help and safety you deserve',
      'youngerThanSixteenInfo.supportCenter': '{obligation} to report to the Police if you are under 16. They can only report if you provide identifying information; if you do not provide this information they will not make a report.',
      'youngerThanSixteenInfo.supportCenter.obligation': 'Support Center Workers have an obligation and a duty',
      'youngerThanSixteenInfo.police': '{obligation} to investigate. They can only investigate if you provide identifying information; if you do not provide this information they will be unable to investigate.',
      'youngerThanSixteenInfo.police.obligation': 'Police have a legal duty and responsibility',
      'reportStart.body.first': 'Take a deep breath, and think about the incident.',
      'reportStart.body.second': 'How you felt, what you saw, and what you heard.',
      'reportStart.body.third': 'Write down as much as possible of what you can remember about your experience. Include minor details. Take as much time as you need.',
      'reportStart.body.fourth': 'You choose who can see your story.',
      'reportStart.body.fifth': 'There are no wrong answers. Spelling and grammar are not important.',
      'reportStart.body.youAreInControl': 'You are in control',
      'reportStart.body.locationText': 'If the city/town that is most convenient for you is not listed and you’d like to see more resources in your area, let us know by providing your city or region.',
      'report.learnMore': 'Learn more',
      'reportStart.learnMore': 'I’d like to learn more',
      'report.reportAnonymously': 'Report anonymously',
      'report.contactLawEnfOrSuppCen': 'Connect with or report to',
      'report.contactLawEnfOrSuppCen.Message': 'This option allows you to connect with local services. {bold} Depending on your choice, you will be asked to provide your contact details so that they can connect with you and discuss your options and possible next steps.',
      'report.contactLawEnfOrSuppCen.Message.bold': 'You can decide to connect with all of them or just one or two the choice is yours.',
      'report.contactLawEnfOrSuppCen.Message.Disabled': '{bold1}{linebreak}At this time, {location} does not have organizations partnered with Vesta. To help us bring more services to your area, you may continue to {bold2}',
      'report.contactLawEnfOrSuppCen.Message.Disabled.bold1': 'We encourage you to download and print your journal and connect with your local services.',
      'report.contactLawEnfOrSuppCen.Message.Disabled.bold2': 'submit your journal anonymously.',
      'report.contactLawEnfOrSuppCen.Message.Disabled.locationDefault': 'your location',
      'report.saveAndSeek': 'Save and connect with inperson support services',
      'report.saveYourRecord': 'Save your record',
      'report.whatDoesThisMean': 'What does this mean?',
      'report.notReadyStepAway': `I'm not ready. I need to step away.`,
      'resources.loading': 'Loading resources',
      'resources.noResources': 'No resources have been found',
      'resources.nearme.input.placeholder': 'Address, City or Postal Code...',
      'resources.whereDoIStart': 'Where do I start',
      'resources.whereDoIStart.understandFeelings' : 'I want to understand my feelings',
      'resources.whereDoIStart.whatHappensAfterFilingAReport' : 'I want to know what happens after I file a report',
      'resources.whereDoIStart.knowProcess' : 'I want to know the process',
      'resources.header.categories': 'Categories',
      'resources.header.resourcesInYourArea': 'Here are resources that are available in your area',
      'updateReport.reportInformation': 'Report Information',
      'report.submit': 'Submit report and view resources',
      'report.save': 'Save report',
      'report.submitted': 'Your report has been successfully submitted',
      'report.failedAttachment': 'Your attachment failed to upload, but don\'t worry! Your report was successfully submitted. Make sure you remember your {reportKey} and {password} so you can try uploading your attachments again later.',
      'report.failedAttachment.reportKey': 'Report Key',
      'report.failedAttachment.password': 'Password',
      'report.failedAttachment.title': 'We submitted your report, but there was a problem uploading your attachment',
      'report.completeReport': 'Complete report',
      'report.acknowledgementOfConsentHeader': 'Read Acknowledgement of Consent',
      'report.acknowledgementOfConsentBody.consentScenario1': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application, and understand that nobody will be able to view the details of your report without your consent. VESTA, sexual assault clinics, and the police will only have access to non-identifiable aggregate information. For more information about VESTA information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.consentScenario2': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application, and to have a member of the sexual assault clinic contact you to discuss the details of your report. VESTA and the police will only have access to non-identifiable aggregate information. For more information about VESTA information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.consentScenario3': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application, and to have a member of the sexual assault clinic contact you to discuss the details of your report. You also agree to share the details with the police to start an investigation. VESTA will only have access view non-identifiable aggregate information. For more information about information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.consentScenario4': 'By clicking the “I consent” button, you agree to save the information you entered in the VESTA application, and to share the information with the police to start an investigation. VESTA and the sexual assault clinic will only have access to non-identifiable aggregate information. For more information about VESTA information practices, please review their full privacy policy at {privacyPolicyLink}',
      'report.acknowledgementOfConsentBody.privacyPolicyLink': 'vestasit.com/privacy-policy',
      'report.iConsent': 'I consent',
      'report.doNotConsent': 'I do not consent',
      'report.consentNotGiven': 'Consent not given',
      'report.report.SaveReport': 'Save Record',
      'report.reviewReport': 'Review report',
      'report.saveRecord': 'Save record',
      'report.reviewSupport': 'Next',
      'reviewReport.whatToExpect': ', based on your answers, here is what you can expect to happen when your report is submitted',
      'reviewReport.whatToExpectNoName': 'Based on your answers, here is what you can expect to happen when your report is submitted',
      'report.editReport': 'Edit Report',
      'report.viewReportInfo': 'View all report info',
      'reportSummary.title': 'Report Summary',
      'reportSummary.allowPoliceContact.header': 'Police contact allowed: ',
      'reportSummary.allowPoliceContact.yes': 'You will be contacted by police. Your report will remain anonymous and encrypted, and will be used for statistical data. Law enforcement will be respecftul of your decision and only contact you twice.',
      'reportSummary.allowPoliceContact.no': 'You will not be contacted by police. Your report will remain anonymous and encrypted, and will only be used for statistical data.',
      'reportSummary.allowSupportCenterContact.header': 'Access to support center or counselor: ',
      'reportSummary.allowSupportCenterContact.yes': 'Your contact information will remain encrypted, and be sent to a support center or counselor.',
      'reportSummary.allowSupportCenterContact.no': 'Your contact information will remain anonymous and encrypted, and not be sent to a support center or counselor.',
      'reportSummary.allowCampusContact.header': 'Access to support center or counselor: ',
      'reportSummary.allowCampusContact.yes': 'Your contact information will remain encrypted, and be sent to a campus support center.',
      'reportSummary.allowCampusContact.no': 'Your contact information will remain anonymous and encrypted, and not be sent to a campus support center or counselor.',
      'reportSummary.allowVestaContact.header': 'Encryption key will ',
      'reportSummary.allowVestaContact.beEmailed': 'be emailed',
      'reportSummary.allowVestaContact.not be emailed': 'not be emailed',
      'reportSummary.allowVestaContact.yes': 'Your encryption key to access your report will be emailed to you.',
      'reportSummary.allowVestaContact.no': 'Your encryption key to access your report will not be emailed to you. Please be sure to remember your key and password combination.',
      'reportSummary.allowCampusReportDetails.header': 'Report Details for Campus',
      'reportSummary.allowCampusReportDetails.yes': 'Your report details will be sent to the campus support center with your contact information.',
      'reportSummary.allowCampusReportDetails.no': 'Your report details will remain anonymous and encrypted, and not be sent to a campus support center.',
      'reportSummary.allowSupportCenterReportDetails.header': 'Report Details for Support Center',
      'reportSummary.allowSupportCenterReportDetails.yes': 'Your report details will be sent to the support center or counselor with your contact information.',
      'reportSummary.allowSupportCenterReportDetails.no': 'Your report details will remain anonymous and encrypted, and not be sent to a support center or counselor.',
      'report.otherReports': 'Other submitted reports',
      'report.updateReport': 'Update Report',
      'report.saveAndExit': `I'm not ready. Save and exit.`,
      'report.successfullyUpdated': 'Report Successfully Updated',
      'report.successfullyDeleted': 'Report Successfully Deleted',
      'report.dontSave': 'I don’t want to save this report',
      'report.next': 'Next',
      'report.prev': 'Prev',
      'report.continueFilingForm':'Continue filing form',
      'report.why': 'Why?',
      'report.deleteBody': 'Are you sure you want to delete this report? This report will not be saved, and you will not be able to access this report after it has been deleted.',
      'report.delete': 'Delete report',
      'report.cancelAndReturn': 'Cancel and return',
      'report.form.error.required': 'This question is required',
      'supportTypes.individualCounselling': 'Individual Counselling',
      'supportTypes.groupCounselling': 'Group Counselling',
      'supportTypes.crisisLineTextChat': 'Crisis Line Text/Chat',
      'supportTypes.financialSupport': 'Financial Support',
      'personalInfo.finalDetails': 'You’re doing great. We need to ask a few more details to finalize the report.',
      'personalInfo.whyText': 'Why are we asking for this?',
      'personalInfo.whyModal.title': 'Why are we asking for this information?',
      'personalInfo.whyModal.consentTitle': 'Consent',
      'personalInfo.whyModal.consentTitleDetails': 'We ask for your consent to ensure you are aware that by submitting your report, you are consenting to be contacted. {highlight1} If you do not want to be contacted at this time, you can save your record without submitting it and decide later.',
      'personalInfo.whyModal.consentTitleDetails.Highlight1': 'It is your choice.',
      'personalInfo.whyModal.NameAndContactTitle': 'Name and Contact information', 
      'personalInfo.whyModal.NameAndContactDetails': 'When you consent to being contacted by police, a campus or a support center, we request that you provide both email and phone number {highlight1} Typing mistakes happen, please double check that you\'ve typed in the right email address or we may not be able to connect you to the support you selected.',
      'personalInfo.whyModal.NameAndContactDetails.Highlight1': 'to give everyone the best chance of connecting, and providing you with the support you need. We ask for your name to give us a way to properly address you, and to verify who it is we are speaking to.',
      'personalInfo.whyModal.button': 'I understand',
      'personalInfo.relationshipModal.title': 'Relationship to Offender and Consent to Police',
      'personalInfo.relationshipModal.description1': 'You have indicated that the offender is either {highlight}.',
      'personalInfo.relationshipModal.title.description2': 'If you decide to consent to police contact and once police are made aware, {highlight} and you cannot withdraw or decide to not proceed.',
      'personalInfo.relationshipModal.description3': '{highlight} Allowing police to contact you and access information about the offender is your choice.',
      'personalInfo.relationshipModal.description.highlight1': 'a romantic partner or family member',
      'personalInfo.relationshipModal.description.highlight2': 'they have a duty to investigate',
      'personalInfo.relationshipModal.description.highlight3': 'This is your story.',
      'personalInfo.relationshipModal.button': 'I understand',
      'personalInfo.relationshipModal.cancel': 'I changed my mind. I do not consent to police contact.',
      'personalInfo.howOldAreYou': 'How old are you?',
      'personalInfo.olderThanNineteen': '19 years old +',
      'personalInfo.sixteenToEighteen': '16-18',
      'personalInfo.youngerThanSixteen': 'younger than 16',
      'personalInfo.youngerThanSixteen.info': 'You’ve stated that you are younger than 16: We at Vesta want to ensure you are given the help and safety you deserve. {learnMore}',
      'personalInfo.youngerThanSixteen.info.learnMore': 'Read more on what that means for you.',
      'personalInfo.form.error.required': 'This field is required',
      'personalInfo.form.error.methodOfContact.fill': 'Please fill in method of contact',
      'personalInfo.form.error.methodOfContact.fill.email': 'Please fill in email',
      'personalInfo.form.error.methodOfContact.fill.phone': 'Please fill in phone number',
      'personalInfo.form.error.methodOfContact.select': 'Please select method of contact',
      'personalInfo.form.error.consentRequired': 'Please let us know if you consent to be contacted',
      'personalInfo.form.error.invalidEmail': 'Must be a valid email',
      'personalInfo.form.error.invalidPhone': 'Must be a 10 digit phone number',
      'personalInfo.filedReportBefore': 'Have you filed a report before?',
      'personalInfo.addReportKey': 'Add report key',
      'personalInfo.addReportKeys': 'Add report keys',
      'personalInfo.consentToPoliceModal.title': 'Consent to Police',
      'personalInfo.consentToPoliceModal.text': 'You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location.',
      'personalInfo.consentToPoliceModal.textFirstHighlight': ' the incident location is needed. If you do not remember the exact location, adding the city of the incident is enough.',
      'personalInfo.consentToPoliceModal.textSecondHighlight': 'cannot',
      'personalInfo.consentToPoliceModal.button': 'Save location',
      'personalInfo.consentToPoliceModal.continueWithoutConsent': 'Continue without location and police consent',
      'personalInfo.consetToPoliceModal.locationAddressLabel': 'Please provide the location of the incident',
      'passwordModal.title': 'Password Protect Your Information',
      'passwordModal.body.first': 'We will bring you to a resource to help ground yourself. Remember, you are in control.',
      'passwordModal.body.second.title': 'Your journal has been downloaded!',
      'passwordModal.body.second.message': 'If you would like to come back to this journal in the future, for any reason, such as simply saving your information digitally or choosing to connect to an organization in the future, protect your information by setting a password on your journal. Only you may use this password.',
      'passwordModal.rule.charactersCount': '8 or more characters',
      'passwordModal.rule.specialCharacter': 'Includes special character',
      'passwordModal.rule.numbersCount': 'Includes one or more numbers',
      'passwordModal.rule.uppercaseCount': 'Includes at least one uppercase letter',
      'errorModal.general.main': `Something went awry - don't worry, we're working on it!`,
      'errorModal.submit.main': `We're having trouble submitting your report right now.`,
      'errorModal.save.main': `We're having trouble saving your report right now.`,
      'errorModal.delete.main': `We're having trouble deleting your report right now.`,
      'errorModal.tryAgainLaterOrContactSupport': 'Please try again later, or contact Vesta support at {vestaSupport}',
      'errorModal.contactSupportAndTryAgainLater': 'Contact support at {vestaSupport} and try again later.',
      'errorModal.contactSupport': 'Contact support at {vestaSupport}.',
      'errorModal.vestaSupport': 'support@vestasit.com',
      'errorModal.tryAgainLater': 'You may remain on this page and try again later.',
      'errorModal.doNotHoldInformation': 'If you leave this page, we do not hold your information.',
      'errorModal.safeAndSecure': 'Your information always remains safe and secure.',
      'updateReport.updateYourReport': 'Update your report',
      'updateReport.reportUpdates': 'Report Updates',
      'updateReport.submissionDate': 'Submission date',
      'updateReport.allowSupportCenterContact': 'Allow Support Center Contact',
      'updateReport.allowCampusContact': 'Allow Campus Contact',
      'updateReport.allowPoliceContact': 'Allow Police Contact',
      'updateReport.allowVestaContact': 'Allow Vesta Contact',
      'updateReport.reportLocation': 'Report Location',
      'updateReport.allowSupportCenterReportDetails': 'Allow Support Center to View Report Details',
      'updateReport.allowCampusReportDetails': 'Allow Campus Contact to View Report Details',
      'updateReport.attachments': 'Uploaded Attachments',
      'updateReport.methodOfContact': 'Method of Contact',
      'updateReport.incidentInformation': 'Incident Information',
      'updateReport.incidentDescriptionLabel': 'Incident Description',
      'updateReport.incidentDescriptionText': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nisl neque, rhoncus sed magna id, suscipit euismod orci. Vestibulum mollis pulvinar augue, eu egestas nisl semper vel. Curabitur accumsan felis nec venenatis fringilla. Ut molestie nisl a erat euismod molestie. Vivamus feugiat nisl id dolor finibus mollis in vulputate nulla. Phasellus volutpat mauris nunc, nec ullamcorper magna tristique non. Nullam dapibus eu eros vel vestibulum. Nulla id nulla iaculis, rutrum est id, suscipit lorem. Nunc non scelerisque quam.',
      'updateReport.incidentOccurrence': 'Incident Occurrence',
      'updateReport.incidentTime': 'Incident Time',
      'updateReport.incidentLocation': 'Incident Location',
      'updateReport.offenderInformation': 'Offender Information',
      'updateReport.offenderKnown': 'Offender Known',
      'updateReport.offenderGender': 'Offender Gender',
      'updateReport.attachedFiles': 'Attached Files',
      'updateReport.vehicleModel': 'VehicleModel',
      'updateReport.circumcision': 'Circumcision',
      'updateReport.privacyInfo': 'Your report is always encrypted, and your information will remain safe and protected.',
      'updateReport.campusSupportOrganization': 'Campus Support Location',
      'sensoryInformation.title': 'Sensory Information',
      'thirdParty.title': 'Third Party Report',
      'thirdParty.body.first': 'Anonymous reporting is information regarding your sexual assault which, at your request, we can share with the police, without them knowing any of your personal details.',
      'thirdParty.body.second': 'If you decide you don’t want to report the assault to the police, we will ask if you want to provide anonymous information to them. If you do, we will capture an account of what happened but without any of your details and submit this to the police. This will help them build a picture about what is happening in their area but without knowing anything about you – again, this is completely up to you.',
      'thirdParty.list.header': 'The app allows you to:',
      'thirdParty.list.item1': 'Document the event and your experience and save for a later date. No personal details are collected',
      'thirdParty.list.item2': 'Submit an anonymous report without any of your personal details',
      'thirdParty.list.item3': `Submit a report and request to have a local counselor from a community agency, such as a sexual assault center to contact you`,
      'thirdParty.list.item4': 'Submit a report and request that police contact you',
      'thirdParty.body.last': 'Should you choose option 1, 2 or 3 and later want to talk to the police, by entering your personal encryption key, you can amend your report and choose to have the police contact you.',
      'common.loading': 'loading...',
      'form.attachments.attachFile': 'Attach File',
      'button.submitReport': 'Submit Report',
      'button.saveReport': 'Save Report',
      'button.deleteReport': 'I would like to delete this report instead',
      'button.savePassword': 'Save password',
      'button.home': 'Home',
      'button.okay': 'Okay',
      'button.start': `Let's start`,
      'button.showMeResources': 'Show me resources',
      'button.viewVestaResources': 'View Vesta resources',
      'button.iUnderstand': 'I understand',
      'reportBreak.title': 'Do you need a break?',
      'reportBreak.nextSectionAbout' : 'The next section will ask you about anything you might have seen, heard, felt, or tasted, as well as how this experience has impacted your life. These questions may trigger trauma responses.',
      'reportBreak.proceedOnlyIfComfortable' : 'Proceed only if you feel comfortable or have access to support.',
      'reportBreak.skipOrContinue' : 'You can skip this section or continue if you feel comfortable.',
      'report.additionalInformation': 'Add comments, notes, or any additional information you would like to include in your initial report',
      'report.additionalInformation.placeholder': `Did you remember anything you'd like to share?`,
      'savedReportModal.savedReport': 'Your report has been saved',
      'savedReportModal.savedReportDetails': `You're doing great {reportUser}. You can return to this report at any time using this key and your selected password. Your key is case-sensitive.`,
      'seekingSupportModal': 'Seeking Support',
      'seekingSupportModal.BodyM': 'Support Centers strive to connect survivors to a counsellor as quickly as possible. However, depending on which counselling program you select there may be a wait list.  Wait lists can be a little as 2 weeks or as long as several months. Each Centre is unique and you will know more once you connect with them directly.',
      'submitReport.title':'Your report has been successfully submitted',
      'submitReportModal.reportKeyHeader': 'You can use this key and your selected password\nto access your report.',
      'submitReport.body':'{youDidGreat} You’ve taken an amazing step towards healing. Now, we’ll bring you to explore more resources to help you through your journey. Take a deep breath and have a cold glass of water. You are in control.',
      'submitReport.resourceReminder':'Once you view Vesta resources, your session will end. You will need to access your journal using your key and password if you need to download your report later.',
      'submitReport.body.youDidGreat':'You did great!',
      'feedback.experience': 'How was your experience with Vesta?',
      'feedback.helpful': 'How helpful was Vesta for you?',
      'feedback.recommend': 'How likely would you recommend Vesta?',
      'feedback.improve': 'How do you think Vesta could improve?',
      'feedback.improve.placeholder': 'What can we improve about the experience, or ease of use?',
      'feedback.submit': 'Submit feedback',
      'feedback.successfullySubmitted': 'Feedback Successfully Submitted',
      'feedback.errorMessageFill': 'Please answer all feedback form questions before submitting.',
      'vesta.termsOfUse': 'Terms of Use',
      'vesta.privacyPolicy': 'Privacy Policy',
      'search211Term.placeholder': 'Enter a topic or organization',
      'reportSuccess.doesNotHaveInfo': 'Vesta does not have access to any information\nprovided through these links.',
      'reportSuccess.youSelectedLocation': 'You selected {organizationLocation} as the closest city/town that would be convenient for you to receive support.',
      'reportSuccess.orgBlurb': '{organizationName} provides free, confidential, non-judgmental support for all survivors 12+ regardless of gender or identity of recent and/or historic sexual violence.',
      'reportSuccess.providesIntake': '{organizationName} provides a separate intake form {fillOutIntake}',
      'reportSuccess.fillOutIntake': 'for you to fill out. Filling out this intake form does not submit your Vesta report to {organizationName}.',
      'reportSuccess.providesOther': '{organizationName} provides other resources if you want immediate access to support.',
      'reportSuccess.intakeForm': 'Intake form:',
      'reportSuccess.crisisLine': 'Crisis line:',
      'reportSuccess.phoneNumber': 'Phone number:',
      'reportSuccess.youSelectedCampus': 'You’ve selected {campusName}. You will be contacted directly through your preferred method of contact.',
      'reportSuccess.policeContact': 'You will be contacted by {police} directly through your preferred method of contact.',
      'reportSuccess.police': 'police',
      'downloadModal.paragraph': 'You may continue filling out your Vesta journal online to connect to local services. Your information will {highlight} be saved nor shared if you choose not to.',
      'downloadModal.pararaph.highlight': 'not',
      'downloadModal.button.downloadLink': 'Here is your print link for your journal. (If you want a PDF, you can print and then choose to save to PDF)',
      'downloadModal.button.link': 'Download / Print Link',
      'downloadModal.button.continue': 'I would like to continue to connect',
      'downloadModal.button.updateSubmittedReport': 'I would like to update the submitted report',
      'downloadModal.button.saveUnsubmittedReport': 'I would like to save and continue another time',
      'report.saveAndDownload': 'Save and download / print my journal',
      'report.downloadOnly': 'Download / print my journal',
      'surveyRequestModal.button.yes': 'Yes, I would like to',
      'surveyRequestModal.button.no': 'No, thank you',
      'surveyRequestModal.body.default': 'Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?',
      'surveyRequestModal.body.downloadPagesConfirmation': 'Your journal pages have been downloaded! Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?',
      'reportStart.locationNotes.policeAndSupportAndCampus': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres, police, or campus services.',
      'reportStart.locationNotes.policeAndSupport': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres or police.',
      'reportStart.locationNotes.supportAndCampus': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres or campus services.',
      'reportStart.locationNotes.policeAndCampus': 'Because you selected {location}, you have the option to continue to connect to local services, including police or campus services.',
      'reportStart.locationNotes.policeOnly': 'Because you selected {location}, you have the option to continue to connect to local services, including police.',
      'reportStart.locationNotes.supportOnly': 'Because you selected {location}, you have the option to continue to connect to local services, including support centres.',
      'reportStart.locationNotes.campusOnly': 'Because you selected {location}, you have the option to continue to connect to local services, including campus sexual assault services.',
      'reportStart.locationNotes.noOrganizations': 'You may continue to fill out your journal entry, however, there are no local services in your area that you may connect to through Vesta.',
      'reportStart.flagText.second': 'If you have downloaded your Vesta journal before, you may also upload a copy of it as you go through the form.',
      'survey.header': 'Thank you for answering our survey',
      'survey.subHeader': 'Your answers help us identify trends and patterns to create safer spaces for everyone.',
      'survey.where.placeholder': 'Example: Toronto, Ontario',
      'survey.organizationTypeSuggestions.placeholder': 'Examples: My workplace, my school, my sports organization, my local police service, my neighbourhood organization, other',
      'survey.serviceTypeSuggestions.placeholder': 'Example: Lawyer, therapist, self-defence, other',
      'survey.button.submit': 'Submit survey',
      'survey.button.returnHome': 'Return to the homepage',
      'survey.submitModal.title': 'Thank you so much!',
      'survey.submitModal.text': 'Your survey answers have been successfully submitted.',
      'survey.submitModal.submitButton': 'Return to homepage',
      'report.print.footer.fillOutJournal': 'You also have the option to fill out your journal out online at:',
      'report.print.footer.companyName': 'VESTA Social Innovation Technologies',
    }, ...(reportIntl['fr-CA'])},
  };
