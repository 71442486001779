import React, { FC, ReactNode, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { useEditUserState, UserInfo } from 'context/editUser';

interface PhoneNumberProps {
  required?: boolean;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  errorClassName?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  errorMessage?: ReactNode;
}

export const PhoneNumber : FC<PhoneNumberProps> = ({ 
  onChange, 
  disabled = false,
  errorMessage, 
  required, 
  className, 
  labelClassName, 
  fieldClassName, 
  errorClassName = styles.displayNone 
}) => {

  const userInfo: UserInfo = useEditUserState();

  const first = useRef<HTMLInputElement>(null);
  const middle = useRef<HTMLInputElement>(null);
  const last = useRef<HTMLInputElement>(null);

  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>, nextField: any) => {
    const element = e.target;
    if (element.value.length >= element.size) {
      element.value = element.value.substring(0, element.size);
      nextField.current.focus();
    }

    const phoneNumber = [first, middle, last].map((phoneNumberSection) => {
      return phoneNumberSection.current?.value;
    });

    if (onChange) {
      onChange(phoneNumber.join(''));
    }
  }


  return (
    <div className={classNames(styles.phoneNumberContainer, className)}>
      
      <label htmlFor="PhoneNumber" className={classNames(styles.label, labelClassName)}>
        <FormattedMessage id="report.phoneNumber" defaultMessage="Phone number"/>
        {required && (<span className={styles.required}>*</span>)}
      </label>
        
      <input onChange={(e) => { onPhoneNumberChange(e, middle); }} 
        placeholder="123" size={3} ref={first} 
        className={classNames(errorMessage && styles.errorField, styles.textInput, styles.phoneNumber3Digits, fieldClassName)} 
        value={userInfo.phoneNumber?.slice(0,3) || ''}
        disabled={disabled}/>

      <input onChange={(e) => { onPhoneNumberChange(e, last); }} 
        placeholder="456" size={3} ref={middle}
        className={classNames(errorMessage && styles.errorField, styles.textInput, styles.phoneNumber3Digits, fieldClassName)} 
        value={userInfo.phoneNumber?.slice(3,6) || ''}
        disabled={disabled}/>

      <input onChange={(e) => { onPhoneNumberChange(e, first); }} 
        placeholder="7891" size={4} ref={last}
        className={classNames(errorMessage && styles.errorField, styles.textInput, styles.phoneNumber4Digits, fieldClassName)} 
        value={userInfo.phoneNumber?.slice(6,10) || ''}
        disabled={disabled}/> 
      
      {errorMessage && <p className={classNames(styles.errorMessage, errorClassName)}>{errorMessage}</p>}

    </div>
  );
};
