import React, { ReactNode } from "react";
import { StarIcon, StarFilledIcon } from "components/Icons";
import styles from './styles.module.scss';
import _ from "lodash";

interface StarScaleProps {
  feedbackQuestion: ReactNode;
  starNumberChosen: number;
  setStarNumberChosen: (starNumberClicked: number) => void;
}

export const StarScale = ({ feedbackQuestion, starNumberChosen, setStarNumberChosen }: StarScaleProps ) => {

  const onStarClick = (starNumberClicked: number) => {
    setStarNumberChosen(starNumberClicked);
  }

  return (
    <div className={styles.starScale}>
      
      <h1>{feedbackQuestion}</h1>
      <div className={styles.scale}>
        { 
        _.range(1,6).map((starNumber) => {
          return (
            (starNumberChosen >= starNumber) 
              ? ( <StarFilledIcon  className={styles.starIconSize} 
                    key={`star${starNumber}`}
                    onClick={() => { onStarClick(starNumber); }}/> ) 
              : ( <StarIcon  className={styles.starIconSize} 
                    key={`star${starNumber}`}
                    onClick={() => { onStarClick(starNumber); }}/> )
          );
        }) 
        }
      </div>
    </div>
  );
}
