import timezoneDate from "date-and-time";
import { isNullOrUndefined } from "util";
import useHasOrganizationTypes from "./useHasOrganizationsTypes";
import { HasOrganizationTypes } from "actions/organizationActions";

export const convertYesNoToBool = (selected: string) => {
  return (selected === "Yes") ? true :  false; 
}

export const convertBoolToYesOrNo = (selected: boolean) => {
  // returns translation keys for yes no
  return selected ? "common.yes" : "common.no"; 
}

export const isUndefined = (input: any) => {
return ( input === undefined );
}

export const sortByOrder = (a: any, b: any) => { 
  return a.order < b.order ? -1 : a.order > b.order ? 1 : 0; 
};

export const dateFormatter = (date: string | Date, dateTime: boolean = false) => {
  let parsedDate;
  if (typeof date === "string") {
    parsedDate = timezoneDate.parse(
      date.replace('T', ' ').replace(/\..*$|T/gm, ''),  // quick fix in case a date wasn't formatted using dateFormatter before storing in db
      "YYYY-MM-DD HH:mm:ss", 
      true // if this is false it assumes that the INPUT dateString is a local date-time, while true says that it is UTC date-time
    );
  } else {
    parsedDate = date;
  }
  if (parsedDate && (parsedDate instanceof Date)) {
    const format = dateTime ? 'MMMM DD, YYYY, hh:mm A' : 'MMMM DD, YYYY';
    return timezoneDate.format(parsedDate, format, false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
  }
};

export const parsedPhoneNumber = (phoneNumber: any) => {
  const parsedNumbers = !isNullOrUndefined(phoneNumber) && phoneNumber.match(/(\d+)/g);
  return parsedNumbers ? parsedNumbers.join('') : '';
}

export const openInNewTab = (url: string | undefined): void => {
  if (!url) return;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

// TODO: this may need to be changed if we start recording partnered organizations (T-51 on VESTA 2023 trello board)
export const hasPartneredOrganization = (hasPartneredOrganizationTypes: HasOrganizationTypes) => {
  return hasPartneredOrganizationTypes.support || hasPartneredOrganizationTypes.campusSupport || hasPartneredOrganizationTypes.police;
}