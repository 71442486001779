import React from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import { LearnMoreLink, LearnMoreLinkType } from "../../Common/LearnMore";
import styles from './styles.module.scss';
import { PhoenixImage } from "components/Images";
import classNames from "classnames";


interface WhoIsVestaModalProps {
  whoIsVestaModalIsOpen: boolean;
  setWhoIsVestaModalIsOpen: (value: boolean) => void;
}

export const WhoIsVestaModal = ({ 
  whoIsVestaModalIsOpen, 
  setWhoIsVestaModalIsOpen 
} : WhoIsVestaModalProps) => {
  
  const closeModal = () => {
    setWhoIsVestaModalIsOpen(false);
  }
  
  return (
    <BaseModal title={<FormattedMessage id="home.whoIsVesta" defaultMessage="Who is Vesta?"/>}
      centerTitle={true}
      isOpen={whoIsVestaModalIsOpen} 
      onClose={closeModal} 
      className={styles.whoIsVestaModal}
      icon={<PhoenixImage className={styles.phoenixImage}/>}>

        <p className={styles.body}>
          <FormattedMessage id="whoIsVesta.body" 
            values={{ 
              vesta: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="whoIsVesta.body.vesta" defaultMessage="Vesta"/></span>, 
              learnMore: (<LearnMoreLink learnMoreLinkType={LearnMoreLinkType.About} 
                            className={styles.learnMoreLink}
                            customText={<FormattedMessage id="whoIsVesta.visitOurWebsite" defaultMessage="To learn more about Vesta, visit our website."/>}/>)
            }}
            defaultMessage="Vesta is a social enterprise at the intersection of gender based technology and access to justice. We are here to help survivors get the help they want, from finding local services, to documenting their experience or reporting to the police. To learn more about Vesta, visit our website."/>
        </p>

    </BaseModal>
  );
}
