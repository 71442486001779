import { HasOrganizationTypes } from 'actions/organizationActions';
import styles from './styles.module.scss';  
import { FormattedMessage } from "react-intl";

export interface LocationNotesProps {
  location: string,
  hasOrganizationTypes: HasOrganizationTypes,
}

export const LocationNotes = ({
  location,
  hasOrganizationTypes,
}: LocationNotesProps) => {

  const {
    police,
    support,
    campusSupport,
  } = hasOrganizationTypes;

  if (police && support && campusSupport) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.policeAndSupportAndCampus" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including support centres, police, or campus services."
        values={{location}}
      />
    );
  } else if (police && support) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.policeAndSupport" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including support centres or police."
        values={{location}}
      />
    );
  } else if (support && campusSupport) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.supportAndCampus" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including support centres or campus services."
        values={{location}}
      />
    );
  } else if (police && campusSupport) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.policeAndCampus" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including police or campus services."
        values={{location}}
      />
    );
  } else if (police) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.policeOnly" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including police."
        values={{location}}
      />
    );
  } else if (support) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.supportOnly" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including support centres."
        values={{location}}
      />
    );
  } else if (campusSupport) {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.campusOnly" 
        defaultMessage="Because you selected {location}, you have the option to continue to connect to local services, including campus sexual assault services."
        values={{location}}
      />      
    );
  } else {
    return (
      <FormattedMessage 
        id="reportStart.locationNotes.noOrganizations" 
        defaultMessage="You may continue to fill out your journal entry, however, there are no local services in your area that you may connect to through Vesta."
      />
    );
  }
}