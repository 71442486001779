import React from "react";
import { FormattedMessage } from "react-intl";
import { FileIcon, ThoughtBubbleIcon } from "components/Icons";
import classNames from "classnames";
import styles from './styles.module.scss';
import { UnSubmittedAttachmentType, UploadedAttachmentType } from "actions/awsActions";
import { ReportData, useEditReportState } from 'context/editReport';

interface HowToAnswerIfUnsureProps {
  className?: string;
}

interface AttachmentProps {
  attachmentName: string;
  isLastElement: boolean;
}

export const Attachment = ({ attachmentName, isLastElement}: AttachmentProps) => {
  const comma = isLastElement ? "" : ",";
  return (
    <div className={styles.attachment}>
      <div className={styles.fileIcon}><FileIcon/></div>
      S
      <p>{attachmentName}{comma}</p>
    </div>
  );
}

export const Attachments = ( { className }: HowToAnswerIfUnsureProps ) => {
  const reportData: ReportData = useEditReportState();
  const unUploadedAttachments: UnSubmittedAttachmentType[] = reportData.attachmentsToUpload || [];
  const uploadedAttachments: UploadedAttachmentType[] = reportData.uploadedAttachments || [];
  return (
    <div className={classNames(styles.howToAnswerIfUnsure, className)}>
            {([...unUploadedAttachments, ...uploadedAttachments]
            )?.map((attachment: UnSubmittedAttachmentType | UploadedAttachmentType, index, arr) => {
            return <>
              <Attachment
                key={attachment.filename}
                attachmentName={attachment.filename}
                isLastElement={arr.length === index + 1}
              />
            </>
        })}
    </div>
  );
}
