import React from "react";
import { FormattedMessage } from 'react-intl';
import { GenericHeaderBodyConstructorModal } from "../GenericHeaderBodyConstructorModal";
import { LearnMoreLink, LearnMoreLinkType } from "components/Common/LearnMore";
import styles from './styles.module.scss';

interface ReportAnonymouslyModalProps {
  reportAnonymouslyModalIsOpen: boolean;
  setReportAnonymouslyModalIsOpen: (value: boolean) => void;
}

const reportAnonymouslyModalContent = [
  {
    header: <FormattedMessage id="reportAnonymously.whatThisMeansForYou.header" defaultMessage="What this means for you"/>,
    body: <FormattedMessage id="reportAnonymously.whatThisMeansForYou.body" defaultMessage="This will allow you to share your experience anonymously. A summary of anonymous data will available to our partners."/>
  },
  {
    header: <FormattedMessage id="reportAnonymously.howThisReportWillBeUsed.header" defaultMessage="How this report will be used"/>,
    body: (
      <FormattedMessage id="reportAnonymously.howThisReportWillBeUsed.body" 
        values={{ 
          notAPoliceReport: <span className={styles.highlightedText}><FormattedMessage id="reportAnonymously.howThisReportWillBeUsed.body.notAPoliceReport" defaultMessage="This is not a police report."/></span>,
          learnMore: <LearnMoreLink learnMoreLinkType={LearnMoreLinkType.PrivacyPolicy} className={styles.learnMoreLink}/> 
        }} 
        defaultMessage="This is not a police report nor is a complaint. An investigation will not be started. Instead, the data filed in this report will be added to informational database used to aid law enforcement, local centres and campus partners to identify trends. By doing this, we hope to make our community a safer place for everyone."/>
    )
  }
];

export const ReportAnonymouslyModal = ({ reportAnonymouslyModalIsOpen, setReportAnonymouslyModalIsOpen } : ReportAnonymouslyModalProps) => {
  return (
    <GenericHeaderBodyConstructorModal 
      genericHeaderBodyConstructorModalIsOpen={reportAnonymouslyModalIsOpen}
      setGenericHeaderBodyConstructorModalIsOpen={setReportAnonymouslyModalIsOpen}
      className={styles.reportAnonymouslyModal}
      title={<h5><FormattedMessage id="report.reportAnonymously" defaultMessage="Report Anonymously"/></h5>}
      headerBodyArray={reportAnonymouslyModalContent}/>
  );
}
