import React from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "../../../components/Buttons/Button";
import classNames from "classnames";
import {QueensLogoImage} from "components/Images";


interface CampusSupportModalProps {
  campusSupportModalIsOpen: boolean;
  setCampusSupportModalIsOpen: (value: boolean) => void;
}

export const CampusSupportModal = ({ 
  campusSupportModalIsOpen, 
  setCampusSupportModalIsOpen 
} : CampusSupportModalProps) => {
  
  const closeModal = () => {
    setCampusSupportModalIsOpen(false);
  }
  
  return (
    <BaseModal title={<FormattedMessage id="report.connectingCampusTitle" defaultMessage="Connecting to a campus \n Sexual Violence Prevention office"/>}
      centerTitle={true}
      isOpen={campusSupportModalIsOpen} 
      onClose={closeModal} 
      className={styles.campusModalContent}
      showCloseButton={false}
      titleStyle={styles.title}
     >
      <div className={styles.image}>
        <QueensLogoImage />
      </div>

      <p className={styles.body}>
        <FormattedMessage id="report.sexualViolencePrevention"
          defaultMessage="Sexual Violence Prevention and Response Office offers non-emergency support by helping you understand your on and off-campus options, connecting you to services, assessing possible next steps that are right for you, including filing a formal complaint. You can access all these services even if you decide not to file a formal complaint."/>
      </p>

      <p className={styles.body}>
        <FormattedMessage id="report.connectingToCampus"
        values={{
          membersOfCommuntiy: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="report.connectingToCampus.membersOfCommuntiy"/></span>
        }} 
          defaultMessage="Connecting to a campus sexual violence prevention office is available to members of the campus community."/>
      </p>

      <Button onClick={closeModal} style={ButtonStyle.Secondary} className={ButtonStyle.Full}>
        <FormattedMessage id="button.iUnderstand" />
      </Button>

    </BaseModal>
  );
}
