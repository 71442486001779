export const logGaEvent = (
  eventName: string,
  params?: any,
) => {
  if (eventName) {
    try {
      // @ts-ignore: erroneous typing issue on window.dataLayer, dataLayer is needed to push events to google analytics
      window.dataLayer.push({
        event: eventName,
        ...params
      }); 
    } catch (err) {
      console.log("logGaEvent() err: there was an error with dataLayer");
    }
  } else {
    console.log("logGaEvent() err: eventName is required");
  }
};

export default {
  logGaEvent
};
