import React from "react";
import { FormattedMessage } from "react-intl";
import { ThoughtBubbleIcon } from "components/Icons";
import classNames from "classnames";
import styles from './styles.module.scss';

interface HowToAnswerIfUnsureProps {
  className?: string;
}

export const HowToAnswerIfUnsure = ( { className }: HowToAnswerIfUnsureProps ) => {
  return (
    <div className={classNames(styles.howToAnswerIfUnsure, className)}>
      <ThoughtBubbleIcon className={styles.thoughtBubbleIcon}/>
      <p className={styles.smallText}><FormattedMessage 
        id="report.howToAnswerIfUnsure"
        defaultMessage="It’s okay to not know or not to be sure. It’s better to leave a question blank than to guess. There are no right or wrong answers."
        values={{ 
          highlight: <span className={styles.highlightedText}><FormattedMessage id="report.howToAnswerIfUnsure.highlight" defaultMessage=" This is..."/></span>,
        }} 
      /></p>
    </div>
  );
}
