import React from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import { Button, ButtonStyle } from "components/Buttons/Button";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import styles from './styles.module.scss';

export enum ConfirmationType {
  ReportUpdated = "ReportUpdated",
  ReportDeleted  = "ReportDeleted",
  FeedbackSubmitted = "FeedbackSubmitted"
};

interface ConfirmationModalProps {
  confirmationModalIsOpen: boolean;
  setConfirmationModalIsOpen: (value: boolean) => void;
  confirmationType: ConfirmationType;
}

export const ConfirmationModal = ({ confirmationModalIsOpen, setConfirmationModalIsOpen, confirmationType } : ConfirmationModalProps) => {

  const history = useHistory();

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  }

  const backToHome = () => {
    history.push('/');
  }

  const confirmationMessage = () => {
    if ( confirmationType === ConfirmationType.ReportUpdated) {
      return ( <FormattedMessage id="report.successfullyUpdated" defaultMessage="Report Successfully Updated"/> );
    } else if ( confirmationType === ConfirmationType.ReportDeleted) {
      return ( <FormattedMessage id="report.successfullyDeleted" defaultMessage="Report Successfully Deleted"/> );
    } else  if ( confirmationType === ConfirmationType.FeedbackSubmitted) {
      return ( <FormattedMessage id="feedback.successfullySubmitted" defaultMessage="Feedback Successfully Submitted"/> )
    }
  }

  return (
    <BaseModal isOpen={confirmationModalIsOpen} onClose={closeConfirmationModal} >
      {/* change the icon - ask nichie - not currently in google drive assets */}
      <div className={styles.confirmationModalContent}>
        <div className={classNames(styles.check, "i--icon-green-check")} />
        <p>{confirmationMessage()}</p>
        <Button onClick={backToHome} style={ButtonStyle.Primary} className={ButtonStyle.Full}>
          <FormattedMessage id="common.backToHome" defaultMessage="Back To Home"/>
        </Button>
      </div>
    </BaseModal>
  );
}
