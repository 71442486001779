import React, { useState, useEffect, ReactNode } from "react";
import classNames from "classnames";
import { RadioChoice } from "components/Forms/Toggle/RadioChoice";
import { Select } from "components/Forms/Select";
import { ToggleBoolean } from "components/Forms/Toggle/ToggleBoolean";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { Location } from 'components/Forms/Location';
import { MultiChoice } from 'components/Forms/MultiChoice';
import { DateTime } from 'components/Forms/DateTime';
import { Attachments } from 'components/Forms';
import { FormattedOptionType, OptionType, QuestionType } from "../../../../actions/questionActions";
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import styles from './styles.module.scss';

import { ReportFormErrorType } from "pages/Report";
import { PictureOptions, PictureOptionsType } from "components/Forms/PictureOptions";
import { OffenderFace } from "components/OffenderFace";

interface SectionQuestionsProps {
  question: QuestionType;
  value?: any;
  scale?: any;
  updatedAnswer: (value: any) => void;
  error?: ReportFormErrorType;
  locked?: boolean;
}

export const SectionQuestion = ({ error, question, updatedAnswer, value, scale, locked }: SectionQuestionsProps) => {
  const [questionScale, setQuestionScale] = useState();
  const [questionValue, setQuestionValue] = useState(); 

  useEffect(
    () => {
      if(value) {
        setQuestionValue(value);
      }
      if(scale) {
        setQuestionScale(scale);
      }
    }
  ,[value, scale])

  const onScaleChange = (value: any) => {
    updatedAnswer({value: questionValue, scale: value });
    setQuestionScale(value);
  };

  const onValueChange = (value: any) => {
    updatedAnswer({value: value, scale: questionScale });
    setQuestionValue(value);
  }

  if(question.scale) {
    return (
      <div className={classNames(styles.inputToggleContainer, error && styles.error)}>
        <SectionValue {...{ question, value: questionValue, onChange: onValueChange, locked }} />
        <SectionScale {...{ question, value: questionScale, onChange: onScaleChange }} />
        { error && <p className={styles.errorMessage}>{error.message}</p>}
      </div>
    ) 
  } else {
    return <SectionValue {...{ question, value, onChange: onValueChange, errorMessage: error && error.message, locked }} />
  }
};

interface SectionScaleProps {
  question: QuestionType;
  onChange: (value: any) => void;
  value?: string;
};

export const SectionScale = ( { question, value, onChange }: SectionScaleProps) => {
  const intl = useIntl();
  const formattedScaleOptions = _.map(question.scale,
    (scale)  => {
      return {
        value: scale.key,
        text: intl.formatMessage({id: `report.${scale.key}`}) 
      }
    }
  );

  return <RadioChoice
    groupName={question.key}
    value={value}
    options={formattedScaleOptions}
    onChange={onChange}
  />
};

interface SectionValueProps {
  question: QuestionType;
  value?: any;
  onChange: (value: any) => void;
  errorMessage?: ReactNode;
  defaultValue?: string;
  locked?: boolean;
}

export const SectionValue = ( { question, value, onChange, errorMessage, locked }: SectionValueProps ) => {
  const intl = useIntl();
  
  const key = `report.${question.key}`;
  const label = <FormattedMessage id={key} />;
  const placeholder: string = question.placeholder ? question.placeholder : "";
  const required = question.required;
  const description = question.description;
  const formattedOptions: FormattedOptionType[] = _.map(
    question.options,
    (option: OptionType) => {
      return {
        value: option.key,
        text: intl.formatMessage({ 
          id: `report.${option.key}`, 
          defaultMessage: (option.text && option.text !== "") ? option.text : option.key 
        }),
      }
    }
  );
  useEffect(() => {
    if (question.defaultValue && !value) {
      onChange(question.defaultValue)
    }
  }, []);

  switch (question.type) {
    case "boolean":
      return ( 
        <ToggleBoolean  
          label={label}
          value={value}
          onChange={(value) => { onChange(value); }}
          groupName={key} 
          disabled={locked}
          {...{ required, errorMessage }}
        /> 
      );
    case "options":
      return <RadioChoice    
        label={label}
        groupName={key}
        value={value}
        onChange={(value) => { onChange(value); }}
        options={formattedOptions}
        description={description}
        disabled={locked}
        {...{ required, errorMessage }}

      />;
    case "number":
      return <TextField label={label} 
        type={TextFieldTypes.Number}
        value={value}
        onChange={onChange}
        name={key} 
        disabled={locked}
        {...{ required, errorMessage }}
        className={'inlineTextInput'}
      />;
    case "text":
      return ( <TextField
        label={label} 
        type={TextFieldTypes.Text}
        value={value}
        onChange={onChange} 
        placeholder={placeholder}
        name={key} 
        disabled={locked}
        {...{ required, errorMessage }}

      /> );
    case "text-long":
      return ( <TextField      
        label={label} 
        type={TextFieldTypes.TextArea}
        onChange={onChange}
        placeholder={placeholder}
        name={key} 
        value={value}
        imageKey={question.imageKey}
        disabled={locked}
        {...{ required, errorMessage }}

      /> );
    case "multichoice":
      return (
        <MultiChoice    
          label={label}
          groupName={key}
          options={formattedOptions}
          onChange={onChange}
          values={value}
          {...{ required, errorMessage }}

        /> 
      );
    case "complexion":
      return (<PictureOptions
        pictureOptionsType={PictureOptionsType.Complexion}
        label={label}
        groupName={key}
        onChange={onChange}
        options={formattedOptions}
        className={styles.complexionQuestion}
        disabled={locked}
        {...{ required, errorMessage }}

      />);
    case "hairColour":
      return (<PictureOptions
        pictureOptionsType={PictureOptionsType.HairColour}
        label={label}
        groupName={key}
        onChange={onChange}
        options={formattedOptions}
        className={styles.hairColourQuestion}
        disabled={locked}
        {...{ required, errorMessage }}

      />);
    case "offender-face":
      return (<OffenderFace className={styles.offenderFaceSection} />);
    case "address":
      return ( <Location
        onChange={onChange}
        value={value}
        question={label}
        className={styles.locationInput}
        disabled={locked}
        {...{ required, errorMessage }}

      /> );
    case "dateTimeRange":
      return ( <DateTime       
        label={label}
        value={value}
        onChange={onChange}
        isRange={true}
        groupName={key}
        disabled={locked}
        {...{ required, errorMessage }}

      /> );
    case "attachments":
      return ( <Attachments    
        label={label}
        values={value}
        onChange={onChange}
        groupName={key}
        disabled={locked}
        {...{ required, errorMessage }}
      /> );
    case "select":
      return ( 
        <Select
          label={label}
          options={formattedOptions}
          value={value}
          onChange={(value) => { onChange(value); }}
          defaultValue={question.defaultValue}
          disabled={locked}
          {...{ required, errorMessage }}
        /> 
      );
    default:
      return <div>{label}</div>;
    }

}
