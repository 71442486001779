import React, { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { ArrowRightLight } from "components/Icons";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import classNames from "classnames";
import { LoginModal } from 'components/Modals/LoginModal'

export enum LoginInputCardStyle {
  thin = 'thin',
  thick = 'thick',
}

export interface LoginInputCardProps {
  style?: LoginInputCardStyle
}

export interface SingleInputCardProps {
  className?: string;
  labelIntlId?: string;
  placeholderIntlId: string;
  inputEnter: any;
  value?: string;
  onInputChange?: (val: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const LoginInputCard = ({
  style = LoginInputCardStyle.thick
}: LoginInputCardProps) => {
  const [reportKey, setReportKey] = useState("");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const sendKey = () => {
    setIsPasswordModalOpen(true);
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.keyCode || event.which;
    if (key === 13){ //Enter Key
      sendKey();
    }
  }

  return <>
    <SingleInputCard 
      className={style && styles[style]} 
      labelIntlId="report.accessSavedReport" 
      placeholderIntlId="input.keyPlaceholder"
      value={reportKey} 
      inputEnter={sendKey}
      onInputChange={(val) => { setReportKey(val);}}
      onKeyDown={onKeyDown}
    ></SingleInputCard>
    <LoginModal  {...{ reportKey, isPasswordModalOpen, setIsPasswordModalOpen}} />
  </>
}


const SingleInputCard = ({className, labelIntlId, placeholderIntlId, inputEnter, onInputChange, value, onKeyDown}: SingleInputCardProps) => {
  const intl = useIntl();

  return (
    <div className={classNames(styles.singleInputCard, styles.gradient, className)}>
      {labelIntlId && (<p><FormattedMessage id={labelIntlId} /></p>)}
      <div className={styles.inputContainer}>
        <TextField 
          className={styles.inputTextField}
          type={TextFieldTypes.Text}
          placeholder={ intl.formatMessage({ id: placeholderIntlId }) }
          name=""
          value={value}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
        />
        <ArrowRightLight className={styles.arrowIcon} onClick={inputEnter} />
      </div>
    </div>
  );
}
