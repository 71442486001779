import * as base from "./lib/baseActions";
import { ReportData  } from "context/editReport";
import { AnswersInfo, apiAnswersToAnswersMap, getAnswerKey } from "context/editAnswers";
import { ReportSectionQuestions, ReportSections } from "pages/Report";


// For some report answers we want default values so we have answers in our DB to track for stats
const setDefaultReportAnswers = (data: AnswersInfo) => {
  const offenderKnowAnswerKey = getAnswerKey(ReportSections.Offender, ReportSectionQuestions.OffenderKnow);
  if (!data.answers.has(offenderKnowAnswerKey)) {
    const skipOffenderAnswer = {
      answerKey: offenderKnowAnswerKey,
      sectionKey: ReportSections.Offender,
      questionKey: ReportSectionQuestions.OffenderKnow,
      value: {
        answer: 'noResponse'
      },
      isFile: false,
    };
    data.answers.set(offenderKnowAnswerKey, skipOffenderAnswer);
  }
};

const createReportAnswers = async (reportId: string | number, data: AnswersInfo) => {
  
  setDefaultReportAnswers(data);
  
  const dataToArray = {
    answers: Array.from(data.answers.values())
  }

  const saveReportAnswersResult = await base.post(
    `reports/${reportId}/answers`,
    dataToArray
  );

  return saveReportAnswersResult;
};

const updateReportAnswers = async (reportId: string | number, data: AnswersInfo) => {

  setDefaultReportAnswers(data);
  
  const dataToArray = {
    answers: Array.from(data.answers.values())
  }

  const saveReportAnswersResult = await base.put(
    `reports/${reportId}/answers`,
    dataToArray
  );

  return saveReportAnswersResult;
};

const updateReportTracking = async (reportId: string | number, followIntakeFormSupportLocationId: number) => {
  const dataToArray = {
    followIntakeFormSupportLocationId: followIntakeFormSupportLocationId
  };

  const saveReportAnswersResult = await base.put(
    `reports/${reportId}/tracking`,
    dataToArray
  );
  return saveReportAnswersResult;
};

const updateReportById = async (reportId: string | number, data: ReportData) => {
  const saveReportResult = await base.put(
    `reports/${reportId}`,
    data
  );

  return saveReportResult;
};

const getReportByKey = async (reportKey: string) => {

  try {
    const result = await base.get(`reports/reportKey/${reportKey}`);
    return result;
  } catch(err) {
  }

  // on fail, return
  return;
};

const getReportAnswers = async (reportId: string | number, submitted = false) => {
  const saveReportAnswersResult = await base.get(
    `reports/${reportId}/answers`
  );

  const answersResult = apiAnswersToAnswersMap(saveReportAnswersResult.data, submitted);

  return answersResult;
};

const deleteReportUserAnswers = async (reportKey: string) => {

  const reportUserAnswersResult = await base.del("reportUserAnswers/reportKey/" + reportKey);

  return reportUserAnswersResult;
};

export {
  getReportByKey,
  getReportAnswers,
  createReportAnswers,
  updateReportById,
  updateReportAnswers,
  deleteReportUserAnswers,
  updateReportTracking
};
