import { useState, useEffect } from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { UserInfo, useMergeEditUserState } from 'context/editUser';
import { useEditUserState } from 'context';
import { useEditAnswersState } from 'context/editAnswers';
import { createReportUser, updateReportUser } from "actions/userActions";
import { createReportAnswers, getReportByKey } from "actions/reportActions";
import { ReportData, ReportFields, useEditReportState } from "context/editReport";
import { SavedReportModal } from "../SavedReportModal";
import { SubmitReportModal } from "../SubmitReportModal";
import { Formik, FormikHelpers } from "formik";
import { ErrorSaveSubmitDeleteModal, ErrorType } from "components/Modals/ErrorSaveSubmitDeleteModal";
import { PasswordModalForm } from "./PasswordModalForm";
import { DeleteModal } from "../DeleteModal";
import useUpdateAttachments from "lib/useUpdateAttachments";
import { AttachmentFailedModal } from "../AttachmentFailedModal";
import { RoutePaths } from "App/routing";
import { useHistory } from "react-router-dom";

export enum PasswordModalActionType {
  Save = "Save",
  Resave = "Resave",
  Submit  = "Submit"
}

interface PasswordModalProps {
  isPasswordModalOpen: boolean;
  setIsPasswordModalOpen: (value: boolean) => void;
  passwordModalAction: PasswordModalActionType;
  isBreak?: boolean;
}

export interface SaveReportUserInfoType {
  reportKey?: string;
  fullName?: string;
}

export interface PasswordModalFormFields {
  password: string;
  captchaToken?: string;
};

const initialValues: PasswordModalFormFields = { 
  password: "" 
};

// TODO This file is too bloated
// We should have a call back function for on complete be passed in
// Then each page calling password modal can figure out what to do
export const PasswordModal = ({ 
  isPasswordModalOpen, 
  setIsPasswordModalOpen, 
  passwordModalAction,
  isBreak = false 
}: PasswordModalProps) => {

  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();
  const answerData = useEditAnswersState();
  const reportData: ReportData = useEditReportState();
  const updateAttachments = useUpdateAttachments();

  const [isSavedReportModalOpen, setIsSavedReportOpen] = useState(false);
  const [isSubmitReportModalOpen, setIsSubmitReportOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [attachmentFailedModalIsOpen, setAttachmentFailedModalIsOpen] = useState(false);

  const [errorSaveSubmitDeleteModalIsOpen, setErrorSaveSubmitDeleteModalIsOpen] = useState(false);
  const isSubmission = (passwordModalAction === PasswordModalActionType.Submit);
  
  const [long, isLongEnough] = useState(false);
  const [specialCharacter, hasSpecialCharacter] = useState(false);
  const [number, hasNumber] = useState(false);
  const [uppercase, hasUppercase] = useState(false);
  const [saveReportUserInfo, setSavedReportUserInfo] = useState<SaveReportUserInfoType>({reportKey:'', fullName:''});
  const history = useHistory();

  useEffect(() => {
    if (userInfo?.reportKey) {
      setSavedReportUserInfo({ reportKey: userInfo.reportKey, fullName: userInfo.fullName });
    }
  }, [userInfo]);

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  }

  const onDeleteClick = () => {
    setDeleteModalIsOpen(true);
  }

  const isActionTypeSave = (passwordModalAction === PasswordModalActionType.Save);
  const isActionTypeResave = (passwordModalAction === PasswordModalActionType.Resave);

  const onSavePassword = async (reportUserInfo: ReportData & UserInfo & { captchaToken?: string }) => {
    try {
      // TODO: update entire app so it only works with integer consents instead of booleans - https://trello.com/c/li8fkVjN/718-dev-card-vestaweb-update-entire-app-so-it-only-works-with-integer-consents-instead-of-booleans
      const reportWithModifiedConsentData = Object.entries(reportUserInfo).reduce((reportWithModifiedConsentData: any, c) => {
        if (Object.values(ReportFields).includes(c[0] as ReportFields) && typeof c[1] === 'boolean' ) {
          reportWithModifiedConsentData[c[0]] = (c[1] === true) ? 1 : 0;
        }
        return reportWithModifiedConsentData;
      }, reportUserInfo);
      let reportId: number;
      let reportKey: string;
      reportWithModifiedConsentData.supportLocationId = reportData.supportOrganization?.id
      reportWithModifiedConsentData.campusSupportLocationId = reportData.campusSupportOrganization?.id
      reportWithModifiedConsentData.typeOfSupport = reportData.typeOfSupport
      if (userInfo.reportKey) {
        const reportUserResult = await getReportByKey(userInfo.reportKey);
        reportId = reportUserResult.data?.id;
        reportKey = userInfo.reportKey
      } else {
        const createReportUserResult = await createReportUser(reportWithModifiedConsentData, false);
        reportId = createReportUserResult.report?.id;
        mergeUserData({ reportKey: createReportUserResult.user?.reportKey, reportId });
        reportId = createReportUserResult.report?.id;
        reportKey = createReportUserResult.user?.reportKey;
      }

      if (reportId) {
        await createReportAnswers(reportId, answerData);

        try {
          await updateAttachments(reportId);
        } catch(err) {
          setAttachmentFailedModalIsOpen(true);
        }

        setIsPasswordModalOpen(false);
      }
        
      // We should only submit after creating.
      const submitConsentData = { ...reportWithModifiedConsentData, reportKey, reportId };
      const updateReportUserResult = await updateReportUser(submitConsentData, isSubmission);
      reportId = updateReportUserResult.report?.id;
     
      if (isActionTypeSave || isActionTypeResave) {
        setIsSavedReportOpen(true);
      } else {
        setIsSubmitReportOpen(true);
        history.push(RoutePaths.reportSubmitSuccess);
      }

    } catch (err) {
      setErrorSaveSubmitDeleteModalIsOpen(true);
    }
  }

  const onPasswordModalFormSubmit = async (values: PasswordModalFormFields, { setSubmitting }: FormikHelpers<PasswordModalFormFields>) => {
    setSubmitting(true);
    if (long && number && specialCharacter && uppercase) {
      await onSavePassword({...userInfo, ...reportData, captchaToken: values.captchaToken});
    }
    setSubmitting(false);
  }

  const validatePassword = (values: PasswordModalFormFields) => {
    isLongEnough( values.password.length >= 8 );
    hasNumber( /\d/.test(values.password) );
    hasSpecialCharacter( /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(values.password) );
    hasUppercase( /[A-Z]/.test(values.password) );
  };

  return (
    <>
      <BaseModal 
        isOpen={isPasswordModalOpen} 
        title={<FormattedMessage id="passwordModal.title" defaultMessage="Password Protect Your Information"/>}
        showCloseButton={false}
        onClose={closePasswordModal} 
        titleStyle={styles.modalTitle}
        className={styles.modal}>
        <div className={styles.passwordModalContent}>   
          <Formik
            initialValues={initialValues}
            validate={validatePassword}
            onSubmit={onPasswordModalFormSubmit}
          >
            {() => (
              <PasswordModalForm 
                long={long}
                number={number}
                specialCharacter={specialCharacter}
                uppercase={uppercase}
                isBreak={isBreak}
                isSubmission={isSubmission}
                onDeleteClick={onDeleteClick}/>
            )}
          </Formik>
        </div>
      </BaseModal>

      <SavedReportModal 
        isSavedReportModalOpen={isSavedReportModalOpen}
        setIsSavedReportOpen={setIsSavedReportOpen}
        reportKey={saveReportUserInfo.reportKey}
        fullName={saveReportUserInfo.fullName}/>

      <SubmitReportModal 
        submitReportModalIsOpen={isSubmitReportModalOpen}
        setSubmitReportModalIsOpen={setIsSubmitReportOpen}
        reportKey={saveReportUserInfo.reportKey}/>

      <DeleteModal 
        deleteModalIsOpen={deleteModalIsOpen} 
        setDeleteModalIsOpen={setDeleteModalIsOpen}/>

      <ErrorSaveSubmitDeleteModal 
        errorSaveSubmitDeleteModalIsOpen={errorSaveSubmitDeleteModalIsOpen} 
        setErrorSaveSubmitDeleteModalIsOpen={setErrorSaveSubmitDeleteModalIsOpen} 
        errorType={isSubmission ? ErrorType.Submit : ErrorType.Save}/>

      <AttachmentFailedModal 
        attachmentFailedModalIsOpen={attachmentFailedModalIsOpen}
        setAttachmentFailedModalIsOpen={setAttachmentFailedModalIsOpen}/>
    </>
  );
}
