import styles from './styles.module.scss';
import { IncludedDownloadPages, recordDownload } from "actions/metricTrackingActions";
import { Button, ButtonStyle } from "components/Buttons";
import { FlagText, FlagTextColor } from "components/Common/FlagText";
import { InlineTitledColor, InlineTitledMessage } from "components/Common/InlineTitledMessage";
import { MultChoiceOption, MultiChoiceList } from "components/Forms/MultiChoiceList";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Location } from 'components/Forms/Location';
import { useState } from "react";
import FileSaver from "file-saver";
import JSZip from "jszip";
import axios from "axios";
import { SurveyRequestModal } from 'components/Modals/SurveyRequestModal';

enum JournalDownloadPages {
  aboutIncident = 'aboutIncident',
  unknownOffender = 'unknownOffender',
  knownOffender = 'knownOffender',
  sensoryExperience = 'sensoryExperience',
  impactOfExperience = 'impactOfExperience'
}

export interface AttachmentType {
  fileName: string;
  downloadLink?: string;
  uniqueKey?: string;
}

const PageFiles = new Map<string,AttachmentType>([
  [ JournalDownloadPages.aboutIncident, {
    uniqueKey: JournalDownloadPages.aboutIncident,
    fileName:`About the Incident.pdf`,
    downloadLink:`pdfs/About the Incident.pdf`,
  }],
  [JournalDownloadPages.unknownOffender, {
    uniqueKey: JournalDownloadPages.unknownOffender,
    fileName:`About the Offender - Known.pdf`,
    downloadLink:`pdfs/About the Offender - Known.pdf`,
  }],
  [JournalDownloadPages.knownOffender, {
    uniqueKey: JournalDownloadPages.knownOffender,
    fileName:`About the Offender - Unknown.pdf`,
    downloadLink:`pdfs/About the Offender - Unknown.pdf`,
  }],
  [JournalDownloadPages.sensoryExperience, {
    uniqueKey: JournalDownloadPages.sensoryExperience,
    fileName:`Sensory Experience.pdf`,
    downloadLink:`pdfs/Sensory Experience.pdf`,
  }],
  [JournalDownloadPages.impactOfExperience, {
    uniqueKey: JournalDownloadPages.impactOfExperience,
    fileName:`Impact.pdf`,
    downloadLink:`pdfs/Impact.pdf`,
  }],
]);

export interface useDownloadAllAttachmentsProps {
  pages?: JournalDownloadPages[];
  answers: any;
  className?: string;
  style?: ButtonStyle;
}

const downloadFile = async (file: AttachmentType) => {
  let fullUrl = file.downloadLink;

  if (!fullUrl) {
    return { fileName: file.fileName, file: new Blob([]), uniqueKey: file.uniqueKey };
  } else {

    const downloadedFile = await axios.get(fullUrl, {
      method: 'GET',
      responseType: 'blob', 
    })

    return { fileName: file.fileName, file: new Blob([downloadedFile.data]), uniqueKey: file.uniqueKey };
  }
}

const downloadFiles = async (pages: JournalDownloadPages[]) => {
    const filesToDownload =  pages?.map((x) => {
      return PageFiles.get(x);
    }).reduce<AttachmentType[]>((res, x) => { return x ? [...res, x] : res; },[]);

    if(filesToDownload.length ===1) {
      filesToDownload?.forEach((res) => {
        if(res?.downloadLink) {
          FileSaver.saveAs(res.downloadLink, res.fileName);
        }
      });
    } else if(filesToDownload.length > 1) {

    // // Download Files.
    const downloadedFiles = filesToDownload.filter((file?: AttachmentType) => {
      return !!file;
    }).map((file: AttachmentType) => {
      return downloadFile(file);
    });

    const downloadedResults = await Promise.all(downloadedFiles);

    //Zip Files.
    const zip = new JSZip();

    downloadedResults.reduce((result, file) => {
       result.file(file.fileName,file.file);
       return result;
     }, zip);

    const content = await zip.generateAsync({type:"blob"});

    // see FileSaver.js
    FileSaver.saveAs(content, "pages.zip");
  }
  // No downloads needed;

}

const usePageDownloadOptions = (intl: IntlShape) => {
  const pageDownloadOptions: MultChoiceOption[] = [
    {value: JournalDownloadPages.aboutIncident, text: intl.formatMessage({ id: 'home.multiSelect.option.aboutIncident' })},
    {value: JournalDownloadPages.unknownOffender, text: intl.formatMessage({ id: 'home.multiSelect.option.unknownOffender' })},
    {value: JournalDownloadPages.knownOffender, text: intl.formatMessage({ id: 'home.multiSelect.option.knownOffender' })},
    {value: JournalDownloadPages.sensoryExperience, text: intl.formatMessage({ id: 'home.multiSelect.option.sensoryExperience' })},
    {value: JournalDownloadPages.impactOfExperience, text: intl.formatMessage({ id: 'home.multiSelect.option.impactOfExperience' })}
  ];

  return pageDownloadOptions;
}

const ConfirmationModalText = () => {
  return (
    <FormattedMessage 
      id={"surveyRequestModal.body.downloadPagesConfirmation"}
      defaultMessage={"Your journal pages have been downloaded! Would you be willing to fill out an anonymous survey to help us identify trends and patterns to create safer spaces for everyone?"}
    />
  );
}

export const DownloadPagesForm = () => {
  const intl = useIntl();
  const [location, setLocation] = useState<string>('');
  const [selectedPages, setSelectedPages] = useState<JournalDownloadPages[]>([]);
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>('');
  const [surveyRequestModalIsOpen, setSurveyRequestModalIsOpen] = useState(false); 
  const pageDownloadOptions = usePageDownloadOptions(intl);

  const openSurveyRequestModal = () => {
    setSurveyRequestModalIsOpen(true);
  }

  const onClickDownload = () => {
    if (location) {
      const includedDownloadPages = selectedPages.reduce<IncludedDownloadPages>((acc, page) => {
        acc[page] = true;
        return acc;
      }, {} as IncludedDownloadPages);
     
      downloadFiles(selectedPages);

      setLocation('');
      setSelectedPages([]);
  
      recordDownload({ includedDownloadPages, location });
      openSurveyRequestModal();
    } else {
      setLocationErrorMessage('Location is required');
    }
  }

  const onChangePageSelect = (updatedValues: string[]) => {
    setSelectedPages(updatedValues as JournalDownloadPages[]);
  }

  const onChangeLocation = (updatedLocation: string) => {
    if (updatedLocation) {
      setLocationErrorMessage('');
    } else if (location) {
      setLocationErrorMessage('Location is required');
    }
    setLocation(updatedLocation);
  }
  
  return (
    <>
      <InlineTitledMessage 
        title={intl.formatMessage({ id: 'home.point3.title' })}
        delimiter={": "}
        message={intl.formatMessage({ id: 'home.point3.body' })}
        color={InlineTitledColor.Pink}
      />
      <FlagText color={FlagTextColor.Pink} className={styles.flagText}>
        <FormattedMessage id='home.location.flagText' />
      </FlagText>
      <Location
        onChange={(e) => onChangeLocation(e)}
        placeholder={intl.formatMessage({ id: 'home.location.placeholder' })}
        value={location}
        className={styles.locationInput}
        required={true}
        errorMessage={locationErrorMessage}
      />
      <InlineTitledMessage 
        title={intl.formatMessage({ id: 'home.multiSelect.header.title' })}
        delimiter={". "}
        message={intl.formatMessage({ id: 'home.multiSelect.header.body' })}
        className={styles.multiSelectHeader}
        color={InlineTitledColor.Pink}
      />
      
      <MultiChoiceList    
        groupName={'pageSelect'}
        options={pageDownloadOptions}
        onChange={e => onChangePageSelect(e)}
        values={selectedPages}
        className={styles.multiChoiceList}
      />
      <div className={styles.centerJustifiedButtonContainer}>
        <Button onClick={onClickDownload} style={ButtonStyle.Primary} className={styles.downloadButton}>
          <FormattedMessage id="home.downloadButton"/>
        </Button>
      </div>
      <SurveyRequestModal 
        surveyRequestModalIsOpen={surveyRequestModalIsOpen}
        setSurveyRequestModalIsOpen={setSurveyRequestModalIsOpen}
        ModalText={ConfirmationModalText}
      />
    </>
  );
}
