import { creatContextStore } from './base';
import { SectionType, QuestionType, QuestionTypes } from '../actions/questionActions';
import questions from "data/questions.json";
import { ReportData } from './editReport';


// AnswersInfo = {
//   answers:[{
//       "sectionKey": "blah",
//       "questionKey":"blah",
//       "value": {
//         "answer": "hello no answer",
//         "scale": "brahkalska"
//       }
//     }, {...
//     }, ...
//   ]
// };
export interface AnswerValue {
  answer: any;
  scale?: any;
};

export interface Answer {
  answerKey:string;
  sectionKey: string;
  questionKey: string;
  value: AnswerValue;
  isFile?: boolean;
  uniqueKey?: string;
  file?: File;
  locked?: boolean;
};

// Key is sectionKey
export interface AnswersInfo {
  answers: Map<string,Answer>
};
 
export const createBlankState = () => {
  return {
    answers: new Map<string,Answer>()
  };
};

export const initialState = {
  answers: new Map<string,Answer>()
};

export const getAnswerKey = (sectionKey : string, questionKey: string) => {
  return `${sectionKey}:${questionKey}`;
}

/// Takes Questions section, question, value, scale, and populated to Answer.
const createAnswer = (section: SectionType, question: QuestionType, value: any, scale?: any) => {
  return {
    answerKey: getAnswerKey(section.key, question.key),
    sectionKey: section.key,
    questionKey: question.key,
    isFile: question.type === QuestionTypes.Attachments,
    value: {
      answer: value ? value: undefined,
      scale: scale ? scale : undefined,
    }
  };
}

// add Answer to answers
export const addAnswerToAnswersMap = (answers: AnswersInfo, answer: Answer) => {
  const newAnswers = new Map(answers.answers);
  newAnswers.set(answer.answerKey,answer);

  return {
    answers: newAnswers
  };
};

interface ApiAnswer {
  questionKey: string;
  sectionKey: string;
  id: number;
  reportId: number;
  value: string;
};

const isEmptyValue = (value: AnswerValue): boolean => {
  const emptyAnswers = new Set([
    null,
    undefined,
    '',
    'noResponse',
    'o-notSure',
    'o-idk',
    'o-unknownGender',
  ]);
  
  if (
    !value ||
    typeof value.answer === 'undefined' ||
    emptyAnswers.has(value.answer) ||
    (typeof value.answer === 'string' && value.answer.trim().length === 0) ||
    (Array.isArray(value.answer) && value.answer.length === 0)
  ) {
    return true;
  }
  return false;
}

export const apiAnswersToAnswersMap = (apiAnswers: Array<ApiAnswer>, submitted = false) => {
  const newAnswersMap = new Map<string, Answer>();
  apiAnswers.forEach((apiAnswer) => {
    const value = JSON.parse(apiAnswer.value);
    const answer = {
      ...apiAnswer,
      answerKey: `${apiAnswer.sectionKey}:${apiAnswer.questionKey}`,
      value: value,
      locked: submitted && !isEmptyValue(value),
    }
    newAnswersMap.set(answer.answerKey, answer);
  });

  // We need to handle special case types that need to be converted, i.e. Date.
  if(questions) {
    questions.sections.forEach((section: SectionType) => {
      section.questions.forEach((question: QuestionType) => {
        const apiAnswer = newAnswersMap.get(getAnswerKey(section.key, question.key));

        if (apiAnswer) {
          if(question.type === QuestionTypes.DateTimeRange && Array.isArray(apiAnswer.value.answer)) {
            apiAnswer.value.answer = apiAnswer.value.answer.map((v) => new Date(v));
            newAnswersMap.set(apiAnswer.answerKey, { ...apiAnswer });
          }
        }
      });
    })
  }

  return { answers: newAnswersMap };
};


// add answers in array to answersInfo
export const addAnswersToAnswersMap = (answersInfo: AnswersInfo, newAnswers?: Array<Answer>) => {
  if (!newAnswers) {
    return answersInfo;
  } else {
    const newAnswersMap = new Map(answersInfo.answers);
    newAnswers.forEach((newAnswer) => {
      newAnswersMap.set(newAnswer.answerKey, newAnswer);
    });
   
   return { answers: newAnswersMap };
  }
}

export const mergeAnswerMap = (map1: Map<string, Answer>, map2?: Map<string, Answer>) => {
  if (!map2) {
    return new Map(map1);
  } else if (!map1) {
    return new Map(map2);
  } else {
    const finalMap = new Map(map1);

    map2.forEach((val, key) => {
      finalMap.set(key, val);
    });

    return finalMap;
  }
}


export const getCurrentAnswer = (answers: AnswersInfo, section : SectionType, question: QuestionType ) => {
  return answers?.answers.get(getAnswerKey(question?.sectionKey || section.key, question.key));
}

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<AnswersInfo>(initialState);
 
// Add Answer
export const useAddAnswer = () => {
  const addAnswer = (setReportState: React.Dispatch<React.SetStateAction<AnswersInfo>>, answers: AnswersInfo, section: SectionType, question: QuestionType, value: any, scale?: any) => {
    
    // prevents locked answers from being overriden
    if (getCurrentAnswer(answers, section, question)?.locked) {
      return; 
    }
    
    const newAnswer =  createAnswer(section, question, value, scale);
    const newAnswers = addAnswerToAnswersMap(answers, newAnswer);

    setReportState(newAnswers);
  };

  return addAnswer;
}

export {
  Provider as EditAnswersContextProvider,
  useState as useEditAnswersState,
  useSetState as useSetEditAnswersState,
  useMergeState as useMergeEditAnswerState
};
