import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';

interface FooterProps {
  isReportForm?: boolean;
  isBreak?: boolean;
}

const PrintHeader = ({isReportForm, isBreak} : FooterProps) => {
  return (
    <header className={classNames(styles.footer, 
                     )}>
      <h2 className={classNames(styles.headerImage, styles.vestaLogo)}></h2>      
      <h2 className={styles.headerText}><FormattedMessage id="home.header.yourVoiceMatters"/></h2>
    </header>
  );
}

export default PrintHeader;
