import React, { ReactNode, FC } from 'react';
import { RadioField } from "../RadioField";
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { convertYesNoToBool, isUndefined } from 'lib/commonFunctions';
import styles from './styles.module.scss';


interface ToggleBooleanProps {
    label?: ReactNode;
    required?: boolean;
    className?: string;
    labelClassName?: string;
    groupName: string;
    value?: boolean;
    onChange?: (valueToSet: string | boolean, groupName: string) => void;
    onClick?: (valueToSet: string | boolean, groupName: string) => void;
    buttonIcons?: Array<JSX.Element>;
    errorMessage?: ReactNode;
    children?: ReactNode;
    disabled?: boolean;
}

export const ToggleBoolean: FC<ToggleBooleanProps> = ({
  label,
  groupName,
  required,
  className,
  labelClassName,
  value,
  onChange,
  onClick,
  buttonIcons,
  errorMessage,
  children,
  disabled,
}) => {
    const intl = useIntl();    

    const onChangeBool = (e: React.ChangeEvent<HTMLInputElement>) => { 
        onChange && onChange( convertYesNoToBool(e.target.value), groupName ); 
    }

    const onClickBool = (e: any) => { 
      onClick && onClick( convertYesNoToBool(e.target.value), groupName ); 
    }

    return (
    <div>
      <div className={classNames(styles.toggleStyle, className)}>
          <div className={classNames(styles.radioLabel, labelClassName)}>
              {label}
              {buttonIcons}
              {children}
              {required && <span className={styles.required}>*</span>}
          </div>
          <div className={classNames(styles.booleanOptions)}>
              <RadioField value="Yes" 
                text={intl.formatMessage({ id:"report.o-yes"})} 
                groupName={groupName} 
                key={`TrueValue-${groupName}`} 
                onChange={onChangeBool} 
                onClick={onClickBool}
                checked={!isUndefined(value) && value}
                disabled={disabled}/>
              <RadioField value="No"  
                text={intl.formatMessage({ id:"report.o-no"})} 
                groupName={groupName} 
                key={`FalseValue-${groupName}`} 
                onChange={onChangeBool} 
                onClick={onClickBool}
                checked={!isUndefined(value) && !value}
                disabled={disabled}/>
          </div>
      </div>
      { errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>) }
    </div>
  );
};
