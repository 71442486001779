import { UnSubmittedAttachmentType, UploadedAttachmentType, uploadReportAnswerAttachments } from "actions/awsActions";
import { updateReportAnswers } from "actions/reportActions";
import { addAnswersToAnswersMap, Answer, useEditAnswersState } from "context/editAnswers";
import { ReportData, useEditReportState, useMergeEditReportState } from "context/editReport";
import { ReportSectionQuestions, ReportSections } from "pages/Report";

const useUpdateAttachments = () => {
  const reportData: ReportData = useEditReportState();
  const mergeReportData = useMergeEditReportState();
  const answerData = useEditAnswersState();

  return async (reportId: number | undefined) => {
    if (reportId) {
      try {
        // upload all un-uploaded attachments (stored in reportData.attachmentsToUpload)
        // then move successfully uploaded attachments from reportData.attachmentsToUpload to reportData.uploadedAttachments
        const unUploadedAttachments: UnSubmittedAttachmentType[] = reportData.attachmentsToUpload || [];
        const uploadedAttachments: UploadedAttachmentType[] = reportData.uploadedAttachments || [];
        if (unUploadedAttachments?.length) {
          let updatedAttachmentsAnswerArray;

          const uploadReportAnswerAttachmentsResult = await uploadReportAnswerAttachments(reportId, unUploadedAttachments);
          if (uploadReportAnswerAttachmentsResult?.uploadedAnswers) {
            mergeReportData({
              attachmentsToUpload: undefined,
              uploadedAttachments: uploadReportAnswerAttachmentsResult.uploadedAnswers // make sure uploaded field is true
            });

            updatedAttachmentsAnswerArray = Array.from(new Set([...uploadedAttachments, ...uploadReportAnswerAttachmentsResult.uploadedAnswers]));
          } else {
            updatedAttachmentsAnswerArray = [...uploadedAttachments];
          }

          const attachmentAnswers : Array<Answer> = [{
            answerKey: `${ReportSections.Experience}:${ReportSectionQuestions.FileAttachments}`,  
            sectionKey: ReportSections.Experience,
            isFile: true,
            questionKey: ReportSectionQuestions.FileAttachments,
            value: {
              answer: updatedAttachmentsAnswerArray
            }
          }];

          const fullAnswers = addAnswersToAnswersMap(answerData, attachmentAnswers);
          await updateReportAnswers(reportId, fullAnswers);
        }
      } catch (err) {
        throw err;
      }
    }
  }
};

export default useUpdateAttachments;
