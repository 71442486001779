import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';


export interface LoadingProps {
  loadingMessage?: string;
}

export const Loading = ({ 
    loadingMessage
}: LoadingProps) => {
  return (
    <div>
        <div className={styles.load}>
            <div className={styles.firstCircle}></div>
            <div className={styles.secondCircle}></div>
            <div className={styles.thirdCircle}></div>
        </div>
        { 
          loadingMessage 
          && 
          <p className={styles.loadingMessage}>
            <FormattedMessage id={loadingMessage}></FormattedMessage>
          </p>
        }
       
    </div>

  );
}
