import { FlagIconBlue, FlagIconPink } from 'components/Icons';
import styles from './styles.module.scss'
import classNames from 'classnames';

interface FlagTextProps {
  className?: string;
  children: any;
  color?: FlagTextColor;
}

interface FlagIconProps {
  color: FlagTextColor;
  className: string;
}

export enum FlagTextColor {
  Blue = "blue",
  Pink = "pink",
}

const FlagIcon = ({ color, className }: FlagIconProps) => {
  switch (color) {
    case FlagTextColor.Pink:
      return <FlagIconPink className={className} />;
    case FlagTextColor.Blue:
      return <FlagIconBlue className={className} />;
    default:
      return <FlagIconBlue className={className} />;
  };
}

const getColorStyle = (color: FlagTextColor) => {
  switch (color) {
    case FlagTextColor.Pink:
      return styles[FlagTextColor.Pink];
    case FlagTextColor.Blue:
      return styles[FlagTextColor.Blue];
    default:
      return styles[FlagTextColor.Blue];
  };
}

export const FlagText = ({ 
  className, 
  children,
  color = FlagTextColor.Blue,
}: FlagTextProps) => {
  const colorStyle = getColorStyle(color);
  return (
    <div className={classNames(styles.container, className)}>
      <FlagIcon color={color} className={styles.flag} />
      <p className={classNames(styles.text, styles.attention, colorStyle)}>
        {children}
      </p>
    </div>
  );
}
