import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "../../Buttons/Button";
import classNames from "classnames";
interface RequiresLocationModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
}

export const WhyWeAreAskingForInfoModal = ({ 
  modalIsOpen, 
  setModalIsOpen,
} : RequiresLocationModalProps) => {
  const closeModal = () => {
    setModalIsOpen(false);
  }

  return (
    <BaseModal title={<FormattedMessage id="personalInfo.whyModal.title" defaultMessage="Consent to Police"/>}
      centerTitle={true}
      isOpen={modalIsOpen} 
      onClose={closeModal} 
      className={styles.campusModalContent}
      showCloseButton={false}
      titleStyle={styles.title}
     >
      <h5 className={classNames(styles.header)}>
      <FormattedMessage id="personalInfo.whyModal.consentTitle"
          defaultMessage="You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location."
        />
      </h5>
      <p className={styles.body}>
        <FormattedMessage id="personalInfo.whyModal.consentTitleDetails"
          values={{
            highlight1: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.whyModal.consentTitleDetails.Highlight1"/></span>,
          }}
          defaultMessage="You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location."
        />
      </p>
        <h5 className={classNames(styles.header)}>
        <FormattedMessage id="personalInfo.whyModal.NameAndContactTitle"
          defaultMessage="You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location."
        />
        </h5>      
        <p className={styles.body}>
        <FormattedMessage id="personalInfo.whyModal.NameAndContactDetails"
          values={{
            highlight1: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.whyModal.NameAndContactDetails.Highlight1"/></span>,
          }}
          defaultMessage="You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location."
        />
      </p>
      <div className={styles.resultButtons}>
        <Button onClick={closeModal} style={ButtonStyle.Secondary} className={ButtonStyle.Full}>
          <FormattedMessage id="personalInfo.whyModal.button" />
        </Button>
      </div>
    </BaseModal>
  );
}
