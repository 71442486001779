import React, { FC, ReactNode, useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';

export enum TextFieldTypes {
  TextArea = "textarea",
  Text = "text",
  Email = "email",
  Password = "password",
  InlineText = "inlineText",
  Number = "number",
};

export type TextFieldEventType = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>;

export interface TextFieldProps {
  name?: string;
  type: TextFieldTypes;
  label?: ReactNode;
  required?: boolean;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  errorClassName?: string;
  errorMessage?: ReactNode;
  value?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  onRawChange?: (event: TextFieldEventType ) => void;
  onBlur?: (event: TextFieldEventType) => void;
  onFocus?: (event: TextFieldEventType) => void;
  icon?: any;
  imageKey?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TextField : FC<TextFieldProps> = ({
  name,
  errorMessage,
  label,
  value,
  onRawChange,
  required,
  type,
  placeholder,
  className = "",
  labelClassName,
  fieldClassName,
  errorClassName = styles.displayNone,
  onChange,
  disabled,
  icon,
  imageKey = "",
  onKeyDown,
  onBlur,
  onFocus
}) => {
  const intl = useIntl();

  const showLabel = intl.formatMessage({ id: "common.show", defaultMessage: "Show" });
  const hideLabel = intl.formatMessage({ id: "common.hide", defaultMessage: "Hide" });

  const [internalValue, setInternalValue] = useState<string>(value || "");
  const [showHideLabel, setShowHideLabel] = useState(showLabel);
  const [showHidePasswordType, setShowHidePasswordType] = useState(TextFieldTypes.Password);

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  const fieldOnChange = useCallback((e: TextFieldEventType) => {
    if (!value) {
      setInternalValue(e.target.value || "");
    }

    if (onRawChange) {
      onRawChange(e);
    }
    if (onChange) {
      onChange(e.target.value);
    }
  }, [value, onChange, setInternalValue, onRawChange]);

  const togglePasswordVisibility = useCallback(() => {
    if (showHidePasswordType === TextFieldTypes.Password) {
      setShowHidePasswordType(TextFieldTypes.Text);
      setShowHideLabel(hideLabel);
    } else {
      setShowHidePasswordType(TextFieldTypes.Password);
      setShowHideLabel(showLabel);
    }
  }, [showHidePasswordType, setShowHideLabel, setShowHidePasswordType, showLabel, hideLabel]);

  const getTextFieldType = useCallback((type: string) => {
    if (type === TextFieldTypes.Password) {
      return showHidePasswordType;
    } else {
      return (type === TextFieldTypes.InlineText) ? TextFieldTypes.Text : type;
    }
  }, [showHidePasswordType]);

  const actualValue = useMemo(() => { return value || internalValue; }, [value, internalValue]);

  return (
    <div className={classNames(styles.textFieldContainer, className, styles[className], (type === TextFieldTypes.InlineText) && styles.inlineTextInput)}>
      {(label || required) && (
        <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
          {label}
          {required && (<span className={styles.required}>*</span>)}
        </label>
      )}

      {(type === TextFieldTypes.TextArea) ? (
          <div className={classNames(styles.textAreaContainer, styles[imageKey])}>
            <textarea name={name} 
              onChange={fieldOnChange} 
              onBlur={(event) => { fieldOnChange(event); onBlur && onBlur(event);  }} 
              onFocus={onFocus}
              placeholder={placeholder} 
              disabled={disabled}
              className={classNames(errorMessage && styles.errorField, styles.textarea, fieldClassName, imageKey === '' && styles.textAreaNoImage)} 
              value={actualValue}/>
          </div>
      ) : (
          <input name={name} 
            onChange={fieldOnChange} 
            onBlur={(event) => { fieldOnChange(event); onBlur && onBlur(event);  }} 
            onFocus={onFocus}
            type={getTextFieldType(type)} 
            placeholder={placeholder} 
            disabled={disabled}
            className={classNames(errorMessage && styles.errorField, styles.textInput, (type === TextFieldTypes.InlineText) && styles.inlineTextInput, fieldClassName)} 
            value={actualValue} 
            onKeyDown={onKeyDown}/>
      )}

      {(type === TextFieldTypes.Password) && (
        <p onClick={togglePasswordVisibility} className={styles.passwordShowHide}>{showHideLabel}</p>
      )}

      {icon}

      {errorMessage && <p className={classNames(styles.errorMessage, errorClassName)}>{errorMessage}</p>}
    </div>
  );
};
