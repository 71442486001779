import styles from 'pages/Home/styles.module.scss';
import { onClickAccessJournalHeaderLink, onClickAccessJournalButton, onClickConnectThroughJournal } from 'pages/Home';
import { HomeContainer } from 'pages/Home/HomeContainer';
import { LeftContent } from 'pages/Home/LeftContent';
import { RightContent } from 'pages/Home/RightContent';

export const ClickthroughPage = () => {
  return (
    <HomeContainer onClickAccessJournalHeaderLink={onClickAccessJournalHeaderLink}>
      <div className={styles.contentContainer}>
        <LeftContent 
          showClickthroughPage={true} 
          onClickAccessJournalButton={onClickAccessJournalButton}
        />
        <RightContent 
          showClickthroughPage={true}
          onClickConnectThroughJournal={onClickConnectThroughJournal}
        />
      </div>
    </HomeContainer>
  );
}