import { UpdateQuestionType } from "pages/UpdateSubmittedReport/UpdateQuestions";
import * as base from "./lib/baseActions";

export interface NoteType {
  note: string;
  type: UpdateQuestionType;
  createdAt?: Date;
}

const getReportNotes = async (reportId: string | number) => {

  const getReportNotesResult = await base.get(`reports/${reportId}/notes`);
  
  return getReportNotesResult;
};

const createReportNotes = async (reportId: string | number, data: { notes: NoteType[] }) => {

  const createNoteResult = await base.post(
    `reports/${reportId}/notes`,
    data
  );

  return createNoteResult;
};

export {
  getReportNotes,
  createReportNotes
};
