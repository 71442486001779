import React from "react";
import { BaseModal } from "../BaseModal";
import { FormattedMessage } from 'react-intl';
import { Button, ButtonStyle } from "components/Buttons/Button";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "App/routing";
import styles from './styles.module.scss';
import { LockIcon } from "../../Icons";
import { vestaExternalLinks } from "lib/config";

export enum ErrorType {
  Save = "Save",
  Submit  = "Submit",
  Delete  = "Delete"
};

interface ErrorSaveSubmitDeleteModalProps {
  errorSaveSubmitDeleteModalIsOpen: any;
  setErrorSaveSubmitDeleteModalIsOpen: any;
  errorType: ErrorType;
}

export const ErrorSaveSubmitDeleteModal = ({ errorSaveSubmitDeleteModalIsOpen, 
                                             setErrorSaveSubmitDeleteModalIsOpen, 
                                             errorType } : ErrorSaveSubmitDeleteModalProps) => {

  const history = useHistory();

  const closeModal = () => {
    setErrorSaveSubmitDeleteModalIsOpen(false);
  }

  const onOkayButtonClick = () => {
    closeModal();
  }

  const firstParagraph: Array<JSX.Element> = [];
  const secondParagraph: Array<JSX.Element> = [];
  if (errorType === ErrorType.Delete) {
    firstParagraph.push(
    <FormattedMessage id="errorModal.delete.main" key="errorModal.delete.main" defaultMessage="We're having trouble deleting your report right now."/>,
    <FormattedMessage id="errorModal.safeAndSecure" key="errorModal.safeAndSecure" defaultMessage="Your information always remains safe and secure."/>
    );

    secondParagraph.push(
      <FormattedMessage id="errorModal.contactSupportAndTryAgainLater" key="errorModal.contactSupportAndTryAgainLater" defaultMessage="Contact support at {vestaSupport} and try again later." 
        values={{vestaSupport: <a href={vestaExternalLinks.contact}><FormattedMessage id="errorModal.vestaSupport" defaultMessage="vesta.com/support"/></a>}}/>
    );
  } else {
    firstParagraph.push(
      (errorType === ErrorType.Save)
        ? <FormattedMessage id="errorModal.save.main" key="errorModal.save.main" defaultMessage="We're having trouble saving your report right now."/> 
        : <FormattedMessage id="errorModal.submit.main" key="errorModal.submit.main" defaultMessage="We're having trouble submitting your report right now."/>
    );

    firstParagraph.push(
      <FormattedMessage id="errorModal.contactSupport" key="errorModal.contactSupport" defaultMessage="Contact support at {vestaSupport}." 
        values={{ vestaSupport: <a href={vestaExternalLinks.contact}><FormattedMessage id="errorModal.vestaSupport" defaultMessage="vesta.com/support"/></a>}}/>  
    );

    secondParagraph.push(
      <FormattedMessage id="errorModal.doNotHoldInformation" key="errorModal.doNotHoldInformation" defaultMessage="If you leave this page, we do not hold your information."/>,
      <FormattedMessage id="errorModal.tryAgainLater" key="errorModal.tryAgainLater" defaultMessage="You may remain on this page and try again later."/>
    );
  }

  return (
    <BaseModal showCloseButton={false} 
      isOpen={errorSaveSubmitDeleteModalIsOpen} 
      onClose={closeModal}
      className={styles.modalContent}
      icon={(errorType === ErrorType.Delete) && <LockIcon className={styles.lockIcon}/>}>

      <p className={styles.firstParagraph}>
        {firstParagraph}
      </p>
      <p className={styles.secondParagraph}>
        {secondParagraph}
      </p>

      <Button onClick={onOkayButtonClick} style={ButtonStyle.Secondary} className={ButtonStyle.Full}>
        <FormattedMessage id="button.okay" defaultMessage="Okay"/>
      </Button>
    </BaseModal>
  );
}
