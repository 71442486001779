import { HomeContainer } from 'pages/Home/HomeContainer';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'components/Forms/Select';
import { Location } from 'components/Forms/Location';
import { TextField, TextFieldTypes, ToggleBoolean } from 'components/Forms';
import { Button, ButtonStyle, LinkButton } from 'components/Buttons';
import { RoutePaths } from 'App/routing';
import { useState } from 'react';
import { SubmitSurveyModal } from 'components/Modals/SubmitSurveyModal';
import { createSurveyReponse } from 'actions/surveyActions';
import { JournalAccessSources, recordJournalAccess } from 'actions/metricTrackingActions';

type AgeOption = {
  text: string;
  value: string;
}

type ClassificationOption = {
  text: string;
  value: string;
}

enum SurveyFormFields {
  age = "age",
  classification = "classification",
  location = "location",
  wantsVestaResource = "wantsVestaResource",
  wantsFreeSupport = "wantsFreeSupport",
  wantsPaidSupport = "wantsPaidSupport",
  organizationTypeSuggestions = "organizationTypeSuggestions",
  serviceTypeSuggestions = "serviceTypeSuggestions"
}

export interface SurveyForm {
  age?: string,
  classification?: string,
  location?: string,
  wantsVestaResource?: boolean,
  wantsFreeSupport?: boolean,
  wantsPaidSupport?: boolean,
  organizationTypeSuggestions?: string,
  serviceTypeSuggestions?: string
}

const onClickAccessJournalHeaderLink = async () => {
  recordJournalAccess({ 
    source: JournalAccessSources.surveyHeaderLink 
  });
}

const useSurveyHook = () => {
  const intl = useIntl();

  const [surveyResponses, setSurveyResponses] = useState<SurveyForm>({wantsFreeSupport: false, wantsPaidSupport: false, wantsVestaResource: false} as SurveyForm);
  const [submitSurveyModalIsOpen, setSubmitSurveyModalIsOpen] = useState(false);

  const ageOptions: AgeOption[] = [
    {text: intl.formatMessage({ id: "report.o-ratherNotSay", defaultMessage: "I’d rather not say" }), value: "o-ratherNotSay"},
    {text: intl.formatMessage({ id: "report.o-under16", defaultMessage: "Under 16" }), value: "o-under16"},
    {text: intl.formatMessage({ id: "report.o-16to24", defaultMessage: "16-24" }), value: "o-16to24"},
    {text: intl.formatMessage({ id: "report.o-25to34", defaultMessage: "25-34" }), value: "o-25to34"},
    {text: intl.formatMessage({ id: "report.o-35to44", defaultMessage: "35-44" }), value: "o-35to44"},
    {text: intl.formatMessage({ id: "report.o-45to54", defaultMessage: "45-54" }), value: "o-45to54"},
    {text: intl.formatMessage({ id: "report.o-55to64", defaultMessage: "55-64" }), value: "o-55to64"},
    {text: intl.formatMessage({ id: "report.o-over65", defaultMessage: "65+" }), value: "o-over65"},
  ];

  const classificationOptions: ClassificationOption[] = [
    {text: intl.formatMessage({ id: "report.o-sexualAssault", defaultMessage: "Sexual Assault" }), value: "o-sexualAssault"},
    {text: intl.formatMessage({ id: "report.o-sexualHarassment", defaultMessage: "Sexual Harassment" }), value: "o-sexualHarassment"},
    {text: intl.formatMessage({ id: "report.o-sexualExploitation", defaultMessage: "Sexual Exploitation" }), value: "o-sexualExploitation"},
    {text: intl.formatMessage({ id: "report.o-stalking", defaultMessage: "Stalking" }), value: "o-stalking"},
    {text: intl.formatMessage({ id: "report.o-exhibitionism", defaultMessage: "Exhibitionism" }), value: "o-exhibitionism"},
    {text: intl.formatMessage({ id: "report.o-voyeurism", defaultMessage: "Voyeurism" }), value: "o-voyeurism"},
    {text: intl.formatMessage({ id: "report.o-cyberOrOnlineHarassment", defaultMessage: "Cyber/Online Harassment" }), value: "o-cyberOrOnlineHarassment"},
    {text: intl.formatMessage({ id: "report.o-humanTrafficking", defaultMessage: "Human Trafficking" }), value: "o-humanTrafficking"},
    {text: intl.formatMessage({ id: "report.o-imNotSure", defaultMessage: "I’m not sure" }), value: "o-imNotSure"},
  ];

  const getValue = (field: SurveyFormFields) => {
    return surveyResponses[field];
  };

  const setValue = (field: SurveyFormFields, updatedValue: any) => {
    let updatedSurveyResponses = {...surveyResponses};
    updatedSurveyResponses[field] = updatedValue;
    setSurveyResponses(updatedSurveyResponses);
  };

  const onCloseSubmitSurveyModal = () => {
    setSubmitSurveyModalIsOpen(false);
  }
  
  const onSubmit = async () => {
    await createSurveyReponse(surveyResponses);
    setSubmitSurveyModalIsOpen(true);
  };

  return { 
    ageOptions, 
    classificationOptions, 
    getValue, 
    setValue, 
    onSubmit, 
    submitSurveyModalIsOpen, 
    onCloseSubmitSurveyModal, 
  };
};

export const Survey = () => {
  const intl = useIntl();
  const { 
    ageOptions, 
    classificationOptions, 
    getValue, 
    setValue, 
    onSubmit, 
    submitSurveyModalIsOpen, 
    onCloseSubmitSurveyModal, 
  } = useSurveyHook();

  return (
    <>
    <HomeContainer onClickAccessJournalHeaderLink={onClickAccessJournalHeaderLink}>
      <div className={styles.surveyContainer}>
        <div className={styles.left}>
          <h3 className={styles.header}>
            <FormattedMessage 
              id="survey.header"
              defaultMessage="Thank you for answering our survey"
            />
            <p>.</p>
          </h3>
          <p className={styles.subHeader}>
            <FormattedMessage 
              id="survey.subHeader"
              defaultMessage="Your answers help us identify trends and patterns to create safer spaces for everyone."
            />
          </p>
          <div className={styles.surveySelectContainer}>
            <Select
              label={intl.formatMessage({ 
                id: "report.q-howOldAreYou", 
                defaultMessage: "How old are you?"
              })}
              options={ageOptions}
              value={getValue(SurveyFormFields.age) as string}
              onChange={e => setValue(SurveyFormFields.age, e)}
              className={styles.ageSelect}
            /> 
            <Select
              label={intl.formatMessage({ 
                id: "report.q-classifyWhatHappened", 
                defaultMessage: "How would you classify what happened?"
              })}
              options={classificationOptions}
              value={getValue(SurveyFormFields.classification) as string}
              onChange={e => setValue(SurveyFormFields.classification, e)}
              className={styles.classificationSelect}
            />
          </div>
          <Location 
            question={
              <FormattedMessage id="report.q-where" defaultMessage="Where did the incident happen?"/>
            }
            value={getValue(SurveyFormFields.location) as string}
            onChange={e => setValue(SurveyFormFields.location, e)}
            placeholder={intl.formatMessage({ id: "survey.where.placeholder", defaultMessage: "Example: Toronto, Ontario" })}
            className={styles.location}
          />
          
          <ToggleBoolean 
            label={
              <FormattedMessage 
                id="report.q-vestaResource" 
                defaultMessage="Would you like to see Vesta available as a resource of your area, workplace, school, or other?" 
              />
            }
            value={getValue(SurveyFormFields.wantsVestaResource) as boolean}
            onChange={e => setValue(SurveyFormFields.wantsVestaResource, e)}
            groupName={SurveyFormFields.wantsVestaResource}
            className={styles.vestaResourceToggle}
            labelClassName={styles.toggleBooleanLabel}
          />
          <TextField 
            type={TextFieldTypes.TextArea} 
            label={intl.formatMessage({ 
              id: "report.q-organizationTypeSuggestions", 
              defaultMessage: "Where would you like to see Vesta?" 
            })}
            placeholder={intl.formatMessage({ 
              id: "survey.organizationTypeSuggestions.placeholder", 
              defaultMessage: "Examples: My workplace, my school, my sports organization, my local police service, my neighbourhood organization, other" 
            })}
            value={getValue(SurveyFormFields.organizationTypeSuggestions) as string}
            onChange={e => setValue(SurveyFormFields.organizationTypeSuggestions, e)}
            className={styles.textArea}
          />
        </div>
        <div className={styles.right}>
          <ToggleBoolean 
            label={
              <FormattedMessage 
                id="report.q-freeSupport" 
                defaultMessage="Do you have interest in free support services from local organizations?" 
              />
            }
            value={getValue(SurveyFormFields.wantsFreeSupport) as boolean}
            onChange={e => setValue(SurveyFormFields.wantsFreeSupport, e)}
            groupName={SurveyFormFields.wantsFreeSupport}
            className={styles.freeSupportToggle}
            labelClassName={styles.toggleBooleanLabel}
          />
          <ToggleBoolean 
            label={
              <FormattedMessage 
                id="report.q-paidSupport" 
                defaultMessage="Do you have interest in paid or fee-based support services from local organizations?" 
              />
            }
            value={getValue(SurveyFormFields.wantsPaidSupport) as boolean}
            onChange={e => setValue(SurveyFormFields.wantsPaidSupport, e)}
            groupName={SurveyFormFields.wantsPaidSupport}
            className={styles.paidSupportToggle}
            labelClassName={styles.toggleBooleanLabel}
          />
          <TextField 
            type={TextFieldTypes.TextArea} 
            label={intl.formatMessage({ 
              id: "report.q-serviceTypeSuggestions", 
              defaultMessage: "If you are interested in support services, what types of services would you want to connect to?" 
            })}
            placeholder={intl.formatMessage({ 
              id: "survey.serviceTypeSuggestions.placeholder", 
              defaultMessage: "Example: Lawyer, therapist, self-defence, other"
            })}
            value={getValue(SurveyFormFields.serviceTypeSuggestions) as string}
            onChange={e => setValue(SurveyFormFields.serviceTypeSuggestions, e)}
            className={styles.textArea}
          />
          <div className={styles.bottomButtonsContainer}>
            <Button 
              onClick={onSubmit}
              className={styles.submitButton}
              style={ButtonStyle.Primary}>
              <FormattedMessage id="survey.button.submit" defaultMessage="Submit survey" />
            </Button>
            <LinkButton
              to={RoutePaths.index} 
              style={ButtonStyle.Underline}
              className={styles.returnHomeButton}>
              <FormattedMessage id="survey.button.returnHome" defaultMessage="Return to the homepage" />
            </LinkButton>
          </div>
        </div>
      </div>
    </HomeContainer>
    <SubmitSurveyModal isOpen={submitSurveyModalIsOpen} onClose={onCloseSubmitSurveyModal} />
    </>
  );
}