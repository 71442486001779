import React, { useEffect, useMemo, useState } from "react";
import { ReportData, useEditReportState } from "context/editReport";
import { Organization, getCampusSupportOrganizations, getSupportOrganizations } from "actions/organizationActions";


const useOrganizations = () => {
  const reportData: ReportData = useEditReportState();
  const [supportOrganizations, setSupportOrganizations] = useState<Organization[]>();
  const [campusSupportOrganizations, setCampusSupportOrganizations] = useState<Organization[]>();

  useEffect(() => {
    const fetchData = async () => {
      setSupportOrganizations(await getSupportOrganizations());
      setCampusSupportOrganizations(await getCampusSupportOrganizations());
    };
    fetchData();
  }, []);

  const supportOrganization = useMemo(() => {
    return supportOrganizations?.find(org => org.organizationLocation === reportData.supportLocation);
  }, [reportData.reportLocation, supportOrganizations]);

  const campusSupportOrganization = useMemo(() => {
    return campusSupportOrganizations?.find(org => org.organizationLocation === reportData.supportLocation);
  }, [reportData.reportLocation, campusSupportOrganizations]);

  return { supportOrganization, campusSupportOrganization };
}

export default useOrganizations;
