import {useIntl, FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { Button, ButtonStyle } from "components/Buttons";
import { useMergeEditUserState } from 'context/editUser';
import { Rules } from "../Rules";
import { PasswordModalFormFields } from "..";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { ButtonType } from "components/Buttons/Button";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { captchaSiteKey } from "../../../../lib/config";
import { useRef } from 'react';
import { InlineTitledMessage } from 'components/Common/InlineTitledMessage';

interface PasswordModalFormProps {
  long: boolean;
  number: boolean;
  specialCharacter: boolean;
  uppercase: boolean;
  isBreak?: boolean;
  isSubmission?: boolean;
  onDeleteClick?: () => void;
}

export const PasswordModalForm = ({ 
  long,
  number,
  specialCharacter,
  uppercase,
  isBreak = false,
  isSubmission = false,
  onDeleteClick
}: PasswordModalFormProps) => {

  const mergeUserData = useMergeEditUserState();

  const { values, handleChange, handleSubmit, isSubmitting, setFieldValue } = useFormikContext<PasswordModalFormFields>();

  const captchaRef = useRef<any>(null);

  const intl = useIntl();

  return (
    <form 
      onSubmit={async (e) => {
        e.preventDefault();
        await captchaRef.current?.execute();
        captchaRef.current?.resetCaptcha();
      }} 
      className={styles.passwordModalForm}
    >

      {isBreak && (
        <p className={classNames(styles.body, styles.highlightedText)}>
          <FormattedMessage id="passwordModal.body.first" defaultMessage="We will bring you to a resource to help ground yourself. Remember, you are in control."/>
        </p>
      )}

      <InlineTitledMessage 
        delimiter=" "
        message={intl.formatMessage({ id: "passwordModal.body.second.message" })}
        className={styles.body}
      />

      <TextField type={TextFieldTypes.Password} 
        label="Password"
        name="password" 
        placeholder="password"  
        value={values.password}
        onRawChange={(e)=>{ handleChange(e); mergeUserData({password: e.target.value}); }}
        required
        className={styles.passwordInput}
        labelClassName={styles.labels} />

      {/* a cross icon in this component informs the user what requirement their password didn't meet */}
      <Rules long={long} number={number} specialCharacter={specialCharacter} uppercase={uppercase}/>

      {captchaSiteKey && (
        <HCaptcha
          sitekey={captchaSiteKey} 
          ref={captchaRef}
          size="invisible"
          onVerify={async (token) => { 
            await setFieldValue('captchaToken', token); 
            handleSubmit();
          }}
        />
      )}

      <div className={styles.savePasswordButtonContainer}>
        <Button 
          type={ButtonType.Submit} 
          loading={isSubmitting} 
          disabled={isSubmitting} 
          style={ButtonStyle.Secondary}
          className={styles.savePasswordButton}
        > {isSubmission 
          ? (<FormattedMessage id="button.submitReport" defaultMessage="Submit Report"/>)
          : (<FormattedMessage id="button.savePassword" defaultMessage="Save Password"/>)}
        </Button>
      </div>


      <Button 
        type={ButtonType.Button} 
        onClick={onDeleteClick} 
        style={ButtonStyle.Underline} 
        className={classNames(ButtonStyle.Full, styles.cancelLink)}>
        <FormattedMessage id="button.deleteReport" defaultMessage="I don’t want to save this report"/>
      </Button>
      
    </form>
  );
}
