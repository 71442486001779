import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "../../Buttons/Button";
import { ReportData, ReportFields, useMergeEditReportState } from "context/editReport";
import { ReportSectionQuestions, ReportSections } from "pages/Report";

import classNames from "classnames";
interface RequiresLocationModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
  acknowledgeRelationshipToOffender: () => void;
}

export const RelationshipToOffenderModal = ({ 
  modalIsOpen, 
  setModalIsOpen,
  acknowledgeRelationshipToOffender, 
} : RequiresLocationModalProps) => {
  const mergeReportData = useMergeEditReportState(); 
  const closeModal = () => {
    mergeReportData({ [`${ReportFields.AllowPoliceContact}` as keyof ReportData]: false });
    setModalIsOpen(false);
  }

  const consent = () => {
    acknowledgeRelationshipToOffender();
    setModalIsOpen(false);
  }

  return (
    <BaseModal title={<FormattedMessage id="personalInfo.whyModal.title" defaultMessage="Consent to Police"/>}
      centerTitle={true}
      isOpen={modalIsOpen} 
      onClose={closeModal} 
      className={styles.campusModalContent}
      showCloseButton={false}
      titleStyle={styles.title}
     >
      <p className={styles.body}>
        <FormattedMessage id="personalInfo.relationshipModal.description1"
          values={{
            highlight: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.relationshipModal.description.highlight1"/></span>,
          }}
          defaultMessage="Text."
        />
      </p>
      <p className={styles.body}>
        <FormattedMessage id="personalInfo.relationshipModal.description2"
          values={{
            highlight: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.relationshipModal.description.highlight2"/></span>,
          }}
          defaultMessage="Text."
        />
      </p>
      <p className={styles.body}>
        <FormattedMessage id="personalInfo.relationshipModal.description3"
          values={{
            highlight: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.relationshipModal.description.highlight3"/></span>,
          }}
          defaultMessage="Text."
        />
      </p>
      <div className={styles.resultButtons}>
        <Button onClick={consent} style={ButtonStyle.Secondary} className={ButtonStyle.Full}>
          <FormattedMessage id="personalInfo.whyModal.button" />
        </Button>
        <div className={styles.iDoNotConsent}>
        <Button onClick={closeModal} style={ButtonStyle.Underline} className={styles.doNotConsent}>
          <FormattedMessage id="report.doNotConsent" />
        </Button>
      </div>
      </div>
    </BaseModal>
  );
}
