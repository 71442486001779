import React from "react";
import styles from './styles.module.scss';
import { ReportData, useEditReportState } from "context/editReport";
import { NoteType } from "actions/noteActions";
import { Note } from "./Note";
import { FormattedMessage } from "react-intl";

export const Notes = () => {
  const reportData: ReportData = useEditReportState();
  return (
    <>
    {reportData.notes && (
      <div className={styles.notes}>
        <h1><FormattedMessage id="updateReport.reportUpdates" defaultMessage="Report Updates"/></h1>
        {reportData.notes.map((note: NoteType, index) => {
          return ( <Note note={note} key={index}/> );
        })}
      </div>
      )}
    </>
  );
};
