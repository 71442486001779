import React, { FC } from 'react';
import styles from './styles.module.scss';
import { WhiteCheckIcon } from '../../../Icons';

interface CheckboxType {
  id:string;
  value?: string;
  checked?: boolean;
  name?:string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: FC<CheckboxType> = ({
  value,
  checked = false,
  name,
  id,
  onChange,
}) => {
  return (
    <div>
      <input
        className={styles.checkbox}
        id={id}
        type='checkbox'
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => { 
          onChange(event)
        }}
      />
      <span className={styles.wrapper}>
        <WhiteCheckIcon />
      </span>
    </div>
  );
};
