import * as base from "./lib/baseActions";

export interface Organization {
  id: number;
  organizationTypeId?: OrganizationType;
  organizationNameAndLocation: string;
  organizationLocation: string;
  organizationName: string;
  hasDashboardAccess: boolean;
  hasReportsAccess: boolean;
  websiteLink: string;
  intakeFormLink: string;
  crisisLink: string;
  crisisPhone: string;
  isPartnered: boolean;
  hidden?:boolean
}

export enum OrganizationType {
  Vesta = 1,
  Police = 2,
  CrisisCenter = 3,
  Support = 4,
  CampusSupport = 5
}

export interface HasOrganizationTypes {
  vesta: boolean,
  police: boolean,
  crisisCenter: boolean,
  support: boolean,
  campusSupport: boolean,
}

export interface OrganizationFilter {
  isPartnered: boolean;
}

const getSupportOrganizations = async (): Promise<Organization[]>=> {
  const getSupportOrganizations = await base.get(`supportOrganizations`);
  return getSupportOrganizations.data;
};

const getCampusSupportOrganizations = async (): Promise<Organization[]>=> {
  const getCampusSupportOrganizations = await base.get(`campusSupportOrganizations`);
  return getCampusSupportOrganizations.data;
};

const getAllOrganizationLocations = async (): Promise<string[]> => {
  const locations = await base.get(`organizationLocations`);
  return locations.data;
};

const getOrganizationTypesByLocation = async (location: string, filter: OrganizationFilter): Promise<HasOrganizationTypes> => {
  if (!location) return {} as HasOrganizationTypes;

  const results = await base.get(`organizationTypes?location=${encodeURIComponent(location)}&partneredFilter=${filter.isPartnered}`);
  const organizationTypes: OrganizationType[] = await results.data;

  const hasOrganizationTypes = organizationTypes.reduce<HasOrganizationTypes>((acc, orgType) => {
    return {
      vesta:         acc.vesta         || orgType === OrganizationType.Vesta,
      police:        acc.police        || orgType === OrganizationType.Police,
      crisisCenter:  acc.crisisCenter  || orgType === OrganizationType.CampusSupport,
      support:       acc.support       || orgType === OrganizationType.Support,
      campusSupport: acc.campusSupport || orgType === OrganizationType.CampusSupport,
    }
  }, {} as HasOrganizationTypes);
  return hasOrganizationTypes;
}

export {
  getSupportOrganizations,
  getCampusSupportOrganizations,
  getAllOrganizationLocations,
  getOrganizationTypesByLocation
};