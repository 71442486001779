import * as base from "./lib/baseActions";
import jwtDecode from "jwt-decode";
import { UserInfo } from 'context/editUser';
import { ReportData } from "context/editReport";
import { useMergeAppState, useClearState } from "context";

/// Fetch data for logged in user
const fetchUser = () => {
  return base.get("users/me");
};

export interface loginUserData {
  email: string;
  password: string;
  passwordConfirmation?: string;
};

export interface authResult {
  data: {
    token: string;
  }
}

export type loginUserResult = UserInfo;

const fetchBasicUserInfo = async () => {
  const userToken = localStorage.getItem('authToken'); 

  if (userToken) {
    return jwtDecode(userToken);
  } else {
    return null;
  }
}

/// logout user
const useLogoutUser = () => {
  const mergeAppState = useMergeAppState();
  const clearState = useClearState();

  return () => {
    if (navigator.cookieEnabled) {
      localStorage.removeItem('authToken');
    }
    mergeAppState({ Authenticated: false, Authenticating: false });
    clearState();
  };
};

export interface loginReportUserData {
  reportKey: string;
  password: string;
  passwordConfirmation?: string;
};


/// Login
const loginReportUser = async (data : loginReportUserData, setToken: boolean = true) : Promise<UserInfo> => {
  const { reportKey, password } = data;

  try {
    const loginResult = await base.post("reportLogin", {
      reportKey,
      password
    });

    setToken && localStorage.setItem('authToken', loginResult.data.token);

    return loginResult.data.user;
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      err.status = 400;
      err.message = "Incorrect Report Key or Password.";
    } else if (err?.original?.response?.status === 429) {
      err.status = 429;
      err.message = "Too many failed logins on this report";
    }

    throw err;
  }
};


// create report user
const createReportUser =  async (data: ReportData & UserInfo, isSubmission: boolean) => {
  const userResult = await base.post(
    "reportUser",
    { ...data, isSubmission }
  );

  localStorage.setItem('authToken', userResult.data.token);
  return userResult.data;
};

const updateReportUser =  async (data: ReportData & UserInfo, isSubmission: boolean) => {

  const userResult = await base.put(
    "reportUser",
    { ...data, isSubmission }
  );

  return userResult.data;
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken = localStorage && localStorage.getItem('authToken');
  return initialAuthToken ? true : false;
}

export {
  fetchUser,
  fetchBasicUserInfo,
  useLogoutUser,
  loginReportUser,
  createReportUser,
  updateReportUser,
  userIsAuthorized
};
