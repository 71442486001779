import styles from './styles.module.scss';
import { RoutePaths } from "App/routing";
import Footer from "Layout/Footer";
import Header from "Layout/Header";
import classNames from "classnames";
import { Button, ButtonStyle, LinkButton } from "components/Buttons";
import { QuestionButtonIcon } from "components/Icons";
import { WhoIsVestaModal } from "components/Modals/WhoIsVestaModal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

export interface HomeContainerProps {
  children: JSX.Element;
  onClickAccessJournalHeaderLink: () => void;
}

interface HomeHeaderProps {
  onClickAccessJournalHeaderLink: () => void;
}

const HomeHeader = ({ onClickAccessJournalHeaderLink }: HomeHeaderProps) => {
  const [whoIsVestaModalIsOpen, setWhoIsVestaModalIsOpen] = useState(false);

  const openWhoIsVestaModal = () => {
    setWhoIsVestaModalIsOpen(true);
  }

  return (
    <>
    <div className={styles.homeHeader}>
      <Header showBackButton={false}/>
      <h2 className={styles.headerText}><FormattedMessage id="home.header.yourVoiceMatters"/></h2>

      <LinkButton 
        to={RoutePaths.reportStart} 
        onClick={onClickAccessJournalHeaderLink}
        style={ButtonStyle.None}
        className={styles.headerLinkLeft}>
        <span className={styles.headerLinkText}><FormattedMessage id="home.header.accessVestaJournal"/></span>
      </LinkButton>

      <Button onClick={openWhoIsVestaModal} style={ButtonStyle.None} className={styles.headerLinkRight}>
        <span className={styles.headerLinkText}><FormattedMessage id="home.header.whoIsVesta"/></span>
        <QuestionButtonIcon className={styles.questionIcon}/>
      </Button>
    </div>
    <WhoIsVestaModal 
      whoIsVestaModalIsOpen={whoIsVestaModalIsOpen}
      setWhoIsVestaModalIsOpen={setWhoIsVestaModalIsOpen}
    />
    </>
  );
}

export const HomeContainer = ({ children, onClickAccessJournalHeaderLink }: HomeContainerProps) => {
  return (
    <div className={classNames(styles.homePage, 'homePageAnim')}>
      <div className={styles.homeContainer}>
        <HomeHeader onClickAccessJournalHeaderLink={onClickAccessJournalHeaderLink} />
        {children}
      </div>
    <Footer/>
    </div>
  );
}