import React, { useState, useEffect, ReactNode } from "react";
import classNames from "classnames";
import { RadioChoice, RadioType } from "components/Forms/Toggle/RadioChoice";
import { ToggleBoolean } from "components/Forms/Toggle/ToggleBoolean";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { Location } from 'components/Forms/Location';
import { MultiChoice } from 'components/Forms/MultiChoice';
import { DateTime, DefaultTimeTypes } from 'components/Forms/DateTime';
import { FormattedOptionType, OptionType, QuestionType } from "../../../../actions/questionActions";
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import styles from './styles.module.scss';

import { ReportFormErrorType } from "pages/Report";
import { OffenderFace } from "components/OffenderFace";
import { Attachments } from "../Attachments";

interface SectionQuestionsProps {
  question: QuestionType;
  value?: any;
  scale?: any;
  error?: ReportFormErrorType;
}

export const PrintSectionQuestion = ({ error, question, value, scale}: SectionQuestionsProps) => {
  const [questionScale, setQuestionScale] = useState();
  const [questionValue, setQuestionValue] = useState(); 

  useEffect(
    () => {
      if(value) {
        setQuestionValue(value);
      }
      if(scale) {
        setQuestionScale(scale);
      }
    }
  ,[value, scale])

  const onScaleChange = (value: any) => {
    setQuestionScale(value);
  };

  const onValueChange = (value: any) => {
    setQuestionValue(value);
  }

  if(question.scale) {
    return (
      <div className={classNames(styles.inputToggleContainer, error && styles.error)}>
        <SectionValue {...{ question, value: questionValue, onChange: onValueChange }} />
        <SectionScale {...{ question, value: questionScale, onChange: onScaleChange }} />
        { error && <p className={styles.errorMessage}>{error.message}</p>}
      </div>
    ) 
  } else {
    return <SectionValue {...{ question, value, onChange: onValueChange, errorMessage: error && error.message }} />
  }
};

interface SectionScaleProps {
  question: QuestionType;
  onChange: (value: any) => void;
  value?: string;
};

export const SectionScale = ( { question, value, onChange }: SectionScaleProps) => {
  const intl = useIntl();
  const formattedScaleOptions = _.map(question.scale,
    (scale)  => {
      return {
        value: scale.key,
        text: intl.formatMessage({id: `report.${scale.key}`}) 
      }
    }
  );

  return <RadioChoice
    groupName={question.key}
    value={value}
    options={formattedScaleOptions}
    onChange={onChange}
  />
};

interface SectionValueProps {
  question: QuestionType;
  value?: any;
  onChange: (value: any) => void;
  errorMessage?: ReactNode;
  defaultValue?: string;
}

export const SectionValue = ( { question, value, onChange, errorMessage }: SectionValueProps ) => {
  const intl = useIntl();
  
  const key = `report.${question.key}`;
  const label = <FormattedMessage id={key} />;
  const placeholder: string = question.placeholder ? question.placeholder : "";
  const required = question.required;
  const description = question.description;
  const formattedOptions: FormattedOptionType[] = _.map(
    question.options,
    (option: OptionType) => {
      return {
        value: option.key,
        text: intl.formatMessage({ 
          id: `report.${option.key}`, 
          defaultMessage: (option.text && option.text !== "") ? option.text : option.key 
        }),
      }
    }
  );


  switch (question.type) {
    case "boolean":
      return ( 
        <ToggleBoolean  
          label={label}
          value={value}
          onChange={(value) => { onChange(value); }}
          groupName={key} 
          {...{ required, errorMessage }}
        /> 
      );
    case "options":
      return <>
      <RadioChoice    
        label={label}
        groupName={key}
        value={value || null}
        type={RadioType.Stacked}
        onChange={(value) => { onChange(value); }}
        options={formattedOptions}
        description={description}
        {...{ required, errorMessage }}

      />
      </>;
    case "number":
      return <TextField label={label} 
        type={TextFieldTypes.Number}
        value={value}
        onChange={onChange}
        name={key} 
        {...{ required, errorMessage }}
        className={'inlineTextInput'}
      />;
    case "note":
      return <div>{label}</div>
    case "text":
      return ( <TextField
        label={label} 
        type={TextFieldTypes.Text}
        value={value}
        onChange={onChange} 
        placeholder={placeholder}
        name={key} 
        {...{ required, errorMessage }}

      /> );
    case "text-long":
      return ( <TextField      
        label={label} 
        type={TextFieldTypes.TextArea}
        onChange={onChange}
        placeholder={placeholder}
        name={key} 
        value={value}
        imageKey={question.imageKey}
        {...{ required, errorMessage }}

      /> );
    case "multichoice":
      return (<>
        <MultiChoice    
          label={label}
          groupName={key}
          options={formattedOptions}
          onChange={onChange}
          values={value}
          {...{ required, errorMessage }}

        /> 
        </>
      );
    case "complexion":
      return <RadioChoice    
      label={label}
      groupName={key}
      value={value  || null}
      type={RadioType.Stacked}
      onChange={(value) => { onChange(value); }}
      options={formattedOptions}
      description={description}
      {...{ required, errorMessage }}

      />
    case "hairColour":
      return <RadioChoice    
      label={label}
      groupName={key}
      value={value  || null}
      type={RadioType.Stacked}
      onChange={(value) => { onChange(value); }}
      options={formattedOptions}
      description={description}
      {...{ required, errorMessage }}

      />

    case "offender-face":
      return (<OffenderFace className={styles.offenderFaceSection} />);
    case "address":
      return ( <TextField
        label={label} 
        type={TextFieldTypes.Text}
        value={value}
        onChange={onChange} 
        placeholder={placeholder}
        name={key} 
        {...{ required, errorMessage }}

      /> );
    case "dateTimeRange":
      // TODO: Change Date Range to From: Time Day Month Year to Time Day Month Year
      return ( <DateTime       
        label={label}
        value={value }
        onChange={onChange}
        isRange={true}
        defaultDateTimeType={DefaultTimeTypes.Unselected}
        groupName={key}
        showDateRange={true}
        readOnlyMode={true}
        {...{ required, errorMessage }}
      /> );
    case "attachments":
        return <div><Attachments /></div>
    case "select":
      return ( 
        <RadioChoice    
        label={label}
        groupName={key}
        value={value  || null}
        type={RadioType.Stacked}
        onChange={(value) => { onChange(value); }}
        options={formattedOptions}
        description={description}
        {...{ required, errorMessage }}

      />
      );
    default:
      return <div>{label}</div>;
    }
}
