import { UploadedAttachmentType } from "actions/awsActions";
import { getReportNotes } from "actions/noteActions";
import { getReportAnswers, getReportByKey } from "actions/reportActions";
import { RoutePaths } from "App/routing";
import { Answer, useEditAnswersState, useSetEditAnswersState, mergeAnswerMap } from "context/editAnswers";
import { useMergeEditReportState } from "context/editReport";
import { ReportSectionQuestions, ReportSections } from "pages/Report";
import { useHistory, useLocation } from "react-router-dom";



const usePopulateReportNotesAnswers = () => {
  const mergeReportData = useMergeEditReportState();
  const setEditAnswersState = useSetEditAnswersState();
  const answerData = useEditAnswersState();
  const currentPath = useLocation().pathname;
  const history = useHistory();

  return async (
    reportId: number | undefined, 
    reportKey: string | undefined
  ) => {
    if (!reportKey || !reportId) {
      throw "Undefined reportKey or reportId for user";
    }

    try {
      const reportRaw = await getReportByKey(reportKey);
      const answersRaw = await getReportAnswers(reportId, !!(reportRaw && reportRaw?.data?.dateSubmitted));
      const notesRaw = await getReportNotes(reportId);
      
      const finalAnswers = { answers: mergeAnswerMap(answerData?.answers, answersRaw?.answers)};
      const getReportAnswersNotesResult = [reportRaw, finalAnswers, notesRaw];

      if (getReportAnswersNotesResult) {
        const reportResult = getReportAnswersNotesResult[0].data;
        const answerResult = getReportAnswersNotesResult[1];

        if (reportResult) {
          const notesResult = getReportAnswersNotesResult[2].data;

          const report = {
            ...reportResult, 
            notes: notesResult
          };
          
          const attachmentsArray: UploadedAttachmentType[] = answerResult?.answers?.get(`${ReportSections.Experience}:${ReportSectionQuestions.FileAttachments}`)?.value.answer;
          if (attachmentsArray) {
            report.uploadedAttachments = attachmentsArray;
          }

          mergeReportData(report);
        }

        if (answerResult) {
          setEditAnswersState(answerResult);
        }

        if (reportResult.dateSubmitted) {
          if (currentPath === RoutePaths.index) {
            history.push(RoutePaths.updateSubmittedReport);
          }
        } else {
          history.push(RoutePaths.report);
        }
      }
    } catch (err) {
      throw err;
    }
  }
};

export default usePopulateReportNotesAnswers;
