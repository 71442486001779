import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RadioField } from "../RadioField";
import classNames from 'classnames';
import styles from './styles.module.scss';


interface RadioOption {
  value: string;
  text: string;
  disabled?: boolean;
};

export enum RadioType {
  Stacked = "stacked",
  Default = 'default'
}

interface RadioChoiceProps {
  label?: ReactNode;
  required?: boolean;
  className?: string;
  value?: any;
  errorClassName?: string;
  options: Array<RadioOption>;
  groupName: string;
  onChange?: (value: any, groupName?: string) => void;
  disabled?: boolean;
  buttonIcons?: Array<JSX.Element>;
  errorMessage?: ReactNode;
  description?: string;
  type?: RadioType;
};

export const RadioChoice: FC<RadioChoiceProps> = ({
  label, 
  groupName, 
  required, 
  options, 
  value, 
  className, 
  errorClassName = styles.displayNone, 
  onChange, 
  disabled = false,
  buttonIcons, 
  errorMessage,
  description,
  type = RadioType.Default
}) => {
  const [radioValue, setRadioValue] = useState(value || ((options?.length > 0) && options[0]));
  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  const onRadioChange = (value: any) => {
    setRadioValue(value);
     
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <div className={classNames(styles.toggleStyle, className, groupName)}>
      {description && <p className={styles.description}>{description}</p>}
      <div className={classNames(styles.radioChoiceContainer, type === RadioType.Stacked && styles.stackedRadioLabel)}>
          {label && (
            <p className={styles.radioLabel} >
              {label}
              {buttonIcons}
              {required && (<span className={styles.required}>*</span>)}
            </p>
          )}

          <div className={classNames(styles.radioOptions)}>
            {options.map((option: RadioOption) => {
              return <RadioField 
                value={option.value} 
                text={option.text}
                disabled={disabled || option.disabled}
                checked={radioValue ? (radioValue === option.value) : false}
                groupName={groupName} 
                key={`${option.value}Value-${groupName}`} 
                onChange={(e) => {  onRadioChange(e.target.value); }  }/>
              })}
          </div>
      </div>

      <p className={classNames(styles.errorMessage, errorClassName)}>{errorMessage}</p>
    </div>
  );
};
