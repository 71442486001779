import React, { useState } from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useLogoutUser } from "actions/userActions";
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "components/Buttons";
import { SurveyRequestModal } from "../SurveyRequestModal";

interface SavedReportModalProps {
  isSavedReportModalOpen: boolean;
  setIsSavedReportOpen: (value: boolean) => void;
  reportKey?: string;
  fullName?: string;
}

export const SavedReportModal = ({ isSavedReportModalOpen, setIsSavedReportOpen, reportKey, fullName } : SavedReportModalProps) => {
  const [surveyRequestModalIsOpen, setSurveyRequestModalIsOpen] = useState(false);
  const logoutUser = useLogoutUser();

  const closeSavedReportModal = () => {
    setIsSavedReportOpen(false);
    logoutUser();
    openSurveyRequestModal();
  }

  const openSurveyRequestModal = () => {
    setSurveyRequestModalIsOpen(true);
  }

  return (
    <>
    <BaseModal isOpen={isSavedReportModalOpen} 
      showCloseButton={false}
      centerTitle={true}
      titleId={"savedReportModal.savedReport"} 
      onClose={closeSavedReportModal}>
      <div className={styles.savedReportModalContent}>
        <p><FormattedMessage id="savedReportModal.savedReportDetails" values={{ reportUser: fullName }} defaultMessage="You're doing great. You can return to this report at any time using this key and your selected password:"/></p>
        {/* TODO: pull reportKey from report context instead when editReport context is back, https://trello.com/c/5C3TSCe9/633-use-report-key-from-report-context-not-edit-context */}
        <h1 className={styles.reportKey}>{reportKey}</h1>
        <Button onClick={closeSavedReportModal} style={ButtonStyle.Underline}><FormattedMessage id="common.backToMainMenu" defaultMessage="Back to main menu"></FormattedMessage></Button>
      </div>
    </BaseModal>
    <SurveyRequestModal 
      surveyRequestModalIsOpen={surveyRequestModalIsOpen}
      setSurveyRequestModalIsOpen={setSurveyRequestModalIsOpen}
    />
    </>
  );
}
