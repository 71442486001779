import React from "react";
import styles from './styles.module.scss';
import classNames from "classnames";
import { fetchQuestionSections, SectionType } from "actions/questionActions";

interface ProgressBarProps {
  currentSectionIndex: number;
}

export const ProgressBar = ({currentSectionIndex}: ProgressBarProps) => {
  return (
    <div className={styles.progressBarContainer}>
      {fetchQuestionSections()?.map((section: SectionType, index) => {
        return (
          <div className={classNames(currentSectionIndex >= index+1 && styles.active, styles.progressCircleContainer)} key={section.key}>
            <div className={styles.progressCircle}></div>
          </div>
        );
      })}
    </div>
  );
}
