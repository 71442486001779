import classNames from "classnames";
import { ArrowButton, ArrowDirection, ArrowPosition, ButtonStyle } from "components/Buttons/Button";
import { Loading } from 'components/Common/Loading';
import { LocationUnfilledIcon, MapLocationColouredIcon, PhoneIcon, WebsiteIcon } from "components/Icons";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { fetch211Resources } from '../../actions/resourceActions';
import { ResourceLayout } from '../../pages/Resources';
import { PageNavigation } from "./PageNavigation";
import styles from './styles.module.scss';

interface fetchResourcesProps {
  latitude: string;
  longitude: string; 
  pageNumber?: number; 
  search211Term?: string;
  distance?: string;
  address?: string;
}

const pageSize = 10;

export const ResourceLocations = () => {
  const intl = useIntl();
  const location: any = useLocation();

  const [resources, setResources] = useState<Array<any>>([]);
  const [showMoreListingsOpen, setShowMoreListingsOpen] = useState(new Set());
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [search211Term, setSearch211Term] = useState(location.state?.search211Term || "");
  const [distance, setDistance] = useState<string>(location.state?.distance || "50");
  const [loading, setIsLoading] = useState(false);

  const fetchResources = async ({ latitude, longitude, pageNumber, search211Term, distance, address }: fetchResourcesProps) => {
    setIsLoading(true);
    try {
      const result = await fetch211Resources({
        latitude: parseFloat(latitude), 
        longitude: parseFloat(longitude), 
        locationQuery: address, 
        lang: intl.locale,
        pageNumber,
        pageSize,
        searchTerm: search211Term,
        distance 
      });

      if (result) {
        setResources(result.Records);
        const resourcesPageCount = Math.ceil(result.RecordCount/pageSize);
        if (resourcesPageCount !== numPages) {
          setPageNumber(1);
          setNumPages(Math.ceil(result.RecordCount/pageSize));
        }
      }
    } catch(err) {
    }
    setIsLoading(false);
  };

  const latitude = location.state?.latitude;
  const longitude = location.state?.longitude;
  const address = location.state?.address;

  // want immediate search if lat, long or page number changes
  useEffect(() => {
    if (latitude && longitude) {
      fetchResources({ 
        latitude, 
        longitude, 
        address,
        pageNumber, 
        search211Term: (search211Term.length >= 2) ? search211Term : "*", 
        distance 
      });
    };
  }, [latitude, longitude, pageNumber, distance]);

  // want delayed search when search term is changing
  useEffect(() => {
    if (latitude && longitude && (search211Term.length >= 2)) {
      const debouncedFetchResources = _.debounce(fetchResources, 1000);
      debouncedFetchResources({ latitude, longitude, pageNumber, search211Term, distance });
    };
  }, [search211Term]);

  const handleShowMoreListingOpen = (showMoreListing: string) => {
    const showMoreListingToOpen = new Set(showMoreListingsOpen.values());
    if (showMoreListingToOpen.has(showMoreListing)) {
      showMoreListingToOpen.delete(showMoreListing);
    } else {
      showMoreListingToOpen.add(showMoreListing);
    }

    setShowMoreListingsOpen(showMoreListingToOpen);
  };

  return (
    <ResourceLayout 
      search211Term={search211Term}
      setSearch211Term={setSearch211Term}
      distance={distance}
      setDistance={setDistance}
    >
      {loading && ( 
        <div>
          <Loading loadingMessage={intl.formatMessage({ id: "resources.loading", defaultMessage: "Loading resources"})}/>
        </div>
      )}

      {!resources?.length && (
        <div>
          <p>
            <FormattedMessage id="resources.noResources" defaultMessage="No resources have been found"/>
          </p>
        </div>
      )}

      {resources.map((resource) => {
        // construct google maps destination search query
        let googleMapSearchQuery = `https://www.google.com/maps/dir/?api=1&destination=`;

        let mapQuery = [];
        if (resource["PhysicalAddressStreet1"].length) {
          mapQuery.push(resource["PhysicalAddressStreet1"]);

          if (resource["PhysicalAddressStreet2"].length) {
            mapQuery.push(resource["PhysicalAddressStreet2"]);
          }
        } 
        if (resource["PhysicalAddressCity"].length) {
          mapQuery.push(resource["PhysicalAddressCity"]);
        }
        if (resource["PhysicalAddressProvince"].length) {
          mapQuery.push(resource["PhysicalAddressProvince"]);
        }
        if (resource["PhysicalAddressPostalCode"].length) {
          mapQuery.push(resource["PhysicalAddressPostalCode"]);
        }
        if (resource["PhysicalAddressCountry"].length) {
          mapQuery.push(resource["PhysicalAddressCountry"]);
        }

        googleMapSearchQuery += mapQuery.join(" ");
        googleMapSearchQuery += `&travelmode=driving`;

        // if there's an array of phone numbers - choose office, business or toll free numbers (in that order) - if those don't exist, choose a non-fax/tty number
        let phoneNumberObj: any;
        if (resource["PhoneNumbers"]?.length) {
          phoneNumberObj = resource["PhoneNumbers"]?.find((phoneNumberObj: Object) => {
            return Object.values(phoneNumberObj).some((value: string) => {
              return ["office", "business", "toll free"].some(phoneNumberType => {
                return value.toLowerCase().includes(phoneNumberType);
              })
            });
          });

          if (!phoneNumberObj) {
            const filteredPhoneNumbers = resource["PhoneNumbers"].filter((phoneNumberObj: Object) => {
              return Object.values(phoneNumberObj).some((value: string) => {
                return ["fax", "tty"].some(phoneNumberType => {
                  return !value.toLowerCase().includes(phoneNumberType);
                })
              });
            });

            if (filteredPhoneNumbers.length) {
              phoneNumberObj = filteredPhoneNumbers[0];
            }
          }
        }
      

        return (
          <div key={resource.id} className={classNames(styles.locationSuggestionListItem, showMoreListingsOpen.has(resource.id) && styles.listingOpen)}>
            <div className={styles.locationIcon}><LocationUnfilledIcon /></div>
            <div className={styles.locationListing}>
              <div className={styles.header}>
                <div className={styles.listingTitle}>
                  <h5 className={styles.headerTitle}>{resource["PublicName"]}</h5>
                  <a href={`https://211central.ca/record/${resource["id"]}`} rel="noopener noreferrer" target="_blank" className={classNames(styles.viewMoreButton, ButtonStyle.Secondary)}>
                    <FormattedMessage id="viewListing" defaultMessage="View Listing"/>
                  </a> 
                </div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: resource["Description"] }} />
              </div>

              <div className={styles.showMoreButton}>
                {showMoreListingsOpen.has(resource.id)
                  ? (<FormattedMessage id="showMore" defaultMessage="SHOW LESS"/>) 
                  : (<FormattedMessage id="showLess" defaultMessage="SHOW MORE"/>)}

                <ArrowButton arrowDirection={showMoreListingsOpen.has(resource.id) ? ArrowDirection.Up : ArrowDirection.Down} 
                  clickAction={() => { handleShowMoreListingOpen(resource.id) }} 
                  arrowPosition={ArrowPosition.Right} 
                  style={ButtonStyle.None}
                />
              </div>

              <div className={styles.directionsWebsiteContact}>
                <div className={styles.contactContainer}>
                  <div className={styles.mapIcon}>
                    <MapLocationColouredIcon />
                  </div>
                  <a href={googleMapSearchQuery} className={styles.directions}>
                    <FormattedMessage id="directionsViaGoogleMap" defaultMessage="Google Map Directions"/>
                  </a>
                </div>

                <div className={styles.contactContainer}>
                  <div className={styles.mapIcon}>
                    <WebsiteIcon />
                  </div>
                  <a href={`http://${resource["Website"]}`} rel="noopener noreferrer" target="_blank" className={styles.website}>
                    {resource["Website"]}
                  </a> 
                </div>

                <div className={styles.contactContainer}>
                  <div className={styles.mapIcon}>
                    <PhoneIcon />
                  </div>
                  <div className={styles.phoneNumber} dangerouslySetInnerHTML={{ __html: phoneNumberObj?.["Phone"] }}/>
                </div>

                <div className={styles.contactContainer}>
                  <div className={styles.hours} dangerouslySetInnerHTML={{ __html: resource["Hours"] }}/>
                </div>
              </div>
            </div>
            
          </div>
      )})}

      <PageNavigation
        numPages={numPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        className={styles.resourcePageNav}
      />  
    </ResourceLayout>
  );
}


