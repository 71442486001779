import classNames from 'classnames';
import styles from './styles.module.scss';

export interface InlineTitledMessageProps {
  title?: string;
  message: string;
  delimiter?: string;
  className?: string;
  color?: InlineTitledColor;
}

export enum InlineTitledColor {
  Pink = "pink",
  Blue = "blue",
}

export const InlineTitledMessage = ({ 
  title = "",
  message,
  delimiter,
  className,
  color = InlineTitledColor.Pink,
}: InlineTitledMessageProps) => {
  return (
    <p className={className}>
      <span className={classNames(styles.title, styles[color])}>{title}</span>{title ? delimiter : ``}{message}
    </p>
  );
}
