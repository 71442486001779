import React, { useState, ReactNode, useEffect } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useEditAnswersState, AnswersInfo, } from "context/editAnswers";
import { ReportStates, useEditReportState, isSubmitted } from "context/editReport";
import { ReportPage } from "./ReportPage";
import { ReportPrevNext } from "./ReportPrevNext";
import { QuestionType, fetchQuestionSections, fetchNextQuestionSection, fetchPrevQuestionSection, fetchSensoryNextQuestionSection, fetchQuestionSection } from "actions/questionActions";
import { SectionType } from "actions/questionActions";
import Header from "Layout/Header";
import { AppTalkImage, PhoenixImage, OffenderStatementImage, UseVoiceImage, WindowHandsTouchImage, DoveImage, ShoulderSupportImage } from "components/Images";
import { PasswordModal, PasswordModalActionType } from "components/Modals/PasswordModal";
import { SavedReportModal } from "components/Modals/SavedReportModal";
import styles from './styles.module.scss';
import { useEditUserState, UserInfo  } from "context/editUser";
import { DeleteModal } from "components/Modals/DeleteModal";
import { ReportBreak } from "./ReportBreak";
import _ from "lodash";
import Footer from "Layout/Footer";

export enum ReportSections {
  Incident = "s-incident",
  Offender = "s-offender",
  Break = "s-break",
  Experience = "s-experience",
  OtherDetails = "s-details",
  SensoryExperience = "s-sensory",
  Impact = "s-impact"
}

// this list is hardly complete (there are 58 question keys) - can expand later if necessary
export enum ReportSectionQuestions {
  TellYourStory =  `q-tellYourStory`,
  FileAttachments = "q-fileAttachments",
  OtherAttachments = "q-otherAttachments",
  OffenderKnow = "q-offenderKnow",
  When = "q-when",
  Where = "q-whereAddress",
  PriorRelationship = "q-priorRelationship"
}

export enum ReportSectionAnswers {
  TellYourStory =  `q-tellYourStory`,
  FileAttachments = "q-fileAttachments",
  OtherAttachments = "q-otherAttachments",
  OffenderKnow = "q-offenderKnow",
  When = "q-when",
  Where = "q-whereAddress",
  PriorRelationship = "q-priorRelationship"
}

export enum ReportSectionAnswersPriorRelationshipOptions {
  RomanticPartners = "o-romanticPartners",
  FamilyMember = "o-familyMember"
}

const renderSectionIllustration = (reportSectionImage: string) => {
  switch(reportSectionImage) {
    case ReportSections.Incident:
      return <AppTalkImage />
    case ReportSections.Offender:
      return <OffenderStatementImage />
    case ReportSections.Break:
      return <PhoenixImage />
    case ReportSections.Experience:
      return <UseVoiceImage />
    case ReportSections.OtherDetails:
      return <WindowHandsTouchImage />
    case ReportSections.SensoryExperience:
      return <DoveImage />
    case ReportSections.Impact:
      return <ShoulderSupportImage />
  }
}

export interface ReportFormErrorType {
  answerKey: string;
  question: QuestionType;
  message?: ReactNode;
}

export const validateQuestions = (reportSection: SectionType | null, reportAnswers: AnswersInfo) => {
  let errors: Map<string, ReportFormErrorType> = new Map();

  const answersMap = reportAnswers.answers;

  if(reportSection) {
    errors = reportSection.questions.reduce((result: Map<string, ReportFormErrorType>, question) => {
      const fullQuestionKey = `${reportSection.key}:${question.key}`;
      const answerForQuestion = answersMap.get(fullQuestionKey);
      
      if(question.required && (!answerForQuestion || !(answerForQuestion.value && answerForQuestion.value.answer))) {
        result.set(
          fullQuestionKey,
          {
            answerKey: fullQuestionKey,
            question: question,
            message: <FormattedMessage id="report.form.error.required" defaultMessage="This question is required" />
          }
        );
      }
      
      return result;
    }, errors);
  }

  return {
    errors,
    valid: errors.size === 0, 
  };
}

export const Report = () => {

  const intl = useIntl();
  const userInfo: UserInfo = useEditUserState();
  const answers = useEditAnswersState();
  const reportData = useEditReportState();

  const translateSectionTitle = (section?: SectionType): string => {
    return intl.formatMessage({ id: `report.${section?.key}`, defaultMessage: section?.title });
  }

  const questionSections: SectionType[] = fetchQuestionSections();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isSavedReportModalOpen, setIsSavedReportOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  
  const [previousSectionTitle, setPreviousSectionTitle] = useState<string>();
  const [nextSectionTitle, setNextSectionTitle] = useState<string>(translateSectionTitle(questionSections[1]));
  const [currentSection, setCurrentSection] = useState<SectionType>(questionSections[0]);
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [isSensorySkipped, setIsSensorySkipped] = useState<boolean>(false);
  
  const [validationErrors, setValidationErrors] = useState<Map<string,  ReportFormErrorType>>(new Map());

  const alreadySubmitted = isSubmitted(reportData);

  useEffect(()=>{
    setIsFirstPage(currentSection.order === 1);
    setIsLastPage(currentSection.order === questionSections.length);
  }, [currentSection]);

  const onPreviousSectionClick = () => {

    setNextSectionTitle(translateSectionTitle(currentSection));
    const isPrevSkipSensory = isSensorySkipped && currentSection.key === ReportSections.OtherDetails;

    if (questionSections) {
      let newCurrentSection = isPrevSkipSensory ? 
                                fetchQuestionSection(ReportSections.Break) 
                                : fetchPrevQuestionSection(questionSections, currentSection.order);

      // if we go back to break, we reset the sensory skipped flag
      if(newCurrentSection?.key === ReportSections.Break) {
        setIsSensorySkipped(false);
      }

      if (newCurrentSection && !_.isEmpty(newCurrentSection)) {
        setCurrentSection(newCurrentSection);

        if (newCurrentSection.key !== ReportSections.Break) {
          const previousSection = fetchPrevQuestionSection(questionSections, newCurrentSection.order);
          if (previousSection && !_.isEmpty(previousSection)) {
            setPreviousSectionTitle(translateSectionTitle(previousSection));
            if(newCurrentSection?.key === ReportSections.OtherDetails && isSensorySkipped) {
              setPreviousSectionTitle(translateSectionTitle(fetchQuestionSection(ReportSections.Break)));
            }
          }
        }
      }
    }
  }

  const onNextSectionClick = (skipSensoryQuestionsType: boolean) => {
    const validationResults = validateQuestions(currentSection || null, answers);
    setIsSensorySkipped(skipSensoryQuestionsType);

    if (validationResults.valid) {
      setValidationErrors(new Map());
      setPreviousSectionTitle(translateSectionTitle(currentSection));
      if (questionSections) {
        const newCurrentSection = skipSensoryQuestionsType && currentSection.key === ReportSections.Break
                                    ? fetchSensoryNextQuestionSection() 
                                    : fetchNextQuestionSection(questionSections, currentSection.order);

        if (newCurrentSection && !_.isEmpty(newCurrentSection)) {
          setCurrentSection(newCurrentSection);

          if (newCurrentSection.key !== ReportSections.Break) {
            const nextSection = fetchNextQuestionSection(questionSections, newCurrentSection.order);
            if (nextSection && !_.isEmpty(nextSection)) {
              setNextSectionTitle(translateSectionTitle(nextSection));
            } else {
              setNextSectionTitle(intl.formatMessage({ id: `report.completeReport`, defaultMessage: `Complete Report` }));
            }
          }
        }
      }
    } else {
      setValidationErrors(validationResults.errors);
    }
  }

  const onSaveAndExitClick = () => {
    setIsPasswordModalOpen(true);
  }

  const onCancelClick = () => {
    setDeleteModalIsOpen(true);
  }

  const isBreak = currentSection.key === ReportSections.Break;
 
  return (
    <div className={classNames(isBreak ? styles.reportBreak : styles.reportPage, styles[ReportStates.Started])}>
      <div className={styles.reportContainer}>
        <Header showBackButton={true} isReturnHomeType={true}/>
        
        <div className={styles.reportContent}>

          <div className={classNames(styles.left, styles[ReportStates.Started])}>
              <div className={styles.reportPageContainer}>
                {isBreak 
                  ? (
                      <div className={styles.breakIllustration}>
                        {renderSectionIllustration(currentSection.key)}
                      </div>
                    )  
                  : 
                  (<ReportPage currentReportSection={currentSection} errors={validationErrors}/>)
                }
              </div>
          </div>

          <div className={classNames(styles.right, styles[ReportStates.Started])}>
              <div className={styles.reportPageContainer}>
                {isBreak 
                  ? (
                      <ReportBreak onPreviousSectionClick={onPreviousSectionClick}
                        onSkipSensoryQuestionsClick={() => { onNextSectionClick(true); }}
                        onContinueToSensoryQuestionsClick={() => { onNextSectionClick(false); }}
                        onStepAwayForLongerClick={onSaveAndExitClick}/>
                    )
                  : (
                      <ReportPrevNext onPreviousSectionClick={onPreviousSectionClick}
                        onNextSectionClick={() => { onNextSectionClick(isSensorySkipped); }}
                        image={renderSectionIllustration(currentSection.key)}
                        isFirstPage={isFirstPage}
                        isLastPage={isLastPage}
                        alreadySubmitted={alreadySubmitted}
                        previousSectionTitle={previousSectionTitle}
                        nextSectionTitle={nextSectionTitle}
                        currentSectionIndex={currentSection.step} />
                    )
                  }
              </div>
          </div>

        </div>
      </div>
      <Footer isReportForm={true} isBreak={isBreak}/>
      <PasswordModal
        setIsPasswordModalOpen={setIsPasswordModalOpen}
        isPasswordModalOpen={isPasswordModalOpen}
        passwordModalAction={userInfo.reportKey ? PasswordModalActionType.Resave : PasswordModalActionType.Save} 
        isBreak={isBreak}
      />
      
      <SavedReportModal isSavedReportModalOpen={isSavedReportModalOpen}
        setIsSavedReportOpen={setIsSavedReportOpen}/>

      <DeleteModal deleteModalIsOpen={deleteModalIsOpen} 
        setDeleteModalIsOpen={setDeleteModalIsOpen}/>
    </div>
  );
}
