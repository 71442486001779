import React, { FC } from "react";
import classNames from "classnames";
import { Button, ButtonStyle} from "../Buttons";
import { IButtonProps } from "../Buttons/Button";
import _ from "lodash";

export const VestaLogo: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, "i--icon-vesta-logo")} 
    />
  );
}

export const VestaLogoPrint: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, "i--icon-vesta-logo-print")} 
    />
  );
}

export const QuestionButtonIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-red-question")} 
    />
  );
}



export const ExclamationButtonIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-red-exclamation")} 
    />
  );
}

export const GreenCheckIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, "i--icon-green-check")} 
    />
  );
}

export const CrossIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, "i--icon-red-cross")} 
    />
  );
}

export const CloseButtonIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-close")} 
    />
  );
}

export const CalendarIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-datetime")} 
    />
  );
}

export const LocationIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-location")} 
    />
  );
}

export enum CategoryIconVersion {
  White = "white",
  Dark = "dark",
  Default = "default"
}

interface CategoryIconProps extends IButtonProps {
  iconKey?: string,
  version: CategoryIconVersion;
}

export const CategoryIcon: FC<CategoryIconProps> = ({
  onClick = () => {},
  iconKey = "default-category",
  version = CategoryIconVersion.Default
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(`i--icon-${iconKey}-${version}`)}
    />
  );
}

export const ArrowIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-input-arrow")} 
    />
  );
}

export const ArrowDownWhite: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-arrow-down-white")} />
      );
}

export const ArrowRightWhite: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-arrow-right-white")} />
      );
}

export const ArrowRightLight: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} 
    className={classNames(className, "i--icon-arrow-right-light")} 
    />
  );
}

export const ArrowRightDark: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames(className, "i--icon-arrow-right-dark")} />
      );
}

export const ArrowRightDefault: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-arrow-right-default")} />
      );
}

export const FlagIconPink: FC<IButtonProps> = ({
  onClick = () => {},
  className = "",
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames(className, "i--icon-flag-pink")} />
      );
}

export const FlagIconBlue: FC<IButtonProps> = ({
  onClick = () => {},
  className = "",
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames(className, "i--icon-flag-blue")} />
      );
}

export const ResourcesHomeIcon: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-resources-home")} />
      );
}

export const LockIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, "i--icon-lock")} 
    />
  );
}

export const ThoughtBubbleIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames( "i--icon-thought-bubble", className)} 
    />
  );
}

export const ReportHomeIcon: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-report-home")} />
      );
}


export const FeedbackHomeIcon: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-feedback-home")} />
      );
}

export const LocationIconGrey: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button 
    onClick={onClick} 
    style={ButtonStyle.None} className={classNames("i--icon-location-grey")} />
      );
}

export const MapLocationIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-map-location")} />
    );
}

export const MapLocationColouredIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-map-coloured")} />
    );
}

export const WebsiteIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-website")} />
    );
}

export const PhoneIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-phone")} />
    );
}

export const SelfCareRedIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-self-care-red")} />
    );
}

export const BadgeIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-badge")} />
    );
}

export const LocationUnfilledIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-location-unfilled")} />
    );
}

export const PaperclipWhiteIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-paperclip-white")} />
    );
}

export const FileIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-file")} />
    );
}

interface SensoryIconProps extends IButtonProps {
  sense: string;
};

export const SensoryIcon: FC<SensoryIconProps> = ({
  sense,
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, `i--icon-${sense}`)} 
    />
  );
}

export interface FeelingIconProps extends IButtonProps {
  feeling: string;
};

export const FeelingIcon: FC<FeelingIconProps> = ({
  feeling = "",
  className = "",
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick}
      style={ButtonStyle.None}
      className={classNames(className, `i--icon-${_.kebabCase(feeling)}`)}/>
  );
}

export const FeelingFilledIcon: FC<FeelingIconProps> = ({
  feeling = "",
  className = ""
}) => {
  return (
    <div className={classNames(className, `i--icon-${_.kebabCase(feeling)}-filled`)}/>
  );
}

export const StarIcon: FC<IButtonProps> = ({
  className = "",
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick}
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-star")}/>
  );
}

export const StarFilledIcon: FC<IButtonProps> = ({
  className = "",
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick}
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-star-filled")}/>
  );
}

export const ExternalLinkIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames("i--icon-external-link-icon", className)} />
    );
}

export const ExternalLinkIconWhite: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames("i--icon-external-link-icon-white", className)} />
    );
}

export const ExternalLinkIconPink: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames("i--icon-external-link-icon-pink", className)} />
    );
}

export const ExternalLinkIconBlue: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames("i--icon-external-link-icon-blue", className)} />
    );
}

export const WhiteEditIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-edit-white")} />
    );
}

export const WhiteCheckIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames("i--icon-check-white", className)} />
    );
}


export const SMGroupIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-sm-group")} />
    );
}

export const Ontario211Icon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-211-ontario")} />
    );
}







