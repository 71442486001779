import { useState } from "react";
import { GreenCheckIcon, CrossIcon, ExclamationButtonIcon } from "components/Icons";
import { Button, ButtonStyle, LinkButton } from "components/Buttons";
import { ToggleBoolean } from "components/Forms/Toggle/ToggleBoolean";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { Attachments } from 'components/Forms';
import { ReportData, useEditReportState, useMergeEditReportState } from "context/editReport";
import { ConsentModal } from "components/Modals/ConsentModal";
import { ConfirmationModal, ConfirmationType } from "components/Modals/ConfirmationModal";
import { updateReportUser } from "actions/userActions";
import { createReportNotes, NoteType } from "actions/noteActions";
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';
import classNames from "classnames";
import { ErrorSaveSubmitDeleteModal, ErrorType } from "components/Modals/ErrorSaveSubmitDeleteModal";
import { RoutePaths } from "App/routing";
import _ from "lodash";
import useUpdateAttachments from "lib/useUpdateAttachments";
import { AttachmentFailedModal } from "components/Modals/AttachmentFailedModal";
import { useEditUserState, useMergeEditUserState, UserInfo } from "context/editUser";
import { isValidEmail } from "pages/PersonalInfo/MethodOfContact";
import { useEditAnswersState } from "context/editAnswers";
import { updateReportAnswers } from "actions/reportActions";

export enum UpdateQuestionType {
  AdditionalInformation = "additionalInformation",
  AttachFiles = "attachFiles",
  AllowPoliceContact = "allowPoliceContact",
  AllowSupportCenterContact  = "allowSupportCenterContact",
  AllowVestaContact = "allowVestaContact",
}

export const UpdateQuestions = () => {

  const intl = useIntl();
  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();

  const reportData: ReportData = useEditReportState();
  const mergeReportData = useMergeEditReportState();
  const updateAttachments = useUpdateAttachments();
  const answerData = useEditAnswersState();

  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [errorSaveSubmitDeleteModalIsOpen, setErrorSaveSubmitDeleteModalIsOpen] = useState(false);
  const [attachmentFailedModalIsOpen, setAttachmentFailedModalIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [notes, setNotes] = useState<{[key in UpdateQuestionType]?: NoteType}>();

  const openConsentModal = () => {
    setConsentModalIsOpen(true);
  }

  const addOrUpdateNotes = (note: string, type: UpdateQuestionType) => {
    const newOrUpdatedNote = {[type]: {note, type}};
    const updatedNotes = notes ? {...notes, ...newOrUpdatedNote} : newOrUpdatedNote;
    setNotes(updatedNotes);
  }

  const removeNote = (type: UpdateQuestionType) => {
    delete notes?.[type];
    setNotes({...notes});
  }

  const onChangeConsentData: any = (valueToSet: boolean, groupName: string) => {
    mergeReportData({...reportData, [groupName]: (valueToSet === true) ? 1 : 0});
  }

  const onChangeAdditionalInformation = (note: string) => {
    if (note.trim().length) {
      addOrUpdateNotes(
        note, 
        UpdateQuestionType.AdditionalInformation
      );
    } else {
      removeNote(UpdateQuestionType.AdditionalInformation);
    }
  }

  const submitUpdatedSurvivorReport = async () => {
    setIsUpdating(true);
    const reportId = reportData?.id?.toString();
    if (reportId) {
      try {
        const updatedSurvivorReport = await updateReportUser({...reportData, ...userInfo}, false);

        if (updatedSurvivorReport.report?.id === reportData.id) { // shouldn't really be any reason for them not to be the same
          let filteredNotes;
          if (notes) {
            filteredNotes = Array.from(new Set(Object.values(notes))).filter(n => {
              return (n && (n.note !== undefined) && (n.note !== null) && (n.note !== ""));
            });
          }
          filteredNotes = (filteredNotes || []) as NoteType[];

          if (filteredNotes.length) {
            await createReportNotes(updatedSurvivorReport.report?.id, { notes: filteredNotes });
          }

          mergeReportData({...updatedSurvivorReport.report, notes: [...reportData.notes || [], ...filteredNotes]});
          mergeUserData({...updatedSurvivorReport.user});
          
          try {
            const fullAnswers = answerData; 
            await updateReportAnswers(reportId, fullAnswers);
            await updateAttachments(reportData.id);
          } catch(err) {
            setAttachmentFailedModalIsOpen(true);
          }
        }

        setConfirmationModalIsOpen(true);
      } catch (err) {
        setErrorSaveSubmitDeleteModalIsOpen(true);
      }
    }
  }

  return (
    <>
      <div className={styles.updateQuestions} >
        <h1><FormattedMessage id="report.updateReport" defaultMessage="Update Report"/></h1>
      
        <TextField type={TextFieldTypes.TextArea} 
          name={UpdateQuestionType.AdditionalInformation}
          onChange={onChangeAdditionalInformation}
          placeholder={ intl.formatMessage({ id: "report.additionalInformation.placeholder", defaultMessage: "Did you remember anything you'd like to share?" }) }
          label={<FormattedMessage id="report.additionalInformation" defaultMessage="Add comments, notes, or any additional information you would like to include in your initial report"/>}/>

        <Attachments label={<FormattedMessage id="form.attachments.attachFile" defaultMessage="Attach file"/>}
          groupName={UpdateQuestionType.AttachFiles}/> 

         <div className={styles.consentButtonContainer}>
            <Button onClick={openConsentModal} style={ButtonStyle.Primary} className={styles.acknowledgementOfConsent}>
              <FormattedMessage id="report.acknowledgementOfConsentHeader" defaultMessage="Read Acknowledgement of Consent"/>
            </Button>
            <ExclamationButtonIcon onClick={openConsentModal} className={styles.iconSize}/>
              {reportData.acknowledgementOfConsent
                ? <GreenCheckIcon className={styles.iconSize}/>
                : <CrossIcon onClick={openConsentModal} className={styles.iconSize}/>}
          </div>

        <div className={styles.updateCancelButtonsContainer}>
        <Button onClick={submitUpdatedSurvivorReport} 
          style={ButtonStyle.Primary} 
          className={ButtonStyle.Full}
          disabled={!reportData.acknowledgementOfConsent}
          loading={isUpdating}
        >
          {reportData?.acknowledgementOfConsent 
            ? (<FormattedMessage id="report.updateReport" defaultMessage="Update Report"/>) 
            : (<FormattedMessage id="report.consentNotGiven" defaultMessage="Consent Not Given"/>)}
        </Button>

          <LinkButton to={RoutePaths.index} 
            style={ButtonStyle.Underline} 
            className={classNames(ButtonStyle.Full, styles.cancelButton)}
          >
            <FormattedMessage id="report.dontSave" defaultMessage="I don’t want to save this report"/>
          </LinkButton>
        </div>
      </div>

      <ConsentModal 
        consentModalIsOpen={consentModalIsOpen} 
        setConsentModalIsOpen={setConsentModalIsOpen}/>

      <ConfirmationModal 
        confirmationModalIsOpen={confirmationModalIsOpen} 
        setConfirmationModalIsOpen={setConfirmationModalIsOpen} 
        confirmationType={ConfirmationType.ReportUpdated}/>
        
      <ErrorSaveSubmitDeleteModal 
        errorSaveSubmitDeleteModalIsOpen={errorSaveSubmitDeleteModalIsOpen} 
        setErrorSaveSubmitDeleteModalIsOpen={setErrorSaveSubmitDeleteModalIsOpen} 
        errorType={ErrorType.Submit}/>

      <AttachmentFailedModal 
        attachmentFailedModalIsOpen={attachmentFailedModalIsOpen}
        setAttachmentFailedModalIsOpen={setAttachmentFailedModalIsOpen}/>
    </>
  );
}
