import React, { ReactNode } from "react";
import styles from './styles.module.scss';
import { LockIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";

enum BasicReportInfoFieldType {
  fullName = 'fullName',
  ageRange = 'ageRange',
}

interface BasicReportInfoFieldProps {
  label: ReactNode;
  value: ReactNode;
  reportInfoKey: string;
}

export const BasicReportInfoField = ( {label, value, reportInfoKey} : BasicReportInfoFieldProps) => {
  return (
  <div className={styles.basicReportInfoField}>
    <div className={styles.label}>
      <h1>{label}</h1>
    </div>
    <p>
      { value ?
      reportInfoKey === BasicReportInfoFieldType.ageRange ? 
      <FormattedMessage id={`personalInfo.${value}`} /> :
      value : 'n/a'}
    </p>
  </div>
  );
}
