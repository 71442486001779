/// Base Actions - calling external API
import axios, { AxiosResponse } from "axios";
import _keys from "lodash.keys";
import { baseAPIUrl, jwtData } from 'lib/config';
import { wrapRefreshAuthTokenOnFail } from './jWTCalls';

const config = {
  authToken: (jwtData && jwtData.authToken) ? jwtData.authToken : 'authToken',
  refreshToken: (jwtData && jwtData.refreshToken) ? jwtData.refreshToken : 'refreshToken'
};

const fullEndpoint = (endpoint : string) => {
  if( endpoint && endpoint.startsWith("http")) {
    return `${endpoint}`;

  } else {
    return `${baseAPIUrl}/${endpoint}`;
  }
}

/// Get from endpoint
const get = <T,>(endpoint: string) => {
  const callAxios = () => {
    return axios.get<any, AxiosResponse<T>>(fullEndpoint(endpoint), getConfig());
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// get Blob from endpoint
const getBlob = (endpoint: string) => {
  const callAxios = () => {
    return axios.get(fullEndpoint(endpoint), getConfig({responseType:'blob'}));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post data to endpoint
const post = (endpoint: string, data : any) => {
  const callAxios = () => {
    return axios.post(fullEndpoint(endpoint), data, getConfig());
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const postFile = (endpoint: string, data : any) => {
  let formData = new FormData();
  let keys = _keys(data);
  let config = getConfig({'content-type':'multipart/form-data'});
  
  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios.post(fullEndpoint(endpoint), formData, config);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const putFile = (endpoint : string, data: any) => {
  let formData = new FormData();
  let keys = _keys(data);
  let config = getConfig({'content-type':'multipart/form-data'});
  
  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios.put(fullEndpoint(endpoint), formData, config);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// put data to endpoint
const put = (endpoint : string, data : any) => {
  const callAxios = () => {
    return axios.put(fullEndpoint(endpoint), data, getConfig());
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// delete endpoint
const del = (endpoint : string) => {
  const callAxios = () => {
    return axios.delete(fullEndpoint(endpoint), getConfig())
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// create general http request
const request = (config : any) => {
  let newConfig = { ...getConfig(), ...config };

  const callAxios = () => {
    return axios.request(newConfig);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// Private Functions
const getHeaders = () => {
  // we store the auth for accessing the api in authToken
  const token = localStorage.getItem(config.authToken);
  let headers : { Authorization?: string } = {};
  if(token) {
    headers.Authorization = 'Bearer ' + token;
  }

  return headers;
};

const getConfig = (overrideParams = {}) => {
  return {
    headers: getHeaders(),
    ...overrideParams
  };
};

export {
  get,
  getBlob,
  post,
  postFile,
  put,
  putFile,
  del,
  request
};
