import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { InlineTitledColor, InlineTitledMessage } from 'components/Common/InlineTitledMessage';
import { ArrowButton, ArrowDirection, ArrowLink, ArrowPosition, Button, ButtonStyle, DoubleArrowButton, LinkButton } from 'components/Buttons/Button';
import { RoutePaths } from 'App/routing';
import { TalkPoliceOrSupportImage } from 'components/Images';
import { LearnMoreLink, LearnMoreLinkType, LearnMoreColor } from 'components/Common/LearnMore';
import classNames from 'classnames';
import { LoginInputCard } from 'components/Common/SingleInputCard';

export interface LeftContentProps {
  showClickthroughPage: boolean,
  onClickAccessJournalButton: (value: boolean) => void;
}

export const LeftContent = ({ 
  showClickthroughPage, 
  onClickAccessJournalButton
}: LeftContentProps) => {
  const intl = useIntl();

  // CLICKTHROUGH PAGE
  if (showClickthroughPage) {
    return (
      <div className={styles.leftContainer}>
        <h3 className={styles.clickthroughPage}>
          <FormattedMessage id="home.clickthrough.header.title"/>
        </h3>
        <FormattedMessage id="home.clickthrough.header.body"/>
        <InlineTitledMessage 
          title={intl.formatMessage({ id: 'home.clickthrough.point1.title' })}
          delimiter={": "}
          message={intl.formatMessage({ id: 'home.clickthrough.point1.body' })}
          className={styles.point1}
          color={InlineTitledColor.Blue}
        />
        <div className={styles.leftJustifiedButtonContainer}>
          <DoubleArrowButton 
            to={RoutePaths.reportStart}
            onClick={() => onClickAccessJournalButton(showClickthroughPage)}
            style={ButtonStyle.DarkSecondary} 
            className={classNames(styles.leftJustifiedButton, styles.attention)}>
            <FormattedMessage id="home.accessVestaJournalButton"/>
          </DoubleArrowButton>
        </div>
        <div className={styles.leftJustifiedButtonContainer}>
          <ArrowLink
            to={RoutePaths.index} 
            style={ButtonStyle.DarkSecondaryInverted} 
            className={classNames(styles.leftJustifiedButton, styles.attention)}
            arrowDirection={ArrowDirection.Left}
            arrowPosition={ArrowPosition.Left}>
            <FormattedMessage id="home.clickthrough.goToLandingButton"/>
          </ArrowLink>
        </div>
        <div className={styles.illustration}>
          <TalkPoliceOrSupportImage />  
        </div>
        <LearnMoreLink
            textColor={LearnMoreColor.Blue}
            iconColor={LearnMoreColor.Blue} 
            learnMoreLinkType={LearnMoreLinkType.HowVestaJournalCanHelp}
            customText={<FormattedMessage id="home.clickthrough.learnMore"/>}
            className={classNames(styles.leftJustifiedButtonContainer, styles.learnMore)}
          />
      </div>
    )
  } 
  // LANDING PAGE
  else {
    return (
      <div className={styles.leftContainer}>
        <h3 className={styles.documentPage}>
          <FormattedMessage id="home.documentingYourExperience.title"/>
        </h3>
        <FormattedMessage id="home.documentingYourExperience.body"/>
        <InlineTitledMessage 
          title={intl.formatMessage({ id: 'home.point1.title' })}
          delimiter={": "}
          message={intl.formatMessage({ id: 'home.point1.body' })}
          className={styles.point1}
          color={InlineTitledColor.Pink}
        />
        <div className={styles.leftJustifiedButtonContainer}>
          <DoubleArrowButton 
            to={RoutePaths.reportStart}
            onClick={() => onClickAccessJournalButton(showClickthroughPage)}
            style={ButtonStyle.DarkPrimary} 
            className={classNames(styles.leftJustifiedButton, styles.attention)}>
            <FormattedMessage id="home.accessVestaJournalButton"/>
          </DoubleArrowButton>
        </div>
        <div className={styles.leftJustifiedButtonContainer}>
          <LinkButton
            to={RoutePaths.clickthrough}
            style={ButtonStyle.DarkPrimaryInverted} 
            className={styles.leftJustifiedButton}>
            <p className={styles.attention}>
              <FormattedMessage id="home.howCanVestaJournalHelpButton"/>
            </p>
          </LinkButton>
        </div>
        <InlineTitledMessage 
          title={intl.formatMessage({ id: 'home.point2.title' })}
          delimiter={": "}
          message={intl.formatMessage({ id: 'home.point2.body' })}
          className={styles.point2}
          color={InlineTitledColor.Pink}
        />
        <LoginInputCard />
      </div>
    );
  }
}
