import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";
import { getAllOrganizationLocations } from "actions/organizationActions";
import { useMergeEditReportState } from "context/editReport";
import { Select } from "components/Forms/Select";
import { LocationNotes } from '../LocationNotes';
import { FlagText } from "components/Common/FlagText";
import useHasOrganizationTypes from "lib/useHasOrganizationsTypes";

interface LocationOption {
  id: number;
  location: string;
}

export const useGetLocations = () => {
  const [locationsOptions, setLocationOptions] = useState<LocationOption[]>([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const locations = await getAllOrganizationLocations();
      const locationOptions = locations.reduce<LocationOption[]>((acc, location) => {
        acc.push({
          id: acc.length,
          location: location
        } as LocationOption);
        return acc;
      }, []);
      setLocationOptions(locationOptions);
    }
    fetchLocations();
  }, []);
  
  return locationsOptions;
}

export interface LocationSelectProps {
  selectedLocation: string,
  setSelectedLocation: (value: string) => void;
}

export const LocationSelect = ({ selectedLocation, setSelectedLocation}: LocationSelectProps) => {

  const mergeReportData = useMergeEditReportState();
  const locationOptions = useGetLocations();
  const hasPartneredOrganizationTypes = useHasOrganizationTypes({ isPartnered: true });

  const onLocationChange = async (location: string) => {
    setSelectedLocation(location);
    mergeReportData({ supportLocation: location });
  };

  return (
    <div className={styles.locationNotes}>
    <div className={styles.closestCity}>
      <FormattedMessage
        id='accessSupport.closestCity'
        defaultMessage="If you are in Ontario, Canada what’s the closest city/town that would be convenient for you to receive support?"
      />
    </div>
    <div className={styles.locationSelectContainer}>
      <Select
        value={selectedLocation}
        options={locationOptions}
        onChange={onLocationChange}
        valueName={'location'}
        textName={'location'}
      />
    </div>

    {selectedLocation &&
      <FlagText className={styles.locationSelectFlagText}>
        {selectedLocation && 
          <LocationNotes 
            location={selectedLocation} 
            hasOrganizationTypes={hasPartneredOrganizationTypes}/>
        }
      </FlagText>
    }
  </div>
  );
}