import React from "react";
import { UploadedAttachmentType } from "actions/awsActions";
import { ReportSectionQuestions, ReportSections } from "../../../Report";
import { SensoryIconCard } from "./SensoryIconCard";
import { QuestionAnswer } from "./QuestionAnswer";
import _ from "lodash";
import { useIntl } from "react-intl";
import { dateFormatter } from "lib/commonFunctions";


interface SectionQuestionAnswerProps {
  sectionKey: string;
  questionKey: string;
  answerObjectValue: any;
}

export const SectionQuestionAnswer = ( { sectionKey, questionKey, answerObjectValue }: SectionQuestionAnswerProps ) => {

  const intl = useIntl();

  const translator = (translationId: string) => {
    return intl.formatMessage({ id: `report.${translationId}`, defaultMessage: translationId });
  }

  const answerFormatted: any = (answerUnformatted: any) => {
    if (questionKey === ReportSectionQuestions.When) {
      //answerUnformatted here will be an array of length 1 or 2
      return ( answerUnformatted.length === 1 ) 
        ? dateFormatter(answerUnformatted[0], true) 
        : `${dateFormatter(answerUnformatted[0], true)} - ${dateFormatter(answerUnformatted[1], true)}`;
    } else if ((questionKey === ReportSectionQuestions.OtherAttachments) || (questionKey === ReportSectionQuestions.FileAttachments)) {
      const attachmentArray = answerUnformatted?.map((attachment: UploadedAttachmentType) => attachment.filename?.toString());
      return attachmentArray.join(',');
    } else if (typeof(answerUnformatted) === "string") {
      return translator(answerUnformatted);
    } else {
      return answerUnformatted.toString();
    }
  }
  
  const answer = answerObjectValue?.answer && answerFormatted(answerObjectValue.answer);

  const sectionQuestionAnswer = () => {
    if ( sectionKey === ReportSections.SensoryExperience ) {
      const sense = _.split(questionKey, '-', 2)[1];
      const senseTranslated = intl.formatMessage({ id: `report-q.${sense}`, defaultMessage: `${_.upperFirst(sense)}` });
      return ( <SensoryIconCard iconType={sense} sensoryTitle={senseTranslated} answer={answer} key={sense}/> );
    } else {
      const scale = answerObjectValue && answerObjectValue.scale;
      const questionTranslated = translator(questionKey);
      return ( <QuestionAnswer question={questionTranslated} answer={answer} scale={scale} key={questionKey}/> );      
    }
  }

  return (
    <>
    {answer && sectionQuestionAnswer()}
    </>
  );
};
 
