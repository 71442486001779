import { FC, useEffect, useState } from "react";
import { AppProvider, useSetEditUserState, useMergeAppState } from 'context/index';
import { RoutePaths, Routing } from './routing';
import { fetchUser, userIsAuthorized } from 'actions/userActions';
import { useGoogleMaps } from '../lib/usePlaces';
import  { googleAPIKey } from '../lib/config';
import "./App.scss";

const useGetAuth =  () => {
  const mergeAppState = useMergeAppState();
  const setUserState = useSetEditUserState();
  const [authenticating, setAuthenticating]  = useState(false);

    // Load User
  useEffect(() => {
    const fetchData = async () => {
      setAuthenticating(true);

      try {
      const result = await fetchUser();
        if(result && result.data) {
          setUserState(result.data);
        }
      } catch(err) {
      }

      setAuthenticating(false);
    };

    fetchData();
  }, [setAuthenticating, setUserState]);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      mergeAppState({ Authenticated: false, Authenticating: false }, true);
      if ((window.location.pathname !== RoutePaths.index)) {
        window.location.pathname = RoutePaths.index;
      }
    } else {
      mergeAppState({ Authenticated: userIsAuthorized(), Authenticating: authenticating }, true);
    }
  }, [authenticating, mergeAppState]);
};

const useGoogleApps = () => {
  const mergeAppState = useMergeAppState();
  const [loaded] =   useGoogleMaps(googleAPIKey);

  useEffect(() => {
    const isLoaded = loaded ? true : false;
    mergeAppState({googleMapsLoaded: isLoaded }, true);
  }, [loaded, mergeAppState]);
};

// We need to attach providers here
const AppProviderWrapper: FC = () => {
  // update User Info


  return (
    <>
      <AppProvider>
          <AppWithContext>
            <Routing />
          </AppWithContext>
      </AppProvider>
    </>
  );
};


// We can now call context here, as it's wrapped by the AppProviderWrapper
const AppWithContext: FC = ({children}) => {
  useGetAuth();
  useGoogleApps();

  return <>
    {children}
  </>
}

export default AppProviderWrapper;
