import React from "react";
import { FormattedMessage } from 'react-intl';
import { GenericHeaderBodyConstructorModal } from "../GenericHeaderBodyConstructorModal";
import { LearnMoreLink, LearnMoreLinkType } from "components/Common/LearnMore";
import styles from './styles.module.scss';

interface SeekSupportModalProps {
  SeekSupportModalIsOpen: boolean;
  setSeekSupportModalIsOpen: (value: boolean) => void;
}

const SeekSupportModalContent = [
  {
    header: <FormattedMessage id="SeekSupportModal.whatThisMeansForYou.header" defaultMessage="What this means for you"/>,
    body: <FormattedMessage id="SeekSupportModal.body" defaultMessage="Support Centers strive to connect survivors to a counsellor as quickly as possible. However, depending on which counselling program you select there may be a wait list.  Wait lists can be a little as 2 weeks or as long as several months. Each Centre is unique and you will know more once you connect with them directly."/>
  }
];

export const SeekSupportModal = ({ SeekSupportModalIsOpen, setSeekSupportModalIsOpen } : SeekSupportModalProps) => {
  return (
    <GenericHeaderBodyConstructorModal 
      genericHeaderBodyConstructorModalIsOpen={SeekSupportModalIsOpen}
      setGenericHeaderBodyConstructorModalIsOpen={setSeekSupportModalIsOpen}
      className={styles.SeekSupportModal}
      title={<h5><FormattedMessage id="seekingSupportModal" defaultMessage="Seeking support"/></h5>}
      headerBodyArray={SeekSupportModalContent}/>
  );
}
