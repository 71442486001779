import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { ExclamationButtonIcon, WhiteEditIcon } from "components/Icons";
import { UserFields, UserInfo, useEditUserState } from 'context/editUser';
import { ReportFields, ReportData, useEditReportState } from "context/editReport";
import { WindowHopeImage } from "components/Images";
import { Button, ButtonStyle } from "components/Buttons";
import { useHistory, useLocation } from "react-router-dom";
import { BasicReportInfoField } from "./BasicReportInfoField"
import styles from './styles.module.scss';
import { sortByOrder } from "lib/commonFunctions";
import { UnSubmittedAttachmentType, UploadedAttachmentType } from "actions/awsActions";
import { RoutePaths } from "App/routing";
import PlacesAndSupport from "data/placesAndSupport";
import useHasOrganizationTypes from "lib/useHasOrganizationsTypes";


export interface BasicReportInfoProps {
  showEditButton?: boolean;
}

export const BasicReportInfo = ({ showEditButton = true }: BasicReportInfoProps) => {

  const userInfo: UserInfo = useEditUserState();
  const reportData: ReportData = useEditReportState();
  const currentPath = useLocation().pathname;

  const [dottedText, setDottedText] = useState(false);
  const { TYPE_OF_SUPPORT } = PlacesAndSupport();
  const hasPartneredOrganizationTypes = useHasOrganizationTypes({ isPartnered: true });

  const history = useHistory();

  const basicReportInfoEntity = [
    {
      key: UserFields.FullName,
      get label() {
        return <FormattedMessage id={`report.${this.key}`} defaultMessage="Name" />
      },
      get value() {
        return userInfo[this.key];
      },
      showLock: true,
      dottedText: !dottedText,
      order: 2
    },
    {
      key: UserFields.AgeRange,
      get label() {
        return <FormattedMessage id={`report.${this.key}`} defaultMessage="Age" />
      },
      get value() {
        return userInfo[this.key];
      },
      showLock: true,
      dottedText: !dottedText,
      order: 2
    },
    {
      key: UserFields.PhoneNumber,
      get label() {
        return <FormattedMessage id={`report.${this.key}`} defaultMessage="Phone Number" />
      },
      get value() {
        return userInfo[this.key];
      },
      showLock: true,
      dottedText: !dottedText,
      order: 3
    },
    {
      key: UserFields.Email,
      get label() {
        return <FormattedMessage id={`report.${this.key}`} defaultMessage="Email" />
      },
      get value() {
        return userInfo[this.key];
      },
      showLock: true,
      dottedText: !dottedText,
      order: 5
    },
    {
      key: UserFields.MethodOfContact,
      get label() {
        return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Method of Contact" />
      },
      get value() {
        return userInfo[this.key] ? userInfo[this.key] : '';
      },
      showLock: false,
      dottedText: false,
      order: 6
    },
    {
      key: ReportFields.AllowPoliceContact,
      get label() {
        return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Allow Police Contact" />
      },
      get value() {
        return reportData[this.key] ? "Yes" : "No";
      },
      showLock: false,
      dottedText: false,
      order: 7
    },
    {
      key: ReportFields.AllowSupportCenterContact,
      get label() {
        return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Allow Support Center Contact" />
      },
      get value() {
        return reportData[this.key] ? "Yes" : "No";
      },
      showLock: false,
      dottedText: false,
      order: 8
    },
    {
      key: ReportFields.SupportLocation,
      get label() {
        return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Support Location" />
      },
      get value() {
        return hasPartneredOrganizationTypes.support ? reportData.supportLocation : undefined},
      showLock: false,
      dottedText: false,
      order: 9
    },
    {
      key: ReportFields.TypeOfSupport,
      get label() {
        return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Types of Support" />
      },
      get value() {
        let val = '';
        if (reportData.typeOfSupport) {
          for (const type of reportData?.typeOfSupport) {
            val += TYPE_OF_SUPPORT[Number(type) - 1].text+"\n"
          }
        }
        return val;
      },
      showLock: false,
      dottedText: false,
      order: 10
    },
  {
    key: ReportFields.AllowCampusContact,
    get label() {
      return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Allow Campus Contact"/>
    },
    get value() {
      return reportData[this.key] ? "Yes" : "No";
    },
    showLock: false,
    dottedText: false,
    order: 11
  },
  {
    key: ReportFields.SupportLocation,
    get label() {
      return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Campus Support Location" />
    },
    get value() {
      return hasPartneredOrganizationTypes.campusSupport ? reportData.supportLocation : undefined},
    showLock: false,
    dottedText: false,
    order: 9
  },
  {
    key: ReportFields.Attachments,
    get label() {
      return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Uploaded Attachments"/>
    },
    get value() { 
      const uploadedAttachments: UploadedAttachmentType[] = reportData.uploadedAttachments || [];
      const unUploadedAttachments: UnSubmittedAttachmentType[] = reportData.attachmentsToUpload || [];

      // TODO: review if this is what we want, https://trello.com/c/3JdAjmO5/634-basic-report-info-review
      let attachmentsToDisplay;
      if (currentPath === RoutePaths.reviewReport) {
        attachmentsToDisplay = [...unUploadedAttachments, ...uploadedAttachments];
      } else if (currentPath === RoutePaths.updateSubmittedReport) {
        attachmentsToDisplay = uploadedAttachments;
      }

      return attachmentsToDisplay?.map((a: UnSubmittedAttachmentType | UploadedAttachmentType) => a.filename).join(", ") || "";
    },
    showLock: false,
    dottedText: false,
    order: 12
  },
  {
    key: ReportFields.AllowSupportCenterReportDetails,
    get label() {
      return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Allow Campus Contact"/>
    },
    get value() {
      return reportData[this.key] ? "Yes" : "No";
    },
    showLock: false,
    dottedText: false,
    order: 13
  },
  {
    key: ReportFields.AllowCampusReportDetails,
    get label() {
      return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Allow Campus Contact"/>
    },
    get value() {
      return reportData[this.key] ? "Yes" : "No";
    },
    showLock: false,
    dottedText: false,
    order: 14
  },
  {
    key: ReportFields.ReportLocation,
    get label() {
      return <FormattedMessage id={`updateReport.${this.key}`} defaultMessage="Report Location"/>
    },
    get value() {
      return reportData[this.key] ? reportData[this.key] : "";
    },
    showLock: false,
    dottedText: false,
    order: 15
  },
  ];

  return (
    <div className="">
      <div className={styles.reportInfoHeader}>
        <h1><FormattedMessage id="updateReport.reportInformation" defaultMessage="Report Information"/></h1>
        <div className={styles.reportInfoHeaderEditButton}>
          {showEditButton && <Button style={ButtonStyle.Secondary} onClick={()=>{ history.push('./report'); }}>
            <div className={styles.editIcon}>
              <WhiteEditIcon />
            </div>
            <FormattedMessage id="report.editReport" />
          </Button>}
        </div>
      </div>

      <div className={styles.reportInformationImage}><WindowHopeImage /></div>

      <div className={styles.infoExplainer}>
        <ExclamationButtonIcon className={styles.iconSize}/>
        <p className={styles.explanation}>
          <FormattedMessage id="updateReport.privacyInfo" defaultMessage="Your report is always encrypted, and your information will remain safe and protected."/>
        </p>
      </div>

      <div className={styles.basicInfo}>

      {basicReportInfoEntity.sort(sortByOrder).map((entity) => {
        return <BasicReportInfoField 
                  label={entity.label} 
                  value={entity.value}
                  reportInfoKey={entity.key}
                  key={entity.key} />})}

      </div>

      <div>
        <h1><FormattedMessage id={`report.otherReports`} defaultMessage="Other submitted reports"></FormattedMessage></h1>
        {userInfo?.otherReportKeys?.map((reportKey) => {
          return (
            <p>{reportKey}</p>
          )
        })}
      </div>
    </div>
  );
}
