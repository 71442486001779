import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './styles.module.scss';

interface RadioFieldType {
  text: string;
  value: string;
  groupName: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
 }

export const RadioField: FC<RadioFieldType> = ({
  value, 
  text, 
  groupName, 
  checked, 
  disabled = false,
  onChange, 
  onClick
}) => {
    return (
      <div className={styles.radioContainer}>
        <input className={classNames(styles.radio, disabled && styles.disabled)} 
          type="radio" 
          id={`${value}Value-${groupName}`} 
          checked={checked} 
          value={value} 
          disabled={disabled}
          onChange={onChange} 
          onClick={onClick}/>
        <label htmlFor={`${value}Value-${groupName}`}>{text}</label>
      </div>
    );
}
