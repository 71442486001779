import React, { useEffect, useRef } from "react";
import Header from "Layout/Header";
import { UpdateQuestions } from './UpdateQuestions'
import { UserInfo, useEditUserState } from "context/editUser";
import { FormattedMessage } from 'react-intl';
import { SectionQuestionAnswers } from "../ReviewReport/SectionQuestionAnswers";
import { BasicReportInfo } from '../ReviewReport/BasicReportInfo';
import { Notes } from '../Notes';
import classNames from "classnames";
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "components/Buttons";
import { dateFormatter } from "lib/commonFunctions";
import usePopulateReportNotesAnswers from "lib/usePopulateReportNotesAnswers";
import { ReportData, useEditReportState } from "context/editReport";

export const UpdateSubmittedReport = () => {

  const userInfo: UserInfo = useEditUserState();
  const reportData: ReportData = useEditReportState();
  const populateReportNotesAnswers = usePopulateReportNotesAnswers();

  useEffect(() => {
    if (userInfo.reportKey && userInfo.reportId) {
      populateReportNotesAnswers(userInfo.reportId, userInfo.reportKey);
    }
  }, [userInfo.reportKey, userInfo.reportId]);

  const scrollToTop = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  } 

  const reportSectionRef = useRef(null);
  const onClickViewReport = () => {
    scrollToTop(reportSectionRef)
  }

  return (
    <div className={styles.updateReportContent}>
      <div className={classNames(styles.left, styles.updateSubmitted)}>
        <div className={styles.headerTitleContainer}>
          <Header showBackButton={false} />
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <h1><FormattedMessage id="updateReport.updateYourReport" defaultMessage="Update report" /></h1>
            </div>
            <div className={styles.viewReportButton}>
              <Button onClick={onClickViewReport} style={ButtonStyle.Secondary}>
                <FormattedMessage id="report.reviewReport" defaultMessage="Review report"/>
              </Button>
            </div>
          </div>
        </div>                
        <div>
          <h1><FormattedMessage id="home.report.title" defaultMessage="Report or Record"/>: {userInfo.reportKey}</h1>
          <span>
            <FormattedMessage id="updateReport.submissionDate" defaultMessage="Submission date"/>:  
            <span className={styles.submittedDate}>{reportData?.dateSubmitted && dateFormatter(reportData.dateSubmitted)}</span>
          </span>
        </div>
        <UpdateQuestions/>
      </div>
      <div ref={reportSectionRef} className={styles.right}>
        <BasicReportInfo showEditButton={true}/>
        <SectionQuestionAnswers/>
        <Notes/>
      </div>   
    </div>
  );
}
