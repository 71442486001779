import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { PrivacyAndOutcomesModal } from "components/Modals/PrivacyAndOutcomesModal";
import Header from "Layout/Header";
import styles from './styles.module.scss';
import { WriteStoryImage } from "components/Images";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, ButtonStyle, LinkButton } from "components/Buttons/Button";
import { RoutePaths } from "App/routing";
import { ArrowDownWhite } from "components/Icons";
import Footer from "Layout/Footer";
import { ReportData, ReportFields, useEditReportState, useMergeEditReportState } from "context/editReport";
import { LocationSelect, useGetLocations } from "./LocationSelect";
import { Location } from 'components/Forms/Location';
import { FlagText } from "components/Common/FlagText";


const getCityIfOntarioCanada = (address?: string) => {
  if (!address) {
    return undefined;
  }

  const parsedAddress = address.split(', ');

  if(parsedAddress.length === 1) {
    // Do not assume this is Ontario, Canada. E.g. it could be 'London, UK', not 'London, ON, Canada'.
    return undefined;
    // return parsedAddress[0];
  } else if (
    parsedAddress.length >= 3 && 
    parsedAddress[parsedAddress.length - 1] === 'Canada' && 
    parsedAddress[parsedAddress.length - 2] === 'ON'
  ) {
    // This only works if it's formatted in the text way for Canada addresses from google.
   return parsedAddress[parsedAddress.length - 3]; 
  } else {
    return undefined;
  }
}

export const ReportStart = () => {
  const [privacyAndOutcomesModalIsOpen, setPrivacyAndOutcomesModalIsOpen] = useState(false);
  const reportData: ReportData = useEditReportState();
  const intl = useIntl();
  const openPrivacyAndOutcomesModal = () => {
    setPrivacyAndOutcomesModalIsOpen(true);
  }

  const [selectedLocation, setSelectedLocation] = useState('');


  const locationOptions = useGetLocations();
  const mergeReportData = useMergeEditReportState();
  const mergeLocationReportData = (userInfoKey: string, userInfoValue: any) => {
    let newLocation = undefined;
    const canadaCity = getCityIfOntarioCanada(userInfoValue);
    if (canadaCity) {
      newLocation = locationOptions.find((lo) => {
        return lo.location === canadaCity;
      });
    }

    const mergeData = { [userInfoKey]: userInfoValue };
    if(newLocation) {
      setSelectedLocation(newLocation.location);
      mergeData.supportLocation = newLocation.location;
    }
    mergeReportData(mergeData);
  }

  const locationValue = useMemo(() => { return reportData.reportLocation; }, [reportData]);

  return (
      <div className={classNames(styles.reportPage, styles.reportPageAnim)}>
        <div className={styles.reportContainer}>
          <Header showBackButton={true} showBackArrow={true} isReturnHomeType={true}/>

          <div className={styles.reportContent}>
            <div className={styles.left}>
              <p><FormattedMessage id="reportStart.body.first" defaultMessage="Take a deep breath, and think about the incident."/></p>

              <p><FormattedMessage id="reportStart.body.second" defaultMessage="How you felt, what you saw, and what you heard."/></p>

              <p><FormattedMessage id="reportStart.body.third" defaultMessage="Write down as much as possible of what you can remember about your experience. Include minor details. Take as much time as you need."/></p>

              <p><FormattedMessage id="reportStart.body.fourth" defaultMessage="You choose who can see your story."/></p>

              <p><FormattedMessage id="reportStart.body.fifth" defaultMessage="There are no wrong answers. Spelling and grammar are not important."/></p>

              <p className={classNames(styles.youAreInControl, styles.attention)}>
                <FormattedMessage id="youAreInControl" defaultMessage="You are in control"/>
            </p>
            <div className={styles.illustration}>
              <WriteStoryImage />
              </div>
            </div>

            <div className={classNames(styles.right, styles.reportNotStarted)}>
              <div className={styles.closestSupportQuestionContainer}>
                
                <LocationSelect selectedLocation={selectedLocation} setSelectedLocation={(value: string) => { setSelectedLocation(value); }}/>

                <FlagText className={styles.locationInputFlagText}>
                  <FormattedMessage 
                    id="reportStart.body.locationText" 
                    defaultMessage="If the city/town that is most convenient for you is not listed and you’d like to see more resources in your area, let us know by providing your city or region."
                  />
                </FlagText>

                <Location
                  onChange={(location) => { mergeLocationReportData(ReportFields.ReportLocation, location) }}
                  placeholder={intl.formatMessage({ id: 'home.location.placeholder' })}
                  value={locationValue}
                  className={styles.locationInput}
                />

              <FlagText className={styles.downloadFlagText}>
                <FormattedMessage id="reportStart.flagText.second" defaultMessage="If you have downloaded your Vesta journal before, you may also upload a copy of it as you go through the form."/>
              </FlagText>

                <div className={styles.reportStartButtons}>
                    <LinkButton to={RoutePaths.report}
                      className={styles.startButton}
                      style={ButtonStyle.Primary}>
                        <FormattedMessage id="button.start" defaultMessage="Let's start"/>
                        <ArrowDownWhite className={styles.buttonArrow} />
                    </LinkButton>

                    <Button onClick={openPrivacyAndOutcomesModal} 
                      style={ButtonStyle.Underline}>
                        <FormattedMessage id="reportStart.privacyAndOutcomes" defaultMessage="I’d like to learn more"/>
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer isReportForm={true} isBreak={false}/>
        <PrivacyAndOutcomesModal privacyAndOutcomesModalIsOpen={privacyAndOutcomesModalIsOpen} 
        setPrivacyAndOutcomesModalIsOpen={setPrivacyAndOutcomesModalIsOpen} />
      </div>
  );
}
