import React, { useEffect, useState } from "react";
import { Button, ButtonStyle } from "components/Buttons";
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import { FormattedMessage, useIntl } from "react-intl";
import { ExperienceScale, FeelingScale } from "./ExperienceScale";
import { StarScale } from "./StarScale";
import styles from './styles.module.scss';
import Header from "Layout/Header";
import { createFeedback, FeedbackType } from "actions/feedbackActions";
import { ConfirmationModal, ConfirmationType } from "components/Modals/ConfirmationModal";
import Footer from "Layout/Footer";


export const Feedback = () => {

  const intl = useIntl();

  const [experience, setExperience] = useState<FeelingScale | undefined>();
  const [helpfulness, setHelpfulStarNumberChosen] = useState<number>(0);
  const [recommended, setRecommendStarNumberChosen] = useState<number>(0);
  const [howToImprove, setHowToImprove] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const submitFeedback = () => {

    let howToImproveText = 'n/a';
    if(howToImprove.length !== 0) {
      howToImproveText = howToImprove;
    }

    const feedbackObj: FeedbackType = {
      experience,
      helpfulness,
      recommended,
      howToImprove: howToImproveText
    }

    return createFeedback(feedbackObj).then(results => {
      setConfirmationModalIsOpen(true);
      setHowToImprove('');
    }).catch((err) => {
      setHasError(true);
    });
  }

  const onTextInput = (e: TextFieldEventType) => {
    setHowToImprove(e.target.value);
  }

  return (
    <>
      <div className={styles.feedbackPage}>
        <div className={styles.feedbackPageContainer}>
          <Header showBackButton={false} />
          <div className={styles.feedbackContent}>
            <div className={styles.left}>
              <ExperienceScale experience={experience} 
                setExperience={setExperience}/>

              <StarScale starNumberChosen={helpfulness} 
                setStarNumberChosen={setHelpfulStarNumberChosen}
                feedbackQuestion={<FormattedMessage id="feedback.helpful" 
                  defaultMessage="How helpful was Vesta for you?"/>}/>

              <StarScale starNumberChosen={recommended} 
                setStarNumberChosen={setRecommendStarNumberChosen} 
                feedbackQuestion={<FormattedMessage id="feedback.recommend" 
                  defaultMessage="How likely would you recommend Vesta?"/>}/>                                               
            </div>

            <div className={styles.right}>
              <TextField  name={"feedback"}
                          type={TextFieldTypes.TextArea} 
                          value={howToImprove}
                          onRawChange={onTextInput}
                          label={<h1><FormattedMessage id="feedback.improve" 
                                                      defaultMessage="How do you think Vesta could improve?"/></h1>} 
                          placeholder={intl.formatMessage({ id: "feedback.improve.placeholder", 
                                                            defaultMessage: "What can we improve about the experience, or ease of use?" })}/>
                                                            
              <Button onClick={submitFeedback} 
                      style={ButtonStyle.Primary} 
                      className={ButtonStyle.Full && styles.submitFeedback}>
                <FormattedMessage id="feedback.submit"
                                  defaultMessage="Submit feedback" />
              </Button>
              {hasError && <p className={styles.errorMessage}>
                <FormattedMessage id="feedback.errorMessageFill" defaultMessage="Please answer all feedback form questions before submitting."></FormattedMessage></p>}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ConfirmationModal confirmationModalIsOpen={confirmationModalIsOpen} 
        setConfirmationModalIsOpen={setConfirmationModalIsOpen} 
        confirmationType={ConfirmationType.FeedbackSubmitted}/>
    </>
  );
}
