import React from "react";
import styles from './styles.module.scss';


interface QuestionAnswerProps {
  question: string;
  answer: string;
  scale: string;
}

export const QuestionAnswer = ( { question, answer, scale }: QuestionAnswerProps ) => {
  return (
    <div className={styles.question}>
      <h1>{question}</h1>
      <p>{answer}{scale && ` ${scale}`}</p>
    </div>
  );
};
 
