import { useState } from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { Button, ButtonStyle } from "components/Buttons";
import { UserInfo, useMergeEditUserState } from 'context/editUser';
import { useEditUserState } from 'context';
import { loginReportUser } from 'actions/userActions';
import { LoginErrorType, LoginErrors } from "components/Modals/LoginModal";
import { Formik } from "formik";


interface AddReportKeysModalProps {
  isAddReportKeysModalOpen:  boolean;
  setIsAddReportKeysModalOpen: (value: boolean) => void;
}

export const AddReportKeysModal = ({ isAddReportKeysModalOpen, setIsAddReportKeysModalOpen } : AddReportKeysModalProps) => {

  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();

  const [error, setError] = useState<LoginErrorType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  

  const closeAddReportKeysModal = () => {
    setIsAddReportKeysModalOpen(false);
  }

  const addReportKey = async (reportKey: string, password: string) => {
    return loginReportUser({reportKey, password}, false)
    .then((userResult) => {
      if (userResult) {
        const otherReportKeys = userInfo.otherReportKeys || [];
        if (userResult?.reportKey && !otherReportKeys.includes(userResult.reportKey)) {
          otherReportKeys.push(userResult.reportKey);
        }
        mergeUserData({otherReportKeys});
      }
      setIsLoading(false);
      setIsAddReportKeysModalOpen(false);
      return;
    })
    .catch(err => {
      setIsLoading(false);
      setError((err.status === 400) ? LoginErrors.InvalidCredentials : LoginErrors.General);
      return Promise.resolve(err);
    });
  }

  return (
    <>
      <BaseModal isOpen={isAddReportKeysModalOpen} 
        titleId={"personalInfo.addReportKey"}
        centerTitle={true}
        showCloseButton={true}
        onClose={closeAddReportKeysModal} >
        <div className={styles.addReportKeysModalContent}>   
          <Formik
            initialValues={{ reportKey: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              addReportKey(values.reportKey, values.password);
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
        
                <TextField type={TextFieldTypes.Text} 
                  label="ReportKey"
                  name="reportKey" 
                  placeholder="reportKey"  
                  value={values.reportKey}
                  onRawChange={handleChange}
                  required={true}
                  className={styles.reportKeyInput}
                  labelClassName={styles.labels} 
                />

                <TextField type={TextFieldTypes.Password} 
                  label="Password"
                  name="password" 
                  placeholder="password"  
                  value={values.password}
                  onRawChange={handleChange}
                  required={true}
                  className={styles.passwordInput}
                  labelClassName={styles.labels} 
                />

                <div className={styles.errorMessage}>
                  {error && ( 
                    <FormattedMessage id={error.intlId} defaultMessage="Sorry, there was an error." /> 
                  )}
                </div>

                <Button loading={isLoading} disabled={isSubmitting} style={ButtonStyle.Secondary}>
                  <FormattedMessage id="personalInfo.addReportKey" defaultMessage="Add report key"/>
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </BaseModal>

    </>
  );
}
