import { FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { RadioChoice } from "components/Forms/Toggle/RadioChoice";
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import { fetchQuestion, fetchQuestionSection, FormattedOptionType, questionKeyPrefix } from 'actions/questionActions';
import { ReportSections } from 'pages/Report';
import { PictureOption } from './PictureOption';
import { useEditAnswersState } from 'context/editAnswers';

export enum PictureOptionsType {
  Complexion = "complexion",
  HairColour = "hairColour"
};

const OtherOptions = {
  NotSure: "o-notSure"
}

const pictureOptions = (pictureOptionType: PictureOptionsType) => {
  let pictureOptions: string[] = [];

  const offenderQuestionSection = fetchQuestionSection(ReportSections.Offender);
  if (offenderQuestionSection && ((pictureOptionType === PictureOptionsType.Complexion) || (pictureOptionType === PictureOptionsType.HairColour))) {
    const pictureOptionTypeQuestion = fetchQuestion(pictureOptionType, offenderQuestionSection);
    if (pictureOptionTypeQuestion?.options) {
      pictureOptions = pictureOptionTypeQuestion.options.map(o => o.key); 
    } 
  }

  return pictureOptions;
};

interface PictureOptionsProps {
  pictureOptionsType: PictureOptionsType;
  label?: ReactNode;
  className?: string;
  labelClassName?: string;
  options: Array<{value: string, text: string}>;
  onChange?: (c: string) => void;
  groupName: string;
  errorMessage?: ReactNode;
  required?: boolean;
  useText?: boolean; // use Text instead of colour swatch
  disabled?: boolean;
}

export const PictureOptions: FC<PictureOptionsProps> = ({
  pictureOptionsType,
  label, 
  groupName,
  onChange, 
  errorMessage, 
  required,
  useText,
  className,
  disabled,
}) => {
  const intl = useIntl();
  const answerData = useEditAnswersState();

  const [pictureOptionValue, setPictureOptionValue] = useState('');

  useEffect(() => {
    const selectedOptionValue = answerData?.answers?.get(`${ReportSections.Offender}:${questionKeyPrefix}${pictureOptionsType}`)?.value?.answer;
    setPictureOptionValue(selectedOptionValue);
  }, [answerData]);

  const onPictureChoice = (value: string) => {
    setPictureOptionValue(value || '');

    if (onChange) {
      onChange(value);
    }
  }

  const notSureOptionKey = OtherOptions.NotSure;
  const notSureOption: FormattedOptionType = {
    value: notSureOptionKey,
    text: intl.formatMessage({ id: `report.${notSureOptionKey}`, defaultMessage: notSureOptionKey }),
  };

  return (
    <div className={classNames(styles.toggleStyle, className, groupName)} key="picture">
      <p>
        {label}
        {required && (<span className={styles.required}>*</span>)}
      </p>

      <div className={styles.pictureOptionsContainer}>
        {pictureOptions(pictureOptionsType).map((pictureOptionKey) => {
          const optionGroupKey = `${pictureOptionKey}Value-${groupName}`;
          const isSelectedOption = (pictureOptionValue === pictureOptionKey);
          
          if (pictureOptionKey === OtherOptions.NotSure) {
            return (
              <RadioChoice groupName={groupName}
                options={[notSureOption]}
                onChange={onPictureChoice}
                value={isSelectedOption && notSureOptionKey}
                className={styles.notSureOption}
                key={optionGroupKey} 
                disabled={disabled} 
              />
            );
          }
          if(useText) {
            return (
              <RadioChoice groupName={groupName}
                options={[{
                  value: notSureOptionKey,
                  text: intl.formatMessage({ id: `report.${pictureOptionKey}`, defaultMessage: pictureOptionKey }),
                }]}
                onChange={onPictureChoice}
                value={isSelectedOption && pictureOptionKey}
                className={styles.notSureOption}
                key={optionGroupKey}
                disabled={disabled}
              /> 
            );
          }
          return ( 
            <PictureOption pictureOptionKey={pictureOptionKey}
              pictureOptionsType={pictureOptionsType}
              groupName={groupName}
              optionGroupKey={optionGroupKey}
              onClick={onPictureChoice}
              checked={isSelectedOption}
              key={optionGroupKey}
              disabled={disabled}
            /> 
          ); 
        })}
      </div>

      {errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>)}
    </div>
  );
};
