import { SingleDateInput } from '../SingleDateInput';
import classNames from "classnames";
import styles from './styles.module.scss';

interface RangeInput {
  startName: string;
  endName: string;
  onStartChange?: (event: any) => void;
  onEndChange?: (event: any) => void;
  showTimeSelect?: boolean;
  values?: Array<Date>;
  disabled?: boolean;
}

export const RangeInput = ({startName, endName, onStartChange, onEndChange, showTimeSelect, values, disabled } : RangeInput) => {
  const startDate = values && values.length > 0 ? values[0] : undefined;
  const endDate = values && values.length > 1 ? values[1] : undefined;

  const filterSelectableEndDateRange = (date: Date) => {
    if (startDate) {
      return ((date > startDate) && (new Date() > date));
    } else {
      return (new Date() > date);
    }
  }

  return  ( 
    <div className={classNames(styles.timeRangeContainer, styles.flex)}>
      <SingleDateInput inputName={startName} value={startDate} onChange={onStartChange} showTimeSelect={showTimeSelect} disabled={disabled} />
      <span className={styles.dash}>&#8212;</span>
      <SingleDateInput inputName={endName} value={endDate} onChange={onEndChange} showTimeSelect={showTimeSelect} filterSelectableDate={filterSelectableEndDateRange} disabled={disabled}/>
    </div> 
  );
};
