import classNames from 'classnames';
import { ReactNode, useEffect } from 'react';
import styles from './styles.module.scss';

type OptionValue = string | number;

type Option<T extends OptionValue> = {
  [key: string ]: any;
  value?: T;
  text?: string;
  hidden?:boolean
};

type Props<T extends OptionValue> = {
  options: Option<T>[];
  value: T ;
  valueName?: string;
  textName?: string;
  hiddenName?: string;
  label?: ReactNode;
  errorMessage?: ReactNode;
  onChange: (value: T) => void;
  defaultValue?: string;
  className?: string;
  disabled?: boolean;
};

export function Select<T extends OptionValue>({
  options,
  value,
  onChange,
  valueName = 'value',
  textName = 'text',
  hiddenName = 'hidden',
  defaultValue,
  label = '',
  errorMessage,
  className,
  disabled,
}: Props<T>) {
  function handleOnChange(e: React.FormEvent<HTMLSelectElement>) {
    const { selectedIndex } = e.currentTarget;
    // Remove 1 for the "Please Select..." index
    const selectedOption = options[selectedIndex - 1];
    onChange(selectedOption[valueName]);
  }
  return (
    <div className={classNames(styles.selectContainer, className)}>
       {label && (<span>{label}</span>)}
      <select className={classNames(errorMessage && styles.errorField)} value={value} onChange={handleOnChange} disabled={disabled}>
        {defaultValue ? <option value="" disabled>Please Select...</option> : <option value="" disabled selected>Please Select...</option>}
        {options.map((option) => (
          option[valueName] === defaultValue ? 
            
          <option selected key={option[valueName]} value={option[valueName]} hidden={option[hiddenName]}>
              {option[textName]} </option>
            : 
          <option key={option[valueName]} value={option[valueName]} hidden={option[hiddenName]}>
              {option[textName]} </option>
        ))}
      </select>
    </div>
  );
}
