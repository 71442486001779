import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "../../Buttons/Button";
import classNames from "classnames";
import { Location } from 'components/Forms/Location';
import {
  useEditAnswersState,
  useSetEditAnswersState,
  addAnswersToAnswersMap,
  Answer 
} from "context/editAnswers";
import { ReportData, ReportFields, useMergeEditReportState } from "context/editReport";
import { ReportSectionQuestions, ReportSections } from "pages/Report";
interface RequiresLocationModalProps {
  requiresLocationModalIsOpen: boolean;
  setRequiresLocationModalIsOpen: (value: boolean) => void;
}

export const RequiresLocationModal = ({ 
  requiresLocationModalIsOpen, 
  setRequiresLocationModalIsOpen,
} : RequiresLocationModalProps) => {
  const [locationAddress, setlocationAddress] = useState('');
  const mergeReportData = useMergeEditReportState(); 
  const closeModal = () => {
    mergeReportData({ [`${ReportFields.AllowPoliceContact}` as keyof ReportData]: false });
    setRequiresLocationModalIsOpen(false);
  }

  const canSubmit = locationAddress && locationAddress.length > 0 ? true : false;

  const answerData = useEditAnswersState();
  const setReportState = useSetEditAnswersState();

  const updateLocationClick = () => {
    // use location, and set entry in answers
    if(locationAddress) {

      const locationAnswers : Array<Answer> = [{
        answerKey: `${ReportSections.Incident}:${ReportSectionQuestions.Where}`,  
        sectionKey: ReportSections.Incident,
        isFile: false,
        questionKey: ReportSectionQuestions.Where,
        value: {
          answer: locationAddress ? locationAddress : undefined 
        }
      }];
      const newAnswers = addAnswersToAnswersMap(answerData, locationAnswers );
      setReportState(newAnswers);
      setRequiresLocationModalIsOpen(false);
    } else {
      // No change, do not submit
    }
  }

  const  onChange = (locationText: string) => {
    setlocationAddress(locationText) 
  };

  return (
    <BaseModal title={<FormattedMessage id="personalInfo.consentToPoliceModal.title" defaultMessage="Consent to Police"/>}
      centerTitle={true}
      isOpen={requiresLocationModalIsOpen} 
      onClose={closeModal} 
      className={styles.campusModalContent}
      showCloseButton={false}
      titleStyle={styles.title}
     >
      <p className={styles.body}>
        <FormattedMessage id="personalInfo.consentToPoliceModal.text"
          values={{
            firstHighlight: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.consentToPoliceModal.textFirstHighlight"/></span>,
            cannotHighlight: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="personalInfo.consentToPoliceModal.textSecondHighlight"/></span>
          }}
          defaultMessage="You indicated that you consent to police contact; however, you did not provide the incident location. For Vesta to connect you with the right police jurisdiction, {firstHighlight} Your report {cannotHighlight} be submitted to police if you do not provide the incident location."
        />
      </p>
      <Location
        question={<FormattedMessage id="personalInfo.consetToPoliceModal.locationAddressLabel" defaultMessage="Please provide location of the incident" 
        />}
        onChange={onChange}
        value={locationAddress}
        className={styles.locationInput}
      />
      <div className={styles.resultButtons}>
        <Button onClick={updateLocationClick} style={ButtonStyle.Secondary} className={ButtonStyle.Full} disabled={!canSubmit}>
          <FormattedMessage id="personalInfo.consentToPoliceModal.button" />
        </Button>
        <Button style={ButtonStyle.Underline} onClick={closeModal}>
          <FormattedMessage id="personalInfo.consentToPoliceModal.continueWithoutConsent" />
        </Button>
      </div>
    </BaseModal>
  );
}
