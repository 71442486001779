import { useLogoutUser } from 'actions/userActions';
import { useEffect } from "react";
import styles from './styles.module.scss';
import { JournalAccessSources, recordJournalAccess } from 'actions/metricTrackingActions';
import { LeftContent } from './LeftContent';
import { RightContent } from './RightContent';
import { HomeContainer } from './HomeContainer';

export const onClickAccessJournalButton = async (showClickthroughPage: boolean) => {
  recordJournalAccess({
    source: showClickthroughPage ? JournalAccessSources.clickthroughPageAccessJournalButton : JournalAccessSources.documentPageAccessJournalButton
  });
}

export const onClickAccessJournalHeaderLink = async () => {
  recordJournalAccess({ 
    source: JournalAccessSources.homeHeaderLink 
  });
}

export const onClickConnectThroughJournal = async () => {
  recordJournalAccess({
    source: JournalAccessSources.connectThroughJournalButton
  });
}

export const Home = () => {
  const logoutUser = useLogoutUser();

  useEffect(()=>{
    logoutUser();
  }, []);

  return (
    <HomeContainer onClickAccessJournalHeaderLink={onClickAccessJournalHeaderLink}>
      <div className={styles.contentContainer}>
        <LeftContent 
          showClickthroughPage={false} 
          onClickAccessJournalButton={onClickAccessJournalButton}
        />
        <RightContent 
          showClickthroughPage={false}
          onClickConnectThroughJournal={onClickConnectThroughJournal}
        />
      </div>
    </HomeContainer>
  );
}
