import { RoutePaths } from "App/routing";
import classNames from "classnames";
import { Button, ButtonStyle } from "components/Buttons/Button";
import { TextField, TextFieldTypes } from 'components/Forms/TextField';
import { ArrowIcon, LocationIcon, LocationIconGrey } from "components/Icons";
import { useAppState } from 'context';
import { useAddressPredictions } from 'lib/usePlaces';
import { useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";
import styles from './styles.module.scss';

interface ResourcesNearMeProps {
  defaultAddress?: string;
  className?: string;
  search211Term: string;
  distance: string;
}

export const ResourcesNearMe = ({ className, defaultAddress, search211Term, distance }: ResourcesNearMeProps) => {
  const appState = useAppState();
  const history = useHistory();

  const [address, setAddress] = useState(defaultAddress ? defaultAddress : '');
  const [usingDefault, setUsingDefault] = useState(defaultAddress ? true : false);
  const [selectedPrediction, setSelectedPrediction] = useState<any>(null);
  const [error, setError] = useState<string | undefined>('No results found');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);

  const [predictions, predictionsLoading, getPredictionDetails] = useAddressPredictions(address, appState.googleMapsLoaded ? true : false);

  const onChange = (address: string) => {
    setUsingDefault(false);
    setSelectedPrediction(null);
    setAddress(address);
  };

  const onSelected = (predictionToSearch: any) => {
    setSelectedPrediction(predictionToSearch);

    setAddress(predictionToSearch.description);

    if (predictionToSearch) {
      getPredictionDetails(predictionToSearch.place_id).then((result: any) => { 
        setError(undefined);
        
        const { latitude, longitude, description } = result;
        history.push({ 
          pathname: RoutePaths.resourceLocations, 
          state: { 
            address: description,
            latitude,
            longitude,
            search211Term,
            distance
          } 
        });
      });
    } else {
      setError("no predictions found!");
    }
  };

  const onSearch = () => {
    let predictionToSearch;
    if (selectedPrediction) {
      predictionToSearch = selectedPrediction;
    } else if (predictions?.length) {
      const firstPrediction = predictions[0];
      setSelectedPrediction(firstPrediction);
      predictionToSearch = firstPrediction;
    }
    if (predictionToSearch) {
      getPredictionDetails(predictionToSearch.place_id).then((result: any) => { 
        setError(undefined);

        const { latitude, longitude, description } = result;
        history.push({ 
          pathname: RoutePaths.resourceLocations, 
          state: { 
            address: description,
            latitude,
            longitude,
            search211Term,
            distance
          } 
        });
      });
    } else {
      setError("no predictions found!");
    }
  }

  return (
    <>
      <ResourcesNearMeInput {...{ className, address, onChange, onSearch, setShowSuggestions }} />
      <ResourceSuggestions {...{ predictions, predictionsLoading: (predictionsLoading ? true : false), onSelected, selected: selectedPrediction !== null || usingDefault, address, showSuggestions, error }} />
    </>
  );
}

interface ResourceSuggestionsType {
  predictions: Array<any>;
  predictionsLoading: boolean;
  selected: boolean;
  address: string;
  onSelected: (prediction: any) => void;
  showSuggestions?: boolean;
  error?: string;
}

const ResourceSuggestions = ({ predictions, predictionsLoading, onSelected, selected, address, showSuggestions, error } : ResourceSuggestionsType) => {
  const intl = useIntl();

  if (!predictions ) {
    return error ? (
      <div className={classNames(styles.resourceSuggestions, showSuggestions && styles.show)}>
        <ul className={styles.resourceList}>
          <li className={styles.locationSuggestionListItem}>
            <p className={styles.resourcesError}>{error}</p>
          </li>
        </ul>
      </div>
    ) : null;
  }

  if (selected || !address ) {
    return null;
  }

  return (
    <div className={classNames(styles.resourceSuggestions, showSuggestions && styles.show)}>
      <ul className={styles.resourceList}>
        {predictions?.map((prediction: any) => (
          <li key={prediction.id} className={styles.locationSuggestionListItem}>
            <div className={styles.greyLocationIcon}><LocationIconGrey /></div>
            <Button style={ButtonStyle.None} onClick={() => { onSelected(prediction); } }>{prediction.description}</Button>
          </li>
        ))}
        </ul>
      { predictionsLoading ? <p>{ intl.formatMessage({ id:'common.loading'})}</p> : null }
    </div>
  );
};

interface ResourceNearMeInputType {
  address: string;
  onChange?: (value: string) => void;
  onSearch?: () => void;
  className?: string;
  setShowSuggestions: (value: boolean) => void;
}

const ResourcesNearMeInput = ({ className, address, onChange, onSearch, setShowSuggestions } : ResourceNearMeInputType) => {

  const intl = useIntl();

  return (
    <div className={classNames(styles.resourcesNearMe, className)}>
      <label>
        <FormattedMessage id="common.resourcesNearMe" defaultMessage="Find services near me" />:
      </label>
      <div className={classNames(styles.singleInputCard, styles.inputContainer)}>
        <LocationIcon className={styles.locationIcon}/>
        <TextField 
          type={TextFieldTypes.Text} 
          placeholder={ intl.formatMessage({ id: "common.resourcesLocationTypes" }) } 
          name="locationInput"  
          onChange={onChange}
          value={address}
          className={styles.locationInput} 
          onBlur={() => { setShowSuggestions(false) }}
          onFocus={() => { setShowSuggestions(true) }}
        />
        <ArrowIcon className={styles.arrowIcon} onClick={onSearch} />
      </div>
    </div>
  );
}
