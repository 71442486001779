import { useEffect, useState } from "react";
import { RadioChoice, RadioType } from "components/Forms/Toggle/RadioChoice";
import { Button, ButtonStyle, LinkButton } from "components/Buttons";
import { GreenCheckIcon, CrossIcon, ExclamationButtonIcon, QuestionButtonIcon } from "components/Icons";
import { FormattedMessage, useIntl } from 'react-intl';
import { ConsentModal } from "components/Modals/ConsentModal";
import { DeleteModal } from "components/Modals/DeleteModal";
import { RequiresLocationModal } from "components/Modals/RequiresLocationModal";
import { CampusSupportModal } from "components/Modals/CampusSupportModal";
import { RelationshipToOffenderModal } from "components/Modals/RelationshipToOffenderModal";
import { useMergeEditUserState, useEditUserState, UserInfo, AgeRange } from "context/editUser";
import { ReportData, useEditReportState } from "context/editReport";
import { useEditAnswersState, getAnswerKey, AnswersInfo, apiAnswersToAnswersMap } from 'context/editAnswers';
import { ReportSectionQuestions, ReportSections, ReportSectionAnswersPriorRelationshipOptions } from "pages/Report";
import styles from './styles.module.scss';
import Header from "Layout/Header";
import { useHistory } from "react-router-dom";
import { PasswordModal, PasswordModalActionType } from "components/Modals/PasswordModal";
import { AddReportKeysModal } from "components/Modals/AddReportKeysModal"
import { WhyWeAreAskingForInfoModal } from "components/Modals/WhyWeAreAskingForInfoModal"
import { Attachment } from "components/Forms/Attachments"
import { isUndefined } from 'lib/commonFunctions';
import { MethodOfContact } from "./MethodOfContact";
import { ConsentFields } from "./ConsentFields";
import { RoutePaths } from "App/routing";
import classNames from "classnames";
import { YoungerThanSixteenInfoModal } from "components/Modals/YoungerThanSixteenInfoModal";
import { ReportPathType } from "pages/ReportPath";
import Footer from "Layout/Footer";
import { SaveAndDownloadJournal } from "components/Common/SaveAndDownloadJournal";
import useHasOrganizationTypes from "lib/useHasOrganizationsTypes";


export const PersonalInfo = () => {
  const intl = useIntl();
  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();
  const reportData: ReportData = useEditReportState();
  const answerData = useEditAnswersState();
  const locationKey = getAnswerKey(ReportSections.Incident, ReportSectionQuestions.Where); 
  const priorRelationshipKey = getAnswerKey(ReportSections.Offender, ReportSectionQuestions.PriorRelationship);
  const history = useHistory();
  const hasPartneredOrganizationTypes = useHasOrganizationTypes({ isPartnered: true });
  
  const locationResult = answerData?.answers.get(locationKey);
  const locationExists = locationResult && locationResult?.value?.answer ? true : false;
  const priorRelationshipResult = answerData?.answers.get(priorRelationshipKey);

  const priorRelationshipThatRequiresPoliceInvestigationIfConsented = priorRelationshipResult 
    && priorRelationshipResult?.value?.answer 
    && (priorRelationshipResult?.value?.answer === ReportSectionAnswersPriorRelationshipOptions.RomanticPartners
      || priorRelationshipResult?.value?.answer === ReportSectionAnswersPriorRelationshipOptions.FamilyMember
    )
  ? true : false;

  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);
  const [whyWeAreAskingForInfoModalIsOpen, setWhyWeAreAskingForInfoModalIsOpen] = useState(false);
  const [relationshipToOffenderModalIsOpen, setRelationshipToOffenderModalIsOpen] = useState(false);
  const [acknowledgeRelationshipToOffender, setAcknowledgeRelationshipToOffender] = useState(false);
  const [campusSupportModalIsOpen, setCampusSupportModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isAddReportKeysModalOpen, setIsAddReportKeysModalOpen] = useState(false);
  const [requiresLocationModalIsOpen, setRequiresLocationModalIsOpen] = useState(false);
  const [reviewReportButtonClicked, setReviewReportButtonClicked] = useState(false);

  const populateUserData = (userInfoKey: string, userInfoValue: any) => {
    mergeUserData({ [userInfoKey]: userInfoValue });
  }

  const refuseContact = () => {
    return(!reportData.allowCampusContact && !reportData.allowPoliceContact && !reportData.allowSupportCenterContact && !reportData.allowVestaContact);
  }

  const acknowledgeRelationshipToOffenderCallback = () => {
    setAcknowledgeRelationshipToOffender(true);
  }

  const openRelationshipToOffenderModalIsOpen = () => {
    if (acknowledgeRelationshipToOffender) {
    } else if (priorRelationshipThatRequiresPoliceInvestigationIfConsented) {
      setRelationshipToOffenderModalIsOpen(true);
    }
  }

  const openConsentModal = () => {
    setConsentModalIsOpen(true);
  }


  const openWhyWeAreAskingForInfoModal = () => {
    setWhyWeAreAskingForInfoModalIsOpen(true);
  }

  const openCampusConsentModal = () => {
    setCampusSupportModalIsOpen(true);
  }

  const cancelReport = () => {   //name corresponds to name of button on screen
    setDeleteModalIsOpen(true);
  }

  const openRequiresLocationModal = () => {   //name corresponds to name of button on screen
    setRequiresLocationModalIsOpen(true);
  }

  const consented = () => {
    if(reportData.reportPathType === ReportPathType.ReportAnonymously) {
      return (
        reportData.acknowledgementOfConsent &&
        !isUndefined(reportData.allowVestaContact)
      );
    } else {
      return (
        reportData.acknowledgementOfConsent &&
        !isUndefined(reportData.allowVestaContact) &&
        (!hasPartneredOrganizationTypes.police || !isUndefined(reportData.allowPoliceContact)) && 
        (!hasPartneredOrganizationTypes.support || !isUndefined(reportData.allowSupportCenterContact))
      );
    }
  }

  const reviewReportIfConsented = () => {
    setReviewReportButtonClicked(true);
    if (consented() && userInfo.ageRange) {
      if ((reportData.reportPathType !== ReportPathType.ReportAnonymously) && (!userInfo.fullName && !refuseContact())) return;
      if (reportData.allowPoliceContact || reportData.allowSupportCenterContact || reportData.allowCampusContact) {
        if ( !userInfo.methodOfContact || !userInfo.email || !userInfo.phoneNumber || userInfo.phoneNumber.length !== 10 ) {
          return;
        }
      }
      return history.push(RoutePaths.reviewReport);
    }
  }

  const ageRangeOptions = () => {
    return Object.values(AgeRange).map((range) => {
      return { text: intl.formatMessage({ id: `personalInfo.${range}` }), value: range };
    });
  }

  const addReportKeys = () => {
    setIsAddReportKeysModalOpen(true);
  }

  const removeReportKey = (reportKeyToRemove: string) => {
    const filteredArray = userInfo.otherReportKeys?.filter((otherReportKey) => {
      return otherReportKey !== reportKeyToRemove;
    });
    mergeUserData({ otherReportKeys: filteredArray });
  }

  const isYoungerThanSixteen = (userInfo.ageRange === AgeRange.YoungerThanSixteen);

  const [isYoungerThanSixteenInfoModalOpen, setIsYoungerThanSixteenInfoModalOpen] = useState(false);

  const openYoungerThanSixteenInfoModal = () => {
    setIsYoungerThanSixteenInfoModalOpen(true);
  }

  const consentButtonLabel = () => {
    return consented() ?
      <FormattedMessage id="report.reviewReport" />
      : <FormattedMessage id="report.consentNotGiven" />;
  }


  return (
    <div className={styles.personalInfoContainer}>
      <div className={styles.header}>
        <Header showBackButton={false} />
      </div>
      <p className={styles.finalDetails}>
        <FormattedMessage id="personalInfo.finalDetails" defaultMessage="You’re doing great. We need to ask a few more details to finalize the report."/>
        <QuestionButtonIcon onClick={openWhyWeAreAskingForInfoModal} className={classNames(styles.iconSize, styles.whyAreWeAskingQuestionButton)} />
        <Button onClick={openWhyWeAreAskingForInfoModal} style={ButtonStyle.Underline} className={styles.whyText}>
          <FormattedMessage id="personalInfo.whyText" defaultMessage="You’re doing great. We need to ask a few more details to finalize the report."/>
        </Button>
      </p>
      <div className={styles.personalInfoContent}>
        <div className={styles.left}>
          <div className={styles.userFormContent} >  
            <RadioChoice    
              label={<FormattedMessage id="personalInfo.howOldAreYou" defaultMessage="How old are you?"/>}
              groupName="ageRange"
              value={userInfo.ageRange}
              onChange={(ageRange) => { populateUserData("ageRange", ageRange) }}
              options={ageRangeOptions()}
              required={true}
              className={styles.howOldQuestion}
              type={RadioType.Stacked}
              errorMessage={reportData.acknowledgementOfConsent && isUndefined(userInfo.ageRange) && 
                <FormattedMessage id="personalInfo.form.error.required" defaultMessage="This field is required"/>}/>

            <ConsentFields
              reviewReportButtonClicked={reviewReportButtonClicked}
              openCampusSupportModalIsOpen={openCampusConsentModal}
              openRequiresLocationModal={openRequiresLocationModal}
              openRelationshipToOffenderModalIsOpen={openRelationshipToOffenderModalIsOpen}
              locationExists={locationExists}
            />
              
            <div className={styles.border} />

            <div className={styles.otherReportKeys}>
              <FormattedMessage id="personalInfo.filedReportBefore" defaultMessage="Have you filed a report before?"/>
              <Button onClick={addReportKeys} 
                      style={ButtonStyle.Primary} 
                      className={ButtonStyle.Full}>
                <FormattedMessage id="personalInfo.addReportKeys" defaultMessage="Add report keys"/>
              </Button>
              
            </div>
            <div className={styles.otherReportKeysArray}>
              {userInfo?.otherReportKeys?.map( (otherReportKey, index, arr) => {
                //used Attachment component because added report keys appear exactly like them
                return ( 
                  <Attachment key={otherReportKey} 
                    attachmentName={otherReportKey} 
                    onRemove={() => { removeReportKey(otherReportKey); }}
                    isLastElement={arr.length === index + 1}
                  /> 
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.right}>

          <div className={classNames(styles.youngerThanSixteenInfo, isYoungerThanSixteen && styles.active)}>
            <ExclamationButtonIcon className={styles.icon}/>
            <p className={styles.smallText}>
              <FormattedMessage id="personalInfo.youngerThanSixteen.info" 
              values={{ 
                learnMore: (<span
                              className={styles.learnMoreLink}
                              onClick={openYoungerThanSixteenInfoModal}
                              ><FormattedMessage id="personalInfo.youngerThanSixteen.info.readMore" defaultMessage="Read more on what that means for you."/></span>)
              }} 
              defaultMessage="You’ve stated that you are younger than 16: We at Vesta want to ensure you are given the help and safety you deserve. Read more on what that means for you."/>
            </p>
          </div>
          
          <MethodOfContact reviewReportButtonClicked={reviewReportButtonClicked}/>

          <div className={styles.footerButtons}>

            <div className={styles.consentButtonContainer}>
              <Button onClick={openConsentModal} style={ButtonStyle.Primary} className={styles.acknowledgementOfConsent}>
                <FormattedMessage id="report.acknowledgementOfConsentHeader" defaultMessage="Read Acknowledgement of Consent"/>
              </Button>
              <ExclamationButtonIcon onClick={openConsentModal} className={styles.iconSize}/>
                {reportData.acknowledgementOfConsent
                  ? <GreenCheckIcon className={styles.iconSize}/>
                  : <CrossIcon onClick={openConsentModal} className={styles.iconSize}/>}
            </div>

            <div className={styles.reportButtonContainer}>
              <Button onClick={reviewReportIfConsented} 
                      style={ButtonStyle.Secondary} 
                      className={ButtonStyle.Full}
                      disabled={!consented() || !userInfo.ageRange}>
                {consentButtonLabel()}
              </Button>
            </div>
              
            <SaveAndDownloadJournal className={styles.saveAndDownloadJournal}/>
            <div className={styles.dontSaveButtonContainer}>
              <LinkButton 
                style={ButtonStyle.Underline} 
                to={RoutePaths.reportSubmitSuccess}
                className={ButtonStyle.Full}>
                <FormattedMessage id="report.update" defaultMessage="Update report" />
              </LinkButton>
            </div>
            <div className={styles.dontSaveButtonContainer}>
              <Button 
                onClick={cancelReport} 
                style={ButtonStyle.Underline} 
                className={ButtonStyle.Full}>
                <FormattedMessage id="report.dontSave" defaultMessage="I don’t want to save this report" />
              </Button>
            </div>
          </div>
        </div>   

        <Footer isReportForm={false} isBreak={false}/>

        <YoungerThanSixteenInfoModal youngerThanSixteenInfoModalIsOpen={isYoungerThanSixteenInfoModalOpen} 
                                     setYoungerThanSixteenInfoModalIsOpen={setIsYoungerThanSixteenInfoModalOpen}
                                     key="youngerThanSixteenInfoModal"/>  

        <ConsentModal consentModalIsOpen={consentModalIsOpen}
          setConsentModalIsOpen={setConsentModalIsOpen}/>

        <DeleteModal deleteModalIsOpen={deleteModalIsOpen}
          setDeleteModalIsOpen={setDeleteModalIsOpen}/>

        <PasswordModal setIsPasswordModalOpen={setIsPasswordModalOpen}
          isPasswordModalOpen={isPasswordModalOpen}
          passwordModalAction={userInfo.reportKey ? PasswordModalActionType.Resave : PasswordModalActionType.Save}/>

        <AddReportKeysModal isAddReportKeysModalOpen={isAddReportKeysModalOpen} 
          setIsAddReportKeysModalOpen={setIsAddReportKeysModalOpen}/>

        <CampusSupportModal campusSupportModalIsOpen={campusSupportModalIsOpen}
          setCampusSupportModalIsOpen={setCampusSupportModalIsOpen}/>
        <RequiresLocationModal 
          requiresLocationModalIsOpen={requiresLocationModalIsOpen}
          setRequiresLocationModalIsOpen={setRequiresLocationModalIsOpen}
        />
        <WhyWeAreAskingForInfoModal 
          modalIsOpen={whyWeAreAskingForInfoModalIsOpen}
          setModalIsOpen={setWhyWeAreAskingForInfoModalIsOpen}
        />
        <RelationshipToOffenderModal 
          modalIsOpen={relationshipToOffenderModalIsOpen}
          setModalIsOpen={setRelationshipToOffenderModalIsOpen}
          acknowledgeRelationshipToOffender={acknowledgeRelationshipToOffenderCallback}
        />
      </div>
    </div>
  );
}
