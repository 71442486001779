import React, { FC, ReactNode, useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface MultChoiceOption {
  value: string;
  text: string;
};

interface MultiChoiceFieldType {
    value?: string;
    text: string;
    checked?: boolean;
    groupName: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
 };

const MultiChoiceField: FC<MultiChoiceFieldType> = ({value, checked = false, text, groupName, onChange, className}) => {
    return <div className={classNames(styles.multiChoiceContainer, className )}>    
                <input
                  className={styles.multiChoiceStyle}
                  id={`${groupName}-${value}`}
                  type="checkbox" 
                  name={value}
                  value={value}
                  checked={checked}
                  onChange={onChange}
                />
                <label htmlFor={`${groupName}-${value}`}>{text}</label>
            </div>;
}

interface MultiChoiceProps {
  label?: ReactNode;
  required?: boolean;
  className?: string;
  labelClassName?: string;
  options: Array<MultChoiceOption>;
  groupName: string;
  values?: Array<string>;
  errorMessage?: ReactNode;
  onChange: (values: Array<string>) => void;
};

export const MultiChoice: FC<MultiChoiceProps> = ({label, groupName, required, options, className, labelClassName, onChange, values=[], errorMessage }) => {
  const [checkedValues, setCheckedValues] = useState(new Map());

  useEffect(() => {
    if(values && values.length) { 
      const newValues = new Map(values && values.map((value) => {
        return [value, true];
      }));

      setCheckedValues(newValues);
    }
  }, [values]);

  const onChoiceChange = (e: any) => {
    const newCheckedValues = new Map(checkedValues);
    newCheckedValues.set(e.target.value, e.target.checked);
    setCheckedValues(newCheckedValues);
    if(onChange) {
      onChange(Array.from(newCheckedValues.keys()).filter((ncv) => { return newCheckedValues.get(ncv) === true}));
    }
  }

  return (
    <div className={classNames(styles.toggleStyle, className)}>
      <p>
        {label}
        {required && (<span className={styles.required}>*</span>)}      
      </p>
      <div className={styles.multiChoicesContainer}>
        {options.map((option) => {
          return (<MultiChoiceField
            onChange={onChoiceChange}
            value={option.value}
            text={option.text}
            groupName={groupName}
            checked={checkedValues.get(option.value) === true }
            key={`${groupName}-${option.value}`}
            className={errorMessage ? styles.errorField : undefined }
          />)
        })}
      </div>
      { errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>) }
    </div>
  );
};
