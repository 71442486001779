import React from "react";
import { FormattedMessage } from 'react-intl';
import { BaseModal } from "../BaseModal";
import styles from './styles.module.scss';
import { PhoenixImage } from "components/Images";
import classNames from "classnames";
import { LearnMoreLink, LearnMoreLinkType } from "components/Common/LearnMore";
import { ButtonStyle } from "components/Buttons";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "App/routing";
import { useLogoutUser } from "actions/userActions";

interface SubmitReportModalProps {
  submitReportModalIsOpen: boolean;
  setSubmitReportModalIsOpen: (value: boolean) => void;
  reportKey?: string;
}

export const SubmitReportModal = ({ 
  submitReportModalIsOpen, 
  setSubmitReportModalIsOpen,
  reportKey 
} : SubmitReportModalProps) => {
  const history = useHistory();
  const logoutUser = useLogoutUser();

  const closeSubmitReportModal = () => {
    setSubmitReportModalIsOpen(false);
    logoutUser();
    history.push(RoutePaths.index);
  }
  
  return (
    <BaseModal title={<FormattedMessage id="submitReport.title" defaultMessage="Your report has been successfully submitted"/>}
      centerTitle={true}
      isOpen={submitReportModalIsOpen} 
      onClose={closeSubmitReportModal} 
      className={styles.submitReportModal}
      icon={<PhoenixImage className={styles.phoenixImage}/>}>

        <div className={styles.reportKeyHeader}>
          <h1 className={classNames(styles.reportKey, styles.highlightedText)}>{reportKey}</h1>
          <p><FormattedMessage id="submitReportModal.reportKeyHeader" defaultMessage="You can use this key and your selected password to access your report."/></p>
        </div>

        <p className={styles.body}>
          <FormattedMessage id="submitReport.body" 
            values={{ youDidGreat: <span className={styles.highlightedText}><FormattedMessage id="submitReport.body.youDidGreat" defaultMessage="You did great!"/></span> }}
            defaultMessage="You did great! You’ve taken an amazing step towards healing. Now, we’ll bring you to explore more resources to help you through your journey. Take a deep breath and have a cold glass of water. You are in control."/>
        </p>

        <LearnMoreLink learnMoreLinkType={LearnMoreLinkType.Resources}
          className={classNames(styles.learnMoreLink, ButtonStyle.Primary)}
          showExternalLinkIcon={false}
          customText={<FormattedMessage id="button.viewVestaResources" defaultMessage="View Vesta resources"/>}/>

    </BaseModal>
  );
}
