import React from "react";
import { FeelingIcon, FeelingFilledIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';

interface ExperienceScaleProps {
  experience: FeelingScale | undefined;
  setExperience: (feeling: FeelingScale) => void;
}

export enum FeelingScale {
  MostSad = "MostSad",
  Sad  = "Sad",
  Neutral = "Neutral",
  Happy = "Happy",
  MostHappy = "MostHappy"
}

export const ExperienceScale = ( {experience, setExperience}: ExperienceScaleProps) => {

  const onFeelingClick = (feeling: FeelingScale) => {
    setExperience(feeling);
  }

  const feelingScale = [FeelingScale.MostSad, FeelingScale.Sad, FeelingScale.Neutral, FeelingScale.Happy, FeelingScale.MostHappy];

  return (
    <div className={styles.experience}>
      <h1>
        <FormattedMessage id="feedback.experience" 
          defaultMessage="How was your experience with Vesta?"/>
      </h1>
      <div className={styles.scale}>
        {
        feelingScale.map((feeling) => {
          return (
            (experience === feeling) 
              ? ( <FeelingFilledIcon feeling={feeling} 
                    className={styles.feelingIconSize}
                    key={`${feeling}-filled`}/> )
              : ( <FeelingIcon feeling={feeling} 
                    className={styles.feelingIconSize} 
                    onClick={() => { onFeelingClick(feeling); }}
                    key={feeling}/> )
          );
        }) 
        }
     </div> 
    </div>
  );
}
