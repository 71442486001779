import React, { ReactNode } from "react";
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { GreenCheckIcon, CrossIcon } from "components/Icons";

interface RuleProps { 
  rule: ReactNode,
  metRule: boolean 
}

const Rule = ({ rule, metRule }: RuleProps) => (
  <li className={styles.passwordRule}>
    {metRule ? <GreenCheckIcon className={styles.iconSize}/> : <CrossIcon className={styles.iconSize}/>}
    {rule}
  </li>
);

interface RulesProps { 
  long: boolean, 
  number: boolean,
  specialCharacter: boolean,
  uppercase: boolean
}

export const Rules = ({long, number, specialCharacter, uppercase}: RulesProps) => (
  <ul className={styles.passwordRulesList}>
    <Rule rule={<FormattedMessage id="passwordModal.rule.charactersCount" defaultMessage="8 or more characters"/>} metRule={long}/>
    <Rule rule={<FormattedMessage id="passwordModal.rule.specialCharacter" defaultMessage="Includes special character"/>} metRule={specialCharacter}/>
    <Rule rule={<FormattedMessage id="passwordModal.rule.numbersCount" defaultMessage="Includes one or more numbers"/>} metRule={number}/>
    <Rule rule={<FormattedMessage id="passwordModal.rule.uppercaseCount" defaultMessage="Includes at least one uppercase letter"/>} metRule={uppercase}/>
  </ul>
);
