import DatePicker from "react-datepicker";
import { useState, forwardRef, useEffect } from "react";
import { CalendarIcon } from "../../../Icons";
import classNames from "classnames";
import styles from './styles.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import textFieldStyles from '../../TextField/styles.module.scss';

interface DateProps {
  showTimeSelect?: boolean;
  inputName: string;
  onChange?: (event: any) => void;
  value?: Date,
  filterSelectableDate?: (date: Date) => boolean
  disabled?: boolean;
};

interface CustomInputProps {
  value?: any;
  onClick?: any;
  name?: string;
  type?: string;
  ref?: any;
  className?: string;
  disabled?: boolean;
}

const CustomInput = forwardRef(({ value, onClick, name, className, disabled }: CustomInputProps, _ref) => {
  return <div className={classNames(
      styles.inputContainer)}>
    <input
      onClick={onClick}
      value={value}
      readOnly
      name={name}
      placeholder={'MM/DD/YYYY'}
      type="text"
      className={classNames(textFieldStyles.textInput, className)}
      disabled={disabled}
    />
      <CalendarIcon className={styles.calendarIcon} onClick={onClick}
               />
    </div>
});

const DateFormat = "MMMM d, yyyy h:mm aa";

export const SingleDateInput = ({ showTimeSelect = true, inputName = "date", onChange = () => {}, value, filterSelectableDate, disabled  }: DateProps) => {
  const [dateSelected, setDateSelected]  = useState<Date | null>(value || null);

  useEffect(() => {
    if(value) {
      setDateSelected(value);
    }
  },[value]);

  return ( 
    <>
      <div className={styles.singleDateInput}>
         <DatePicker
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className={styles.inputContainer}
          selected={dateSelected}
          onChange={(date) => {
            setDateSelected(date);     
            onChange(date);
            //setValue(date);
          }}
          filterDate = {(date) => filterSelectableDate ? filterSelectableDate(date) : (new Date() > date)}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          showPopperArrow={false}
          showTimeSelect={showTimeSelect}
          timeIntervals={30}
          dateFormat={DateFormat}
          customInput={<CustomInput disabled={disabled}/>}
          disabled={disabled}
        />
      </div>
    </>
  ); 
};
