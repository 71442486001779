import { Button, LinkButton } from 'components/Buttons';
import styles from './styles.module.scss';
import { WriteStoryImage } from "components/Images";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { RoutePaths } from 'App/routing';

interface SubmitSurveyModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const SubmitSurveyModal = ({ 
  isOpen, 
  onClose 
}: SubmitSurveyModalProps) => {
  
  return (
    <BaseModal 
      isOpen={isOpen} 
      onClose={onClose}
      className={styles.submitSurveyModal}
      icon={<WriteStoryImage className={styles.writeStoryImage}/>}
      >
      <div className={styles.body}>
        <h5 className={classNames(styles.header, styles.attention)}>
          <FormattedMessage id="survey.submitModal.title" defaultMessage="Thank you so much!"/>
        </h5>
        <p className={styles.text}>
          <FormattedMessage id="survey.submitModal.text" defaultMessage="Your survey answers have been successfully submitted."/>
        </p>
        <LinkButton 
          className={styles.submitButton} 
          to={RoutePaths.index}>
          <FormattedMessage id="survey.submitModal.submitButton" defaultMessage="Return to homepage"/>
        </LinkButton>
      </div>
    </BaseModal>
  );
}