import React, { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { intl } from 'intl/intl';

import { AppContextProvider } from './app';
import { EditUserContextProvider, useSetEditUserState } from './editUser';
import { EditAnswersContextProvider, useSetEditAnswersState, createBlankState } from './editAnswers';
import { EditReportContextProvider, useSetEditReportState } from './editReport';

export { useAppState, useSetAppState, useMergeAppState } from './app';
export { useEditUserState, useSetEditUserState, useMergeEditUserState } from './editUser';
export { useEditReportState, useSetEditReportState, useMergeEditReportState } from './editReport';
export { useEditAnswersState, useSetEditAnswersState, useMergeEditAnswerState} from './editAnswers';

export interface AppContextInterface {
  token?: string;
  email?: string;
  userName?: string;
}

export const useClearState = () => {
  const setUserInfo = useSetEditUserState();
  const setReportData = useSetEditReportState();
  const setAnswerData = useSetEditAnswersState();

  return () => {
    setUserInfo({});
    setReportData({});
    setAnswerData(createBlankState());
  };
};

export const AppProvider : FC = ({children}) => {
  return (
    <AppContextProvider>
      <EditUserContextProvider>
        <EditReportContextProvider>
          <EditAnswersContextProvider>
            <IntlProvider locale="en-CA" defaultLocale="en-CA" messages={intl["en-CA"]}>
              {children}
            </IntlProvider>
          </EditAnswersContextProvider>
        </EditReportContextProvider>
      </EditUserContextProvider>
    </AppContextProvider>
  );
}
