import React, { useState, useRef } from "react";
import { FormattedMessage } from 'react-intl';
import { UserInfo } from "context/editUser";
import { useEditUserState } from 'context';
import classNames from "classnames";
import { VestaLogo, WhiteCheckIcon } from "components/Icons";
import { PasswordModal, PasswordModalActionType } from "components/Modals/PasswordModal";
import { Button, ButtonStyle, ArrowLink } from "components/Buttons/Button";
import { DeleteModal } from "components/Modals/DeleteModal";
import { ReportSummary } from './ReportSummary';
import { BasicReportInfo } from './BasicReportInfo';
import { SectionQuestionAnswers } from "./SectionQuestionAnswers";
import { ReportData, useEditReportState } from "context/editReport";
import styles from './styles.module.scss';
import Footer from "Layout/Footer";
import { SaveAndDownloadJournal } from "components/Common/SaveAndDownloadJournal";
import { SaveModal, SaveModalActionType, useSaveReport } from "components/Modals/SaveModal";

export const ReviewReport = () => {

  const userInfo: UserInfo = useEditUserState();
  const reportData: ReportData = useEditReportState();  

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [passwordModalAction, setPasswordModalAction] = useState<PasswordModalActionType>(PasswordModalActionType.Save);

  const {
    saveReport,
    errorSaveSubmitDeleteModalIsOpen, 
    setErrorSaveSubmitDeleteModalIsOpen,
    attachmentFailedModalIsOpen, 
    setAttachmentFailedModalIsOpen,
    saveReportUserInfo,
    isSavedReportModalOpen, 
    setIsSavedReportOpen,
    isSubmitReportModalOpen, 
    setIsSubmitReportOpen
  } = useSaveReport({ modalAction: SaveModalActionType.Submit });

  const submitReport = async () => {
    const alreadySaved = !!reportData.id;

    if (alreadySaved) {
      await saveReport();
    } else {
      setPasswordModalAction(PasswordModalActionType.Submit);
      setIsPasswordModalOpen(true);
     }
  }

  const scrollToTop = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  } 

  const reportSectionRef = useRef(null)
  const onClickViewReport = () => {
    scrollToTop(reportSectionRef)
  }

  const acknowledgementOfConsent = reportData?.acknowledgementOfConsent;

  return (
    <div className={styles.reviewReportPage}>
      <div className={classNames(styles.left)}>
        <div className={styles.vestaIcon}>
            <VestaLogo />
        </div>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}><FormattedMessage id="reportSummary.title" defaultMessage="Report Summary"/></h1>
          <div className={styles.viewReportButton}>
            <Button onClick={onClickViewReport} style={ButtonStyle.Secondary}><FormattedMessage id="report.reviewReport" defaultMessage="Review report"/></Button>
          </div>
        </div>
        <div>
          {userInfo.fullName? 
          <div>
            <FormattedMessage id="common.hello" defaultMessage="Hello"/>
            <span> {userInfo.fullName}</span>
            <FormattedMessage id="reviewReport.whatToExpect" defaultMessage=", based on your answers, here is what you can expect to happen when your report is submitted"/>:
          </div>
          :
          <div>
            <FormattedMessage id="reviewReport.whatToExpectNoName" defaultMessage="Based on your answers, here is what you can expect to happen when your report is submitted"/>:
          </div>}
        </div>
        
        <ReportSummary/>
        <div className={styles.reviewButtonsContainer}>
          <Button onClick={submitReport} disabled={!acknowledgementOfConsent}>
            <div className={styles.buttonIcon}>
              <WhiteCheckIcon />
            </div>
            <FormattedMessage id="report.submit" defaultMessage="Submit report and view resources"/>
          </Button>
          <SaveAndDownloadJournal />
        </div>
      </div>

      <div ref={reportSectionRef} className={styles.right}>

        <BasicReportInfo />

        <SectionQuestionAnswers/>

      </div>


      <Footer isReportForm={false} isBreak={false}/>

      <PasswordModal setIsPasswordModalOpen={setIsPasswordModalOpen}
        isPasswordModalOpen={isPasswordModalOpen}
        passwordModalAction={passwordModalAction}/>
      <SaveModal
      errorSaveSubmitDeleteModalIsOpen={errorSaveSubmitDeleteModalIsOpen} 
      setErrorSaveSubmitDeleteModalIsOpen={setErrorSaveSubmitDeleteModalIsOpen} 
      attachmentFailedModalIsOpen={attachmentFailedModalIsOpen} 
      setAttachmentFailedModalIsOpen={setAttachmentFailedModalIsOpen} 
      saveReportUserInfo={saveReportUserInfo} 
      isSavedReportModalOpen={isSavedReportModalOpen} 
      setIsSavedReportOpen={setIsSavedReportOpen} 
      isSubmitReportModalOpen={isSubmitReportModalOpen} 
      setIsSubmitReportOpen={setIsSubmitReportOpen} 
      saveModalAction={SaveModalActionType.Save}
    />
      <DeleteModal deleteModalIsOpen={deleteModalIsOpen} 
        setDeleteModalIsOpen={setDeleteModalIsOpen}/>
    </div>
  );
}
