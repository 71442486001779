import { HasOrganizationTypes, OrganizationFilter, getOrganizationTypesByLocation } from "actions/organizationActions";
import { ReportData, useEditReportState } from "context/editReport";
import { useEffect, useState } from "react";

const useHasOrganizationTypes = (filter: OrganizationFilter) => {

  const reportData: ReportData = useEditReportState();
  const [hasOrganizationTypes, setHasOrganizationTypes] = useState<HasOrganizationTypes>({} as HasOrganizationTypes);
  useEffect(() => {
    const fetchData = async () => {
      if (reportData.supportLocation) {
        setHasOrganizationTypes(await getOrganizationTypesByLocation(reportData.supportLocation, filter));
      }
    }
    fetchData();
  }, [reportData.supportLocation]);
  return hasOrganizationTypes;
}

export default useHasOrganizationTypes;