import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { InlineTitledColor, InlineTitledMessage } from 'components/Common/InlineTitledMessage';
import { Button, ButtonStyle, DoubleArrowButton } from 'components/Buttons/Button';
import { RoutePaths } from 'App/routing';
import { ExternalLinkIconWhite } from 'components/Icons';
import { ThoughtBlurb, ThoughtBlurbColor } from 'components/Common/thoughtBlurb';
import classNames from 'classnames';
import { LoginInputCard, LoginInputCardStyle } from 'components/Common/SingleInputCard';
import { DownloadPagesForm } from '../DownloadPagesForm';
import { useHistory } from 'react-router-dom';

export interface RightContentProps {
  showClickthroughPage: boolean,
  onClickConnectThroughJournal: () => void;
}

const SurveyLinkContent = () => {
  return (
    <div className={styles.surveyLinkContent}>
      <p className={styles.surveyLinkText}><FormattedMessage id="home.surveyLinkText"/></p>
      <ExternalLinkIconWhite className={styles.externalLinkIcon}/>
    </div>
  );
}

export const RightContent = ({ 
  showClickthroughPage,
  onClickConnectThroughJournal
}: RightContentProps) => {
  const intl = useIntl();
  const history = useHistory();
  
  const openSurveyPage = () => {
    history.push(RoutePaths.survey);
  }

  // CLICKTHROUGH PAGE
  if (showClickthroughPage) {
    return (
      <div className={styles.rightContainer}>
        <InlineTitledMessage 
          title={intl.formatMessage({ id: 'home.clickthrough.point2.title' })}
          delimiter={": "}
          message={intl.formatMessage({ id: 'home.clickthrough.point2.body' })}
          className={styles.point2}
          color={InlineTitledColor.Blue}
        />
        <div className={styles.leftJustifiedButtonContainer}>
          <DoubleArrowButton 
            to={RoutePaths.reportStart}
            onClick={onClickConnectThroughJournal}
            style={ButtonStyle.DarkSecondary} 
            className={classNames(styles.leftJustifiedButton, styles.attention)}>
            <FormattedMessage id="home.clickthrough.connectButtonThroughJournalButton"/>
          </DoubleArrowButton>
        </div>
        <InlineTitledMessage 
          title={intl.formatMessage({ id: 'home.clickthrough.connectInPerson.title' })}
          delimiter={" "}
          message={intl.formatMessage({ id: 'home.clickthrough.connectInPerson.body' })}
          color={InlineTitledColor.Blue}
        />
        <InlineTitledMessage 
          title={intl.formatMessage({ id: 'home.clickthrough.printAnotherCopy.title' })}
          delimiter={", "}
          message={intl.formatMessage({ id: 'home.clickthrough.printAnotherCopy.body' })}
          className={styles.printAnotherCopy}
          color={InlineTitledColor.Blue}
        />
        <LoginInputCard style={LoginInputCardStyle.thick}/>
        
        <Button onClick={openSurveyPage} style={ButtonStyle.None}>
          <ThoughtBlurb
            content={<SurveyLinkContent />} 
            className={classNames(styles.surveyLinkContainer, styles.clickthroughPage)}
            color={ThoughtBlurbColor.Pink}
          />
        </Button>
      </div>  
    );
  } 
  // LANDING PAGE
  else {
    return (
      <div className={styles.rightContainer}>
        <Button onClick={openSurveyPage} style={ButtonStyle.None}>
          <ThoughtBlurb 
            content={<SurveyLinkContent />} 
            className={classNames(styles.surveyLinkContainer, styles.documentPage)}
            color={ThoughtBlurbColor.Blue}
          />
        </Button>
       <DownloadPagesForm />
      </div>  
    );
  }
}