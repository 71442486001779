import timezoneDate from "date-and-time";
import styles from './styles.module.scss';
import { NoteType } from "actions/noteActions";
import { useIntl } from "react-intl";

interface NoteProps {
  note: NoteType;
}

const noteDateFormatter = (date: string | Date) => {
  const parsedDate = (typeof date === "string") ? timezoneDate.parse(date, "YYYY-MM-DD HH:mm:ss", true) : date; // third parameter of false assumes the INPUT dateString is a local date-time, while true says that it is UTC date-time
  if (parsedDate && (parsedDate instanceof Date)) {
    return timezoneDate.format(parsedDate, 'MMMM DD, YYYY hh:mm A', false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
  }
}

export const Note = ({ note }: NoteProps) => {

  const intl = useIntl();

  return (
    <div className={styles.note}>
      <h2>{note.createdAt && noteDateFormatter(note.createdAt)}</h2>
      <p>{note.note}</p>
    </div>
  );
};
 
