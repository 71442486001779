import React, { FC, ReactNode, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Button, ButtonStyle } from 'components/Buttons';
import { FormattedMessage } from 'react-intl';
import { UnSubmittedAttachmentType, UploadedAttachmentType } from '../../../actions/awsActions';
import _ from 'lodash';
import styles from './styles.module.scss';
import { FileIcon, PaperclipWhiteIcon, CloseButtonIcon, SMGroupIcon } from 'components/Icons';
import { ReportData, useEditReportState, useMergeEditReportState } from 'context/editReport';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from 'App/routing';


interface AttachmentProps {
  attachmentName: string;
  onRemove: () => void;
  uploaded?: boolean;
  isLastElement: boolean;
  disabled?: boolean;
}

export const Attachment = ({ attachmentName, onRemove, uploaded=false, isLastElement, disabled}: AttachmentProps) => {
  const comma = isLastElement ? "" : ",";
  return (
    <div className={styles.attachment}>
      <div className={styles.fileIcon}><FileIcon/></div>
      <p>{attachmentName}{comma}</p>
      {!uploaded && !disabled && (
        <Button style={ButtonStyle.None} onClick={onRemove} disabled={disabled}>
          <div className={styles.closeIcon}><CloseButtonIcon/></div>
        </Button>
      )}
    </div>
  );
}

// A Dirty almost-unique File key based on its properties.  Although not guaranteed to be unique, it's close enough.
const getFileKey = (file: File) => {
  return `name-${file.name}-lastModified-${file.lastModified}-size-${file.size}-type-${file.type}`;
}

const getUniqueAttachments = (attachmentsToUpload: UnSubmittedAttachmentType[]) => {
  // use simple check for uniqueness of files uploaded, by checking file info.
  return _.uniqBy(attachmentsToUpload, (attachment) => { return getFileKey(attachment.file); });
};

const mapFilesToUnSubmittedAttachmentTypes = (files: File[]): UnSubmittedAttachmentType[] => {
  return files.map((file: File) => ({
    key: getFileKey(file),
    filename: file.name,
    uploaded: false,
    file
  })); 
};

interface AttachmentsProps {
  label?: ReactNode;
  required?: boolean;
  className?: string;
  groupName: string;
  multiple?: boolean;
  values?: Array<UnSubmittedAttachmentType | UploadedAttachmentType>;
  errorMessage?: ReactNode;
  onChange?: (files: Array<File>) => void;
  disabled?: boolean;
};

const maxFileSize = 20971520; // 20MB
let error: string | null = null;

export const resetError = () => {
  error = null;
}

export const Attachments: FC<AttachmentsProps> = ({
  label,
  groupName,
  required,
  values,
  onChange,
  errorMessage,
  multiple = true,
  disabled,
}) => {
  const currentPath = useLocation().pathname;
  const isUpdateSubmittedReportPage = (currentPath === RoutePaths.updateSubmittedReport);

  const reportData: ReportData = useEditReportState();
  const mergeReportData = useMergeEditReportState();
  const attachmentInput = useRef<HTMLInputElement>(null);

  const unUploadedAttachments: UnSubmittedAttachmentType[] = reportData.attachmentsToUpload || [];
  const uploadedAttachments: UploadedAttachmentType[] = reportData.uploadedAttachments || [];

  const addAttachments = useCallback((files: File[]) => {
    const updatedUnUploadedAttachments: UnSubmittedAttachmentType[] = getUniqueAttachments([
      ...unUploadedAttachments, 
      ...mapFilesToUnSubmittedAttachmentTypes(files)
    ]);
    mergeReportData({ attachmentsToUpload: updatedUnUploadedAttachments });
  }, [reportData.attachmentsToUpload, reportData.uploadedAttachments]);

  const removeAttachment = useCallback((attachment) => {
    const updatedUnUploadedAttachments = reportData.attachmentsToUpload ? [...reportData.attachmentsToUpload].filter((a) => (a !== attachment)) : [];
    const updatedUploadedAttachments = reportData.uploadedAttachments ? [...reportData.uploadedAttachments].filter((a) => (a !== attachment)) : [];
    mergeReportData({ 
      attachmentsToUpload: updatedUnUploadedAttachments,
      uploadedAttachments: updatedUploadedAttachments,
    });
    if (onChange) { // for some reason onChange on line 111 isn't triggering on removal - that's why placed here
      onChange(updatedUnUploadedAttachments.map(a => a.file)); // this removes corresponding note; onChange above isn't being triggered on removal for some reason
    }
  }, [reportData.attachmentsToUpload, reportData.uploadedAttachments]);

  return (
    <div className={classNames(styles.attachments, required && styles.required)}>
      <input
        style={{ display: 'none'}}
        type="file"
        multiple={multiple}
        accept=".png, .jpeg, .jpg, .wav, .mp3, .mp4, .pdf, .heic"
        name={`${groupName}-selector`} 
        disabled={disabled}
        onChange={(event) => { 
          const {
            currentTarget: { files },
          } = event; 

          if (files) {
            let filesArray = Array.from(files);

            filesArray = filesArray.filter(file => {
              if (file.size > maxFileSize) {
                error = "One or more files was too large"
              }
              
              return file.size <= maxFileSize
            });

            addAttachments(filesArray);

            if (onChange) {
              onChange(filesArray);
            }
          }
          
          // This is to allow you to remove an attachment then upload it again
          event.target.value = "";
        }}
        ref={attachmentInput}
      />
      <div className={styles.attachmentButtonContainer}>
        <p>{label}</p>
        <div className={styles.smGroupIcon}><SMGroupIcon /></div>
        <div className={styles.attachmentsContainer}>
          <div className={styles.attachmentsList}>
          {(isUpdateSubmittedReportPage 
              ? unUploadedAttachments 
              : [...unUploadedAttachments, ...uploadedAttachments]
            )?.map((attachment: UnSubmittedAttachmentType | UploadedAttachmentType, index, arr) => (
            <>
              <Attachment
                key={attachment.filename}
                attachmentName={attachment.filename}
                onRemove={() => { removeAttachment(attachment); } }
                uploaded={attachment.uploaded}
                isLastElement={arr.length === index + 1}
                disabled={disabled}
              />
            </>
          ))}
          </div>
          <Button className={styles.attachFileButton} onClick={() => { attachmentInput.current?.click(); }} disabled={disabled}>
            <FormattedMessage id="form.attachments.attachFile" defaultMessage="Attach File" />
            <div className={styles.paperclipWhiteIcon}><PaperclipWhiteIcon /></div>
          </Button>
        </div>
      </div>
      {required && (<span className={styles.required}>*</span>)}
      {errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>)}
      {error && (<p className={styles.errorMessage}>{error}</p>)}
    </div>
  );
};
