import React from "react";
import { BaseModal } from "../BaseModal";
import { FormattedMessage } from 'react-intl';
import { Button, ButtonStyle } from "../../../components/Buttons/Button";
import styles from './styles.module.scss';
import classNames from "classnames";

interface AttachmentFailedModalProps {
  attachmentFailedModalIsOpen: boolean;
  setAttachmentFailedModalIsOpen: (value: boolean) => void;
}

export const AttachmentFailedModal = ({ 
  attachmentFailedModalIsOpen, 
  setAttachmentFailedModalIsOpen 
} : AttachmentFailedModalProps) => {

  const closeAttachmentFailedModal = () => {
    setAttachmentFailedModalIsOpen(false);
  }

  return (
    <BaseModal 
      isOpen={attachmentFailedModalIsOpen}
      onClose={closeAttachmentFailedModal}
      className={styles.attachmentFailedModalModal}
    >
      <div>
        <h4 className={classNames(styles.textCenter, styles.title)}>
          <FormattedMessage id="report.failedAttachment.title" defaultMessage="We submitted your report, but there was a problem uploading your attachment"/>
        </h4>

        <p className={styles.textCenter}>
          <FormattedMessage id="report.failedAttachment"
            defaultMessage="Your attachment failed to upload, but don\'t worry! Your report was successfully submitted. Make sure you remember your {reportKey} and {password} so you can try uploading your attachments again later."
            values={{
              reportKey: (<span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="report.failedAttachment.reportKey" defaultMessage="Report Key"/></span>),
              password: (<span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="report.failedAttachment.password" defaultMessage="Password"/></span>)
            }}/>
        </p>

        <Button style={ButtonStyle.Secondary} 
          className={styles.okayButton} 
          onClick={closeAttachmentFailedModal}
        >
          <FormattedMessage id="button.okay" defaultMessage="Okay"/>
        </Button>
      </div>
    </BaseModal>
  );
}
