import React, { ReactNode, useState } from "react";
import styles from './styles.module.scss';
import { ArrowButton, ArrowDirection, ButtonStyle, ArrowPosition, Button, LinkButton } from "components/Buttons/Button";
import { FormattedMessage } from 'react-intl';
import classNames from "classnames";
import { WhiteCheckIcon } from "components/Icons";
import { RoutePaths } from "App/routing";
import { ProgressBar } from "components/ProgressBar";
import { DownloadModal } from "components/Modals/DownloadModal";
import { SaveAndDownloadJournal } from "components/Common/SaveAndDownloadJournal";

export interface ReportPrevNextProps {
  onPreviousSectionClick: () => void,
  onNextSectionClick: () => void,
  image: ReactNode,
  isFirstPage: boolean,
  isLastPage: boolean,
  previousSectionTitle?: string,
  nextSectionTitle?: string,
  alreadySubmitted?: boolean,
  currentSectionIndex: number
}

export const ReportPrevNext = ( { 
  onPreviousSectionClick,
  onNextSectionClick,
  image,
  isFirstPage,
  isLastPage,
  previousSectionTitle,
  nextSectionTitle,
  alreadySubmitted,
  currentSectionIndex
} : ReportPrevNextProps ) => {
  
  return (
    <div className={styles.reportPrevNextContainer}>
      <div className={styles.startReport}>
        <div className={styles.illustration}>{image}</div>

        {!isFirstPage && (
          <ArrowButton clickAction={onPreviousSectionClick}
            arrowDirection={ArrowDirection.Up} 
            arrowPosition={ArrowPosition.Right} 
            style={ButtonStyle.Secondary}
            className={styles.prevButton}>
              {previousSectionTitle}
          </ArrowButton>
        )}
      </div>

      {isLastPage 
        ? (
            <LinkButton to={RoutePaths.reportPath} 
              style={ButtonStyle.SkinnyPrimary}
              className={classNames(styles.nextButton, styles.completeButton)}>
                <WhiteCheckIcon className={styles.whiteCheckIcon}/>
                {nextSectionTitle}
            </LinkButton>
          ) 
        : (
            <ArrowButton arrowDirection={ArrowDirection.Down} 
              clickAction={onNextSectionClick} 
              arrowPosition={ArrowPosition.Right} 
              style={ButtonStyle.Primary}
              className={styles.nextButton}>
                {nextSectionTitle}
            </ArrowButton>
        )
      }
      <SaveAndDownloadJournal />
      {alreadySubmitted && <div className={styles.dontSaveButtonContainer}>
              <LinkButton 
                style={ButtonStyle.DarkPrimaryUnderline}
                to={RoutePaths.updateSubmittedReport}
                className={ButtonStyle.Full}>
                <FormattedMessage id="report.update" defaultMessage="Review Report" />
              </LinkButton>
      </div>}
      <ProgressBar currentSectionIndex={currentSectionIndex}/>
    </div>
  );
}
