import React, { ReactNode } from "react";
import styles from "./styles.module.scss";
import Modal from 'react-modal';
import {CloseButtonIcon} from "../../Icons";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface BaseModalProps extends IModalProps {
  titleId?: string;
  title?: ReactNode;
  children?: ReactNode;
  showCloseButton?: boolean;
  centerTitle?: boolean;
  icon?: ReactNode;
  className?: string;
  titleStyle?: string;
}

export const BaseModal = ({ isOpen, titleId, title, children, onClose, showCloseButton = true, centerTitle = false, icon, className, titleStyle } : BaseModalProps) => {

  const getTitleStyle = () => {
    return titleStyle ?
      titleStyle
      :
      centerTitle ? styles.centerTitle : styles.title;
  }

  return (
    <Modal isOpen={isOpen}
      className={classNames(styles.modal, className)}
      onRequestClose={onClose} 
      ariaHideApp={false}>
      { showCloseButton && <div className={styles.closeIcon}><CloseButtonIcon onClick={onClose}/></div> }
      <div>
        {icon}
        <h5 className={getTitleStyle()}>
          {titleId ? <FormattedMessage id={titleId}/> : title}
        </h5>
        {children}
      </div>
    </Modal>
  );
}
