import classNames from "classnames";
import { vestaExternalLinks } from "lib/config";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from './styles.module.scss';

interface FooterProps {
  isReportForm?: boolean;
  isBreak?: boolean;
}

const Footer = ({isReportForm, isBreak} : FooterProps) => {
  return (
    <footer className={classNames(styles.footer, 
                      isReportForm && styles.reportForm, 
                      isBreak && styles.reportBreak)}>
      <a target="_blank" href={vestaExternalLinks.termsOfConditions}>
        <FormattedMessage id="vesta.termsOfUse" defaultMessage="Terms of Use"/>
      </a>
      <a target="_blank" href={vestaExternalLinks.privacyPolicy}>
        <FormattedMessage id="vesta.privacyPolicy" defaultMessage="Privacy Policy"/>
      </a>
    </footer>
  );
}

export default Footer;
