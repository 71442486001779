import React, { useEffect, useMemo, useState } from "react";
import { ToggleBoolean } from "components/Forms/Toggle/ToggleBoolean";
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportData, ReportFields, useEditReportState, useMergeEditReportState } from "context/editReport";
import styles from './styles.module.scss';
import { isUndefined } from 'lib/commonFunctions';
import { AgeRange, useEditUserState, useMergeEditUserState, UserFields, UserInfo } from "context/editUser";
import { ContactTypes, isValidEmail } from "../MethodOfContact";
import { TextField, TextFieldTypes } from "components/Forms/TextField";
import classNames from "classnames";
import { ReportPathType } from "pages/ReportPath";
import { AttachReportToggle } from "./AttachReportToggle";
import useHasOrganizationTypes from "lib/useHasOrganizationsTypes";
import useOrganizations from "lib/useOrganizations";

interface ConsentFieldsProps {
  reviewReportButtonClicked: boolean;
  openCampusSupportModalIsOpen: () => void;
  locationExists: boolean;
  openRequiresLocationModal: () => void;
  openRelationshipToOffenderModalIsOpen: () => void;
}

export const ConsentFields = ({
  reviewReportButtonClicked,
  openCampusSupportModalIsOpen,
  locationExists,
  openRequiresLocationModal,
  openRelationshipToOffenderModalIsOpen 
}: ConsentFieldsProps) => {
  const intl = useIntl();
  const userInfo: UserInfo = useEditUserState();
  const mergeUserData = useMergeEditUserState();
  const reportData: ReportData = useEditReportState();
  const mergeReportData = useMergeEditReportState();
  const hasPartneredOrganizationTypes = useHasOrganizationTypes({ isPartnered: true });
  const { supportOrganization, campusSupportOrganization } = useOrganizations();

  useEffect(() => {
    if (!reportData.allowPoliceContact && !reportData.allowSupportCenterContact && !reportData.allowVestaContact  && !reportData.allowCampusContact) {
      // empty contact fields

      mergeUserData({ 
        [UserFields.MethodOfContact]: "",
        [UserFields.Email]: "",
        [UserFields.PhoneNumber]: "",
        [UserFields.FullName]: "",
      });
    }
  }, [reportData.allowPoliceContact, reportData.allowSupportCenterContact, reportData.allowVestaContact, reportData.allowCampusContact]);

  useEffect(() => {
    // if user wants encrypted key emailed but hasn't selected a method of contact of type "Email" or "Both", set it to "Email"
    if (reportData.allowVestaContact && !((userInfo.methodOfContact === ContactTypes.Email) || (userInfo.methodOfContact === ContactTypes.Both))) {
      mergeUserData({ [UserFields.MethodOfContact]: ContactTypes.Email });
    }
  }, [reportData.allowVestaContact]);

  const isReportPathAnonymous = (reportData.reportPathType === ReportPathType.ReportAnonymously);
  const isYoungerThanSixteen = (userInfo.ageRange === AgeRange.YoungerThanSixteen);
  const isAnonymousAndOverSixteen = isReportPathAnonymous && !isYoungerThanSixteen;

  const updateConsents = (consentValue: string | boolean, consentKey: string) => {

    if(consentValue && ReportFields.AllowPoliceContact === consentKey  && !locationExists) {
      openRequiresLocationModal(); 
    }

    if(consentValue && ReportFields.AllowPoliceContact === consentKey) {
      openRelationshipToOffenderModalIsOpen(); 
    }

    if (typeof consentValue === "boolean" && ReportFields.AllowSupportCenterContact === consentKey) {
      mergeReportData({
        [ReportFields.AllowSupportCenterContact]: consentValue,
        [ReportFields.AllowSupportCenterReportDetails]: consentValue ? undefined : false,
        [ReportFields.SupportOrganization]: consentValue ? supportOrganization : undefined,
      });
    } else if (typeof consentValue === "boolean" && ReportFields.AllowCampusContact === consentKey) {
      mergeReportData({ 
        [ReportFields.AllowCampusContact]: consentValue,
        [ReportFields.AllowCampusReportDetails]: consentValue ? undefined : false,
        [ReportFields.CampusSupportOrganization]: consentValue ? campusSupportOrganization : undefined,
      });
    } else {
      mergeReportData({
        [`${consentKey}` as keyof ReportData]: consentValue,
      });
    }
  }

  return (
    <div className={styles.consentFieldsContainer}>
      <div className={styles.consentFields}>

        {/* CAMPUS SUPPORT CONSENT TOGGLE */}
        {hasPartneredOrganizationTypes.campusSupport && campusSupportOrganization &&
          <ToggleBoolean label={
            <FormattedMessage id="report.allowCampusContact" 
              values={{
                campusSexualViolenceOffice: 
                  <span className={classNames(styles.attention, styles.vestaPink)}>
                    <FormattedMessage id="report.allowCampusContact.campusSexualViolenceOffice"/>
                  </span>,
                campusName: campusSupportOrganization.organizationName
              }} 
            />}
            value={reportData.allowCampusContact}
            groupName={ReportFields.AllowCampusContact}
            onClick={updateConsents}
            required={true} 
            className={classNames(styles.consentField, isAnonymousAndOverSixteen && styles.displayNone)}
            errorMessage={reportData.acknowledgementOfConsent && isUndefined(reportData.allowCampusContact) && (
              <FormattedMessage id="personalInfo.form.error.consentRequired" defaultMessage="Please let us know if you consent to be contacted"/>
            )}>
          </ToggleBoolean>
        }
        {reportData.allowCampusContact &&
          <AttachReportToggle 
            reportField={ReportFields.AllowCampusReportDetails}
          />
        }

        {/* SUPPORT CONSENT TOGGLE */}
        {hasPartneredOrganizationTypes.support &&
          <ToggleBoolean label={
            <FormattedMessage id="report.allowSupportCenterContact" 
              values={{
                supportCenter: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="report.allowSupportCenterContact.supportCenter"/></span>
              }} 
              defaultMessage="Do you want access to a support center?"
            />}
            value={reportData.allowSupportCenterContact}
            groupName={ReportFields.AllowSupportCenterContact}
            onClick={updateConsents}
            required={true} 
            className={classNames(styles.consentField, isAnonymousAndOverSixteen && styles.displayNone)}
            errorMessage={reportData.acknowledgementOfConsent && isUndefined(reportData.allowSupportCenterContact) && (
              <FormattedMessage id="personalInfo.form.error.consentRequired" defaultMessage="Please let us know if you consent to be contacted"/>
            )}>
          </ToggleBoolean>
        }
        {reportData.allowSupportCenterContact &&
          <AttachReportToggle 
            reportField={ReportFields.AllowSupportCenterReportDetails}
          />
        }
        
        {/* POLICE CONSENT TOGGLE */}
        {hasPartneredOrganizationTypes.police &&
          <ToggleBoolean label={
            <FormattedMessage id="report.allowPoliceContact" 
              values={{
                police: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="report.allowPoliceContact.police"/></span>,
              }} 
              defaultMessage="Allow police to contact you?"
            />}
            value={reportData.allowPoliceContact}
            groupName={ReportFields.AllowPoliceContact}
            onClick={updateConsents}
            required={true}
            className={classNames(styles.consentField, isAnonymousAndOverSixteen && styles.displayNone)}
            errorMessage={reportData.acknowledgementOfConsent && isUndefined(reportData.allowPoliceContact) && (
              <FormattedMessage id="personalInfo.form.error.consentRequired" defaultMessage="Please let us know if you consent to be contacted"/>
            )}>
          </ToggleBoolean>
        }

        {/* ENCRYPTION KEY EMAIL CONSENT TOGGLE */}
        <ToggleBoolean label={
          reportData.allowVestaContact === false ?
          <FormattedMessage id="report.noVestaContactAllowed" 
            defaultMessage="Please make sure you remember your encrypted key because it will not be emailed to you."
          />
          :
          <FormattedMessage id="report.allowVestaContact" 
            values={{ 
              encryptedKey: <span className={classNames(styles.attention, styles.vestaPink)}><FormattedMessage id="report.allowVestaContact.encryptedKey"/></span> 
            }} 
            defaultMessage="Do you want your encrypted key to access your report emailed to you?"
          />
          }
          value={reportData.allowVestaContact}
          groupName={ReportFields.AllowVestaContact}
          onClick={updateConsents}
          required={true} 
          className={styles.consentField}
          errorMessage={reportData.acknowledgementOfConsent && isUndefined(reportData.allowVestaContact) && (
            <FormattedMessage id="personalInfo.form.error.consentRequired" defaultMessage="Please let us know if you consent to be contacted"/>
          )}>
        </ToggleBoolean>

        <TextField 
          label={<FormattedMessage id="report.email" defaultMessage="Email"/>} 
          type={TextFieldTypes.InlineText} 
          name="email" 
          placeholder={intl.formatMessage({ id: "report.email", defaultMessage: "Email" })} 
          value={userInfo.email} 
          onChange={(email) => { mergeUserData({ email }); }}
          required={reportData.allowVestaContact}
          className={classNames(styles.field, !isAnonymousAndOverSixteen && styles.displayNone)} 
          labelClassName={styles.labels}
          errorClassName={styles.errorMessage}
          errorMessage={
            reviewReportButtonClicked && !isValidEmail(userInfo.email) && 
            <FormattedMessage id="personalInfo.form.error.invalidEmail" defaultMessage="Must be a valid email"/>
          }/>
      </div>
    </div>
  );
}
