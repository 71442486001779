import { useCallback, useEffect, useRef, useState } from "react";
import useScript from './useScript';
import { debounce } from "lodash";

// modified from https://sebastiandedeyne.com/writing-a-custom-react-hook-google-places-autocomplete/

export const useGoogleMaps = (googleAPIKey?: string) => {
    if(!googleAPIKey) {
   //     throw new Error('no API for google Maps found!');
    }

    return useScript(`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`);
};

export const useAddressPredictions = (input: string, googleMapsLoaded: boolean): [Array<any>, boolean, (placeId: string) => any] => {
  const [predictions, setPredictions] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

    // Ref to Google autcomplete. we don't know their implementation fully.
  const autocomplete = useRef<any>(null);
  const places = useRef<any>(null);
  const sessionToken = useRef<any>(null);
  const globalWindow = useRef<any>(window);

  const getDetails = useCallback((placeId: string) => {
    if (places !== null && places.current && placeId) {
         return new Promise((resolve, reject) => {
           const currentSessionToken = sessionToken.current;
           sessionToken.current = new globalWindow.current.google.maps.places.AutocompleteSessionToken();

           places.current.getDetails(
           { 
             placeId,
             sessionToken: currentSessionToken
           },
           (result: any) => {
             // get new token for future searches.
            const latlng = result.geometry.location.toJSON();
            resolve({ 
              result,
              latitude: latlng.lat,
              longitude: latlng.lng,
              description: result.formatted_address,
            });
           }
         )
          });;
    } else {
      // not ready yet, just resolve blank.
      return Promise.resolve({});
    }
  },[places]);


  const getPlacePredictions = (input: string) => {
    if (autocomplete !== null && autocomplete.current) {
      if(input) {
         setLoading(true);

         autocomplete.current.getPlacePredictions(
           { 
             input,
             sessionToken: sessionToken.current,
             componentRestrictions: {
               // country: 'ca'
             }
           },
           (predictions: Array<any>) => {
             setPredictions(predictions);
             setLoading(false);
           }
         );
      } else {
        setLoading(false);
      }
    }
  };

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
);

  // load autocomplete
  useEffect(() => {
    if (!autocomplete.current && googleMapsLoaded) {
      sessionToken.current = new globalWindow.current.google.maps.places.AutocompleteSessionToken();
      autocomplete.current = new globalWindow.current.google.maps.places.AutocompleteService();
      places.current = new globalWindow.current.google.maps.places.PlacesService(document.createElement('div'));

      // load address if it exists
      if (input) {
        debouncedGetPlacePredictions(input);
      }
    } else {
    }
  }, [googleMapsLoaded, input, debouncedGetPlacePredictions]);

  // update predictions
  useEffect(() => {
    debouncedGetPlacePredictions(input);
  }, [input, debouncedGetPlacePredictions]);

  return [predictions, loading, getDetails];
}
