import { logGaEvent } from "lib/googleAnalytics";

export interface IncludedDownloadPages {
  aboutIncident: boolean,
  unknownOffender: boolean,
  knownOffender: boolean,
  sensoryExperience: boolean,
  impactOfExperience: boolean,
}

export interface DownloadInfo {
  includedDownloadPages: IncludedDownloadPages,
  location: string;
}

export enum JournalAccessSources {
  connectThroughJournalButton = 'connect_through_journal_button',
  documentPageAccessJournalButton = 'document_page_access_journal_button',
  clickthroughPageAccessJournalButton = 'clickthrough_page_access_journal_button',
  homeHeaderLink = 'home_header_link',
  surveyHeaderLink = 'survey_header_link',
}

export interface JournalAccessInfo {
  source: JournalAccessSources
}

const recordDownload = async (data: DownloadInfo) => {
  logGaEvent('landing_page_download', {
    includes_about_incident_page: data.includedDownloadPages.aboutIncident || false,
    includes_unknown_offender_page: data.includedDownloadPages.unknownOffender || false,
    includes_known_experience_page: data.includedDownloadPages.knownOffender || false,
    includes_sensory_experience_page: data.includedDownloadPages.sensoryExperience || false,
    includes_impact_of_experience_page: data.includedDownloadPages.impactOfExperience || false,
    location: data.location,
  });
};

const recordJournalAccess = async (data: JournalAccessInfo) => {
  logGaEvent('journal_access', { source: data.source });
};

export {
  recordDownload,
  recordJournalAccess
};
